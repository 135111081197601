import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../../context/GameContext"
import { FaCheckCircle, FaClock } from "react-icons/fa"
import { GetTimeDifference } from "../../../Notification/GetTimeDifference"

type SemiFinalType = {
    CurrentStage: number,
    PlayerState: number,
    FreezeTimeEnd: string | null
}

export const SemiFinal = ({ CurrentStage, PlayerState, FreezeTimeEnd }: SemiFinalType) => {
    const { gameAssets } = useGame()

    const showRedBG = CurrentStage === 1 && FreezeTimeEnd
    const showGreenBG = (CurrentStage === 2 && FreezeTimeEnd) || (CurrentStage > 2)

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Box backgroundColor={(showRedBG && "#cb3e50") || (showGreenBG && "#51ea56") || undefined} borderRadius={"50%"} p={"1vw"}>
                {
                    (CurrentStage === 1 || (CurrentStage === 2 && !FreezeTimeEnd)) &&
                    <FaClock />
                }
                {
                    ((CurrentStage === 2 && FreezeTimeEnd) || CurrentStage > 2) &&
                    <FaCheckCircle />
                }
            </Box>
            <Box textColor={"#e6d1a4"} textDecoration={CurrentStage === 2 && FreezeTimeEnd !== "0001-01-01T00:00:00Z" ? "line-through" : undefined}>
                Semi Final
            </Box>
            <Image
                src={gameAssets.gameLogDivider}
                alt="game-log-divider"
                width={"50%"}
            />
            {
                CurrentStage === 1 && PlayerState === 5 && FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    The Semi Final will begin in
                    <GetTimeDifference finishTime={FreezeTimeEnd} />
                </Box>
            }
            {
                CurrentStage === 2 && PlayerState === 5 && !FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Stay Focus! Waiting for other matches to end!
                </Box>
            }
            {
                (CurrentStage === 2 || CurrentStage === 3) && FreezeTimeEnd &&
                <Box fontSize={"1.25vw"} textColor={"#51ea56"}>
                    You have passed the Semi Final.
                </Box>
            }
        </Box >
    )
}