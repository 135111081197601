import { Box } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"
import { getDiceResult } from "../../../utils/assets/getDiceResult"

export const PlayerDiceResult = ({ turnIndex }: { turnIndex: number }) => {
    const { gameData, dice } = useSync()
    const { gameAssets } = useGame()

    if (gameData.TurnIndex === turnIndex && dice && dice.turnIndex === gameData.TurnIndex) {
        return (
            <Box className='dice-result'>
                <img src={getDiceResult(dice?.diceOne, gameAssets)} alt='dice' width={"50%"} />
                <img src={getDiceResult(dice?.diceTwo, gameAssets)} alt='dice' width={"50%"} />
            </Box>
        )
    } else {
        return null
    }
}