export const getHoverEffectClassName = (turnIndex: number) => {
  if (turnIndex === 0) {
    return "red-hover-effect";
  } else if (turnIndex === 1) {
    return "blue-hover-effect";
  } else if (turnIndex === 2) {
    return "pink-hover-effect";
  } else if (turnIndex === 3) {
    return "gray-hover-effect";
  } else {
    return "";
  }
};

export const getSetupEffectClassName = (turnIndex: number) => {
  if (turnIndex === 0) {
    return "red-setup-effect";
  } else if (turnIndex === 1) {
    return "blue-setup-effect";
  } else if (turnIndex === 2) {
    return "pink-setup-effect";
  } else if (turnIndex === 3) {
    return "gray-setup-effect";
  } else {
    return "";
  }
};
