import { useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, useToast, Box } from '@chakra-ui/react'
import { Button, RadioGroup, Stack, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { CreateRoom } from '../../../../requests/RoomRequests';
import { useNavigate } from 'react-router-dom';
import { useGame } from '../../../../context/GameContext';
import { DashboardCloseButton } from '../../General/DashboardCloseButton';
import { FaInfoCircle } from 'react-icons/fa';
import { ReJoinButton } from './ReJoinButton';
import { CreateGameButton } from './CreateGameButton';
import { SocketType } from '../../../../utils/types/types';
import { SendAnalytiActionEvent } from '../../../../socket/sendMessage/SendAnalyticActionEvent';

export const CreateGameModal = ({ socket }: { socket: SocketType }) => {
    const { setGameID, dashboardAssets } = useGame()

    const { isOpen, onOpen, onClose } = useDisclosure({
        onOpen() {
            SendAnalytiActionEvent(socket, "CreateGameModalOpen")
        }
    })

    const navigate = useNavigate()
    const toast = useToast()

    const [playerNumber, setPlayerNumber] = useState<number>(3)
    const [gameType, setGameType] = useState<string>("free")
    const [roomName, setRoomName] = useState<string>("")

    const handleCreate = async () => {
        const response = await CreateRoom(roomName, playerNumber)
        if (response.error) {
            toast({
                description: "You are already in game.",
                status: "error",
                duration: 2000
            })
        }
        else if (response) {
            setGameID(response.id)
            navigate(`/game`)
        }
    }

    return (
        <>
            <CreateGameButton onOpen={onOpen} />
            <ReJoinButton />

            <Modal
                isCentered
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset={"slideInBottom"}
                scrollBehavior={"inside"}
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                    maxH={"100vh"}
                >
                    <ModalHeader className='menu-modal-headers'>
                        Create Room
                    </ModalHeader>

                    <DashboardCloseButton onClose={onClose} />

                    <ModalBody>
                        {/* Room Name Input */}
                        <FormControl mb={3}>
                            <FormLabel htmlFor='roomNameInput'>Room Name</FormLabel>
                            <Input
                                id="roomNameInput"
                                placeholder='Room Name'
                                onChange={(e) => setRoomName(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        {/* Game Type Input */}
                        <FormControl mb={3}>
                            <FormLabel htmlFor='radio'>Game Type</FormLabel>
                            <RadioGroup
                                id='gameTypeInput'
                                className='d-flex justify-content-center'
                                onChange={(value) => setGameType(value)}
                                value={gameType}
                            >
                                <Stack direction='row' spacing={5}>
                                    <Button
                                        leftIcon={<img src={dashboardAssets.freeModeIcon} alt='1v1' />}
                                        variant={gameType === "free" ? "solid" : "outline"}
                                        colorScheme={gameType === "free" ? "green" : "blackAlpha"}
                                        onClick={() => setGameType("free")}
                                    >
                                        Free Game
                                    </Button>
                                    <Button
                                        leftIcon={<img src={dashboardAssets.tokenModeIcon} alt='3-player' />}
                                        variant={gameType === "token" ? "solid" : "outline"}
                                        colorScheme={gameType === "token" ? "green" : "blackAlpha"}
                                        onClick={() => setGameType("token")}
                                        isDisabled
                                    >
                                        Token Game
                                    </Button>
                                </Stack>
                            </RadioGroup>
                        </FormControl>

                        {/* Number of Player Input */}
                        <FormControl>
                            <FormLabel htmlFor='radio'>Number of Player</FormLabel>
                            <RadioGroup
                                id='numberOfPlayersInput'
                                className='d-flex justify-content-center'
                                onChange={(value) => setPlayerNumber(Number(value))}
                                value={playerNumber.toString()}
                            >
                                <Stack direction='row' spacing={5}>
                                    <Button
                                        leftIcon={<img src={dashboardAssets.twoPlayerIcon} alt='1v1' width={"60px"} />}
                                        variant={playerNumber === 2 ? "solid" : "outline"}
                                        colorScheme={playerNumber === 2 ? "blue" : "blackAlpha"}
                                        onClick={() => setPlayerNumber(2)}
                                    >
                                        1 v 1
                                    </Button>
                                    <Button
                                        leftIcon={<img src={dashboardAssets.threePlayerIcon} alt='3-player' width={"40px"} />}
                                        variant={playerNumber === 3 ? "solid" : "outline"}
                                        colorScheme={playerNumber === 3 ? "blue" : "blackAlpha"}
                                        onClick={() => setPlayerNumber(3)}
                                    >
                                        3 Player
                                    </Button>
                                    <Button
                                        leftIcon={<img src={dashboardAssets.fourPlayerIcon} alt='4-player' width={"50px"} />}
                                        variant={playerNumber === 4 ? "solid" : "outline"}
                                        colorScheme={playerNumber === 4 ? "blue" : "blackAlpha"}
                                        onClick={() => setPlayerNumber(4)}
                                    >
                                        4 Player
                                    </Button>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2} textColor={"InfoText"}>
                            <FaInfoCircle className="me-2" />
                            {
                                playerNumber > 2 ?
                                    "10 VP for Victory, 7 Card Discard Limit" :
                                    "15 VP for Victory, 9 Card Discard Limit"
                            }
                        </Box>
                    </ModalBody>

                    <ModalFooter pt={2}>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={roomName.length < 3 || roomName.length > 23}
                            onClick={handleCreate}
                        >
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}