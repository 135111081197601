import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Portal, IconButton, Box, PopoverArrow, useDisclosure } from '@chakra-ui/react'
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { SocketType } from "../../../utils/types/types"
import { useGame } from '../../../context/GameContext'
import { useEffect, useState } from 'react'
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent';

export const Emoji = ({ socket }: { socket: SocketType }) => {
    const { gameID, gameAssets } = useGame()
    const { isOpen, onToggle, onClose } = useDisclosure()

    const emoji = <img src={gameAssets.emojiButton} alt='emoji-icon' width={"90%"} />

    const [coolTime, setCoolTime] = useState(0)

    useEffect(() => {
        if (coolTime > 0) {
            const timeout = setTimeout(() => {
                setCoolTime(prevState => prevState - 1)
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [coolTime])

    const handleEmojiSend = (emojiType: number) => {
        const eventData = {
            id: gameID,
            emoji: emojiType
        }
        SendGameEvent(socket, "EmojiMessage", eventData)
        setCoolTime(30)
    }

    return (
        <Popover
            placement={"top"}
            isOpen={isOpen}
            onClose={onClose}
        >
            <PopoverTrigger>
                <IconButton
                    zIndex={1}
                    position={"fixed"}
                    left={"16%"}
                    bottom={"1%"}
                    width={"4%"}
                    height={"7%"}
                    fontSize={"2.5vw"}
                    backgroundColor={"transparent"}
                    colorScheme='yellow'
                    aria-label="emoji-button"
                    onClick={onToggle}
                    icon={emoji}
                />
            </PopoverTrigger>
            <Portal>
                <PopoverContent
                    w={"15vw"}
                    height={"fit-content"}
                    background={"linear-gradient(rgba(61, 51, 25,1), rgba(61, 51, 25,0.75))"}
                    border={"0.1vw solid #A58E54"}
                    borderRadius={"0.5vw"}
                    textColor={"#825128"}
                >
                    <PopoverArrow />
                    <PopoverBody
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={"column"}
                    >
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-around"}>
                            <IconButton
                                aria-label='happy'
                                backgroundColor={"transparent"}
                                colorScheme='yellow'
                                height={"8vh"}
                                isDisabled={coolTime !== 0}
                                onClick={() => handleEmojiSend(0)}
                                icon={<img src={gameAssets.happyEmoji} alt='happy' width={"80%"} />}
                            />
                            <IconButton
                                aria-label='crying'
                                backgroundColor={"transparent"}
                                colorScheme='yellow'
                                height={"8vh"}
                                isDisabled={coolTime !== 0}
                                onClick={() => handleEmojiSend(1)}
                                icon={<img src={gameAssets.cryingEmoji} alt='crying' width={"80%"} />}
                            />
                            <IconButton
                                aria-label='evil'
                                backgroundColor={"transparent"}
                                colorScheme='yellow'
                                height={"8vh"}
                                isDisabled={coolTime !== 0}
                                onClick={() => handleEmojiSend(2)}
                                icon={<img src={gameAssets.evilEmoji} alt='evil' width={"80%"} />}
                            />
                        </Box>

                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-evenly"}>
                            <IconButton
                                aria-label='suprise'
                                backgroundColor={"transparent"}
                                colorScheme='yellow'
                                height={"8vh"}
                                isDisabled={coolTime !== 0}
                                onClick={() => handleEmojiSend(3)}
                                icon={<img src={gameAssets.suprisedEmoji} alt='suprise' width={"80%"} />}
                            />
                            <IconButton
                                aria-label='annoyed'
                                backgroundColor={"transparent"}
                                colorScheme='yellow'
                                height={"8vh"}
                                isDisabled={coolTime !== 0}
                                onClick={() => handleEmojiSend(4)}
                                icon={<img src={gameAssets.annoyedEmoji} alt='annoyed' width={"80%"} />}
                            />
                            <IconButton
                                aria-label='angry'
                                backgroundColor={"transparent"}
                                colorScheme='yellow'
                                height={"8vh"}
                                isDisabled={coolTime !== 0}
                                onClick={() => handleEmojiSend(5)}
                                icon={<img src={gameAssets.angryEmoji} alt='angry' width={"80%"} />}
                            />
                        </Box>

                        {
                            coolTime !== 0 &&
                            <CircularProgress
                                isIndeterminate
                                m={"auto"}
                                size={"3vw"}
                                thickness='0.5vw'
                                color='yellow.500'
                                trackColor='#6D5535'
                            >
                                <CircularProgressLabel
                                    fontSize={"1.25vw"}
                                    fontWeight={"bolder"}
                                >
                                    {coolTime}
                                </CircularProgressLabel>
                            </CircularProgress>
                        }
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover >
    )
}