import { Button, Box, Tooltip } from "@chakra-ui/react"
import { useState } from "react";
import { useGame } from "../../../context/GameContext";
import { PlayerTradeResponse } from './PlayerTradeResponse';
import { PlayerResCard } from './PlayerResCard';
import { PlayerTradeInputValidation } from './PlayerTradeInputValidation';
import { ResourceNumbers, SocketType } from '../../../utils/types/types';
import { useSync } from '../../../context/SyncContext';
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent";

export const PlayerTrade = ({ socket, onClose }: { socket: SocketType, onClose: () => void }) => {
    const { gameID, gameAssets, enableCounterTrade, setEnableCounterTrade, setHaveCounterTrade } = useGame()
    const { resources, players, responses, trade, gameData, myGameData } = useSync()

    const [disabled, setDisabled] = useState<boolean>(true)

    const [wantedCards, setWantedCards] = useState<ResourceNumbers>({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
    const [givenCards, setGivenCards] = useState<ResourceNumbers>({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })

    PlayerTradeInputValidation({
        resources,
        wantedCards,
        givenCards,
        setDisabled
    })

    // Send Trade Offer
    const handleTradeOffer = async () => {
        const eventData = {
            id: gameID,
            offered: {
                ...(givenCards.Wood > 0 ? { wood: givenCards.Wood } : {}),
                ...(givenCards.Brick > 0 ? { brick: givenCards.Brick } : {}),
                ...(givenCards.Sheep > 0 ? { sheep: givenCards.Sheep } : {}),
                ...(givenCards.Wheat > 0 ? { wheat: givenCards.Wheat } : {}),
                ...(givenCards.Ore > 0 ? { ore: givenCards.Ore } : {})
            },
            wanted: {
                ...(wantedCards.Wood > 0 ? { wood: wantedCards.Wood } : {}),
                ...(wantedCards.Brick > 0 ? { brick: wantedCards.Brick } : {}),
                ...(wantedCards.Sheep > 0 ? { sheep: wantedCards.Sheep } : {}),
                ...(wantedCards.Wheat > 0 ? { wheat: wantedCards.Wheat } : {}),
                ...(wantedCards.Ore > 0 ? { ore: wantedCards.Ore } : {})
            }
        }
        SendGameEvent(socket, "TradeOffer", eventData)

        if (gameData.Status === 8) {
            setHaveCounterTrade(true)
            setEnableCounterTrade(false)
        }

        setWantedCards({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
        setGivenCards({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
    }

    const isOffererMe = trade && myGameData && trade.offerer === myGameData.address

    if (!trade || enableCounterTrade) {
        return (
            <Box>
                <Box display={"flex"} p={"0.5vw 0.5vw 0.5vw 0vw"} backgroundColor={"#5B472D"}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Tooltip label="Cards that you want">
                            <img src={gameAssets.receiveIcon} alt="receive-icon" width={"50%"} />
                        </Tooltip>
                    </Box>
                    <PlayerResCard name={"Wood"} iconSrc={gameAssets.woodIcon} resourceName="wood-card-taken" count={wantedCards.Wood} onChange={setWantedCards} borderColor="green" />
                    <PlayerResCard name={"Brick"} iconSrc={gameAssets.brickIcon} resourceName="brick-card-taken" count={wantedCards.Brick} onChange={setWantedCards} borderColor="green" />
                    <PlayerResCard name={"Sheep"} iconSrc={gameAssets.sheepIcon} resourceName="sheep-card-taken" count={wantedCards.Sheep} onChange={setWantedCards} borderColor="green" />
                    <PlayerResCard name={"Wheat"} iconSrc={gameAssets.wheatIcon} resourceName="wheat-card-taken" count={wantedCards.Wheat} onChange={setWantedCards} borderColor="green" />
                    <PlayerResCard name={"Ore"} iconSrc={gameAssets.oreIcon} resourceName="ore-card-taken" count={wantedCards.Ore} onChange={setWantedCards} borderColor="green" />
                </Box >
                <Box display={"flex"} p={"0.5vw 0.5vw 0.5vw 0vw"} backgroundColor={"#6D5535"}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Tooltip label="Cards that you give">
                            <img src={gameAssets.giveIcon} alt="receive-icon" width={"50%"} />
                        </Tooltip>
                    </Box>
                    <PlayerResCard name={"Wood"} iconSrc={gameAssets.woodIcon} resourceName="wood-card-given" count={givenCards.Wood} onChange={setGivenCards} borderColor="red" />
                    <PlayerResCard name={"Brick"} iconSrc={gameAssets.brickIcon} resourceName="brick-card-given" count={givenCards.Brick} onChange={setGivenCards} borderColor="red" />
                    <PlayerResCard name={"Sheep"} iconSrc={gameAssets.sheepIcon} resourceName="sheep-card-given" count={givenCards.Sheep} onChange={setGivenCards} borderColor="red" />
                    <PlayerResCard name={"Wheat"} iconSrc={gameAssets.wheatIcon} resourceName="wheat-card-given" count={givenCards.Wheat} onChange={setGivenCards} borderColor="red" />
                    <PlayerResCard name={"Ore"} iconSrc={gameAssets.oreIcon} resourceName="ore-card-given" count={givenCards.Ore} onChange={setGivenCards} borderColor="red" />
                </Box >
                <Box display={"flex"} justifyContent={"center"} mt={"1vh"}>
                    <Button
                        width={"12vw"}
                        height={"5vh"}
                        fontSize={"1.5vw"}
                        backgroundColor={"#73953e"}
                        borderRadius={"0.5vw"}
                        colorScheme='green'
                        isDisabled={disabled}
                        onClick={handleTradeOffer}
                    >
                        Send Offer
                    </Button>
                </Box>
            </Box >
        )
    } else if (trade && isOffererMe) {
        return (
            <PlayerTradeResponse
                responses={responses}
                trade={trade}
                socket={socket}
                gameID={gameID}
                players={players}
                gameData={gameData}
                myGameData={myGameData}
                onClose={onClose}
            />
        )
    } else {
        return null
    }
}