import { useEffect } from "react";
import { useSync } from "../context/SyncContext";
import { SocketType } from "../utils/types/types";

export const LongestRoads = (socket: SocketType) => {
    const { setLongestRoads } = useSync()

    // Update Longest Roads
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "LongestRoads") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setLongestRoads(parsedData.longestRoads);
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}