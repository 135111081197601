export const getSettleCharAnimation = (
  type: number,
  color: number,
  assets: any
) => {
  if (type === 5) {
    if (color === 0) {
      return assets.redMineSettle;
    } else if (color === 1) {
      return assets.blueMineSettle;
    } else if (color === 2) {
      return assets.pinkMineSettle;
    } else if (color === 3) {
      return assets.grayMineSettle;
    } else {
      return "";
    }
  } else if (type === 4) {
    if (color === 0) {
      return assets.redWheatSettle;
    } else if (color === 1) {
      return assets.blueWheatSettle;
    } else if (color === 2) {
      return assets.pinkWheatSettle;
    } else if (color === 3) {
      return assets.grayWheatSettle;
    } else {
      return "";
    }
  } else if (type === 3) {
    if (color === 0) {
      return assets.redSheepSettle;
    } else if (color === 1) {
      return assets.blueSheepSettle;
    } else if (color === 2) {
      return assets.pinkSheepSettle;
    } else if (color === 3) {
      return assets.graySheepSettle;
    } else {
      return "";
    }
  } else if (type === 2) {
    if (color === 0) {
      return assets.redMineSettle;
    } else if (color === 1) {
      return assets.blueMineSettle;
    } else if (color === 2) {
      return assets.pinkMineSettle;
    } else if (color === 3) {
      return assets.grayMineSettle;
    } else {
      return "";
    }
  } else if (type === 1) {
    if (color === 0) {
      return assets.redAxeSettle;
    } else if (color === 1) {
      return assets.blueAxeSettle;
    } else if (color === 2) {
      return assets.pinkAxeSettle;
    } else if (color === 3) {
      return assets.grayAxeSettle;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const getCityCharAnimation = (
  type: number,
  color: number,
  assets: any
) => {
  if (type === 5) {
    if (color === 0) {
      return assets.redMineCity;
    } else if (color === 1) {
      return assets.blueMineCity;
    } else if (color === 2) {
      return assets.pinkMineCity;
    } else if (color === 3) {
      return assets.grayMineCity;
    } else {
      return "";
    }
  } else if (type === 4) {
    if (color === 0) {
      return assets.redWheatCity;
    } else if (color === 1) {
      return assets.blueWheatCity;
    } else if (color === 2) {
      return assets.pinkWheatCity;
    } else if (color === 3) {
      return assets.grayWheatCity;
    } else {
      return "";
    }
  } else if (type === 3) {
    if (color === 0) {
      return assets.redSheepCity;
    } else if (color === 1) {
      return assets.blueSheepCity;
    } else if (color === 2) {
      return assets.pinkSheepCity;
    } else if (color === 3) {
      return assets.graySheepCity;
    } else {
      return "";
    }
  } else if (type === 2) {
    if (color === 0) {
      return assets.redMineCity;
    } else if (color === 1) {
      return assets.blueMineCity;
    } else if (color === 2) {
      return assets.pinkMineCity;
    } else if (color === 3) {
      return assets.grayMineCity;
    } else {
      return "";
    }
  } else if (type === 1) {
    if (color === 0) {
      return assets.redAxeCity;
    } else if (color === 1) {
      return assets.blueAxeCity;
    } else if (color === 2) {
      return assets.pinkAxeCity;
    } else if (color === 3) {
      return assets.grayAxeCity;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
