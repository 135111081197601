import { useEffect } from "react";

export const ZoomEffect = (scale: number, setScale: (value: number) => void) => {
    const handleZoomIn = () => {
        if (scale < 2) {
            setScale(scale + 0.05);
        }
    };

    const handleZoomOut = () => {
        if (scale > 0.75) {
            setScale(scale - 0.05);
        }
    };

    const handleWheel = (event: WheelEvent) => {
        const board = document.getElementById("board");

        if (board && board.contains(event.target as Node)) {
            if (event.deltaY < 0) {
                handleZoomIn();
            } else {
                handleZoomOut();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, [scale]);

    useEffect(() => {
        const checkAndScroll = () => {
            const targetDiv = document.getElementById("tile-9");
            const scrollOptions: any = {
                behavior: "smooth",
                block: "center",
                inline: "center",
            };

            if (targetDiv) {
                targetDiv.scrollIntoView(scrollOptions);
                clearInterval(intervalId);
            }
        };

        const intervalId = setInterval(checkAndScroll, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);
}