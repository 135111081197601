import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"

type CardNameType = {
    IsPrivate: boolean,
    Name: string
}

export const CardName = ({ IsPrivate, Name }: CardNameType) => {
    const { dashboardAssets } = useGame()

    return (
        <Box
            pos={"absolute"}
            textAlign={"center"}
            zIndex={1}
            top={"-7.5%"}
            left={"-1%"}
            w={"102%"}
        >
            <Image
                src={dashboardAssets.tournamentBanner}
                zIndex={1}
                w={"100%"}
                alt='tournament-banner'
            />
            <Box className='tournament-banner-title'>
                {
                    IsPrivate &&
                    <Image src={dashboardAssets.lockIcon}
                        alt='lock-icon'
                        w={"1.4vw"}
                        me={3}
                    />
                }
                {Name}
            </Box>
        </Box>
    )
}