import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Button, useToast } from '@chakra-ui/react'
import { useGame } from '../../../context/GameContext'
import { CreateJoinGame } from '../../../requests/RoomRequests'
import { useNavigate } from 'react-router-dom'

export const DirectJoinGameModal = () => {
    const { directJoinGameID, setDirectJoinGameID, setGameID } = useGame()
    const { isOpen, onClose } = useDisclosure({ isOpen: true })

    const navigate = useNavigate()

    const toast = useToast()

    const handleJoin = async () => {
        if (directJoinGameID) {
            const response = await CreateJoinGame(directJoinGameID)

            if (response.message && response.message === "success") {
                const gameID = directJoinGameID
                setGameID(gameID)
                setDirectJoinGameID(null)
                navigate(`/game`)
            } else if (response.error === "room is full") {
                toast({
                    description: "Room is full.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                })
            } else if (response.error === "user already in another room") {
                toast({
                    description: "You are already in another game.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                })
            }
        }
    }

    if (directJoinGameID) {
        return (
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                isCentered={true}
                motionPreset={"slideInBottom"}
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader className='menu-modal-headers'>
                        Direct Join to the Game
                    </ModalHeader>

                    <ModalBody textAlign={"center"} fontSize={"20px"}>
                        Do you want to directly join to the game?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            me={3}
                            colorScheme='green'
                            onClick={handleJoin}
                        >

                            Join
                        </Button>
                        <Button
                            width={"100%"}
                            backgroundColor={"#E94F4F"}
                            border={"2px solid black"}
                            borderRadius={"10px"}
                            colorScheme='red'
                            onClick={() => {
                                onClose()
                                setDirectJoinGameID(null)
                            }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    } else {
        return null
    }
}