import { useEffect, useRef } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { useSync } from '../../../context/SyncContext';
import { useGame } from '../../../context/GameContext';
import { PlaySoundEffect } from '../../../sounds/playSoundEffect';

export const ResourceCards = () => {
    const { gameAssets } = useGame()
    const { resources } = useSync()

    return (
        <Box className="res-card-box">
            <AnimatedResourceCard value={resources.Wood} icon={gameAssets.woodIcon} label="Wood" />
            <AnimatedResourceCard value={resources.Brick} icon={gameAssets.brickIcon} label="Brick" />
            <AnimatedResourceCard value={resources.Sheep} icon={gameAssets.sheepIcon} label="Sheep" />
            <AnimatedResourceCard value={resources.Wheat} icon={gameAssets.wheatIcon} label="Wheat" />
            <AnimatedResourceCard value={resources.Ore} icon={gameAssets.oreIcon} label="Ore" />
        </Box>
    );
};

const AnimatedResourceCard = ({ value, icon, label }: { value: number, icon: string, label: string }) => {
    const { soundEffects, sfxEnabled, sfxVolume } = useGame()

    const prevValueRef = useRef<number | undefined>();

    const handleAnimation = () => {
        const textElement = document.getElementById(`resource-card-${label.toLowerCase()}`);
        const imgElement = document.getElementById(`resource-card-image-${label.toLowerCase()}`);

        if (textElement) {
            if (prevValueRef.current === undefined) {
                prevValueRef.current = value;
            } else if (value > prevValueRef.current) {
                textElement.classList.add('scale-animator-green');
                showDifference(value - prevValueRef.current, '#00FF7F');

                PlaySoundEffect(soundEffects.collectResourceSFX, sfxVolume, sfxEnabled)

            } else if (value < prevValueRef.current) {
                textElement.classList.add('scale-animator-red');
                showDifference(value - prevValueRef.current, '#F74747');
            }

            setTimeout(() => {
                textElement.classList.remove('scale-animator-green', 'scale-animator-red');
            }, 1000);

            prevValueRef.current = value;
        }

        if (imgElement) {
            imgElement.classList.add('bounce-effect-bouncing');
            setTimeout(() => {
                imgElement.classList.remove('bounce-effect-bouncing');
            }, 2000);
        }
    };

    const showDifference = (difference: number, color: string) => {
        const diffElement = document.createElement('span');
        diffElement.textContent = `${difference > 0 ? '+' : ''}${difference}`;
        diffElement.style.color = color;
        diffElement.classList.add('resource-difference-number')

        document.getElementById(`resource-card-${label.toLowerCase()}`)!.appendChild(diffElement);

        setTimeout(() => {
            diffElement.remove();
        }, 4000);
    };

    useEffect(() => {
        handleAnimation();
    }, [value]);

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            w={"8vw"}
        >
            <Tooltip label={label}>
                <img
                    src={icon}
                    className="bounce-effect"
                    id={`resource-card-image-${label.toLowerCase()}`}
                    width={"50%"}
                />
            </Tooltip>
            <Box
                id={`resource-card-${label.toLowerCase()}`}
                w={"3.5vw"}
                pos={"relative"}
                backgroundColor={"wheat"}
                borderRadius={"0.5vw"}
                fontSize={"2.3vw"}
                fontWeight={"bolder"}
                boxShadow={"inset 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.5)"}
            >
                {value}
            </Box>
        </Box>
    );
};

