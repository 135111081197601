import { useEffect, useState } from 'react';

export const usePWA = () => {
    const [isPWA, setIsPWA] = useState(false);

    useEffect(() => {
        const isStandalone = window.matchMedia('(display-mode: fullscreen)').matches;
        setIsPWA(isStandalone);
    }, []);

    return isPWA;
};