import { useEffect, useState } from "react"
import { useGame } from "../../../../../context/GameContext"
import { getTimeFromMinute } from "../../../../../utils/constants/getTimeFromMinute"
import { Avatar, Box, Image } from "@chakra-ui/react"
import { getAvatarByIndex } from "../../../../../utils/assets/getAvatarByIndex"
import { getVPLabel } from "../../../../../utils/assets/getVPLabel"
import { GetLastGameStats } from "../../../../../requests/TournamentRequests"

type TournamentSummary = {
    gameID: number
}

export const TournamentSummary = ({ gameID }: TournamentSummary) => {
    const { dashboardAssets, gameAssets } = useGame()

    const [playerData, setPlayerData] = useState<any[]>([])
    const [roomStats, setRoomStats] = useState<{
        diceCount: number[],
        dicePercantage: number[],
        gameTime: number,
        numberOfTurns: number
    } | null>(null)

    useEffect(() => {
        const fetchUserStats = async () => {
            const result = await GetLastGameStats(gameID)
            result && setPlayerData(result.user_data)
            result && setRoomStats(result.general_data)
        }

        if (gameID > -1) {
            fetchUserStats()
        }
    }, [gameID])

    if (playerData && roomStats) {
        return (
            <Box className="summary-tab-box">
                <Box display={"flex"} justifyContent={"center"}>
                    {
                        playerData?.map((player, index) => {
                            return (
                                <Box
                                    key={index}
                                    display={"flex"}
                                    flexDir={"column"}
                                    justifyContent={"end"}
                                >
                                    <Box display={"flex"} flexDir={"column"}>
                                        <Avatar
                                            w={"5vw"}
                                            h={"5vw"}
                                            name={player.Username}
                                            src={getAvatarByIndex(player.TurnIndex, dashboardAssets)}
                                            alignSelf={"center"}
                                        />
                                        <Box className={player.VictoryPoints >= 10 ? 'winner-username' : 'loser-username'}>
                                            {player.Username}
                                        </Box>
                                    </Box>
                                    <Box className={player.VictoryPoints >= 10 ? 'winner-stats-panel' : 'loser-stats-panel'}>
                                        {/* Victory Points */}
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <Box className={player.VictoryPoints >= 10 ? 'vp-banner-winner-div' : 'vp-banner-loser-div'}>
                                                <Image
                                                    src={getVPLabel(player.TurnIndex, gameAssets)}
                                                    alt='label'
                                                    w={"100%"}
                                                />
                                                <Box className={player.VictoryPoints >= 10 ? 'winner-vp-text' : 'loser-vp-text'}>
                                                    {player.VictoryPoints}
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Total Longest Road and Largest Army */}
                                        <Box mb={"0.1vw"} textColor={"white"} fontSize={"1.25vw"}>
                                            <Box>Total Score</Box>
                                            <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                                                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                                                    <Image
                                                        src={gameAssets.largestArmyNumberIcon}
                                                        alt='largest-army'
                                                        width={"35%"}
                                                    />
                                                    <Box>
                                                        {player.Armysize}
                                                    </Box>
                                                </Box>

                                                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                                                    <Image
                                                        src={gameAssets.longestRoadNumberIcon}
                                                        alt='longest-road'
                                                        width={"35%"}
                                                    />
                                                    <Box>
                                                        {player.Roadlength}
                                                    </Box>
                                                </Box>

                                                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                                                    <Image
                                                        src={gameAssets.devCardNumberIcon}
                                                        alt='dev-card'
                                                        width={"35%"}
                                                    />
                                                    <Box>
                                                        {player.Devcards}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Total Resource Gathering */}
                                        <Box mb={"0.5vw"} textColor={"white"} fontSize={"1vw"}>
                                            <Box>Total Resource Gathering</Box>
                                            <Box display={"flex"} color={"white"}>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                                                    <Image src={gameAssets.woodIcon} alt='wood-icon' width={"100%"} />
                                                    <Box backgroundColor={"#4C3921"}>
                                                        {player.WoodTotal}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                                                    <Image src={gameAssets.brickIcon} alt='brick-icon' width={"100%"} />
                                                    <Box backgroundColor={"#4C3921"}>
                                                        {player.BrickTotal}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                                                    <Image src={gameAssets.sheepIcon} alt='sheep-icon' width={"100%"} />
                                                    <Box backgroundColor={"#4C3921"}>
                                                        {player.SheepTotal}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                                                    <Image src={gameAssets.wheatIcon} alt='wheat-icon' width={"100%"} />
                                                    <Box backgroundColor={"#4C3921"}>
                                                        {player.WheatTotal}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                                                    <Image src={gameAssets.oreIcon} alt='ore-icon' width={"100%"} />
                                                    <Box backgroundColor={"#4C3921"}>
                                                        {player.OreTotal}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Total Buildings */}
                                        <Box display={"flex"} flexDir={"column"} mb={"0.5vw"} fontSize={"1vw"}>
                                            <Box fontSize={"1vw"} textColor={"white"}>Total Buildings</Box>
                                            <Box display={"flex"} justifyContent={"space-evenly"} color={"white"}>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"} w={"2vw"}>
                                                    <Image
                                                        src={gameAssets.settleNumberIcon}
                                                        alt='wood-icon'
                                                        width={"100%"}
                                                    />
                                                    <Box mt={"auto"} backgroundColor={"#4C3921"}>
                                                        {player.NumberOfSettlements}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"} w={"2vw"}>
                                                    <Image
                                                        src={gameAssets.cityNumberIcon}
                                                        alt='brick-icon'
                                                        width={"100%"}
                                                        mb={"auto"}
                                                    />
                                                    <Box mt={"auto"} backgroundColor={"#4C3921"}>
                                                        {player.NumberOfCities}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDir={"column"} me={"0.25vw"} w={"2vw"}>
                                                    <Image
                                                        src={gameAssets.roadNumberIcon}
                                                        alt='sheep-icon'
                                                        width={"100%"}
                                                        mt={"auto"}
                                                        mb={"auto"}
                                                    />
                                                    <Box mt={"auto"} backgroundColor={"#4C3921"}>
                                                        {player.NumberOfRoads}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>

                <Box display={"flex"} justifyContent={"center"} mt={"0.5vw"} mb={"0.5vw"}>
                    <Image src={gameAssets.largeDivider} alt='divider' />
                </Box>

                {/* Room Stats */}
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Box display={"flex"} flexDir={"column"} fontSize={"1.5vw"} >
                        <Box >
                            Game Time
                        </Box>
                        <Box>
                            {roomStats && getTimeFromMinute(roomStats.gameTime)}
                        </Box>
                    </Box>

                    <Box display={"flex"} flexDir={"column"} ms={"3vw"} me={"3vw"}>
                        <Box fontSize={"1.5vw"}>
                            Dice Moves
                        </Box>
                        <Box display={"flex"} justifyContent={"space-evenly"}>
                            {
                                roomStats.dicePercantage.map((dice, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            display={"flex"}
                                            flexDir={"column"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            me={"0.5vw"}
                                        >
                                            <Box fontSize={"0.75vw"}>
                                                {(dice * 100).toString().slice(0, 4)}%
                                            </Box>
                                            <Box className='stats-dice'>
                                                {index + 2}
                                            </Box>
                                            <Box fontSize={"1.25vw"}>
                                                {roomStats.diceCount[index]}
                                            </Box>
                                        </Box>
                                    )
                                })
                            }

                        </Box>
                    </Box>

                    <Box display={"flex"} flexDir={"column"} fontSize={"1.5vw"}>
                        <Box>
                            Total Turns
                        </Box>
                        <Box>
                            {roomStats && roomStats.numberOfTurns}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return null
    }
}