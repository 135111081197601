import { useEffect } from "react";
import { useSocket } from "../useSocket";
import { useGame } from "../context/GameContext";
import { Sidebar } from "../components/Dashboard/Sidebar/Sidebar"
import { Content } from "../components/Dashboard/Content/Content"
import { LandscapeWarning } from "../components/Dashboard/General/LandscapeWarning";
import { DirectJoinGameModal } from "../components/Dashboard/General/DirectJoinGameModal";
import { AssetLoading } from "../components/Dashboard/General/AssetLoading";
import { JoinDiscordModal } from "../components/Dashboard/General/JoinDiscordModal";
import { dashboardAssetsList } from "../preloads/dashboardAssetList";
import { gameAssetsList } from "../preloads/gameAssetList";
import { soundEffectList } from "../preloads/soundEffectList";
import { animationAssetList } from "../preloads/animationAssetList";
import { preloadAssets, preloadSFX } from "../preloads/assetPreloader";

export const Dashboard = () => {
    const { setDashboardAssets, setGameAssets, setSoundEffects, setAnimations, setIsAssetLoading, isAssetLoading } = useGame()

    const socket = useSocket(0)

    useEffect(() => {
        const loadAssets = async () => {
            try {
                const preloadedDashboardAssets = await preloadAssets(dashboardAssetsList);
                setDashboardAssets(preloadedDashboardAssets);

                const preloadedGameAssets = await preloadAssets(gameAssetsList);
                setGameAssets(preloadedGameAssets);

                const preloadedSFX = await preloadSFX(soundEffectList)
                setSoundEffects(preloadedSFX)

                const preloadedAnimations = await preloadAssets(animationAssetList)
                setAnimations(preloadedAnimations)
            } catch (error) {
                console.error('Error loading assets', error);
            } finally {
                setIsAssetLoading(false);
            }
        };

        if (isAssetLoading) {
            loadAssets()
        }
    }, []);

    if (isAssetLoading) {
        return (
            <>
                <AssetLoading />
                <LandscapeWarning />
            </>
        )
    }

    return (
        <div className="dashboard-background scroll-bar-with-margin">
            <Sidebar socket={socket} />
            <Content socket={socket} />
            <LandscapeWarning />
            <DirectJoinGameModal />
            <JoinDiscordModal />
        </div>
    )
}