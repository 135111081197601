import tournamentQuestBanner from '../../assets/Landing/tournaments_quest_banner.png'

export const TournamentQuestBanner = () => {
    return (
        <img
            src={tournamentQuestBanner}
            alt='tournament-quest-banner'
            width={"100%"}
        />
    )
}