import { useEffect } from "react";
import { SocketType } from "../utils/types/types";
import { useSync } from "../context/SyncContext";

export const TradeRatio = (socket: SocketType) => {
    const { setDockRatios } = useSync()

    // Update Trade Ratio
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "DockRatio") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setDockRatios(parsedData)
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}