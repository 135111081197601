import { IconButton, Tabs, TabList, TabPanels, Tab, TabPanel, Image } from "@chakra-ui/react"
import { MarketTrade } from "../MarketTrade/MarketTrade";
import { PlayerTrade } from "../PlayerTrade/PlayerTrade";
import { useEffect, useState } from "react";
import { useSync } from "../../../context/SyncContext";
import { SocketType } from "../../../utils/types/types";
import { Drawer, DrawerHeader, DrawerContent, useDisclosure } from '@chakra-ui/react'
import { useGame } from '../../../context/GameContext'
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const TradeBox = ({ socket }: { socket: SocketType }) => {
    const { sfxVolume, sfxEnabled, gameAssets, soundEffects, confirmData } = useGame()
    const { myGameData, gameData, trade } = useSync()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [disabled, setDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (gameData && myGameData) {
            const gameStage = gameData.Status
            const gameTurnIndex = gameData.TurnIndex
            const myTurnIndex = myGameData.turn_index

            let stageCheck = true
            let turnCheck = true
            let confirmStageCheck = true

            if ((gameStage === 4 || gameStage === 8)) {
                stageCheck = false
            } else {
                stageCheck = true
            }

            if (gameTurnIndex === myTurnIndex) {
                turnCheck = false
            } else {
                turnCheck = true
            }

            if (confirmData === null) {
                confirmStageCheck = false
            } else {
                confirmStageCheck = true
            }

            setDisabled(stageCheck || turnCheck || confirmStageCheck)
        }
        else {
            setDisabled(true)
        }
    }, [gameData, myGameData, confirmData])

    useEffect(() => {
        if (myGameData && myGameData.turn_index !== gameData.TurnIndex) {
            onClose()
        }
    }, [gameData, myGameData])

    const handleTradeButtonSoundEffect = () => {
        PlaySoundEffect(soundEffects.openTradeBoxSFX, sfxVolume, sfxEnabled)
    }

    const handleTradeTypeChangeSoundEffect = () => {
        PlaySoundEffect(soundEffects.switchTradeTypeSFX, sfxVolume, sfxEnabled)
    }

    return (
        <>
            <IconButton
                zIndex={1}
                position={"fixed"}
                left={"72%"}
                bottom={"1%"}
                width={"5%"}
                height={"7%"}
                aria-label='trade-box'
                colorScheme={"yellow"}
                background={"transparent"}
                isDisabled={disabled}
                onClick={() => {
                    onOpen()
                    handleTradeButtonSoundEffect()
                }}
                icon={<img src={gameAssets.tradeButton} alt="trade-hud" width={"75%"} />}
            />
            <Drawer
                placement={"bottom"}
                onClose={onClose}
                isOpen={(trade !== null && myGameData && gameData.TurnIndex === myGameData.turn_index) || isOpen}
                closeOnOverlayClick={trade ? false : true}
            >
                <DrawerContent
                    containerProps={{
                        zIndex: 7
                    }}
                    backgroundColor={"transparent"}
                    width={"25vw"}
                    ml={"65vw"}
                    mr={"auto"}
                    mb={"10vh"}
                >
                    <DrawerHeader
                        display={"flex"}
                        justifyContent={"space-between"}
                        textColor={"white"}
                        backgroundColor={"#9B8152"}
                        borderRadius={"0.75vw"}
                        p={"1vh 0vh 1vh 0vh"}
                    >
                        <Tabs
                            isFitted
                            align={"center"}
                            variant={"unstyled"}
                            defaultIndex={0}
                            width={"100%"}
                            onChange={handleTradeTypeChangeSoundEffect}
                        >
                            {
                                trade === null &&
                                <TabList pe={"0.5vw"} ps={"0.5vw"}>
                                    <Tab
                                        w={"10vw"}
                                        h={"6vh"}
                                        fontSize={"1.5vw"}
                                        backgroundColor={"#493D2F"}
                                        borderRadius={"0.75vw 0 0 0"}
                                        textColor={"white"}
                                        _selected={{ color: 'white', bg: 'blue.500' }}
                                        defaultValue={0}
                                    >
                                        <Image
                                            src={gameAssets.playersHudIcon}
                                            alt='bank-icon'
                                            width={"25%"}
                                            me={"0.5vw"}
                                        />
                                        Player
                                    </Tab>
                                    <Tab
                                        w={"10vw"}
                                        h={"6vh"}
                                        fontSize={"1.5vw"}
                                        backgroundColor={"#493D2F"}
                                        borderRadius={"0 0.75vw 0 0"}
                                        textColor={"white"}
                                        _selected={{ color: 'white', bg: 'green.400' }}
                                    >
                                        <Image
                                            src={gameAssets.bankButton}
                                            alt='bank-icon'
                                            width={"25%"}
                                            me={"0.5vw"}
                                        />
                                        Bank
                                    </Tab>
                                </TabList>
                            }
                            <TabPanels>
                                <TabPanel p={"0.5vw 0 0 0"}>
                                    <PlayerTrade socket={socket} onClose={onClose} />
                                </TabPanel>
                                <TabPanel p={"0.5vw 0 0 0"}>
                                    <MarketTrade socket={socket} onClose={onClose} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs >
                        {
                            trade === null &&
                            <IconButton
                                position={"absolute"}
                                right={-5}
                                top={-5}
                                scale={0.5}
                                background={"transparent"}
                                colorScheme={"none"}
                                aria-label='close-button'
                                onClick={onClose}
                                icon={<div className='trade-box-close-button'></div>} />
                        }
                    </DrawerHeader>
                </DrawerContent>
            </Drawer>
        </>
    )
}