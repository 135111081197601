import { Box, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { getTimeStringFromSecond } from '../../../utils/functions/getTimeStringFromSecond';
import { getStageTimesInSeconds } from '../../../utils/constants/getStageTimesInSeconds';
import { useGame } from '../../../context/GameContext';

type StageTimerType = {
    activatedTime: string,
    color: string,
    gameData: any,
    myGameData: any
}

export const PlayerStageTimer = ({ activatedTime, color, gameData, myGameData }: StageTimerType) => {
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()

    const audioRef = useRef<HTMLAudioElement>(null);

    const [timeDiff, setTimeDiff] = useState<number | null>(null);
    const [playAudio, setPlayAudio] = useState<boolean>(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date();
            const activatedDate = new Date(activatedTime);

            const diffInSeconds = Math.floor(Math.abs((currentTime.getTime() - activatedDate.getTime()) / 1000));

            const timeLimit = getStageTimesInSeconds(gameData.Status)
            if (diffInSeconds > timeLimit) {
                setTimeDiff(timeLimit);
            } else {
                setTimeDiff(timeLimit - diffInSeconds);
                if (sfxEnabled && myGameData && gameData) {
                    const remainingTime = timeLimit - diffInSeconds
                    const isMyTurn = gameData.TurnIndex === myGameData.turn_index
                    const isDiceState = gameData.Status === 3

                    if (remainingTime < 7 && remainingTime > 1 && isMyTurn && !isDiceState) {
                        setPlayAudio(true);
                    } else {
                        setPlayAudio(false)
                    }
                }
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [activatedTime, gameData]);

    useEffect(() => {
        if (audioRef.current) {
            if (playAudio) {
                audioRef.current.volume = sfxVolume;
                audioRef.current?.play().catch((error) => {
                    console.error("Audio play was prevented by the browser:", error);
                });
            } else {
                audioRef.current?.pause();
                audioRef.current!.currentTime = 0;
            }
        }
    }, [playAudio]);

    if (timeDiff && gameData.Status !== 5 && !gameData.KnightBeforeDice) {
        return (
            <CircularProgress
                position={"absolute"}
                size={"6vw"}
                marginLeft={"-5.25vw"}
                marginTop={"-0.15vw"}
                color={color}
                trackColor={"#303030"}
                max={getStageTimesInSeconds(gameData.Status)}
                value={timeDiff}
            >
                <audio ref={audioRef} src={soundEffects.timeUpSFX} preload="auto" />
                <CircularProgressLabel>
                    {
                        gameData.Status !== 3 &&
                        <Box className='stage-timer'>
                            {getTimeStringFromSecond(timeDiff)}
                        </Box>
                    }
                </CircularProgressLabel>
            </CircularProgress>
        )
    } else {
        return null
    }
};

