export const isRoadPosition = (
  roadPositions: any[],
  xStart: number,
  yStart: number,
  xEnd: number,
  yEnd: number
) => {
  if (roadPositions) {
    return (
      roadPositions.find((data) => {
        return (
          data.position[0] === xStart &&
          data.position[1] === yStart &&
          data.position[2] === xEnd &&
          data.position[3] === yEnd
        );
      }) !== undefined
    );
  } else {
    return false;
  }
};
