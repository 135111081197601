import { Box } from "@chakra-ui/react"
import { getColorNameForPlayerUIByIndex } from "../../../utils/assets/getColorByIndex"
import { useSync } from "../../../context/SyncContext"
import { PlayerUsername } from "./PlayerUsername";
import { PlayerLargestArmy } from "./PlayerLargestArmy"
import { PlayerLongestRoad } from "./PlayerLongestRoad"
import { PlayerDevCards } from "./PlayerDevCards"
import { PlayerResCards } from "./PlayerResCards"
import { PlayerDiceResult } from "./PlayerDiceResult"
import { PlayerStealCircle } from "./PlayerStealCircle"
import { PlayerAvatar } from "./PlayerAvatar";
import { PlayerTradeOffer } from "../Trade/PlayerTradeOffer";
import { SocketType } from "../../../utils/types/types";
import { PlayerCounterTradeOffer } from "../Trade/PlayerCounterTradeOffer";
import { PlayerTradeAnswer } from "./PlayerTradeAnswer";

type PlayerType = {
    socket: SocketType,
    username: string,
    victoryPoint: number,
    largestArmy: number,
    longestRoad: number,
    devCardNumber: number,
    resCardNumber: number,
    turnIndex: number,
    address: string,
    isBot: boolean,
    isConnected: boolean
}

export const Player = (
    {
        socket,
        username,
        victoryPoint,
        largestArmy,
        longestRoad,
        devCardNumber,
        resCardNumber,
        turnIndex,
        address,
        isBot,
        isConnected
    }: PlayerType) => {
    const { gameData } = useSync()

    return (
        <Box
            id={`player-${turnIndex}`}
            className={`player-profile ${gameData.TurnIndex === turnIndex && `${getColorNameForPlayerUIByIndex(turnIndex)}-drop-shadow`} `}
        >
            <PlayerAvatar
                turnIndex={turnIndex}
                username={username}
                isBot={isBot}
                isConnected={isConnected}
            />

            <Box display={"flex"} flexDir={"column"} alignItems={"center"} zIndex={0}>
                <PlayerUsername
                    turnIndex={turnIndex}
                    username={username}
                    victoryPoint={victoryPoint}
                    isBot={isBot}
                />
                <Box className='player-stats'>
                    <PlayerLargestArmy largestArmy={largestArmy} turnIndex={turnIndex} />
                    <PlayerLongestRoad longestRoad={longestRoad} turnIndex={turnIndex} />
                    <PlayerDevCards devCardNumber={devCardNumber} />
                </Box>

            </Box>

            <PlayerResCards resCardNumber={resCardNumber} />
            <PlayerStealCircle turnIndex={turnIndex} />
            <PlayerDiceResult turnIndex={turnIndex} />

            <PlayerTradeOffer
                socket={socket}
                address={address}
            />

            <PlayerCounterTradeOffer
                socket={socket}
                address={address}
            />

            <PlayerTradeAnswer
                turnIndex={turnIndex}
                address={address}
            />
        </Box>
    )
}