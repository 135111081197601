import { Button } from "@chakra-ui/react"
import { FaLink } from "react-icons/fa";
import { useGame } from "../../../../context/GameContext";
import { useState } from "react";

export const ShareRoomLinkButton = () => {
    const { gameID } = useGame()

    const [textToCopy] = useState(import.meta.env.VITE_CLIENT_URL + "/?joinRoom=" + gameID);
    const [hasCopied, setHasCopied] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setHasCopied(true);
        }).catch((err) => {
            console.error("Failed to copy: ", err);
        });
    };

    return (
        <Button
            leftIcon={<FaLink />}
            mr={"auto"}
            width={"15vw"}
            height={"4vw"}
            fontSize={"1.5vw"}
            backgroundColor={"#7f6440"}
            borderRadius={"0.5vw"}
            colorScheme={'blue'}
            onClick={handleCopy}
        >
            {hasCopied ? 'Copied!' : 'Share Link'}
        </Button>
    )
}