import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Button, useDisclosure, Box, Image, Link } from '@chakra-ui/react'
import { useEffect } from 'react';
import { useGame } from '../../../context/GameContext';
import { FaLink, FaCheck } from "react-icons/fa";

export const JoinDiscordModal = () => {
    const { dashboardAssets } = useGame()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const discordLinkTarget = "https://discord.com/invite/DvgwsgTXdp"

    useEffect(() => {
        const hasJoinedDiscord = localStorage.getItem('hasJoinedDiscord');
        if (!hasJoinedDiscord) {
            onOpen();
        }
    }, [onOpen]);

    const handleJoinDiscord = () => {
        // Set the local storage item when the player joins Discord
        localStorage.setItem('hasJoinedDiscord', 'true');
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
        >
            <ModalOverlay
                zIndex={1300}
                backgroundColor={"rgba(0,0,0,0.9)"}
            />
            <ModalContent
                containerProps={{
                    zIndex: 1300
                }}
                backgroundColor={"#5964ed"}
                border={"0.3vw solid #232369"}
                borderRadius={"2vw"}
                maxW={"50vw"}
                padding={"0.5vw"}
            >

                <ModalBody textColor={"white"}>
                    <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"}>
                        <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"20vw"}>
                            <Box fontSize={"2.5vw"}>
                                JOIN US ON DISCORD
                            </Box>
                            <Box fontSize={"1.75vw"}>
                                Find players and get support in our friendly community.
                            </Box>
                        </Box>

                        <Box w={"30vw"}>
                            <Image
                                src={dashboardAssets.discordAsset}
                                alt='discord-asset'
                            />
                        </Box>
                    </Box>
                </ModalBody>

                <ModalFooter display={"flex"} justifyContent={"space-evenly"}>
                    <Button
                        w={"15vw"}
                        h={"3vw"}
                        fontSize={"1.1vw"}
                        colorScheme={"blue"}
                        border={"0.2vw solid black"}
                        borderRadius={"1vw"}
                        onClick={handleJoinDiscord}
                    >
                        <FaCheck className='me-2' />
                        I've already joined
                    </Button>
                    <Link href={discordLinkTarget} isExternal>
                        <Button
                            w={"15vw"}
                            h={"3vw"}
                            fontSize={"1.1vw"}
                            colorScheme={"green"}
                            border={"0.2vw solid black"}
                            borderRadius={"1vw"}
                            onClick={handleJoinDiscord}
                        >
                            <FaLink className='me-2' />
                            Join Pioneers Discord
                        </Button>
                    </Link>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}