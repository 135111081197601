import { Box, Image } from "@chakra-ui/react"
import { Tournament } from "./Tournament"
import { useGame } from "../../../../../context/GameContext"
import { useDashboard } from "../../../../../context/DashboardContext"

export const DailyTournaments = () => {
    const { tournamentList, dailyTicketNumber, setTournamentNotif } = useDashboard()
    const { dashboardAssets } = useGame()

    const dailyTournaments = tournamentList.filter((tournament) => tournament.Name === "Daily Tournament" && tournament.State < 3)

    return (
        <Box
            className="daily-tournament-background"
            w={"20vw"}
            h={"47.5vh"}
            overflowY={"auto"}
        >
            <Box
                pos={"sticky"}
                zIndex={"1"}
                top={"0"}
                backgroundColor={"#2c3947"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Image
                    src={dashboardAssets.tournamentModalIcon}
                    alt="tournament-trophy"
                    w={"15%"}
                    me={"0.5vw"}
                />
                <Box className="gradient-text-color">
                    Daily Tournaments
                </Box>
            </Box>

            <Box>
                {
                    dailyTournaments &&
                        dailyTournaments.length > 0 ?
                        dailyTournaments.map((tournament, index) => {
                            return (
                                <Tournament
                                    key={index}
                                    backgroundColor={index % 2 === 0 ? "daily-tournament-odd-background" : "daily-tournament-even-background"}
                                    id={tournament.ID}
                                    state={tournament.State}
                                    mode={tournament.Class}
                                    startTime={tournament.StartTime}
                                    currentPlayers={tournament.CurrentPlayers}
                                    maxPlayers={tournament.Capacity}
                                    prizePool={tournament.Prize}
                                    isUserRegistered={tournament.IsUserSignedUp}
                                    isUserCheckedin={tournament.UserCheckedIn}
                                    dailyTicketNumber={dailyTicketNumber}
                                    setTournamentNotif={setTournamentNotif}
                                />
                            )
                        }) :
                        <Box
                            textColor={"white"}
                            textAlign={"center"}
                            fontSize={"1.25vw"}
                            mt={"1.25vw"}
                        >
                            There is no daily tournament!
                        </Box>
                }
            </Box>
        </Box>
    )
}