import { Box, IconButton, Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { Timer } from "../Timer/Timer"
import { FaPencil } from "react-icons/fa6"
import { FaCheck, FaTimes } from "react-icons/fa"
import { SocketType } from "../../../utils/types/types"
import { useEffect, useState } from "react"
import { useSync } from "../../../context/SyncContext"
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent"

export const PlayerTradeOffer = ({ socket, address }: { socket: SocketType, address: string }) => {
    const { resources, trade, myGameData } = useSync()
    const { gameAssets, gameID, setEnableCounterTrade, haveCounterTrade, isSpectator } = useGame()

    const [disable, setDisable] = useState(true)

    // Validation for offer accepts
    useEffect(() => {
        if (trade) {
            const cardsGiven = {
                brick: trade.wanted.brick ? trade.wanted.brick : 0,
                ore: trade.wanted.ore ? trade.wanted.ore : 0,
                sheep: trade.wanted.sheep ? trade.wanted.sheep : 0,
                wheat: trade.wanted.wheat ? trade.wanted.wheat : 0,
                wood: trade.wanted.wood ? trade.wanted.wood : 0,
            }

            if (resources.Brick >= cardsGiven.brick &&
                resources.Ore >= cardsGiven.ore &&
                resources.Sheep >= cardsGiven.sheep &&
                resources.Wheat >= cardsGiven.wheat &&
                resources.Wood >= cardsGiven.wood) {
                setDisable(false)
            } else {
                setDisable(true)
            }
        } else {
            setDisable(true)
        }
    }, [trade, resources])

    // Give response to active trade offer
    const handleOffer = async (response: boolean) => {
        const eventData = {
            id: gameID,
            tradeID: trade?.id,
            response: response,
            counterOffer: false
        }
        SendGameEvent(socket, "TradeRespond", eventData)
        setEnableCounterTrade(false)
    }

    const amIOfferer = myGameData && trade && trade.offerer === myGameData.address

    if (trade && trade.offerer === address && !amIOfferer) {
        return (
            <Box className="trade-notification player-trade-offer-notif">
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"space-around"}
                >
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-evenly"}
                        backgroundColor={"#FDEEDA"}
                        w={"16.5vw"}
                        mb={"0.5vw"}
                        pl={"12%"}
                    >
                        <Image
                            src={gameAssets.receiveIcon}
                            alt='receive-icon'
                            width={"8%"}
                            pos={"absolute"}
                            left={"0.5vw"}
                        />
                        <OfferData number={amIOfferer ? trade.wanted.wood : trade.offer.wood} iconSrc={gameAssets.woodIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? trade.wanted.brick : trade.offer.brick} iconSrc={gameAssets.brickIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? trade.wanted.sheep : trade.offer.sheep} iconSrc={gameAssets.sheepIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? trade.wanted.wheat : trade.offer.wheat} iconSrc={gameAssets.wheatIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? trade.wanted.ore : trade.offer.ore} iconSrc={gameAssets.oreIcon} isGiving={false} />
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-evenly"}
                        backgroundColor={"#FDEEDA"}
                        w={"16.5vw"}
                        pl={"12%"}
                    >
                        <Image
                            src={gameAssets.giveIcon}
                            alt='give-icon'
                            width={"8%"}
                            pos={"absolute"}
                            left={"0.5vw"}
                        />
                        <OfferData number={amIOfferer ? trade.offer.wood : trade.wanted.wood} iconSrc={gameAssets.woodIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? trade.offer.brick : trade.wanted.brick} iconSrc={gameAssets.brickIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? trade.offer.sheep : trade.wanted.sheep} iconSrc={gameAssets.sheepIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? trade.offer.wheat : trade.wanted.wheat} iconSrc={gameAssets.wheatIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? trade.offer.ore : trade.wanted.ore} iconSrc={gameAssets.oreIcon} isGiving={true} />
                    </Box>
                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} w={"17.5vw"} p={"0.25vw"}>
                    <Timer activatedTime={trade.time} timeLimit={30} />
                    {
                        !isSpectator &&
                        <>
                            <IconButton
                                minW={"2.5vw"}
                                maxH={"2.5vw"}
                                fontSize={"1vw"}
                                backgroundColor={"#52d55b"}
                                colorScheme={"green"}
                                border={"0.25vw solid #408053"}
                                borderRadius={"0.5vw"}
                                aria-label='accept-offer'
                                isDisabled={disable}
                                onClick={() => handleOffer(true)}
                                icon={<FaCheck />} />
                            <IconButton
                                minW={"2.5vw"}
                                maxH={"2.5vw"}
                                fontSize={"1vw"}
                                backgroundColor={"#e3d229"}
                                colorScheme={"yellow"}
                                border={"0.25vw solid #ae8700"}
                                borderRadius={"0.5vw"}
                                aria-label='counter-offer'
                                isDisabled={haveCounterTrade}
                                onClick={() => setEnableCounterTrade(true)}
                                icon={<FaPencil />} />
                            <IconButton
                                minW={"2.5vw"}
                                maxH={"2.5vw"}
                                fontSize={"1vw"}
                                backgroundColor={"#e4685d"}
                                border={"0.25vw solid #c23d28"}
                                colorScheme={"red"}
                                borderRadius={"0.5vw"}
                                aria-label='reject-offer'
                                onClick={() => handleOffer(false)}
                                icon={<FaTimes />} />
                        </>
                    }
                </Box>
            </Box>
        )
    } else {
        return null
    }
}

type OfferDataType = {
    number: number,
    iconSrc: string,
    isGiving: boolean
}

const OfferData = ({ number, iconSrc, isGiving }: OfferDataType) => {
    if (number && number > 0) {
        return (
            <Box
                display={"flex"}
                alignItems={"center"}
            >
                <Box
                    textColor={isGiving ? "#d1523d" : "#77a34b"}
                    fontSize={"1.5vw"}
                    fontWeight={"700"}
                >
                    {isGiving ? "-" : "+"}
                    {number}
                </Box>
                <Image
                    src={iconSrc}
                    alt={"res-icpn"}
                    width={"2vw"}
                />
            </Box>
        )
    } else {
        return null;
    }
}