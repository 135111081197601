import { useEffect, useState } from "react"
import { useSync } from "../../../context/SyncContext"
import { getColorByIndex, getColorNameForPlayerUIByIndex } from "../../../utils/assets/getColorByIndex"
import { useGame } from "../../../context/GameContext"
import { getSettlementAsset } from "../../../utils/assets/getSettlementAsset"
import { Box, Image } from "@chakra-ui/react"

export const ColorInfoNotification = () => {
    const { gameAssets } = useGame()
    const { gameData, myGameData, playersSkinChoice } = useSync()

    const [showAnimation, setShowAnimation] = useState(false)

    useEffect(() => {
        if (gameData && gameData.TurnNumber === 0) {
            setShowAnimation(true)

            const timer = setTimeout(() => {
                setShowAnimation(false)
            }, 4000)

            return () => clearTimeout(timer)
        }
    }, [])

    if (showAnimation && myGameData) {
        return (
            <Box id="colorInfoIndicator">
                <Box className={`color-banner ${getColorNameForPlayerUIByIndex(myGameData.turn_index)}-game-started-banner`}>
                    The Game has Started!
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    Your color is
                    <Image
                        src={playersSkinChoice && getSettlementAsset(myGameData.turn_index, gameAssets, playersSkinChoice[myGameData.turn_index])}
                        alt="settle"
                        width={"15%"}
                        me={"1vw"}
                        ms={"1vw"}
                    />
                    <Box textColor={getColorByIndex(myGameData.turn_index)}>
                        {getColorNameForPlayerUIByIndex(myGameData.turn_index).toLocaleUpperCase()}
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return null
    }
}