import { useEffect, useState } from "react";
import { SocketType } from "../../../utils/types/types";
import { getEmoji } from "../../../utils/assets/getEmoji";
import { useGame } from "../../../context/GameContext";
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const EmojiNotification = (socket: SocketType) => {
    const { emojiSoundEnabled, soundEffects, gameAssets } = useGame()

    const [showPlayer1, setShowPlayer1] = useState(false)
    const [showPlayer2, setShowPlayer2] = useState(false)
    const [showPlayer3, setShowPlayer3] = useState(false)
    const [showPlayer4, setShowPlayer4] = useState(false)

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "EmojiMessage") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                const turnIndex = parsedData.turnIndex
                const emojiIndex = parsedData.emoji

                if (turnIndex === 0) {
                    setShowPlayer1(true)
                } else if (turnIndex === 1) {
                    setShowPlayer2(true)
                } else if (turnIndex === 2) {
                    setShowPlayer3(true)
                } else if (turnIndex === 3) {
                    setShowPlayer4(true)
                }

                const emojiDiv = document.createElement("div")
                emojiDiv.classList.add("emoji")
                emojiDiv.id = `emoji-${turnIndex}`

                const emojiImg = document.createElement("img")
                emojiImg.src = getEmoji(emojiIndex, gameAssets)

                emojiDiv.appendChild(emojiImg)

                const playerElement = document.getElementById(`player-${turnIndex}`)

                if (playerElement) {
                    playerElement.appendChild(emojiDiv)
                }

                if (emojiIndex === 0) {
                    PlaySoundEffect(soundEffects.emojiHappySFX, 1, emojiSoundEnabled)
                } else if (emojiIndex === 1) {
                    PlaySoundEffect(soundEffects.emojiCrying, 1, emojiSoundEnabled)
                } else if (emojiIndex === 2) {
                    PlaySoundEffect(soundEffects.emojiEvilSFX, 1, emojiSoundEnabled)
                } else if (emojiIndex === 3) {
                    PlaySoundEffect(soundEffects.emojiSuprisedSFX, 1, emojiSoundEnabled)
                } else if (emojiIndex === 4) {
                    PlaySoundEffect(soundEffects.emojiAnnoyedSFX, 1, emojiSoundEnabled)
                } else if (emojiIndex === 5) {
                    PlaySoundEffect(soundEffects.emojiAngrySFX, 1, emojiSoundEnabled)
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (showPlayer1) {
            const timeout = setTimeout(() => {
                const emoji = document.getElementById("emoji-0")
                if (emoji) {
                    emoji.remove()
                    setShowPlayer1(false)
                }
            }, 10000);

            return () => clearTimeout(timeout);
        }
    }, [showPlayer1])

    useEffect(() => {
        if (showPlayer2) {
            const timeout = setTimeout(() => {
                const emoji = document.getElementById("emoji-1")
                if (emoji) {
                    emoji.remove()
                    setShowPlayer2(false)
                }
            }, 10000);

            return () => clearTimeout(timeout);
        }
    }, [showPlayer2])

    useEffect(() => {
        if (showPlayer3) {
            const timeout = setTimeout(() => {
                const emoji = document.getElementById("emoji-2")
                if (emoji) {
                    emoji.remove()
                    setShowPlayer3(false)
                }
            }, 10000);

            return () => clearTimeout(timeout);
        }
    }, [showPlayer3])

    useEffect(() => {
        if (showPlayer4) {
            const timeout = setTimeout(() => {
                const emoji = document.getElementById("emoji-3")
                if (emoji) {
                    emoji.remove()
                    setShowPlayer4(false)
                }
            }, 10000);

            return () => clearTimeout(timeout);
        }
    }, [showPlayer4])
}