import { Box, Text, Image, Avatar } from "@chakra-ui/react"
import { getOrdinalSuffix } from '../../../../../utils/functions/getOrdinalSuffix';
import { getAvatarByIndex } from '../../../../../utils/assets/getAvatarByIndex';
import { useGame } from '../../../../../context/GameContext';

type PastTournamentOverviewType = {
    Name: string,
    Prize: number[],
    Winners: string[]
}

export const PastTournamentOverview = ({ Name, Prize, Winners }: PastTournamentOverviewType) => {
    const { dashboardAssets } = useGame()

    return (
        <Box borderRadius={"25px"}>
            <Box position={"relative"} textAlign={"center"} w={"102%"} left={"-1%"}>
                <Image
                    src={dashboardAssets.overviewtournamentBanner}
                    zIndex={1}
                    alt='tournament-banner'
                />
                <span className='tournament-banner-overview-title'>
                    {Name}
                </span>
            </Box>

            <Text textColor={"#392516"} fontSize={"35px"} fontWeight={"700"} textAlign={"center"}>
                Winners
            </Text>

            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} textColor={"#392516"}>
                {
                    Winners && Winners.length > 0 &&
                    Winners.map((player, index) => {
                        return (
                            <Box key={index} display={"flex"} flexDir={"column"} alignItems={"center"} pe={4} ps={4}>
                                <Avatar
                                    backgroundColor={"#dcd1c4"}
                                    h={index === 0 ? "135px" : index === 1 ? "115px" : "95px"}
                                    w={index === 0 ? "135px" : index === 1 ? "115px" : "95px"}
                                    mb={index === 0 ? "0px" : index === 1 ? "20px" : "40px"}
                                    border={"3px solid black"}
                                    src={getAvatarByIndex(index, dashboardAssets)}
                                />
                                <Text mb={0}>{getOrdinalSuffix(index + 1)}</Text>
                                <Box fontSize={"25px"}>
                                    {player}
                                </Box>
                                <Box display={"flex"} fontSize={"25px"}>
                                    <Image src={dashboardAssets.coinIcon} width={"40px"} alt='coin-cion' />
                                    {Prize[index]}
                                </Box>
                            </Box>
                        )
                    })
                }

            </Box>

            <Box display={"flex"} alignItems={"center"} backgroundColor={"#dcb88a"} mt={2} p={3} borderRadius={"25px"}>
                <Box className="col-6" borderRight={"1px solid black"}>
                    <Text textColor={"#392516"} fontSize={"25px"} textAlign={"center"} mb={2}>
                        Prize Pool
                    </Text>
                    <Text className='tournament-prizes-yellow-text'>
                        $
                        {
                            Prize.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue;
                            }, 0)
                        }
                    </Text>
                </Box>

                <Box className="col-6" display={"flex"} justifyContent={"center"} alignItems={"center"} p={3}>
                    <Box h={"100%"} w={"75px"}>
                        <Box className='tournament-prizes-pool-yellow-text'>
                            ${Prize[0]}
                        </Box>
                        <Box
                            className='tournament-prizes-pool-yellow-text'
                            borderRadius={"10px 10px 0px 0px"}
                            border={"2px solid #72532f"}
                            backgroundColor={"#ad813e"}
                            height={"100px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            1st
                        </Box>
                    </Box>
                    <Box h={"100%"} w={"75px"}>
                        <Box className='tournament-prizes-pool-yellow-text' mt={"10px"}>
                            ${Prize[1]}
                        </Box>
                        <Box
                            className='tournament-prizes-pool-yellow-text'
                            borderRadius={"10px 10px 0px 0px"}
                            border={"2px solid #72532f"}
                            backgroundColor={"#866439"}
                            height={"90px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            2nd
                        </Box>
                    </Box>
                    <Box h={"100%"} w={"75px"}>
                        <Box className='tournament-prizes-pool-yellow-text' mt={"20px"}>
                            ${Prize[2]}
                        </Box>
                        <Box
                            className='tournament-prizes-pool-yellow-text'
                            borderRadius={"10px 10px 0px 0px"}
                            border={"2px solid #72532f"}
                            backgroundColor={"#866439"}
                            height={"80px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            3rd
                        </Box>
                    </Box>
                    <Box h={"100%"} w={"75px"}>
                        <Box className='tournament-prizes-pool-yellow-text' mt={"30px"}>
                            ${Prize[3]}
                        </Box>
                        <Box
                            className='tournament-prizes-pool-yellow-text'
                            borderRadius={"10px 10px 0px 0px"}
                            border={"2px solid #72532f"}
                            backgroundColor={"#866439"}
                            height={"70px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            4th
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}