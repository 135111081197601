import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, useDisclosure, FormControl, FormLabel, Input, ModalFooter, useToast, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { CreateMission } from '../../../../requests/AdminRequests'
import { useState } from 'react'

export const CreateMissionModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast()

    const [missionID, setMissionID] = useState("")
    const [eventType, setEventType] = useState("")
    const [questName, setQuestName] = useState("")
    const [amount, setAmount] = useState(1)
    const [startTime, setStartTime] = useState("")

    const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const localDateTime = event.target.value;

        var localDate = new Date(localDateTime);

        var localTimestamp = localDate.getTime();

        var localTimezoneOffsetMs = localDate.getTimezoneOffset() * 60 * 1000;

        var gmtTimestamp = localTimestamp + localTimezoneOffsetMs;

        var gmtTime = new Date(gmtTimestamp - localTimezoneOffsetMs)
        setStartTime(gmtTime.toISOString())
    }

    const handleCreateMission = async () => {
        const result = await CreateMission(missionID, eventType, amount, questName, startTime)

        if (result && result.message === "mission added") {

            toast({
                description: "Mission is added.",
                status: "success",
                duration: 2000
            })
            onClose()
        }
    }
    return (
        <>
            <Button
                colorScheme='green'
                mb={3}
                w={"100%"}
                onClick={onOpen}
            >
                Create Mission
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Create Mission</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel htmlFor='missionIDInput'>Mission ID</FormLabel>
                            <Input
                                id="missionIDInput"
                                placeholder='Mission ID'
                                onChange={(e) => setMissionID(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='eventTypeInput'>Event Type</FormLabel>
                            <Input
                                id="eventTypeInput"
                                placeholder='Event Type'
                                onChange={(e) => setEventType(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='amountInput' mb={10}>Amount</FormLabel>
                            <Slider
                                id='amountInput'
                                aria-label='slider-ex-1'
                                min={1}
                                max={20}
                                defaultValue={amount}
                                onChange={(value) => setAmount(value)}
                            >
                                <SliderMark
                                    value={amount}
                                    textAlign='center'
                                    bg={"#544C42"}
                                    color='white'
                                    mt='-10'
                                    ml='-5'
                                    w='12'
                                >
                                    {amount}
                                </SliderMark>
                                <SliderTrack bg={"#544C42"}>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='questNameInput'>Quest Name</FormLabel>
                            <Input
                                id="questNameInput"
                                placeholder='Quest Name'
                                onChange={(e) => setQuestName(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel htmlFor="dateInput">Start Time</FormLabel>
                            <Input
                                id="dateInput"
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                                placeholder='Select Date and Time'
                                size='md'
                                type='datetime-local'
                                onChange={(e) => handleStartTimeChange(e)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            onClick={handleCreateMission}
                        >
                            Create Mission
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}