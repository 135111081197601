export const getDevCardImgSrcByCardType = (cardType: string, assets: any) => {
  if (cardType === "Knight") {
    return assets.devCardKnight;
  } else if (cardType === "Monopoly") {
    return assets.devCardMonoply;
  } else if (cardType === "RoadBuilding") {
    return assets.devCardRoadBuilding;
  } else if (cardType === "YearOfPlenty") {
    return assets.devCardYearOfPlenty;
  } else if (cardType === "VictoryPoint") {
    return assets.devCardVictoryPoint;
  } else {
    return "";
  }
};
