import { useContext, createContext, ReactNode, useState, useEffect } from "react";
import { GetTokenByRefresh, GetUsername, GetVerifyToken } from "../requests/AuthRequests";

type AuthContextType = {
    loggedIn: boolean;
    logout: () => void;
    username: string | null;
    setUsername: (value: string | null) => void;
    isAdmin: boolean;
    isPartner: boolean;
};

const AuthContext = createContext<AuthContextType>({
    loggedIn: false,
    logout: () => { },
    username: null,
    setUsername: () => { },
    isAdmin: false,
    isPartner: false,
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode; }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false)

    const [username, setUsername] = useState<string | null>(null)

    const [isAdmin, setIsAdmin] = useState(false)
    const [isPartner, setIsPartner] = useState(false)

    useEffect(() => {
        const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            ? 'Mobile'
            : 'Desktop';

        const abortController = new AbortController();

        const isValidToken = async () => {
            const response = await GetVerifyToken(abortController, deviceType);
            if (response) {
                const result = await GetTokenByRefresh(abortController)
                if (result && result.message === "success") {
                    setLoggedIn(true);
                    if (response.role === 4) {
                        setIsPartner(true);
                    } else if (response.role === 5) {
                        setIsAdmin(true)
                    }
                }
            }
        };

        isValidToken();

        const interval = setInterval(() => {
            isValidToken();
        }, 2.5 * 60 * 60 * 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        const fetchUsername = async () => {
            const data = await GetUsername()
            data && setUsername(data.username)
        }

        if (loggedIn) {
            fetchUsername()
        }
    }, [loggedIn])

    const logout = () => {
        setLoggedIn(false)
    };

    const results: AuthContextType = {
        loggedIn,
        logout,
        username,
        setUsername,
        isAdmin,
        isPartner
    };

    return (
        <AuthContext.Provider value={results}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };