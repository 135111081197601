import { Box, Image, Button, useDisclosure, Modal, ModalContent } from '@chakra-ui/react'
import { FaEye } from "react-icons/fa";
import { useGame } from '../../../../context/GameContext'
import { useNavigate } from 'react-router-dom'
import { useSync } from '../../../../context/SyncContext'
import { useEffect, useState } from 'react'
import { GetRoomStats, GetUserStats } from '../../../../requests/GameRequests'
import { QuestModal } from '../../../Dashboard/Content/Quest/QuestModal'
import { ConfettiAnimation } from '../../Animations/ConfettiAnimation'
import { PlayerAvatar } from './PlayerAvatar';
import { PlayerUsername } from './PlayerUsername';
import { PlayerVictoryPoint } from './PlayerVictoryPoint';
import { PlayerTotalScore } from './PlayerTotalScore';
import { PlayerTotalResGather } from './PlayerTotalResGather';
import { PlayerTotalBuildings } from './PlayerTotalBuildings';
import { GameTime } from './GameTime';
import { DiceMoves } from './DiceMoves';
import { TotalTurns } from './TotalTurns';
import { getGameBackground } from '../../../../utils/assets/getGameBackground';

export const WinnerLobby = () => {
    const { gameID, setGameID, setIsReconnect, gameAssets } = useGame()
    const { gameData, players } = useSync()

    const { isOpen, onClose, onOpen } = useDisclosure()
    const { isOpen: winnerIsOpen, onClose: winnerOnClose, onOpen: winnerOnOpen } = useDisclosure({ defaultIsOpen: true })

    const navigate = useNavigate()

    const [userStats, setUserStats] = useState<any[]>([])
    const [roomStats, setRoomStats] = useState<any>(null)

    useEffect(() => {
        const fetchUserStats = async () => {
            const result = await GetUserStats(gameID)
            setUserStats(result)
        }

        if (gameID > -1) {
            fetchUserStats()
        }
    }, [])

    useEffect(() => {
        const fetchRoomStats = async () => {
            const result = await GetRoomStats(gameID)
            setRoomStats(result)
        }

        if (gameID > -1) {
            fetchRoomStats()
        }
    }, [])

    const handleLeave = () => {
        setGameID(-1)
        setIsReconnect(false)
        navigate("/dashboard")
    }

    return (
        <>
            <Button
                leftIcon={<FaEye />}
                pos={"fixed"}
                w={"20vw"}
                height={"4vw"}
                fontSize={"2vw"}
                left={"2%"}
                bottom={"2%"}
                color={"black"}
                backgroundColor={"#B2F64E"}
                colorScheme={"green"}
                boxShadow={"0 0.25vw 0 0 #7FA67C"}
                border={"0.25vw solid #7FA67C"}
                borderRadius={"1vw"}
                onClick={winnerOnOpen}
            >
                Show Result
            </Button>
            <Modal
                isOpen={winnerIsOpen}
                onClose={winnerOnClose}
                size={"full"}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <QuestModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    isDashboard={false}
                />

                <ConfettiAnimation />

                <ModalContent
                    className="winner-box"
                    backgroundImage={getGameBackground(localStorage.getItem("background"), gameAssets)}
                    backgroundSize={"cover"}
                >
                    <Box display={"flex"} justifyContent={"center"}>
                        {
                            players?.map((player, index) => {
                                return (
                                    <Box
                                        key={index}
                                        display={"flex"}
                                        flexDir={"column"}
                                        justifyContent={"end"}
                                        w={"15vw"}
                                    >
                                        <Box display={"flex"} flexDir={"column"}>
                                            <PlayerAvatar
                                                username={player.username}
                                                turnIndex={player.turnIndex}
                                            />
                                            <PlayerUsername
                                                username={player.username}
                                                turnIndex={player.turnIndex}
                                            />
                                        </Box>
                                        <Box className={gameData.WinnerIndex === player.turnIndex ? 'winner-stats-panel' : 'loser-stats-panel'}>
                                            {/* Victory Points */}
                                            <PlayerVictoryPoint turnIndex={player.turnIndex} />

                                            {/* Total Longest Road and Largest Army */}
                                            <PlayerTotalScore turnIndex={player.turnIndex} />

                                            {/* Total Resource Gathering */}
                                            <PlayerTotalResGather
                                                turnIndex={player.turnIndex}
                                                userStats={userStats}
                                            />

                                            {/* Total Buildings */}
                                            <PlayerTotalBuildings
                                                turnIndex={player.turnIndex}
                                                userStats={userStats}
                                            />
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>

                    <Box display={"flex"} justifyContent={"center"} mt={"0.5vw"} mb={"0.5vw"}>
                        <Image src={gameAssets.largeDivider} alt='divider' />
                    </Box>

                    {/* Room Stats */}
                    <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} textColor={"white"}>
                        <GameTime roomStats={roomStats} />
                        <DiceMoves roomStats={roomStats} />
                        <TotalTurns roomStats={roomStats} />
                    </Box>

                    {/* Action Buttons */}
                    <Box display={"flex"} justifyContent={"space-evenly"} mt={"1vw"}>
                        <Button
                            width={"15vw"}
                            height={"3vw"}
                            fontSize={"1.5vw"}
                            backgroundColor={"#EBBE57"}
                            colorScheme={"yellow"}
                            boxShadow={"0 0.25vw 0 0 #CEA368"}
                            border={"0.25vw solid #CEA368"}
                            borderRadius={"1vw"}
                            onClick={handleLeave}
                        >
                            <Image src={gameAssets.backButton} alt='back-button' w={"25%"} me={"0.5vw"} />
                            Quit
                        </Button>

                        <Button
                            leftIcon={<FaEye />}
                            width={"15vw"}
                            height={"3vw"}
                            fontSize={"1.5vw"}
                            textColor={"black"}
                            backgroundColor={"#B2F64E"}
                            colorScheme={"green"}
                            boxShadow={"0 0.25vw 0 0 #7FA67C"}
                            border={"0.25vw solid #7FA67C"}
                            borderRadius={"1vw"}
                            onClick={winnerOnClose}
                        >
                            Show Board
                        </Button>

                        <Button
                            width={"15vw"}
                            height={"3vw"}
                            fontSize={"1.5vw"}
                            backgroundColor={"#DE994C"}
                            colorScheme={"yellow"}
                            boxShadow={"0 0.25vw 0 0 #A7742D"}
                            border={"0.25vw solid #A7742D"}
                            borderRadius={"1vw"}
                            onClick={onOpen}
                        >
                            <Image src={gameAssets.questButton} alt='back-button' w={"25%"} me={"0.5vw"} />
                            Quests
                        </Button>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}