import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { ProtectedDashboard } from "./pages/protected/ProtectedDashboard";
import { ProtectedGame } from "./pages/protected/ProtectedGame";
import { Game } from "./pages/Game";
import { Landing } from "./pages/Landing";
import { ProtectedSpectator } from "./pages/protected/ProtectedSpectator";
import { Spectator } from "./pages/Spectator";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedGame />}>
          <Route element={<Game />} path="/game" />
        </Route>
        <Route element={<ProtectedSpectator />}>
          <Route element={<Spectator />} path="/spectator" />
        </Route>
        <Route element={<ProtectedDashboard />}>
          <Route element={<Dashboard />} path="/dashboard" />
        </Route>
        <Route element={<Landing />} path="/" />
      </Routes>
    </BrowserRouter >
  )
}

export default App