import Rive from '@rive-app/react-canvas'
import ScrollContainer from 'react-indiana-drag-scroll'
import riveWave from '../../../animations/Wave/wave.riv'
import { useRef, useState } from "react"
import { getClassByTileNumber } from '../../../utils/constants/getClassByTileNumber'
import { getDataByElementID } from "../../../utils/functions/getDataByElementID"
import { getDotsFromNumber } from "../../../utils/constants/getDotsFromNumber"
import { getResourceAsset } from "../../../utils/assets/getResourceAsset"
import { getShipAsset } from "../../../utils/assets/getShipAsset"
import { isRedNumber } from "../../../utils/functions/isRedNumber"
import { isRoadPosition } from "../../../utils/functions/isRoadPosition"
import { isSettlePosition } from "../../../utils/functions/isSettlePosition"
import { isRoadConnected } from "../../../utils/functions/isRoadConnected"
import { isSettleDistanceValid } from "../../../utils/functions/isSettleDistanceValid"
import { SettlementEffects } from "./Effects/SettlementEffects"
import { RoadEffects } from "./Effects/RoadEffects"
import { RobberEffects } from "./Effects/RobberEffects"
import { useGame } from "../../../context/GameContext"
import { getMyRoadPosition } from "../../../utils/functions/getMyRoadPositions"
import { SocketType } from "../../../utils/types/types"
import { ZoomEffect } from "./Effects/ZoomEffect"
import { BoardEffects } from "./Effects/BoardEffects"
import { useSync } from "../../../context/SyncContext"
import { useBoard } from "../../../hooks/useBoard"
import { BuildConfirmModal } from './BuildConfirm/BuildConfirmModal';
import { getPlaceCoordsByHexId } from '../../../utils/functions/getPlaceCoordsByHexId'
import { getUserIndexByPlace } from '../../../utils/functions/getUserIndexByPlace'
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent'

export const Board = ({ socket }: { socket: SocketType }) => {
    const { gameID, gameAssets, animations, setConfirmData, disableBuildConfirm, isMobile, isSpectator } = useGame()
    const { myGameData, gameData, settlePositions, roadPositions, robberPosition, largestArmyOwner, longestRoadOwner, players, dice, points, playersSkinChoice } = useSync()

    const { tileResources, tileNumbers, dockConfig, desertPosition } = useBoard(gameID)

    const [scale, setScale] = useState(1)

    BoardEffects(
        gameData,
        myGameData,
        largestArmyOwner,
        longestRoadOwner,
        players,
        dice,
        tileNumbers,
        tileResources,
        robberPosition,
        settlePositions,
        roadPositions,
        gameAssets,
        animations
    )
    SettlementEffects(settlePositions, myGameData, gameAssets, playersSkinChoice)
    RoadEffects(roadPositions, gameAssets, playersSkinChoice)
    RobberEffects(desertPosition, robberPosition, gameData, myGameData, settlePositions, points)
    ZoomEffect(scale, setScale)

    /* Mobile - Tablets touch scale handler */
    const initialDistance = useRef(0);
    const previousScale = useRef(1);

    const handleTouchStart = (e) => {
        if (e.touches.length === 2) {
            const distance = getDistance(e.touches);
            initialDistance.current = distance;
            previousScale.current = scale;
        }
    };

    const handleTouchEnd = (e) => {
        if (e.touches.length < 2) {
            initialDistance.current = 0;
        }
    };

    const getDistance = (touches) => {
        const [touch1, touch2] = touches;
        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const handleTouchMove = (e) => {
        if (e.touches.length === 2) {
            const distance = getDistance(e.touches);
            if (initialDistance.current > 0) {
                const scaleChange = distance / initialDistance.current;
                const dampingFactor = isMobile ? 1.5 : 0.5;
                const newScale = previousScale.current + dampingFactor * (scaleChange - 1);
                if (newScale < 2.5 && newScale > 0.75) {
                    setScale(newScale);
                }
            }
        }
    };

    /* Handle board clicks */
    const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
        const data = getDataByElementID(event)
        const status = gameData.Status
        const gameOver = gameData.GameOver

        if (data && status > 0 && !gameOver && !isSpectator) {
            const type = data.type
            if (type === "h" && status === 1) {
                const x = data.x
                const y = data.y

                if (!isSettlePosition(settlePositions, x!, y!)) {
                    if (disableBuildConfirm) {
                        const eventData = {
                            id: gameID,
                            position: [
                                x,
                                y
                            ]
                        }
                        SendGameEvent(socket, "SetupSettle", eventData)
                    } else {
                        setConfirmData({ showCost: false, costType: "settle", settlePosition: { xStart: x!, yStart: y!, xEnd: null, yEnd: null } })
                    }
                }
            } else if (type === "r" && status === 2) {
                const xStart = data.xStart
                const yStart = data.yStart
                const xEnd = data.xEnd
                const yEnd = data.yEnd

                if (isSettlePosition(settlePositions, xStart!, yStart!) || isSettlePosition(settlePositions, xEnd!, yEnd!)) {
                    if (disableBuildConfirm) {
                        const eventData = {
                            id: gameID,
                            position: [
                                xStart,
                                yStart,
                                xEnd,
                                yEnd
                            ]
                        }
                        SendGameEvent(socket, "SetupRoad", eventData)
                    } else {
                        setConfirmData({ showCost: false, costType: "road", settlePosition: { xStart: xStart!, yStart: yStart!, xEnd: xEnd!, yEnd: yEnd! } })
                    }
                }
            } else if (type === "h" && status === 4) {
                const x = data.x
                const y = data.y

                if (!isSettlePosition(settlePositions, x!, y!) && isSettleDistanceValid(settlePositions, x!, y!)) {
                    if (disableBuildConfirm) {
                        const eventData = {
                            id: gameID,
                            position: [
                                x,
                                y
                            ]
                        }
                        SendGameEvent(socket, "BuildSettle", eventData)
                    } else {
                        setConfirmData({ showCost: true, costType: "settle", settlePosition: { xStart: x!, yStart: y!, xEnd: null, yEnd: null } })
                    }
                } else if (isSettlePosition(settlePositions, x!, y!)) {
                    if (disableBuildConfirm) {
                        const eventData = {
                            id: gameID,
                            position: [
                                x,
                                y
                            ]
                        }
                        SendGameEvent(socket, "BuildCity", eventData)
                    } else {
                        setConfirmData({ showCost: true, costType: "city", settlePosition: { xStart: x!, yStart: y!, xEnd: null, yEnd: null } })
                    }
                }
            } else if (type === "r" && status === 4) {
                const xStart = data.xStart
                const yStart = data.yStart
                const xEnd = data.xEnd
                const yEnd = data.yEnd

                if (!isRoadPosition(roadPositions, xStart!, yStart!, xEnd!, yEnd!) &&
                    (isSettlePosition(settlePositions, xStart!, yStart!) ||
                        isSettlePosition(settlePositions, xEnd!, yEnd!) ||
                        isRoadConnected(getMyRoadPosition(roadPositions, myGameData.address!.toLowerCase()), xStart!, yStart!, xEnd!, yEnd!))) {
                    if (disableBuildConfirm) {
                        const eventData = {
                            id: gameID,
                            position: [
                                xStart,
                                yStart,
                                xEnd,
                                yEnd
                            ]
                        }
                        SendGameEvent(socket, "BuildRoad", eventData)
                    } else {
                        setConfirmData({ showCost: true, costType: "road", settlePosition: { xStart: xStart!, yStart: yStart!, xEnd: xEnd!, yEnd: yEnd! } })
                    }
                }
            } else if (type === "hex" && status === 6) {
                const hexId = data.id
                const isOneVOne = gameData.RoomLimit === 2

                if (isOneVOne) {
                    const places = getPlaceCoordsByHexId(hexId!)
                    const userIndexes = getUserIndexByPlace(settlePositions, places)

                    if (userIndexes && userIndexes.length > 0) {
                        const distinctNumbers = userIndexes!.filter(
                            (number, index, array) => array.indexOf(number) === index
                        );

                        if (distinctNumbers.length > 0) {
                            if (distinctNumbers.length === 1) {
                                if (points[distinctNumbers[0]] < 3) {
                                    return
                                }
                            } else if (distinctNumbers.length === 2) {
                                let shouldAddClass = true;

                                distinctNumbers.forEach((turnIndex) => {
                                    if (points[turnIndex] < 3) {
                                        shouldAddClass = false;
                                    }
                                });

                                if (!shouldAddClass) {
                                    return
                                }
                            }
                        }
                    }
                }

                const eventData = {
                    id: gameID,
                    position: hexId
                }
                SendGameEvent(socket, "RobberMove", eventData)
            } else if (type === "r" && status === 9 || status === 10) {
                const xStart = data.xStart
                const yStart = data.yStart
                const xEnd = data.xEnd
                const yEnd = data.yEnd

                if (!isRoadPosition(roadPositions, xStart!, yStart!, xEnd!, yEnd!) &&
                    (isSettlePosition(settlePositions, xStart!, yStart!) ||
                        isSettlePosition(settlePositions, xEnd!, yEnd!) ||
                        isRoadConnected(getMyRoadPosition(roadPositions, myGameData.address!.toLowerCase()), xStart!, yStart!, xEnd!, yEnd!))) {
                    if (disableBuildConfirm) {
                        const eventData = {
                            id: gameID,
                            position: [
                                xStart,
                                yStart,
                                xEnd,
                                yEnd
                            ]
                        }
                        SendGameEvent(socket, "BuildRoad", eventData)
                    } else {
                        setConfirmData({ showCost: false, costType: "road", settlePosition: { xStart: xStart!, yStart: yStart!, xEnd: xEnd!, yEnd: yEnd! } })
                    }
                }
            }
        }
    }

    return (
        <ScrollContainer className="scroll-container" style={{ height: `100vh`, width: `100vw` }}>
            <BuildConfirmModal socket={socket} />
            <section
                className='board'
                id="board"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                style={{
                    transform: `scale(${scale}) translateZ(0)`,
                    transition: 'transform 0.2s ease',
                    willChange: "transform",
                }}
            >

                <div
                    className="hex-normal"
                    style={{ left: "34.99655%", top: "24.45%", backgroundImage: `url(${getResourceAsset(tileResources[0], gameAssets)})` }}
                    id='tile-0'
                >
                    <div className="hex-water" style={{ marginTop: "-86%", marginLeft: "-49.5%", backgroundImage: `url(${gameAssets.bottomRightSingleDock})`, pointerEvents: "none" }} id="dock-0">
                        <div
                            className="ship"
                            style={{ top: "0%", right: "15%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[0].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(-120deg)", zIndex: "1", width: "50%", marginTop: "10%", marginLeft: "25%" }}
                        />
                    </div>
                    <div
                        className="hex-water vertical-flip"
                        style={{ marginTop: "-86%", marginLeft: "49.5%", backgroundImage: `url(${gameAssets.topDualBeach})`, pointerEvents: "none" }}
                    >
                    </div>
                    <div
                        className="hex-water horizontal-flip"
                        style={{ marginLeft: "-99%", backgroundImage: `url(${gameAssets.leftBottomDualBeach})`, pointerEvents: "none" }}
                    >
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/0,0/1,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/0,0/1,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/1,0/2,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/1,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/0,0"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/0" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[0])}`} id="circle-0">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[0])}`}>
                            {tileNumbers.length > 0 && tileNumbers[0] !== 7 && tileNumbers[0]}
                        </div>
                        <div className={`prob-dots-base  ${getClassByTileNumber(tileNumbers[0])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[0])}
                        </div>
                    </div>
                    <span id="thief-0"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "50%", top: "24.45%", backgroundImage: `url(${getResourceAsset(tileResources[1], gameAssets)})` }}
                    id='tile-1'
                >
                    <div className="hex-water" style={{ marginTop: "-86%", marginLeft: "49.5%", backgroundImage: `url(${gameAssets.bottomDualDock})`, pointerEvents: "none" }} id="dock-1">
                        <div
                            className="ship"
                            style={{ top: "-5%", right: "5%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[1].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(-60deg)", zIndex: "1", width: "50%", marginTop: "5%", marginLeft: "25%" }}
                        />
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/2,0/3,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/2,0/3,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/3,0/4,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/3,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/2,0"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/1" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[1])}`} id="circle-1">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[1])}`}>
                            {tileNumbers.length > 0 && tileNumbers[1] !== 7 && tileNumbers[1]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[1])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[1])}
                        </div>
                    </div>
                    <span id="thief-1"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "65.00345%", top: "24.45%", backgroundImage: `url(${getResourceAsset(tileResources[2], gameAssets)})` }}
                    id='tile-2'
                >
                    <div
                        className="hex-water"
                        style={{ marginTop: "-86%", marginLeft: "49.5%", backgroundImage: `url(${gameAssets.leftBottomSingleBeach})`, pointerEvents: "none" }}
                    >
                    </div>
                    <div className="hex-water" style={{ marginLeft: "99%", backgroundImage: `url(${gameAssets.bottomLeftDualDock})`, pointerEvents: "none" }} id="dock-2">
                        <div
                            className="ship"
                            style={{ top: "0%", right: "5%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[2].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(-60deg)", zIndex: "1", width: "50%", marginTop: "10%", marginLeft: "30%" }}
                        />
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/4,0/5,1"></div>
                    <div onClick={(event) => handleClick(event)} className='right-road road-effect' id="r/6,0/7,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/4,0/5,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/5,0/6,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/5,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/4,0"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-house settle-effect' id="h/6,0"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/2" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[2])}`} id="circle-2">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[2])}`}>
                            {tileNumbers.length > 0 && tileNumbers[2] !== 7 && tileNumbers[2]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[2])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[2])}
                        </div>
                    </div>
                    <span id="thief-2"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "27.494825000000002%", top: "37.225%", backgroundImage: `url(${getResourceAsset(tileResources[3], gameAssets)})` }}
                    id='tile-3'
                >
                    <div className="hex-water" style={{ marginLeft: "-99%", backgroundImage: `url(${gameAssets.bottomRightDualDock})`, pointerEvents: "none" }} id="dock-8">
                        <div
                            className="ship"
                            style={{ top: "0%", right: "15%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[8].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(180deg)", zIndex: "1", width: "50%", marginTop: "0%", marginLeft: "30%" }}
                        />
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/0,1/1,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/0,1/1,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/1,1/2,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/1,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/0,1"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/3" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[3])}`} id="circle-3">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[3])}`}>
                            {tileNumbers.length > 0 && tileNumbers[3] !== 7 && tileNumbers[3]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[3])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[3])}
                        </div>
                    </div>
                    <span id="thief-3"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "42.498275%", top: "37.225%", backgroundImage: `url(${getResourceAsset(tileResources[4], gameAssets)})` }}
                    id='tile-4'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/2,1/3,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/2,1/3,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/3,1/4,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/3,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/2,1"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/4" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[4])}`} id="circle-4">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[4])}`}>
                            {tileNumbers.length > 0 && tileNumbers[4] !== 7 && tileNumbers[4]}
                        </div>
                        <div className={`prob-dots-base  ${getClassByTileNumber(tileNumbers[4])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[4])}
                        </div>
                    </div>
                    <span id="thief-4"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "57.501725%", top: "37.225%", backgroundImage: `url(${getResourceAsset(tileResources[5], gameAssets)})` }}
                    id='tile-5'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/4,1/5,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/4,1/5,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/5,1/6,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/5,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/4,1"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/5" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[5])}`} id="circle-5">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[5])}`}>
                            {tileNumbers.length > 0 && tileNumbers[5] !== 7 && tileNumbers[5]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[5])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[5])}
                        </div>
                    </div>
                    <span id="thief-5"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "72.505175%", top: "37.225%", backgroundImage: `url(${getResourceAsset(tileResources[6], gameAssets)})` }}
                    id='tile-6'
                >
                    <div
                        className="hex-water"
                        style={{ marginLeft: "99%", backgroundImage: `url(${gameAssets.leftBottomDualBeach})`, pointerEvents: "none" }}
                    >
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/6,1/7,2"></div>
                    <div onClick={(event) => handleClick(event)} className='right-road road-effect' id="r/8,1/9,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/6,1/7,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/7,1/8,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/7,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/6,1"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-house settle-effect' id="h/8,1"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/6" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[6])}`} id="circle-6">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[6])}`}>
                            {tileNumbers.length > 0 && tileNumbers[6] !== 7 && tileNumbers[6]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[6])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[6])}
                        </div>
                    </div>
                    <span id="thief-6"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "19.993100000000002%", top: "50%", backgroundImage: `url(${getResourceAsset(tileResources[7], gameAssets)})` }}
                    id='tile-7'
                >
                    <div
                        className="hex-water horizontal-flip"
                        style={{ marginLeft: "-99%", backgroundImage: `url(${gameAssets.leftSingleBeach})`, pointerEvents: "none" }}
                    >
                    </div>
                    <div className="hex-water" style={{ marginLeft: "-49.5%", marginTop: "85%", backgroundImage: `url(${gameAssets.topRightDualDock})`, pointerEvents: "none" }} id="dock-7">
                        <div
                            className="ship"
                            style={{ top: "10%", right: "15%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[7].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(180deg)", zIndex: "1", width: "50%", marginTop: "0%", marginLeft: "30%" }}
                        />
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/0,2/0,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/0,2/1,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/1,2/2,2"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-road road-effect' id="r/0,3/1,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/1,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/0,2"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-house settle-effect' id="h/0,3"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/7" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[7])}`} id="circle-7">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[7])}`}>
                            {tileNumbers.length > 0 && tileNumbers[7] !== 7 && tileNumbers[7]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[7])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[7])}
                        </div>
                    </div>
                    <span id="thief-7"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "34.99655%", top: "50%", backgroundImage: `url(${getResourceAsset(tileResources[8], gameAssets)})` }}
                    id='tile-8'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/2,2/2,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/2,2/3,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/3,2/4,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/3,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/2,2"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/8" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[8])}`} id="circle-8">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[8])}`}>
                            {tileNumbers.length > 0 && tileNumbers[8] !== 7 && tileNumbers[8]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[8])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[8])}
                        </div>
                    </div>
                    <span id="thief-8"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "50%", top: "50%", backgroundImage: `url(${getResourceAsset(tileResources[9], gameAssets)})` }}
                    id='tile-9'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/4,2/4,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/4,2/5,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/5,2/6,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/5,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/4,2"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/9" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[9])}`} id="circle-9">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[9])}`}>
                            {tileNumbers.length > 0 && tileNumbers[9] !== 7 && tileNumbers[9]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[9])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[9])}
                        </div>
                    </div>
                    <span id="thief-9"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "65.00345%", top: "50%", backgroundImage: `url(${getResourceAsset(tileResources[10], gameAssets)})` }}
                    id='tile-10'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/6,2/6,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/6,2/7,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/7,2/8,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/7,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/6,2"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/10" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[10])}`} id="circle-10">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[10])}`}>
                            {tileNumbers.length > 0 && tileNumbers[10] !== 7 && tileNumbers[10]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[10])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[10])}
                        </div>
                    </div>
                    <span id="thief-10"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "80.0069%", top: "50%", backgroundImage: `url(${getResourceAsset(tileResources[11], gameAssets)})` }}
                    id='tile-11'
                >
                    <div className="hex-water" style={{ marginLeft: "99%", backgroundImage: `url(${gameAssets.leftSingleDock})`, pointerEvents: "none" }} id="dock-3">
                        <div
                            className="ship"
                            style={{ right: "0%", top: "0%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[3].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ zIndex: "1", width: "50%", marginTop: "0%", marginLeft: "17%" }}
                        />
                    </div>
                    <div
                        className="hex-water vertical-flip"
                        style={{ marginLeft: "49.5%", marginTop: "85%", backgroundImage: `url(${gameAssets.leftBottomDualBeach})`, pointerEvents: "none" }}
                    >
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/8,2/8,3"></div>
                    <div onClick={(event) => handleClick(event)} className='right-road road-effect' id="r/10,2/10,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/8,2/9,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/9,2/10,2"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-road road-effect' id="r/9,3/10,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/9,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/8,2"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-house settle-effect' id="h/10,2"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-house settle-effect' id="h/10,3"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/11" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[11])}`} id="circle-11">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[11])}`}>
                            {tileNumbers.length > 0 && tileNumbers[11] !== 7 && tileNumbers[11]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[11])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[11])}
                        </div>
                    </div>
                    <span id="thief-11"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "27.494825000000002%", top: "62.775%", backgroundImage: `url(${getResourceAsset(tileResources[12], gameAssets)})` }}
                    id='tile-12'
                >
                    <div
                        className="hex-water hor-ver-flip"
                        style={{ marginLeft: "-49.5%", marginTop: "86%", backgroundImage: `url(${gameAssets.leftBottomDualBeach})`, pointerEvents: "none" }}
                    >
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/1,3/0,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/1,3/2,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/2,3/3,3"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-road road-effect' id="r/0,4/1,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/2,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/1,3"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-house settle-effect' id="h/0,4"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/12" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[12])}`} id="circle-12">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[12])}`}>
                            {tileNumbers.length > 0 && tileNumbers[12] !== 7 && tileNumbers[12]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[12])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[12])}
                        </div>
                    </div>
                    <span id="thief-12"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "42.498275%", top: "62.775%", backgroundImage: `url(${getResourceAsset(tileResources[13], gameAssets)})` }}
                    id='tile-13'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/3,3/2,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/3,3/4,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/4,3/5,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/4,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/3,3"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/13" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[13])}`} id="circle-13">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[13])}`}>
                            {tileNumbers.length > 0 && tileNumbers[13] !== 7 && tileNumbers[13]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[13])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[13])}
                        </div>
                    </div>
                    <span id="thief-13"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "57.501725%", top: "62.775%", backgroundImage: `url(${getResourceAsset(tileResources[14], gameAssets)})` }}
                    id='tile-14'
                >
                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/5,3/4,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/5,3/6,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/6,3/7,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/6,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/5,3"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/14" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[14])}`} id="circle-14">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[14])}`}>
                            {tileNumbers.length > 0 && tileNumbers[14] !== 7 && tileNumbers[14]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[14])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[14])}
                        </div>
                    </div>
                    <span id="thief-14"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "72.505175%", top: "62.775%", backgroundImage: `url(${getResourceAsset(tileResources[15], gameAssets)})` }}
                    id='tile-15'
                >
                    <div className="hex-water" style={{ marginLeft: "49.5%", marginTop: "85%", backgroundImage: `url(${gameAssets.topLeftDualDock})`, pointerEvents: "none" }} id="dock-4">
                        <div
                            className="ship"
                            style={{ top: "0%", right: "0%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[4].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(60deg)", zIndex: "1", width: "50%", marginTop: "-5%", marginLeft: "25%" }}
                        />
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/7,3/6,4"></div>
                    <div onClick={(event) => handleClick(event)} className='right-road road-effect' id="r/9,3/8,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/7,3/8,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/8,3/9,3"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-road road-effect' id="r/7,4/8,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/8,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/7,3"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-house settle-effect' id="h/9,3"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-house settle-effect' id="h/8,4"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/15" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[15])}`} id="circle-15">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[15])}`}>
                            {tileNumbers.length > 0 && tileNumbers[15] !== 7 && tileNumbers[15]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[15])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[15])}
                        </div>
                    </div>
                    <span id="thief-15"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "34.99655%", top: "75.55%", backgroundImage: `url(${getResourceAsset(tileResources[16], gameAssets)})` }}
                    id='tile-16'
                >
                    <div className="hex-water" style={{ marginLeft: "-49.5%", marginTop: "86%", backgroundImage: `url(${gameAssets.topRightSingleDock})`, pointerEvents: "none" }} id="dock-6">
                        <div
                            className="ship"
                            style={{ top: "10%", right: "15%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[6].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(120deg)", zIndex: "1", width: "50%", marginTop: "-10%", marginLeft: "25%" }}
                        />
                    </div>

                    <div
                        className="hex-water horizontal-flip"
                        style={{ marginLeft: "49.5%", marginTop: "85.5%", backgroundImage: `url(${gameAssets.topDualBeach})`, pointerEvents: "none" }}
                    >
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/1,4/0,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/1,4/2,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/2,4/3,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-road road-effect' id="r/0,5/1,5"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-road road-effect' id="r/1,5/2,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/2,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-house settle-effect' id="h/1,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/1,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-house settle-effect' id="h/0,5"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/16" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[16])}`} id="circle-16">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[16])}`}>
                            {tileNumbers.length > 0 && tileNumbers[16] !== 7 && tileNumbers[16]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[16])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[16])}
                        </div>
                    </div>
                    <span id="thief-16"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "50%", top: "75.55%", backgroundImage: `url(${getResourceAsset(tileResources[17], gameAssets)})` }}
                    id='tile-17'
                >
                    <div className="hex-water" style={{ marginLeft: "49.5%", marginTop: "86%", backgroundImage: `url(${gameAssets.topDualDock})`, pointerEvents: "none" }} id="dock-5">
                        <div
                            className="ship"
                            style={{ top: "8%", right: "10%", zIndex: "2", backgroundImage: dockConfig ? `url(${getShipAsset(dockConfig[5].resource, gameAssets)})` : "" }}
                        >
                        </div>
                        <Rive
                            src={riveWave}
                            style={{ transform: "rotateZ(60deg)", zIndex: "1", width: "50%", marginTop: "-10%", marginLeft: "20%" }}
                        />
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/3,4/2,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/3,4/4,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/4,4/5,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-road road-effect' id="r/3,5/4,5"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-road road-effect' id="r/2,5/3,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/4,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-house settle-effect' id="h/3,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/3,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-house settle-effect' id="h/2,5"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/17" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[17])}`} id="circle-17">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[17])}`}>
                            {tileNumbers.length > 0 && tileNumbers[17] !== 7 && tileNumbers[17]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[17])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[17])}
                        </div>
                    </div>
                    <span id="thief-17"></span>
                </div>

                <div className="hex-normal"
                    style={{ left: "65.00345%", top: "75.55%", backgroundImage: `url(${getResourceAsset(tileResources[18], gameAssets)})` }}
                    id='tile-18'
                >
                    <div
                        className="hex-water vertical-flip"
                        style={{ marginTop: "85%", marginLeft: "49.5%", backgroundImage: `url(${gameAssets.leftBottomSingleBeach})`, pointerEvents: "none" }}
                    >
                    </div>

                    <div onClick={(event) => handleClick(event)} className='left-road road-effect' id="r/5,4/4,5"></div>
                    <div onClick={(event) => handleClick(event)} className='right-road road-effect' id="r/7,4/6,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-road road-effect' id="r/5,4/6,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-road road-effect' id="r/6,4/7,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-road road-effect' id="r/5,5/6,5"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-road road-effect' id="r/4,5/5,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-house settle-effect' id="h/6,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-house settle-effect' id="h/5,5"></div>
                    <div onClick={(event) => handleClick(event)} className='top-left-house settle-effect' id="h/5,4"></div>
                    <div onClick={(event) => handleClick(event)} className='top-right-house settle-effect' id="h/7,4"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-left-house settle-effect' id="h/4,5"></div>
                    <div onClick={(event) => handleClick(event)} className='bottom-right-house settle-effect' id="h/6,5"></div>

                    <div onClick={(event) => handleClick(event)} id="hex/18" className='hex-div'></div>

                    <div className={`circle-normal circle-base font-size-wrap desert-chit ${tileNumbers.length > 0 && isRedNumber(tileNumbers[18])}`} id="circle-18">
                        <div className={`tile-chit-normal chit-number-base ${getClassByTileNumber(tileNumbers[18])}`}>
                            {tileNumbers.length > 0 && tileNumbers[18] !== 7 && tileNumbers[18]}
                        </div>
                        <div className={`prob-dots-base ${getClassByTileNumber(tileNumbers[18])}`}>
                            {tileNumbers.length > 0 && getDotsFromNumber(tileNumbers[18])}
                        </div>
                    </div>
                    <span id="thief-18"></span>
                </div>
            </section>
        </ScrollContainer>
    );
}