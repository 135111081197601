import { useEffect, useState } from 'react'
import { Box, } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { GetTournamentStandings } from '../../../../../requests/TournamentRequests'
import { StandingsTable } from './StandingsTable'

type ChampioshipStandingsType = {
    ID: number,
    State: number,
    CurrentStage: number,
    Matches: null | any[]
}

export const ChampionshipStandings = ({ ID, State, CurrentStage, Matches }: ChampioshipStandingsType) => {
    const [players, setPlayers] = useState<any[]>([])

    useEffect(() => {
        const fetchStandings = async () => {
            const result = await GetTournamentStandings(ID)
            result && setPlayers(result)
        }

        const isStateCorrect = State === 3 || State === 4 || State === 6

        if (ID && isStateCorrect) {
            fetchStandings()
        }
    }, [ID, State, Matches])

    const partOnePlayers = players.filter((player) => player.Part === 1)
    const partTwoPlayers = CurrentStage > 3 ? players.filter((player) => player.Part === 2) : []

    return (
        <Box border={"3px solid black"} backgroundColor={"#583a16"} borderRadius={"0px 0px 25px 25px"}>
            <Tabs
                isFitted
                variant='unstyled'
            >
                <TabList backgroundColor={"#745833"} >
                    <Tab
                        p={"1vw"}
                        fontSize={"1.5vw"}
                        _selected={{ color: 'white', bg: "#53422b" }}
                    >
                        Qualify Standings
                    </Tab>
                    <Tab
                        p={"1vw"}
                        fontSize={"1.5vw"}
                        _selected={{ color: 'white', bg: "#53422b" }}
                        isDisabled={CurrentStage < 4}
                    >
                        Last 16 Standings
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <StandingsTable
                            players={partOnePlayers}
                            isQualifyStandings={true}
                        />
                    </TabPanel>
                    <TabPanel>
                        <StandingsTable
                            players={partTwoPlayers}
                            isQualifyStandings={false}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Box>
    )
}