import { Button, Box, Image, Tooltip } from '@chakra-ui/react'
import { getInventoryChestAsset } from '../../../../../utils/assets/getChestAsset'
import { useGame } from '../../../../../context/GameContext'

type ChestCardType = {
    chestRowID: number,
    chestID: number,
    chestImage: string,
    chestRarity: string,
    keys: any[],
    setOpenData: (value: any) => void
    onOpen: () => void
}

export const ChestCard = ({ chestRowID, chestRarity, keys, chestID, chestImage, setOpenData, onOpen }: ChestCardType) => {
    const { dashboardAssets } = useGame()

    const relatedKeys = keys.filter((key) => key.rarity === chestRarity)
    const numberOfKey = relatedKeys.length

    const handleOpenChest = () => {
        setOpenData({ chestRowID, chestRarity, chestID, chestImage, relatedKeys })
        onOpen()
    }

    return (
        <Box
            h={"100%"}
            minW={"10vw"}
            maxW={"10vw"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            me={"1vw"}
        >
            <Box pos={"relative"}>
                <Tooltip
                    backgroundColor={"#745833"}
                    label={`You need the ${chestRarity} Rarity Key to open this chest!`}
                    isDisabled={numberOfKey > 0}
                >
                    <Image
                        mb={1}
                        src={getInventoryChestAsset(chestRarity, dashboardAssets)}
                        alt='chest'
                        filter={numberOfKey === 0 ? "grayscale(100%)" : undefined}
                    />
                </Tooltip>
                {
                    numberOfKey === 0 &&
                    <Image
                        src={dashboardAssets.inventoryLockIcon}
                        w={"5vw"}
                        alt='locked-icon'
                        pos={"absolute"}
                        top={"50%"}
                        left={"50%"}
                        transform={"translate(-50%, -50%)"}
                        pointerEvents={"none"}
                    />
                }
            </Box>
            <Button
                width={"10vw"}
                height={"1.75vw"}
                fontSize={"1vw"}
                colorScheme={"green"}
                border={"0.15vw solid #3F7600"}
                boxShadow={"0 0.15vw 0 0 #3F7600"}
                mb={"0.5vw"}
                isDisabled={numberOfKey === 0}
                onClick={() => numberOfKey > 0 && handleOpenChest()}
            >
                {
                    numberOfKey > 0 ?
                        "Open" :
                        "Locked"
                }
            </Button>
        </Box>
    )
}