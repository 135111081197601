import { Modal, ModalContent, ModalBody, Tabs, TabList, TabPanels, Tab, TabPanel, useToast, IconButton, Tooltip } from '@chakra-ui/react'
import { SocketType } from '../../../../../utils/types/types';
import { useState, useEffect } from 'react'
import { useGame } from '../../../../../context/GameContext'
import { FaEye, FaTable } from 'react-icons/fa6'
import { BsClipboardDataFill } from "react-icons/bs";
import { TbBroadcast, TbTournament } from 'react-icons/tb'
import { ChampionshipStandings } from '../Standings/ChampionshipStandings'
import { SingleElimStageInfo } from './StageInfo/SingleElim/SingleElimStageInfo'
import { KnockoutStageInfo } from './StageInfo/Knockout/KnockoutStageInfo';
import { ChampionshipStageInfo } from './StageInfo/Championship/ChampionshipStageInfo';
import { KnockoutBracket } from '../Bracket/KnockoutBracket';
import { SingleElimBracket } from '../Bracket/SingleElimBracket';
import { ChampionshipBracket } from '../Bracket/ChampionshipBracket';
import { TournamentMatchList } from './TournamentMatchList'
import { TournamentSummary } from './TournamentSummary';
import { CreateTournamentCheckinCancel } from '../../../../../requests/TournamentRequests';
import { Standings } from '../Standings/Standings';
import { useDashboard } from '../../../../../context/DashboardContext';

export const TournamentWaitingRoom = ({ socket }: { socket: SocketType }) => {
    const { tournamentNotif } = useDashboard()
    const { gameAssets, dashboardAssets } = useGame()

    const toast = useToast()
    const id = 'double-click-toast'

    const [matches, setMatches] = useState<any[]>([])

    const [startedTournament, setStartedTournament] = useState<any>(null);

    useEffect(() => {
        const foundTournament = tournamentNotif.find(
            (tournament) => (tournament.player_state === 2 || tournament.player_state === 5) && tournament.checked_in
        );

        if (foundTournament) {
            setStartedTournament(foundTournament)
        } else {
            setStartedTournament(null)
        }
    }, [tournamentNotif])

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentMatchOver") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setMatches(prevMatches =>
                        prevMatches.map(match =>
                            match.id === parsedData.gameID
                                ? { ...match, state: 2 }
                                : match
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    const handleCheckinCancel = async () => {
        if (startedTournament) {
            const tournamentID = startedTournament.id
            const response = await CreateTournamentCheckinCancel(tournamentID)
            if (response && response.message === "success") {
                toast({
                    description: "You have successfully checked-out for the tournament.",
                    status: "success",
                    duration: 5000,
                })
            }
        }
    }

    if (startedTournament) {
        const isChampionship = startedTournament.max_stage === 5
        const isDoubleElimination = startedTournament.max_stage === 3

        const playerState = startedTournament.player_state
        const tournamentState = startedTournament.state
        const tournamentStage = startedTournament.stage

        return (
            <Modal
                isOpen={true}
                onClose={() => { }}
                size={"full"}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                motionPreset={"slideInBottom"}
            >
                <ModalContent
                    containerProps={{
                        zIndex: 1300
                    }}
                    backgroundImage={gameAssets.woodBG}
                    backgroundSize={"cover"}
                    backgroundPosition={"center"}
                    backgroundRepeat={"no-repeat"}
                >
                    <Tooltip label="Check-out">
                        <IconButton
                            pos={"absolute"}
                            left={"0.5vw"}
                            top={"0.5vw"}
                            w={"5vw"}
                            height={"5vw"}
                            backgroundColor={"transparent"}
                            colorScheme={"orange"}
                            onDoubleClick={handleCheckinCancel}
                            onClick={() => {
                                if (!toast.isActive(id)) {
                                    toast({
                                        id,
                                        description: "Double click to check-out for the tournament.",
                                        status: "info",
                                        duration: 2000,
                                    })
                                }
                            }}
                            aria-label='cancel-check-in'
                            icon={<img src={dashboardAssets.logoutIcon} alt='left-tournament' />}
                        />
                    </Tooltip>

                    <ModalBody
                        alignSelf={"center"}
                        width={"80%"}
                        borderRadius={"25px"}
                        backgroundColor={"rgba(0,0,0,0.5)"}
                        p={"0"}
                    >
                        <Tabs isFitted variant={"soft-rounded"}>
                            <TabList borderRadius={"25px 25px 0px 0px"} backgroundColor={"#745833"}>
                                <Tab
                                    borderRadius={"20px 0px 0px 0px"}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.25vw"}
                                    fontSize={"2vw"}
                                >
                                    <FaEye className="me-2" />
                                    Stage
                                </Tab>
                                {
                                    playerState === 5 &&
                                    < Tab
                                        borderRadius={"0px"}
                                        _selected={{ bg: "#53422b" }}
                                        textColor={"#efdebc"}
                                        p={"1.25vw"}
                                        fontSize={"2vw"}
                                    >
                                        <BsClipboardDataFill className="me-2" />
                                        Summary
                                    </Tab>
                                }
                                <Tab
                                    borderRadius={0}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.25vw"}
                                    fontSize={"2vw"}
                                    isDisabled={tournamentState < 3}
                                >
                                    <TbBroadcast className="me-2" color='red' />
                                    Live
                                </Tab>
                                <Tab
                                    borderRadius={"0"}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.25vw"}
                                    fontSize={"2vw"}
                                    isDisabled={tournamentState < 3}
                                >
                                    <FaTable className='me-2' />
                                    Standings
                                </Tab>
                                <Tab
                                    borderRadius={"0px 20px 0px 0px"}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.25vw"}
                                    fontSize={"2vw"}
                                    isDisabled={tournamentState < 3}
                                >
                                    <TbTournament className='me-2' />
                                    Brackets
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {
                                        isChampionship ?
                                            <ChampionshipStageInfo
                                                CurrentStage={tournamentStage}
                                                PlayerState={playerState}
                                                StartTime={startedTournament.startTime}
                                                PrizePool={startedTournament.prize}
                                                FreezeTimeEnd={startedTournament.FreezeTimeEnd}
                                                Matches={matches}
                                            /> :
                                            isDoubleElimination ?
                                                <KnockoutStageInfo
                                                    CurrentStage={tournamentStage}
                                                    PlayerState={playerState}
                                                    StartTime={startedTournament.startTime}
                                                    PrizePool={startedTournament.prize}
                                                    FreezeTimeEnd={startedTournament.FreezeTimeEnd}
                                                    Matches={matches}
                                                />
                                                :
                                                <SingleElimStageInfo
                                                    PlayerState={playerState}
                                                    StartTime={startedTournament.startTime}
                                                    PrizePool={startedTournament.prize}
                                                    FreezeTimeEnd={startedTournament.FreezeTimeEnd}
                                                    Matches={matches}
                                                />
                                    }
                                </TabPanel>
                                {
                                    playerState === 5 &&
                                    <TabPanel>
                                        <TournamentSummary gameID={startedTournament.game_id} />
                                    </TabPanel>
                                }
                                <TabPanel>
                                    <TournamentMatchList Matches={matches} />
                                </TabPanel>
                                <TabPanel p={0}>
                                    {
                                        isChampionship ?
                                            <ChampionshipStandings
                                                ID={startedTournament.id}
                                                State={tournamentState}
                                                CurrentStage={tournamentStage}
                                                Matches={matches}
                                            /> :
                                            <Standings
                                                ID={startedTournament.id}
                                                State={tournamentState}
                                                Matches={matches}
                                            />
                                    }
                                </TabPanel>

                                <TabPanel p={0}>
                                    {
                                        isChampionship ?
                                            <ChampionshipBracket
                                                ID={startedTournament.id}
                                                State={tournamentState}
                                                Prize={startedTournament.prize}
                                                CurrentStage={startedTournament.stage}
                                                setMatches={setMatches}
                                            /> :
                                            isDoubleElimination ?
                                                <KnockoutBracket
                                                    ID={startedTournament.id}
                                                    State={tournamentState}
                                                    Prize={startedTournament.prize}
                                                    CurrentStage={startedTournament.stage}
                                                    setMatches={setMatches}
                                                /> :
                                                <SingleElimBracket
                                                    ID={startedTournament.id}
                                                    State={startedTournament.state}
                                                    Prize={startedTournament.prize}
                                                    CurrentStage={startedTournament.stage}
                                                    setMatches={setMatches}
                                                />
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal >
        )
    } else {
        return null
    }
}