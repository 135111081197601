import { Avatar } from "@chakra-ui/react"
import { getAvatarByIndex } from "../../../../utils/assets/getAvatarByIndex"
import { useGame } from "../../../../context/GameContext"

type PlayerAvatarType = {
    username: string,
    turnIndex: number
}

export const PlayerAvatar = ({ username, turnIndex }: PlayerAvatarType) => {
    const { dashboardAssets } = useGame()

    return (
        <Avatar
            w={"5vw"}
            h={"5vw"}
            name={username}
            src={getAvatarByIndex(turnIndex, dashboardAssets)}
            alignSelf={"center"}
        />
    )
}