import gameSoundTrack from "../sounds/sfx_game_soundtrack.mp3";

import playerJoinSFX from "../sounds/sfx_player_join.mp3";

import takingLeadSFX from "../sounds/sfx_leader_road_army.mp3";

import buildRoadSFX from "../sounds/Board/sfx_build_road.mp3";
import buildSettleDockSFX from "../sounds/Board/sfx_build_settlement_dock.mp3";
import buildSettleSFX from "../sounds/Board/sfx_build_settlement.mp3";
import buildCitySFX from "../sounds/Board/sfx_build_city.mp3";
import collectResourceSFX from "../sounds/Board/sfx_player_collecting_resources.mp3";
import notCollectResourceSFX from "../sounds/Board/sfx_player_dont_collect_resources.mp3";

import drawDevCardSFX from "../sounds/DevCards/sfx_draw_dev_card.mp3";
import victoryPointSFX from "../sounds/DevCards/sfx_card_victory_points.mp3";
import yearOfPlentySFX from "../sounds/DevCards/sfx_card_year_of_plenty.mp3";
import roadBuildingSFX from "../sounds/DevCards/sfx_card_road_building.mp3";
import knightSFX from "../sounds/DevCards/sfx_card_knight.mp3";
import monopolySFX from "../sounds/DevCards/sfx_card_monopoly.mp3";
import knightCardUsed from "../sounds/DevCards/sfx_knight_card_used.mp3";

import emojiAngrySFX from "../sounds/Emoji/sfx_emoji_angry.mp3";
import emojiAnnoyedSFX from "../sounds/Emoji/sfx_emoji_annoyed.mp3";
import emojiCrying from "../sounds/Emoji/sfx_emoji_crying.mp3";
import emojiEvilSFX from "../sounds/Emoji/sfx_emoji_evil.mp3";
import emojiHappySFX from "../sounds/Emoji/sfx_emoji_happy.mp3";
import emojiSuprisedSFX from "../sounds/Emoji/sfx_emoji_surprised.mp3";

import moveThiefSFX from "../sounds/Thief/sfx_move_thief.mp3";
import needDiscardSFX from "../sounds/Thief/sfx_need_discard.mp3";

import bankTradeConfirmSFX from "../sounds/Trade/sfx_bank_trade_confirm.mp3";
import playerTradeConfirmSFX from "../sounds/Trade/sfx_player_trade_confirm.mp3";
import decreaseCardAmountSFX from "../sounds/Trade/sfx_decrease_card_amount.mp3";
import increaseCardAmountSFX from "../sounds/Trade/sfx_increase_card_amount.mp3";
import getTradeOfferSFX from "../sounds/Trade/sfx_get_trade_offer.mp3";
import openTradeBoxSFX from "../sounds/Trade/sfx_open_trade_box.mp3";
import switchTradeTypeSFX from "../sounds/Trade/sfx_switch_trade_type.mp3";

import gameOverSFX from "../sounds/Turn/sfx_game_over.mp3";
import otherPlayerTurnSFX from "../sounds/Turn/sfx_other_player_turn.mp3";
import clickPassTurnSFX from "../sounds/Turn/sfx_press_pass_turn.mp3";
import rollDiceSFX from "../sounds/Turn/sfx_roll_dice.mp3";
import clockTickingSFX from "../sounds/Turn/sfx_clock_ticking.mp3";
import turnChangeSFX from "../sounds/Turn/sfx_turn_change.mp3";
import yourTurnSFX from "../sounds/Turn/sfx_your_turn.mp3";

import tournamentCheckinSFX from "../sounds/Tournament/sfx_tournament_checkin.mp3";
import tournamentStartedSFX from "../sounds/Tournament/sfx_tournament_started.mp3";

import chestOpenSFX from "../sounds/Inventory/sfx_open_chest.mp3";
import dropItemSFX from "../sounds/Inventory/sfx_item_drop.mp3";

export const soundEffectList = {
  gameSoundTrack: gameSoundTrack,
  playerJoinSFX: playerJoinSFX,
  takingLeadSFX: takingLeadSFX,
  buildRoadSFX: buildRoadSFX,
  buildSettleSFX: buildSettleSFX,
  buildSettleDockSFX: buildSettleDockSFX,
  buildCitySFX: buildCitySFX,
  collectResourceSFX: collectResourceSFX,
  notCollectResourceSFX: notCollectResourceSFX,
  drawDevCardSFX: drawDevCardSFX,
  victoryPointSFX: victoryPointSFX,
  yearOfPlentySFX: yearOfPlentySFX,
  roadBuildingSFX: roadBuildingSFX,
  knightSFX: knightSFX,
  monopolySFX: monopolySFX,
  knightCardUsed: knightCardUsed,
  emojiAngrySFX: emojiAngrySFX,
  emojiAnnoyedSFX: emojiAnnoyedSFX,
  emojiCrying: emojiCrying,
  emojiEvilSFX: emojiEvilSFX,
  emojiHappySFX: emojiHappySFX,
  emojiSuprisedSFX: emojiSuprisedSFX,
  moveThiefSFX: moveThiefSFX,
  needDiscardSFX: needDiscardSFX,
  bankTradeConfirmSFX: bankTradeConfirmSFX,
  playerTradeConfirmSFX: playerTradeConfirmSFX,
  decreaseCardAmountSFX: decreaseCardAmountSFX,
  increaseCardAmountSFX: increaseCardAmountSFX,
  getTradeOfferSFX: getTradeOfferSFX,
  openTradeBoxSFX: openTradeBoxSFX,
  switchTradeTypeSFX: switchTradeTypeSFX,
  gameOverSFX: gameOverSFX,
  otherPlayerTurnSFX: otherPlayerTurnSFX,
  clickPassTurnSFX: clickPassTurnSFX,
  rollDiceSFX: rollDiceSFX,
  timeUpSFX: clockTickingSFX,
  turnChangeSFX: turnChangeSFX,
  yourTurnSFX: yourTurnSFX,
  tournamentCheckinSFX: tournamentCheckinSFX,
  tournamentStartedSFX: tournamentStartedSFX,
  chestOpenSFX: chestOpenSFX,
  dropItemSFX: dropItemSFX,
};
