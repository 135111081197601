import { useToast } from "@chakra-ui/react";
import { SocketType } from "../utils/types/types";
import { useEffect } from "react";

export const Errors = (socket: SocketType) => {
    const toast = useToast()

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "Error") {
            const errorMessage = socket.lastJsonMessage.data;
            const capitalizedErrorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);

            toast({
                description: capitalizedErrorMessage,
                status: "error",
                duration: 2500,
                isClosable: true
            })
        }
    }, [socket && socket.lastJsonMessage])
}