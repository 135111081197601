import { Box, Button } from "@chakra-ui/react"
import { MdDeleteForever } from "react-icons/md"

type CardDeleteType = {
    IsAdmin: boolean,
    State: number,
    handleTournamentDelete: () => void
}

export const CardDelete = ({ IsAdmin, State, handleTournamentDelete }: CardDeleteType) => {
    if (IsAdmin && (State === 1 || State === 2)) {
        return (
            <Box display={"flex"} justifyContent={"center"} backgroundColor={"#eed6b7"} p={"0.5vw"}>
                <Button
                    width={"50%"}
                    height={"40%"}
                    fontSize={"1.1vw"}
                    padding={"0.3vw"}
                    backgroundColor={"#c97f64"}
                    border={"0.15vw solid #A72C01"}
                    boxShadow={"0 0.15vw #A72C01"}
                    colorScheme={"red"}
                    onDoubleClick={handleTournamentDelete}
                >
                    <MdDeleteForever className='me-2' />
                    Delete Tournament
                </Button>
            </Box>
        )
    } else {
        return null
    }
}