import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../context/GameContext"

export const KingHoldingTrophy = () => {
    const { dashboardAssets } = useGame()

    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            width={"50%"}
        >
            <Image
                src={dashboardAssets.kingHoldingTrophy}
                alt="king-holding-trophy"
                w={"50%"}
            />
        </Box>
    )
}