import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react';
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from './context/AuthContext'
import { GameProvider } from './context/GameContext'
import { SyncProvider } from './context/SyncContext'
import { DashboardProvider } from './context/DashboardContext'
import { WagmiProvider } from 'wagmi';
import { QueryClientProvider } from "@tanstack/react-query";
import { wagmiAdapter, queryClient } from './wallet/walletConfig'
import './styles/index.css'
import './styles/landing.css'
import './styles/sidebar.css'
import './styles/content.css'
import './styles/quest.css'
import './styles/tournament.css'
import './styles/dailyProgress.css'
import './styles/animation.css'
import './styles/board.css'
import './styles/skin.css'
import './styles/notifications.css'
import './styles/userinterface.css'
import './styles/playerList.css'
import './styles/modals.css'

if (import.meta.env.VITE_CLIENT_URL && import.meta.env.VITE_SENTRY_DNS) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0,

    tracePropagationTargets: [import.meta.env.VITE_CLIENT_URL],
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ChakraProvider toastOptions={{ defaultOptions: { position: "top", variant: "subtle" } }}>
            <GameProvider>
              <SyncProvider>
                <DashboardProvider>
                  <App />
                </DashboardProvider>
              </SyncProvider>
            </GameProvider>
          </ChakraProvider>
        </AuthProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
)
