export const getDevCardNameByType = (cardType: string) => {
  if (cardType === "Knight") {
    return "Knight";
  } else if (cardType === "Monopoly") {
    return "Monopoly";
  } else if (cardType === "RoadBuilding") {
    return "Road Building";
  } else if (cardType === "YearOfPlenty") {
    return "Year of Plenty";
  } else if (cardType === "VictoryPoint") {
    return "Victory Point";
  }
  return null;
};
