import { Box } from "@chakra-ui/react"
import { StartButton } from "../WaitingLobbyButtons/StartButton";
import { ReadyButton } from "../WaitingLobbyButtons/ReadyButton";
import { useSync } from "../../../../context/SyncContext";
import { DisconnectButton } from "../WaitingLobbyButtons/DisconnectButton";
import { SocketType } from "../../../../utils/types/types";
import { JoinDiscord } from "./JoinDiscord";
import { PlayerColumn } from "./PlayerColumn";
import { ChangeRoomLimitButton } from "../WaitingLobbyButtons/ChangeRoomLimitButton";
import { ShareRoomLinkButton } from "../WaitingLobbyButtons/ShareRoomLinkButton";
import { PlayerWaitingColumn } from "./PlayerWaitingColumn";

export const WaitingLobby = ({ socket }: { socket: SocketType }) => {
    const { players, gameData } = useSync()

    const maximumPlayer = gameData.RoomLimit;

    const playerList = Array(maximumPlayer).fill({ username: null });

    players.forEach((player, index) => {
        if (index < maximumPlayer) {
            playerList[index] = player;
        }
    });

    if (playerList && gameData.Status === 0) {
        return (
            <Box h={"100vh"} w={"100vw"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box className="lobby-box">
                    <Box className="lobby-box-header">
                        <Box>
                            {LobbyStatus(players, gameData)}
                            <JoinDiscord />
                        </Box>
                        <ChangeRoomLimitButton />
                    </Box>

                    <Box display={"flex"} justifyContent={"center"}>
                        {
                            playerList.length > 0 ?
                                playerList?.map((player, index) => {
                                    if (player.username !== null) {
                                        return (
                                            <PlayerColumn
                                                key={index}
                                                order={index}
                                                username={player.username}
                                                turnIndex={player.turnIndex}
                                                isBot={player.isBot}
                                                isOwner={player.isOwner}
                                                isReady={player.readyStatus}
                                                maximumPlayer={maximumPlayer}
                                            />
                                        )
                                    } else {
                                        return (
                                            <PlayerWaitingColumn
                                                key={index}
                                                order={index}
                                                maximumPlayer={maximumPlayer}
                                            />
                                        )
                                    }
                                }) :
                                <Box fontSize={"2vw"} margin={"auto"}>
                                    Loading New World...
                                </Box>
                        }
                    </Box>

                    {/* Lobby action buttons */}
                    <Box display={"flex"} alignItems={"center"} height={"6vw"}>
                        <DisconnectButton />
                        <ShareRoomLinkButton />
                        <ReadyButton socket={socket} />
                        <StartButton />
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return null
    }
}

export const LobbyStatus = (players: any[], gameData: any) => {
    if (players.length < gameData.RoomLimit) {
        return "Waiting for the players..."
    } else if (players.length === gameData.RoomLimit && players.filter((players) => players.readyStatus === true).length < gameData.RoomLimit) {
        return "Waiting for players to become ready..."
    } else if (players.length === gameData.RoomLimit && players.filter((players) => players.readyStatus === true).length === gameData.RoomLimit) {
        return "Waiting for the room owner to start the game..."
    }
}