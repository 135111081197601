export const getResourceAsset = (value: number, assets: any) => {
  if (value === 0) {
    return assets.desertTile;
  } else if (value === 1) {
    return assets.woodTile;
  } else if (value === 2) {
    return assets.brickTile;
  } else if (value === 3) {
    return assets.sheepTile;
  } else if (value === 4) {
    return assets.wheatTile;
  } else if (value === 5) {
    return assets.oreTile;
  } else {
    return "";
  }
};
