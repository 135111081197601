export const getResIcon = (type: string, assets: any) => {
  if (type === "wood") {
    return assets.woodIcon;
  } else if (type === "brick") {
    return assets.brickIcon;
  } else if (type === "sheep") {
    return assets.sheepIcon;
  } else if (type === "wheat") {
    return assets.wheatIcon;
  } else if (type === "ore") {
    return assets.oreIcon;
  } else {
    return "";
  }
};
