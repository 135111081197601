import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useGame } from "../context/GameContext"

export const TournamentJoin = (socket: SocketType) => {
    const { setTournamentDirectJoinGameID } = useGame()

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentAssignedRoom") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (parsedData) {
                    setTournamentDirectJoinGameID(parsedData.room_id)
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}