import { useEffect, useState } from "react";
import { SocketType } from "../../../utils/types/types";
import { getTurnIndexByAddress } from "../../../utils/functions/getTurnIndexByAddress";
import { useSync } from "../../../context/SyncContext";
import { AddResourceInfo } from './MarketTradeNotifications';
import { useGame } from "../../../context/GameContext";
import { logDivElement, logResourceElement, logTextDivElement, usernameDivElement } from "../../../utils/gameLogs/gameLogs";
import { getUsernameByIndex } from "../../../utils/functions/getUsernameByIndex";
import { getColorByIndex } from "../../../utils/assets/getColorByIndex";

export const CardDiscardNotification = (socket: SocketType) => {
    const { gameAssets } = useGame()
    const { players, gameData } = useSync()

    const [notifActive, setNotifActive] = useState(false)

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "DiscardList") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                const discardList = parsedData.discardLists
                if (discardList && discardList.length > 0) {
                    discardList.map((address) => {
                        const playerElement = document.getElementById(`player-${getTurnIndexByAddress(players, address)}`)

                        if (playerElement) {
                            const divElement = document.createElement("div")
                            divElement.classList.add("player-notification")
                            divElement.classList.add("being-ambushed-notif")
                            divElement.id = `card-discard-${getTurnIndexByAddress(players, address)}`

                            const imgElement = document.createElement("img")
                            imgElement.src = gameAssets.knightArt
                            imgElement.alt = "knight"
                            imgElement.style.width = "15%"
                            imgElement.style.marginRight = "0.5vw"

                            const textElement = document.createElement("span")
                            textElement.textContent = `is being ambushed!`

                            divElement.appendChild(imgElement)
                            divElement.appendChild(textElement)

                            playerElement.appendChild(divElement)

                            const gameLogs = document.getElementById("game-logs")
                            if (gameLogs) {
                                const playetTurnIndex = getTurnIndexByAddress(players, address)
                                const logDiv = logDivElement()

                                const usernameDiv = usernameDivElement(getUsernameByIndex(players, playetTurnIndex), getColorByIndex(playetTurnIndex))

                                const logText = logTextDivElement("ambushed")

                                const knightIcon = document.createElement("img")
                                knightIcon.src = gameAssets.knightIcon
                                knightIcon.alt = "knight-icon"
                                knightIcon.style.height = "150%"

                                logDiv.appendChild(usernameDiv)
                                logDiv.appendChild(logText)
                                logDiv.appendChild(knightIcon)

                                if (gameLogs.firstChild) {
                                    gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                                } else {
                                    gameLogs.appendChild(logDiv)
                                }
                            }
                        }
                    })
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "DiscardResources") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                const turnIndex = getTurnIndexByAddress(players, parsedData.address)

                const cardDiscardElement = document.getElementById(`card-discard-${turnIndex}`)
                if (cardDiscardElement) {
                    cardDiscardElement.remove()
                }

                const playerElement = document.getElementById(`player-${turnIndex}`)

                if (playerElement) {
                    const divElement = document.createElement("div")
                    divElement.classList.add("player-notification")
                    divElement.classList.add("discard-resource-notif")
                    divElement.id = `resource-discard-${turnIndex}`

                    const childDivElementOne = document.createElement("div")
                    childDivElementOne.style.display = "flex"
                    childDivElementOne.style.alignItems = "center"
                    childDivElementOne.style.justifyContent = "center"

                    const textElement = document.createElement("span")
                    textElement.textContent = "Lost these resources"

                    const imgElement = document.createElement("img")
                    imgElement.src = gameAssets.lostResourceIcon
                    imgElement.alt = "arrow-down"
                    imgElement.style.width = "10%"
                    imgElement.classList.add("me-2")

                    childDivElementOne.appendChild(imgElement)
                    childDivElementOne.appendChild(textElement)

                    divElement.appendChild(childDivElementOne)

                    const childDivElementTwo = document.createElement("div")
                    childDivElementTwo.style.display = "flex"
                    childDivElementTwo.style.justifyContent = "center"
                    childDivElementTwo.style.alignItems = "center"

                    AddResourceInfo(parsedData.discard.wood, "wood", "red", childDivElementTwo, true, gameAssets)
                    AddResourceInfo(parsedData.discard.brick, "brick", "red", childDivElementTwo, true, gameAssets)
                    AddResourceInfo(parsedData.discard.sheep, "sheep", "red", childDivElementTwo, true, gameAssets)
                    AddResourceInfo(parsedData.discard.wheat, "wheat", "red", childDivElementTwo, true, gameAssets)
                    AddResourceInfo(parsedData.discard.ore, "ore", "red", childDivElementTwo, true, gameAssets)

                    divElement.appendChild(childDivElementTwo)
                    playerElement?.appendChild(divElement)

                    setNotifActive(true)

                    const gameLogs = document.getElementById("game-logs")
                    if (gameLogs) {
                        const logDiv = logDivElement()

                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, turnIndex), getColorByIndex(turnIndex))

                        const logText = logTextDivElement("lost")

                        logDiv.appendChild(usernameDiv)
                        logDiv.appendChild(logText)

                        logResourceElement(parsedData.discard.wood, "wood", parsedData.discard.wood, logDiv, gameAssets)
                        logResourceElement(parsedData.discard.brick, "brick", parsedData.discard.brick, logDiv, gameAssets)
                        logResourceElement(parsedData.discard.sheep, "sheep", parsedData.discard.sheep, logDiv, gameAssets)
                        logResourceElement(parsedData.discard.wheat, "wheat", parsedData.discard.wheat, logDiv, gameAssets)
                        logResourceElement(parsedData.discard.ore, "ore", parsedData.discard.ore, logDiv, gameAssets)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv)
                        }
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (notifActive) {
            const timer = setTimeout(() => {
                for (let i = 0; i < gameData.RoomLimit; i++) {
                    const element = document.getElementById(`resource-discard-${i}`)

                    if (element) {
                        element.remove()
                    }
                }
                setNotifActive(false)
            }, 5000)

            return () => clearTimeout(timer)
        }
    }, [notifActive])
}