import { useEffect } from "react"
import { getIsometricRoadAsset } from "../../../../utils/assets/getIsometricRoadAsset"
import { getStraightRoadAsset } from "../../../../utils/assets/getStraightRoadAsset"

export const RoadEffects = (roadPositions: any[], assets: any, playersSkinChoices: string[] | null) => {
    useEffect(() => {
        if (roadPositions) {
            roadPositions.map((road) => {
                const roadElement = document.getElementById(`r/${road.position[0]},${road.position[1]}/${road.position[2]},${road.position[3]}`)
                if (roadElement && playersSkinChoices) {
                    if (roadElement.classList.contains("left-road") || roadElement.classList.contains("right-road")) {
                        roadElement.style.backgroundImage = `url(${getStraightRoadAsset(road.turnIndex, assets, playersSkinChoices[road.turnIndex])})`;
                    } else {
                        roadElement.style.backgroundImage = `url(${getIsometricRoadAsset(road.turnIndex, assets, playersSkinChoices[road.turnIndex])})`;
                    }

                    roadElement.style.backgroundSize = "cover"
                    roadElement.style.backgroundPosition = "center"
                    roadElement.style.pointerEvents = "none"
                }
            })
        }
    }, [roadPositions, playersSkinChoices])
}