import { IconButton, Box, Image } from '@chakra-ui/react'
import { useState } from 'react';
import { useGame } from '../../../context/GameContext';
import { SocketType } from '../../../utils/types/types';
import { SendAnalytiActionEvent } from '../../../socket/sendMessage/SendAnalyticActionEvent';

export const GameLogs = ({ socket }: { socket: SocketType }) => {
    const { gameAssets, isSpectator } = useGame()

    const [isOpen, setIsOpen] = useState(true);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
        !isSpectator && SendAnalytiActionEvent(socket, isOpen ? "GameLogModalClose" : "GameLogModalOpen")
    };

    return (
        <>
            <IconButton
                zIndex={1}
                position={"fixed"}
                left={"11%"}
                bottom={"1%"}
                width={"4%"}
                height={"7%"}
                fontSize={"2.5vw"}
                backgroundColor={"transparent"}
                colorScheme='yellow'
                onClick={toggleDrawer}
                aria-label='game-logs'
                icon={<img src={gameAssets.gameLogButton} alt='info-icon' width={"55%"} />}
            />
            <Box
                id="game-log-drawer"
                className={`game-log-drawer ${isOpen ? "open" : ""}`}
            >
                <Box className='game-log-header'>
                    <Image
                        src={gameAssets.gameLogDivider}
                        alt='horizontal-line'
                        width={"100%"}
                    />
                </Box>
                <Box className='game-log-body' id='game-logs' />
            </Box>
        </>
    )
}