import { Box } from "@chakra-ui/react"
import { JoinLobbyStage } from "./JoinLobbyStage"
import { ReConnectState } from "./ReConnectState"
import { RegisterState } from "./RegisterState"
import { CheckInState } from "./CheckInStage"
import { BreakStage } from "./BreakStage"

type NotificationStates = {
    State: number,
    Start_Time: string,
    FreezeTimeEnd: string,
    IsUserCheckedIn: boolean,
    TournamentID: number,
    GameID: number,
}

export const NotificationStates = ({ State, Start_Time, IsUserCheckedIn, TournamentID, GameID, FreezeTimeEnd }: NotificationStates) => {
    if (State === 1) {
        return <RegisterState startTime={Start_Time} />
    } else if (State === 2) {
        return <CheckInState
            IsUserCheckedIn={IsUserCheckedIn}
            startTime={Start_Time}
            tournamentID={TournamentID}
        />
    } else if (State === 3) {
        return <ReConnectState rejoinGameID={GameID} />
    } else if (State === 4) {
        return <JoinLobbyStage rejoinGameID={GameID} />
    } else if (State === 5) {
        return (
            <Box p={"0.25vw"}>
                Waiting for other tournament matches to over
            </Box>
        )
    } else if (State === 6) {
        return <BreakStage freezeTimeEnd={FreezeTimeEnd} />
    } else {
        return null
    }
}