import { Box, Button, useToast } from "@chakra-ui/react"
import { Task } from "./Task"
import { CreateClaimReward } from "../../../../../requests/DashboardRequests"
import { useDashboard } from "../../../../../context/DashboardContext"

type QuestType = {
    questID: number,
    questIndex: number,
    completedTasks: any[],
    notCompletedTasks: any[],
    isQuestClaimed: boolean
}

export const Quest = ({ questID, questIndex, completedTasks, notCompletedTasks, isQuestClaimed }: QuestType) => {
    const { setQuestList, setDailyTicketNumber } = useDashboard()
    const toast = useToast()

    const handleClaim = async () => {
        const response = await CreateClaimReward(questID)
        if (response && response.message === "quest claimed") {
            toast({
                title: "Reward: Daily Tournament Ticket",
                description: "Daily Tournament Ticket is succesfully claimed. You can use it for Daily Tournaments.",
                status: "success",
                duration: 5000,
            })
            setQuestList((prevState: any[]) => {
                return prevState.map(item => {
                    if (item.id === questID) {
                        return { ...item, is_quest_claimed: true };
                    }
                    return item;
                });
            });
            setDailyTicketNumber(prevDailyTicketNumber => prevDailyTicketNumber + 1)
        }
    }
    return (
        <Box className={questIndex % 2 === 0 ? "daily-tournament-odd-background" : "daily-tournament-even-background"}>
            <Box
                className="gradient-text-color"
                textAlign={"center"}
                fontSize={"1.25vw"}
            >
                Quest {questIndex + 1}
            </Box>
            <Box textColor={"white"}>
                {
                    completedTasks.length > 0 &&
                    completedTasks.map((task, index) => {
                        return (
                            <Task
                                key={index}
                                isCompleted={true}
                                haveBackground={index % 2 === 1}
                                task={task}
                                taskProgress={""}
                            />
                        )
                    })
                }
                {
                    notCompletedTasks.length > 0 &&
                    notCompletedTasks.map((task, index) => {
                        return (
                            <Task
                                key={index}
                                isCompleted={false}
                                haveBackground={index % 2 === 1}
                                task={task.id}
                                taskProgress={task.progress}
                            />
                        )
                    })
                }
            </Box>

            <Box
                p={"0.5vw"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Button
                    backgroundColor={"#f2c101"}
                    boxShadow={"0 0.25vw #976e00"}
                    w={"10vw"}
                    height={"2.5vw"}
                    fontSize={"1vw"}
                    textColor={"black"}
                    colorScheme={"yellow"}
                    isDisabled={notCompletedTasks.length !== 0 || isQuestClaimed}
                    onClick={handleClaim}
                >
                    {
                        isQuestClaimed ?
                            "Claimed" : "Claim"
                    }
                </Button>
            </Box>
        </Box>
    )
}