export const getResourceAnimation = (type: number, assets: any) => {
  if (type === 5) {
    return assets.oreGatherIcon;
  } else if (type === 4) {
    return assets.wheatGatherIcon;
  } else if (type === 3) {
    return assets.sheepGatherIcon;
  } else if (type === 2) {
    return assets.brickGatherIcon;
  } else if (type === 1) {
    return assets.woodGatherIcon;
  } else {
    return "";
  }
};
