import { Box } from "@chakra-ui/react"
import { GetTimeDifference } from "./GetTimeDifference"

export const BreakStage = ({ freezeTimeEnd }: { freezeTimeEnd: string }) => {
    if (freezeTimeEnd) {
        return (
            <Box p={"0.25vw"}>
                Break before the next stage
                <GetTimeDifference finishTime={freezeTimeEnd} />
            </Box>
        )
    } else {
        return null
    }
}