import { Box, RadioGroup, Radio, Stack } from '@chakra-ui/react'

type SkinFilterType = {
    skinFilter: string,
    setSkinFilter: (value: string) => void
}

export const SkinFilter = ({ skinFilter, setSkinFilter }: SkinFilterType) => {
    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={"1vw"}
            mb={"1vw"}
        >
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                me={"1vw"}
                textColor={"#745833"}
                fontSize={"2vw"}
                fontWeight={"600"}
            >
                Filter Skins:
            </Box>
            <RadioGroup
                display={"flex"}
                alignItems={"center"}
                onChange={(value) => setSkinFilter(value)}
                value={skinFilter}
            >
                <Stack direction={"row"} spacing={"2vw"}>
                    <SkinFilterRadioButton
                        value='All'
                        name='All'
                    />
                    <SkinFilterRadioButton
                        value='Ancient'
                        name='Ancient'
                    />
                </Stack>
            </RadioGroup>
        </Box>
    )
}

const SkinFilterRadioButton = ({ value, name }: { value: string, name: string }) => {
    return (
        <Radio
            borderColor={"#745833"}
            borderWidth={"0.25vw"}
            colorScheme={"blackAlpha"}
            sx={{
                height: "1.5vw",
                width: "1.5vw"
            }}
            value={value}
        >
            <Box fontSize={"1.5vw"}>
                {name}
            </Box>
        </Radio>
    )
}