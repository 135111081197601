import { SocketType } from "../../../utils/types/types"
import { useEffect, useState } from "react"
import { getTurnIndexByAddress } from "../../../utils/functions/getTurnIndexByAddress"
import { useSync } from "../../../context/SyncContext"
import { AddResourceInfo } from "./MarketTradeNotifications"
import { useGame } from '../../../context/GameContext'
import { logDivElement, logResourceElement, logTextDivElement, usernameDivElement } from "../../../utils/gameLogs/gameLogs"
import { getUsernameByIndex } from "../../../utils/functions/getUsernameByIndex"
import { getColorByIndex } from "../../../utils/assets/getColorByIndex"

export const PlayerTradeNotifications = (socket: SocketType) => {
    const { gameAssets } = useGame()
    const { players } = useSync()

    const [tradeData, setTradeData] = useState<boolean>(false)

    // Handle achieved trade
    useEffect(() => {
        if (socket && socket.lastJsonMessage && (socket.lastJsonMessage.type === "TradeAchieved" || socket.lastJsonMessage.type === "CounterTradeAchieved")) {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                setTradeData(true)

                const fromTurnIndex = getTurnIndexByAddress(players, parsedData.from)
                const toTurnIndex = getTurnIndexByAddress(players, parsedData.to)

                const playerElement1 = document.getElementById(`player-${fromTurnIndex}`)

                if (playerElement1) {
                    const divElement = document.createElement("div")
                    divElement.classList.add("player-notification")
                    divElement.classList.add("player-trade-notif")

                    const childDivElementOne = document.createElement("div")
                    childDivElementOne.style.display = "flex"
                    childDivElementOne.style.alignItems = "center"
                    childDivElementOne.style.justifyContent = "center"

                    const textElement = document.createElement("span")
                    textElement.textContent = "Player Trade"

                    const imgElement = document.createElement("img")
                    imgElement.src = gameAssets.playersHudIcon
                    imgElement.alt = "player-trade-icon"
                    imgElement.style.width = "10%"
                    imgElement.classList.add("me-2")

                    childDivElementOne.appendChild(imgElement)
                    childDivElementOne.appendChild(textElement)

                    divElement.appendChild(childDivElementOne)

                    const childDivElementTwo = document.createElement("div")
                    childDivElementTwo.style.display = "flex"
                    childDivElementTwo.style.justifyContent = "center"
                    childDivElementTwo.style.alignItems = "center"

                    AddResourceInfo(parsedData.wanted.wood, "wood", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.brick, "brick", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.sheep, "sheep", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.wheat, "wheat", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.ore, "ore", "#2BD01F", childDivElementTwo, false, gameAssets)

                    divElement.appendChild(childDivElementTwo)

                    const childDivElementThree = document.createElement("div")
                    childDivElementThree.style.display = "flex"
                    childDivElementThree.style.justifyContent = "center"
                    childDivElementThree.style.alignItems = "center"

                    AddResourceInfo(parsedData.offer.wood, "wood", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.brick, "brick", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.sheep, "sheep", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.wheat, "wheat", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.ore, "ore", "red", childDivElementThree, false, gameAssets)

                    divElement.appendChild(childDivElementThree)
                    playerElement1?.appendChild(divElement)
                }

                const playerElement2 = document.getElementById(`player-${toTurnIndex}`)

                if (playerElement2) {
                    const divElement = document.createElement("div")
                    divElement.classList.add("player-notification")
                    divElement.classList.add("player-trade-notif")

                    const childDivElementOne = document.createElement("div")
                    childDivElementOne.style.display = "flex"
                    childDivElementOne.style.alignItems = "center"
                    childDivElementOne.style.justifyContent = "center"

                    const textElement = document.createElement("span")
                    textElement.textContent = "Player Trade"

                    const imgElement = document.createElement("img")
                    imgElement.src = gameAssets.playersHudIcon
                    imgElement.alt = "player-trade-icon"
                    imgElement.style.width = "10%"
                    imgElement.classList.add("me-2")

                    childDivElementOne.appendChild(imgElement)
                    childDivElementOne.appendChild(textElement)

                    divElement.appendChild(childDivElementOne)

                    const childDivElementTwo = document.createElement("div")
                    childDivElementTwo.style.display = "flex"
                    childDivElementTwo.style.justifyContent = "center"
                    childDivElementTwo.style.alignItems = "center"

                    AddResourceInfo(parsedData.offer.wood, "wood", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.offer.brick, "brick", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.offer.sheep, "sheep", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.offer.wheat, "wheat", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.offer.ore, "ore", "#2BD01F", childDivElementTwo, false, gameAssets)

                    divElement.appendChild(childDivElementTwo)

                    const childDivElementThree = document.createElement("div")
                    childDivElementThree.style.display = "flex"
                    childDivElementThree.style.justifyContent = "center"
                    childDivElementThree.style.alignItems = "center"

                    AddResourceInfo(parsedData.wanted.wood, "wood", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.brick, "brick", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.sheep, "sheep", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.wheat, "wheat", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.ore, "ore", "red", childDivElementThree, false, gameAssets)

                    divElement.appendChild(childDivElementThree)
                    playerElement2?.appendChild(divElement)
                }

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()

                    const usernameDiv = usernameDivElement(getUsernameByIndex(players, fromTurnIndex), getColorByIndex(fromTurnIndex))

                    const logText = logTextDivElement("got")

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)

                    logResourceElement(parsedData.wanted.wood, "wood", parsedData.wanted.wood, logDiv, gameAssets)
                    logResourceElement(parsedData.wanted.brick, "brick", parsedData.wanted.brick, logDiv, gameAssets)
                    logResourceElement(parsedData.wanted.sheep, "sheep", parsedData.wanted.sheep, logDiv, gameAssets)
                    logResourceElement(parsedData.wanted.wheat, "wheat", parsedData.wanted.wheat, logDiv, gameAssets)
                    logResourceElement(parsedData.wanted.ore, "ore", parsedData.wanted.ore, logDiv, gameAssets)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }

                    const logDiv2 = logDivElement()

                    const usernameDiv2 = usernameDivElement(getUsernameByIndex(players, toTurnIndex), getColorByIndex(toTurnIndex))

                    const logText2 = logTextDivElement("got")

                    logDiv2.appendChild(usernameDiv2)
                    logDiv2.appendChild(logText2)

                    logResourceElement(parsedData.offer.wood, "wood", parsedData.offer.wood, logDiv2, gameAssets)
                    logResourceElement(parsedData.offer.brick, "brick", parsedData.offer.brick, logDiv2, gameAssets)
                    logResourceElement(parsedData.offer.sheep, "sheep", parsedData.offer.sheep, logDiv2, gameAssets)
                    logResourceElement(parsedData.offer.wheat, "wheat", parsedData.offer.wheat, logDiv2, gameAssets)
                    logResourceElement(parsedData.offer.ore, "ore", parsedData.offer.ore, logDiv2, gameAssets)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv2, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv2)
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (tradeData) {
            const timeout = setTimeout(() => {
                const elements = document.querySelectorAll(".player-trade-notif")
                if (elements) {
                    elements.forEach((element) => element.remove())
                }
                setTradeData(false);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [tradeData]);
}