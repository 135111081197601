import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"

export const RemainingResources = (socket: SocketType) => {
    const { setRemainingRes } = useSync()

    // Handle total resources updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RemainingInfo") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                setRemainingRes(parsedData.remaining)
            }
        }
    }, [socket && socket.lastJsonMessage])
}