export const requestWakeLock = async (
  setWakeLock: (value: WakeLockSentinel | null) => void
) => {
  try {
    if ("wakeLock" in navigator) {
      const lock = await navigator.wakeLock.request("screen");
      setWakeLock(lock);
      console.log("Wake Lock is active.");

      // Add an event listener to handle the wake lock being released
      lock.addEventListener("release", () => {
        console.log("Wake Lock was released.");
      });
    } else {
      console.log("Wake Lock API is not supported on this browser.");
    }
  } catch (err) {
    console.error(`Error: ${err.name}, ${err.message}`);
  }
};
