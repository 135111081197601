import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type CardRemaningTimeType = {
    finishTime: string,
    isUserCheckedIn: boolean,
    isUserRegistered: boolean,
    state: number,
    stage: number,
    isChampionship: boolean
}

export const CardRemaningTime = ({ finishTime, isUserCheckedIn, isUserRegistered, state, stage, isChampionship }: CardRemaningTimeType) => {
    const [timeLeft, setTimeLeft] = useState<number>(0);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const finish = new Date(finishTime);

            let adjustedFinish;

            if (state === 1) {
                adjustedFinish = isChampionship
                    ? finish.getTime() - 60 * 60 * 1000
                    : finish.getTime() - 15 * 60 * 1000;
            } else {
                adjustedFinish = finish.getTime();
            }
            const difference = adjustedFinish - now.getTime();

            if (difference > 0) {
                setTimeLeft(difference);
            } else {
                setTimeLeft(0);
            }
        };

        calculateTimeLeft();
        const intervalId = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(intervalId);
    }, [finishTime, state]);

    const formatTime = (milliseconds: number): string => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const hoursStr = hours > 0 ? `${hours} h ` : "";
        const minutesStr = minutes > 0 ? `${minutes} m ` : "";
        const secondsStr = `${seconds} sec`;

        return `${hoursStr}${minutesStr}${secondsStr}`.trim();
    };

    if (state === 1) {
        return (
            <Box
                textColor={"white"}
                backgroundColor={isUserRegistered ? "#69be55" : "#c97f64"}
                borderRadius={"0px 0px 5px 5px "}
                fontSize={"1.25vw"}
                p={"1%"}
            >
                <Box fontWeight={"600"}>
                    You have succesfully registered to the tournament.
                </Box>
                <Box>
                    Check-in will be started in
                </Box>
                <Box
                    m={"auto"}
                    backgroundColor={isUserRegistered ? " #2E5C2B" : "#582116"}
                    w={"fit-content"}
                    pe={"5%"}
                    ps={"5%"}
                    borderRadius={"10px"}
                >
                    {timeLeft !== null ? formatTime(timeLeft) : "Loading..."}
                </Box>
            </Box>
        );
    } else if (state === 2 && stage < 2) {
        return (
            <Box
                textColor={"white"}
                backgroundColor={isUserCheckedIn ? "#69be55" : "#c97f64"}
                borderRadius={"0px 0px 5px 5px "}
                fontSize={"1.25vw"}
                p={"1%"}
            >
                <Box fontWeight={"600"}>
                    {
                        isUserCheckedIn ?
                            "You have succesfully check-in to the tournament." :
                            "Please check-in before the tournament starts."
                    }
                </Box>
                <Box>
                    Tournament will be started in
                </Box>
                <Box
                    m={"auto"}
                    backgroundColor={isUserCheckedIn ? " #2E5C2B" : "#582116"}
                    w={"fit-content"}
                    pe={"5%"}
                    ps={"5%"}
                    borderRadius={"10px"}
                >
                    {timeLeft !== null ? formatTime(timeLeft) : "Loading..."}
                </Box>
            </Box>
        );
    }

    return null
}
