import { Button, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"

type OverviewRegisterType = {
    IsUserSignedUp: boolean,
    IsPrivate: boolean,
    State: number,
    HaveTicket: boolean,
    handleRegister: () => void,
    handleRegisterCancel: () => void
}

export const OverviewRegister = ({ IsUserSignedUp, IsPrivate, State, HaveTicket, handleRegister, handleRegisterCancel }: OverviewRegisterType) => {
    const { dashboardAssets } = useGame()

    if (!IsUserSignedUp) {
        return (
            <Button
                w={"100%"}
                backgroundColor={"#72a8da"}
                border={"2px solid #3c77ae"}
                boxShadow={"0px 2px #3c77ae"}
                colorScheme={"blue"}
                isDisabled={(State !== 1 && State !== 2) || (IsPrivate && !HaveTicket)}
                mb={2}
                onClick={handleRegister}
            >
                {
                    IsPrivate ?
                        <Image src={dashboardAssets.lockIcon} w={"18px"} className='me-2' alt='register-icon' />
                        :
                        <Image src={dashboardAssets.registerIcon} w={"18px"} className='me-2' alt='register-icon' />
                }
                Register
            </Button>
        )
    } else {
        return (
            <Button
                w={"100%"}
                backgroundColor={"#c97f64"}
                border={"2px solid #A72C01"}
                boxShadow={"0px 2px #A72C01"}
                colorScheme={"red"}
                isDisabled={(State !== 1 && State !== 2) || (IsPrivate && !HaveTicket)}
                mb={2}
                onClick={handleRegisterCancel}
            >
                {
                    IsPrivate ?
                        <Image src={dashboardAssets.lockIcon} w={"18px"} className='me-2' alt='register-icon' />
                        :
                        <Image src={dashboardAssets.registerIcon} w={"18px"} className='me-2' alt='register-icon' />
                }
                Cancel Registration
            </Button>
        )
    }
}