import { Avatar, Box, Button } from "@chakra-ui/react"
import { getAvatarByIndex } from "../../../../../utils/assets/getAvatarByIndex"
import { useGame } from "../../../../../context/GameContext"
import { FaEye } from "react-icons/fa6"
import { TbBroadcast } from "react-icons/tb"
import { useNavigate } from "react-router-dom"

export const TournamentMatchList = ({ Matches }: { Matches: any[] }) => {
    const liveMatches = Matches && Matches.filter((match) => match.state === 1)

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                textColor={"white"}
                fontSize={"3vw"}
            >
                <TbBroadcast className="me-2" color='red' />
                Watch Live
            </Box>

            <Box
                textColor={"white"}
                textAlign={"center"}
                fontSize={"2vw"}
                mb={"2vw"}
            >
                {liveMatches.length} Remaining Game in Current Stage
            </Box>

            <Box w={"75%"}>
                {
                    liveMatches.map((match, index) => {
                        return (
                            <MatchInfoRow
                                key={index}
                                Players={match.Players}
                                GameID={match.id}
                            />
                        )
                    })
                }
            </Box>
        </Box>
    )
}

const MatchInfoRow = ({ Players, GameID }: { Players: any[], GameID: number }) => {
    const { setGameID, setIsReconnect, setIsSpectator } = useGame()

    const navigate = useNavigate()

    const handleWatchLive = async () => {
        setIsSpectator(true)
        setGameID(GameID)
        setIsReconnect(true)
        navigate(`/spectator`)
    }

    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={"2vw"}
            textColor={"white"}
        >
            <Button
                height={"8.4vw"}
                width={"8.4vw"}
                borderRadius={"15px 0px 0px 15px"}
                border={"0.1vw solid black"}
                backgroundColor={"#cb3e50"}
                colorScheme={"red"}
                fontSize={"1.5vw"}
                display={"flex"}
                flexDirection={"column"}
                onClick={handleWatchLive}
            >
                <FaEye />
                Watch Live
            </Button>
            <Box
                display={"flex"}
                justifyContent={"space-around"}
                backgroundColor={"#53483c"}
                borderRadius={"0px 15px 15px 0px"}
                border={"0.1vw solid black"}
                padding={"1vw"}
                w={"80%"}
            >
                {
                    Players && Players.length > 0 &&
                    Players.map((player, index) => {
                        return (
                            <MatchPlayerInfo
                                key={index}
                                avatarID={index}
                                playerName={player.username}
                            />
                        )
                    })
                }
            </Box>
        </Box>
    )
}

const MatchPlayerInfo = ({ avatarID, playerName }: { avatarID: number, playerName: string }) => {
    const { dashboardAssets } = useGame()

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Avatar
                width={"4vw"}
                height={"4vw"}
                backgroundColor={"wheat"}
                src={getAvatarByIndex(Number(avatarID), dashboardAssets)}
            />
            <Box fontSize={"1.5vw"}>
                {playerName}
            </Box>
        </Box>
    )
}