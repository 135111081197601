import { useEffect } from "react"
import { getResourceAnimation } from '../../../../utils/assets/getResourceAnimation'
import { getHexIdFromPosition } from '../../../../utils/constants/getHexIdFromPosition'
import { getCityCharAnimation, getSettleCharAnimation } from '../../../../utils/assets/getCharAnimation'
import { getCursorColor } from '../../../../utils/assets/getCursorColor'
import { getHoverEffectClassName, getSetupEffectClassName } from '../../../../utils/constants/getHoverEffectClassName'
import { useGame } from '../../../../context/GameContext'
import { isSettleDistanceValid } from '../../../../utils/functions/isSettleDistanceValid'
import { isRoadConnected } from '../../../../utils/functions/isRoadConnected'
import { isSettlePosition } from '../../../../utils/functions/isSettlePosition'
import { PlaySoundEffect } from '../../../../sounds/playSoundEffect'

export const BoardEffects = (
    gameData: any,
    myGameData: any,
    largestArmyOwner: number,
    longestRoadOwner: number,
    players: any[],
    dice: { diceOne: number, diceTwo: number } | null,
    tileNumbers: any[],
    tileResources: any[],
    robberPosition: number,
    settlementPositions: any[],
    roadPositions: any[],
    assets: any,
    animations: any
) => {
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()

    useEffect(() => {
        if (myGameData) {
            const myTurnIndex = myGameData.turn_index

            if (gameData.TurnIndex === myTurnIndex && (gameData.Status !== 3)) {
                const tileElements = document.getElementsByClassName("hex-normal")
                if (tileElements) {
                    for (let i = 0; i < tileElements.length; i++) {
                        const tileElement = tileElements[i] as HTMLElement;
                        tileElement.style.pointerEvents = "auto";
                    }
                }
            } else {
                const tileElements = document.getElementsByClassName("hex-normal")
                if (tileElements) {
                    for (let i = 0; i < tileElements.length; i++) {
                        const tileElement = tileElements[i] as HTMLElement;
                        tileElement.style.pointerEvents = "none";
                    }
                }
            }

            if (gameData.GameOver) {
                const tileElements = document.getElementsByClassName("hex-normal")
                if (tileElements) {
                    for (let i = 0; i < tileElements.length; i++) {
                        const tileElement = tileElements[i] as HTMLElement;
                        tileElement.style.pointerEvents = "none";
                    }
                }
            }
        }
    }, [gameData, myGameData])

    // Handle largest army lead banner
    useEffect(() => {
        if (largestArmyOwner !== -1 && players && players.length > 0) {
            for (let i = 0; i < players.length; i++) {
                const element = document.getElementById(`player-largest-army-${i}`)
                const element2 = document.getElementById(`largest-army-${i}`)
                if (element?.hasChildNodes && element2) {
                    element.removeChild(element2)
                }
            }

            const imgElement = document.createElement("img");
            imgElement.src = assets.leaderLabel;
            imgElement.alt = "largest-army"
            imgElement.classList.add("leader-label-army")
            imgElement.id = `largest-army-${largestArmyOwner}`
            document.getElementById(`player-largest-army-${largestArmyOwner}`)?.appendChild(imgElement)
        }
    }, [largestArmyOwner, players]);

    // Handle longest road lead banner
    useEffect(() => {
        if (longestRoadOwner !== -1 && players && players.length > 0) {
            for (let i = 0; i < players.length; i++) {
                const element = document.getElementById(`player-longest-road-${i}`)
                const element2 = document.getElementById(`longest-road-${i}`)
                if (element?.hasChildNodes && element2) {
                    element.removeChild(element2)
                }
            }

            const imgElement = document.createElement("img");
            imgElement.src = assets.leaderLabel;
            imgElement.alt = "longest-road"
            imgElement.classList.add("leader-label-road")
            imgElement.id = `longest-road-${longestRoadOwner}`
            document.getElementById(`player-longest-road-${longestRoadOwner}`)?.appendChild(imgElement)
        }
    }, [longestRoadOwner, players]);

    // Resource gather animation
    useEffect(() => {
        if (dice && dice.diceOne + dice.diceTwo !== 7 && tileNumbers.length > 0 && tileResources.length > 0 && !gameData.GameOver) {
            const indices: any[] = [];
            tileNumbers.forEach((tile, index) => {
                if (tile === dice.diceOne + dice.diceTwo && index !== robberPosition) {
                    indices.push(index);

                    PlaySoundEffect(soundEffects.notCollectResourceSFX, sfxVolume, sfxEnabled)
                }
            });

            if (indices.length > 0) {
                indices.forEach((index) => {
                    const resourceType = tileResources[index];

                    const element = document.getElementById(`tile-${index}`);

                    if (element) {
                        const existingAnimation = element.querySelector(".resource-gather-animation");

                        if (existingAnimation && existingAnimation.parentNode === element) {
                            element.removeChild(existingAnimation);
                        }

                        const imgElement = document.createElement("img");
                        const animationUrl = getResourceAnimation(resourceType, assets);
                        imgElement.src = animationUrl;
                        imgElement.alt = "animation";
                        imgElement.classList.add("resource-gather-animation");
                        element.appendChild(imgElement);

                        setTimeout(() => {
                            if (imgElement.parentNode === element) {
                                element.removeChild(imgElement);
                            }
                        }, 2000);
                    }
                });
            }
        }
    }, [dice]);

    // Handle char animations
    useEffect(() => {
        if (tileResources && settlementPositions && settlementPositions.length > 0) {
            settlementPositions.forEach((settle) => {
                const positions = getHexIdFromPosition(settle.position[0], settle.position[1]);

                positions.forEach((pos) => {
                    if (robberPosition !== pos.hexID) {
                        const element = document.getElementById(`tile-${pos.hexID}`);
                        const resourceType = tileResources[pos.hexID];

                        if (element && resourceType && settle.revenue === 1) {
                            const existingAnimation = element.querySelector(`.char-animation-${pos.index}`);
                            if (!existingAnimation) {
                                const imgElement = document.createElement("img");
                                const animationUrl = getSettleCharAnimation(resourceType, settle.turnIndex, animations);
                                imgElement.src = animationUrl;
                                imgElement.style.width = '40%';
                                imgElement.style.pointerEvents = "none";
                                imgElement.style.zIndex = "3";
                                imgElement.classList.add(`char-animation-${pos.index}`);
                                imgElement.id = `char/settle/${settle.position[0]},${settle.position[1]}/${pos.index}`;
                                element.appendChild(imgElement);
                            }
                        } else if (element && resourceType && settle.revenue === 2) {
                            const oldAnimation = document.getElementById(`char/settle/${settle.position[0]},${settle.position[1]}/${pos.index}`);

                            if (oldAnimation) {
                                oldAnimation.remove();
                            }

                            const existingAnimation = element.querySelector(`.char-animation-${pos.index}`);
                            if (!existingAnimation) {
                                const imgElement = document.createElement("img");
                                const animationUrl = getCityCharAnimation(resourceType, settle.turnIndex, animations);
                                imgElement.src = animationUrl;
                                imgElement.style.width = '40%';
                                imgElement.style.pointerEvents = "none";
                                imgElement.style.zIndex = "3";
                                imgElement.classList.add(`char-animation-${pos.index}`);
                                imgElement.id = `char/city/${settle.position[0]},${settle.position[1]}/${pos.index}`;
                                element.appendChild(imgElement);

                            }
                        }
                    }
                });
            });
        }
    }, [settlementPositions, tileResources, robberPosition]);


    // Remove char animation for robber position
    useEffect(() => {
        if (robberPosition) {
            const element = document.getElementById(`tile-${robberPosition}`)

            if (element) {
                for (let i = 0; i < 6; i++) {
                    const existingAnimation = element.querySelector(`.char-animation-${i}`);

                    if (existingAnimation) {
                        existingAnimation.remove()
                    }
                }
            }
        }
    }, [robberPosition])

    // Get Colored Cursor according to player's color
    useEffect(() => {
        if (myGameData) {
            const gameScreen = document.getElementById("game-screen")
            if (gameScreen) {
                gameScreen.style.cursor = `url(${getCursorColor(myGameData.turn_index)}), auto`;
            }
        }
    }, [myGameData])

    // Show player place-able positions
    useEffect(() => {
        if (settlementPositions.length > 0 && roadPositions.length > 0 && myGameData && gameData.TurnNumber > 1 && !gameData.GameOver) {
            const settleElements = document.querySelectorAll(".settle-effect")
            const roadElements = document.querySelectorAll(".road-effect")

            const myRoadPositions = roadPositions.filter((road) => road.turnIndex === myGameData.turn_index)
            const mySettlePositions = settlementPositions.filter((settle) => settle.turnIndex === myGameData.turn_index)

            settleElements.forEach((settle) => {
                const splittedData = settle.id.split("/");
                const coord = splittedData[1].split(",");
                const x = Number(coord[0]);
                const y = Number(coord[1]);

                if (isSettlePosition(mySettlePositions, x, y)) {
                    settle.classList.add("settlement-hover-effect")
                } else if (isSettleDistanceValid(settlementPositions, x, y) && isRoadConnected(myRoadPositions, x, y, x, y)) {
                    settle.classList.add("house-hover-effect")
                    settle.classList.add(getHoverEffectClassName(myGameData.turn_index))
                } else {
                    settle.classList.add("build-position-events-none")
                }
            })

            roadElements.forEach((road) => {
                const splittedData = road.id.split("/");
                const coordStart = splittedData[1].split(",");
                const xStart = Number(coordStart[0]);
                const yStart = Number(coordStart[1]);

                const coordEnd = splittedData[2].split(",");
                const xEnd = Number(coordEnd[0]);
                const yEnd = Number(coordEnd[1]);

                if (isRoadConnected(myRoadPositions, xStart, yStart, xEnd, yEnd)) {
                    road.classList.add("road-hover-effect")
                    road.classList.add(getHoverEffectClassName(myGameData.turn_index))
                } else {
                    road.classList.add("build-position-events-none")
                }
            })

            return () => {
                roadElements.forEach((road) => {
                    if (road.classList.contains("build-position-events-none")) {
                        road.classList.remove("build-position-events-none")
                    }
                })

                settleElements.forEach((settle) => {
                    if (settle.classList.contains("build-position-events-none")) {
                        settle.classList.remove("build-position-events-none")
                    }
                })
            }
        }
    }, [settlementPositions, roadPositions, myGameData, gameData])

    // Show player place-able settle positions in setup stage
    useEffect(() => {
        if (settlementPositions && myGameData && gameData.TurnNumber < 2 && gameData.Status === 1 && gameData.TurnIndex === myGameData.turn_index) {
            const settleElements = document.querySelectorAll(".settle-effect")

            settleElements.forEach((settle) => {
                const splittedData = settle.id.split("/");
                const coord = splittedData[1].split(",");
                const x = Number(coord[0]);
                const y = Number(coord[1]);

                if (isSettleDistanceValid(settlementPositions, x, y) && !isSettlePosition(settlementPositions, x, y)) {
                    settle.classList.add("house-setup-effect")
                    settle.classList.add(getHoverEffectClassName(myGameData.turn_index))
                    settle.classList.add(getSetupEffectClassName(myGameData.turn_index))
                }
            })
        } else if (myGameData) {
            return () => {
                const settleElements = document.querySelectorAll(".settle-effect")

                settleElements.forEach((settle) => {
                    if (settle.classList.contains("house-setup-effect")) {
                        settle.classList.remove("house-setup-effect")
                    }

                    if (settle.classList.contains(getHoverEffectClassName(myGameData.turn_index))) {
                        settle.classList.remove(getHoverEffectClassName(myGameData.turn_index))
                    }

                    if (settle.classList.contains(getSetupEffectClassName(myGameData.turn_index))) {
                        settle.classList.remove(getSetupEffectClassName(myGameData.turn_index))
                    }
                })
            }
        }
    }, [settlementPositions, myGameData, gameData])

    // Show player place-able road positions in setup stage
    useEffect(() => {
        if (settlementPositions && myGameData && gameData.TurnNumber < 2 && gameData.Status === 2 && gameData.TurnIndex === myGameData.turn_index) {
            const roadElements = document.querySelectorAll(".road-effect")

            const mySettlePositions = settlementPositions.filter((settle) => settle.turnIndex === myGameData.turn_index)

            roadElements.forEach((road) => {
                const splittedData = road.id.split("/");
                const coordStart = splittedData[1].split(",");
                const xStart = Number(coordStart[0]);
                const yStart = Number(coordStart[1]);

                const coordEnd = splittedData[2].split(",");
                const xEnd = Number(coordEnd[0]);
                const yEnd = Number(coordEnd[1]);

                if (gameData.TurnNumber === 0) {
                    const firstSetupSettle = mySettlePositions.filter((settle, index) => index === 0)
                    if (isSettlePosition(firstSetupSettle, xStart, yStart) || isSettlePosition(firstSetupSettle, xEnd, yEnd)) {
                        road.classList.add("road-setup-effect")
                        road.classList.add(getHoverEffectClassName(myGameData.turn_index))
                        road.classList.add(getSetupEffectClassName(myGameData.turn_index))
                    }
                } else if (gameData.TurnNumber === 1) {
                    const secondSetupSettle = mySettlePositions.filter((settle, index) => index === 1)
                    if (isSettlePosition(secondSetupSettle, xStart, yStart) || isSettlePosition(secondSetupSettle, xEnd, yEnd)) {
                        road.classList.add("road-setup-effect")
                        road.classList.add(getHoverEffectClassName(myGameData.turn_index))
                        road.classList.add(getSetupEffectClassName(myGameData.turn_index))
                    }
                }

            })
        } else if (myGameData) {
            return () => {
                const roadElements = document.querySelectorAll(".road-effect")

                roadElements.forEach((road) => {
                    if (road.classList.contains("road-setup-effect")) {
                        road.classList.remove("road-setup-effect")
                    }

                    if (road.classList.contains(getHoverEffectClassName(myGameData.turn_index))) {
                        road.classList.remove(getHoverEffectClassName(myGameData.turn_index))
                    }

                    if (road.classList.contains(getSetupEffectClassName(myGameData.turn_index))) {
                        road.classList.remove(getSetupEffectClassName(myGameData.turn_index))
                    }
                })
            }
        }
    }, [settlementPositions, myGameData, gameData])
}