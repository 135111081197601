import pioneersLogo from '../../assets/Landing/pioneers_logo.png'
import betaHeader from '../../assets/Landing/beta_header.png'
import { Box, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { SignInModal } from './SignInModal'

export const Splash = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    const [isSmallScreen] = useMediaQuery('(max-width: 820px)')
    const [is4K] = useMediaQuery('(min-width: 3800px)')

    const goldTextColor = "#ECD96B"

    const trailerURL = "https://pioneers.fra1.cdn.digitaloceanspaces.com/tournament_trailer.webm"

    return (
        <Box className="splash-blur">
            <Box className="splash-centered-div">
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"45%"}>
                    <img
                        src={betaHeader}
                        alt='beta-header'
                        width={is4K ? "225vh" : "100vh"}
                        className='mb-2'
                    />
                    <img
                        src={pioneersLogo}
                        alt='Pioneers of New World Game Logo'
                        width={is4K ? "400vh" : "175vh"}
                    />
                    <Box
                        as={"h2"}
                        fontSize={isSmallScreen ? "40px" : "3vw"}
                        className='germania-one-regular black-text-outline'
                    >
                        Set Sails to
                        <span className='germania-one-regular' style={{ color: goldTextColor }}> New World </span>
                    </Box>
                    <SignInModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onOpen={onOpen}
                    />
                </Box>
                <Box className='trailer-margin trailer-width' w={"45%"}>
                    <video controls muted autoPlay >
                        <source src={trailerURL} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            </Box>
        </Box>
    )
}