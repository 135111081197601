import { Box } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"
import { useNavigate } from "react-router-dom"

export const ReJoinButton = () => {
    const { dashboardAssets, reConnectGameID, setGameID, setIsReconnect } = useGame()

    const navigate = useNavigate()

    const handleRejoin = () => {
        const gameID = reConnectGameID
        setGameID(gameID)
        setIsReconnect(true)
        navigate(`/game`)
    }

    if (reConnectGameID !== 0) {
        return (
            <Box
                onClick={handleRejoin}
                backgroundImage={dashboardAssets.rejoinButtonBG}
                backgroundSize={"cover"}
                backgroundRepeat={"no-repeat"}
                border={"0.25vw solid black"}
                borderRadius={"3vw"}
                w={"20vw"}
                height={"20vw"}
                className="content-button-effects"
            >
                <Box
                    textAlign={"center"}
                    textColor={"white"}
                    fontSize={"2.75vw"}
                    fontWeight={"700"}
                    lineHeight={"1"}
                    mb={"1vw"}
                    mt={"6vw"}
                    sx={{
                        WebkitTextStroke: "0.15vw #764C31",
                    }}
                >
                    Your Match is Running
                </Box>
                <Box
                    backgroundColor={"#FDA314"}
                    textColor={"white"}
                    textShadow={"0 0 1vw black"}
                    border={"0.25vw solid #764C31"}
                    borderRadius={"1vw"}
                    height={"4vw"}
                    w={"17vw"}
                    ml={"auto"}
                    mr={"auto"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    fontSize={"2.5vw"}
                >
                    Re-Join
                </Box>
            </Box>
        )
    } else {
        return null
    }
}