import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, useDisclosure, } from '@chakra-ui/react'
import { DashboardCloseButton } from '../General/DashboardCloseButton'
import { CreateMissionModal } from './Mission/CreateMissionModal'
import { DeleteMissionModal } from './Mission/DeleteMissionModal'
import { CreateQuestModal } from './Mission/CreateQuestModal'
import { AssignTicketModal } from './Tournament/AssignTicketModal'
import { BanPlayerFromTournamentModal } from './Tournament/BanPlayerFromTournamentModal'
import { AddPlayerToBlacklistModal } from './Tournament/AddPlayerToBlacklistModal'

export const AdminModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Button
                w={"75%"}
                height={"fit-content"}
                ml={"auto"}
                mr={"auto"}
                backgroundColor={"#AC8358"}
                fontSize={"2vw"}
                borderRadius={"2vw"}
                colorScheme={"orange"}
                mb={"1vw"}
                onClick={onOpen}
            >
                Admin Panel
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#745833"}
                    textColor={"white"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Admin Panel</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <CreateMissionModal />
                        <DeleteMissionModal />
                        <CreateQuestModal />
                        <AssignTicketModal />
                        <BanPlayerFromTournamentModal />
                        <AddPlayerToBlacklistModal />
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}