import { Box, Link, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react"
import { FaDiscord } from "react-icons/fa"
import { useGame } from "../../../../context/GameContext"

const ASSET_CDN_URL = import.meta.env.VITE_ASSET_CDN_URL

const whatIsPionnersGIF = ASSET_CDN_URL + "GuideVideos/0_What_Is_Pioneers.webm"
const placingAndSettlementGIF = ASSET_CDN_URL + "GuideVideos/1_Placing_2nd_Settlement.webm"
const gamePlayGIF = ASSET_CDN_URL + "GuideVideos/2_Gameplay_Pioneers.webm"
const collectionGIF = ASSET_CDN_URL + "GuideVideos/3_Roll_Dice.webm"
const ambushedGIF = ASSET_CDN_URL + "GuideVideos/4_Player_get_ambushed.webm"
const stealingGIF = ASSET_CDN_URL + "GuideVideos/5_Stealing_Player.webm"
const playerProgressGIF = ASSET_CDN_URL + "GuideVideos/6_Player_Progress.webm"
const upgradeCityGIF = ASSET_CDN_URL + "GuideVideos/7_Upgrade_to_City.webm"
const tradeRequestGIF = ASSET_CDN_URL + "GuideVideos/8_Player_Trade_Request.webm"
const bankTradeGIF = ASSET_CDN_URL + "GuideVideos/9_Player_Bank_Trade.webm"
const buildPortGIF = ASSET_CDN_URL + "GuideVideos/10_Build_Port.webm"
const useCardGIF = ASSET_CDN_URL + "GuideVideos/11_Player_using_Card.webm"
const playerVictoryGIF = ASSET_CDN_URL + "GuideVideos/12_Player_Victory.webm"
const buyDevCardGIF = ASSET_CDN_URL + "GuideVideos/13_Buying_Development_Card.webm"

const section1 = ASSET_CDN_URL + "TutorialSections/1_game_goal.png"
const section2 = ASSET_CDN_URL + "TutorialSections/2_turn_start.png"
const section3 = ASSET_CDN_URL + "TutorialSections/3_resources.png"
const section4 = ASSET_CDN_URL + "TutorialSections/4_rolling_a_7.png"
const section5 = ASSET_CDN_URL + "TutorialSections/5_player_robbed.png"
const section6 = ASSET_CDN_URL + "TutorialSections/6_turn_progression.png"
const section7 = ASSET_CDN_URL + "TutorialSections/7_building_cost.png"
const section8 = ASSET_CDN_URL + "TutorialSections/8_building_info.png"
const section9 = ASSET_CDN_URL + "TutorialSections/9_trade_Action.png"
const section10 = ASSET_CDN_URL + "TutorialSections/10_ratio_ports.png"
const section11 = ASSET_CDN_URL + "TutorialSections/11_develop_cards.png"
const section12 = ASSET_CDN_URL + "TutorialSections/12_Victory_points.png"
const section13 = ASSET_CDN_URL + "TutorialSections/13_two_player_rules.png"
const section14 = ASSET_CDN_URL + "TutorialSections/14_three_four_player_rules.png"
const section15 = ASSET_CDN_URL + "TutorialSections/15_tournament_flow.png"
const section16 = ASSET_CDN_URL + "TutorialSections/16_checkin_register.png"
const section17 = ASSET_CDN_URL + "TutorialSections/17_standings.png"
const section18 = ASSET_CDN_URL + "TutorialSections/18_single_elimination.png"
const section19 = ASSET_CDN_URL + "TutorialSections/19_double_elimination.png"
const section20_1 = ASSET_CDN_URL + "TutorialSections/20_championship_1.png"
const section20_2 = ASSET_CDN_URL + "TutorialSections/20_championship_2.png"
const section21_1 = ASSET_CDN_URL + "TutorialSections/21_vp_calculation_1.png"
const section21_2 = ASSET_CDN_URL + "TutorialSections/21_vp_calculation_2.png"
const section21_3 = ASSET_CDN_URL + "TutorialSections/21_vp_calculation_3.png"

export const TutorialContent = () => {
    const { dashboardAssets } = useGame()

    return (
        <>
            {/* ************** Guide Content **************** */}
            <Box
                className='scroll-bar-with-margin'
                width={"75%"}
                height={"100%"}
                textColor={"#806541"}
                overflowY={"scroll"}
                scrollBehavior={"smooth"}
                fontSize={"18px"}
                pt={5}
            >

                <Box id='what-is-pioneers'>
                    <img src={dashboardAssets.whatPioneersTitle} alt='title1' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5}>
                            <strong>Pioneers of the New World</strong> is a multiplayer turn-based game.
                            Players take the role of settlers, striving to expand their
                            colonies and explore the New World by building settlements, cities, and roads.
                            In order to do so, they’ll need to carefully manage their available resources, and make smart decisions.
                        </Text>
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={whatIsPionnersGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='game-modes'>
                    <img src={dashboardAssets.twoPlayerTitle} alt='title14' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section13} alt='two-player-rules-13' />
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.threePlayerTitle} alt='title15' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section14} alt='three-player-rules-14' />
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.fourPlayerTitle} alt='title16' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section14} alt='four-player-rules-14' />
                    </Box>
                </Box>

                <Box id='tournaments'>
                    <img src={dashboardAssets.tournamentTitle} alt='title17' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section15} alt='tournmanet-flow-15' />
                        <Text p={5}>
                            <strong>Important:</strong> Some tournaments requiere players to hold the appropriate entry ticket.
                            You can get tickets from Quests and from tournament hosts (Private communities tournaments)
                        </Text>
                        <img src={section16} alt='checkin-register-16' />
                        <Text p={5}>
                            <strong>Important:</strong> Being registered doesn’t secure your participation, you also need to check-in, check in opens 15 minutes before the tournament starts.
                            Registered players number could be higher than players number, but check-in not.
                            So be sure to check-in as quickly as possible to secure your spot.
                        </Text>
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.standingsTitle} alt='title18' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section17} alt='standings-17' />
                        <Text p={5}>
                            <UnorderedList>
                                <ListItem>
                                    After players finish the Elimination Stage, the tournament standings are updated accordingly to the tournament standings logic.
                                </ListItem>
                                <ListItem>
                                    These criteria decide the player's position in tournament ranking and in this sequence:
                                </ListItem>
                                <OrderedList>
                                    <ListItem>
                                        We check the highest win count. If some players share a postion we compare Highest VP.
                                    </ListItem>
                                    <ListItem>
                                        Highest VP; If some players share still split a position we compare VP %.
                                    </ListItem>
                                    <ListItem>
                                        We check players table VP %, this means All the Players VP / sum of all VP of the games they have played. If some players share still split a position.
                                    </ListItem>
                                    <ListItem>
                                        Signup time, quicker signup, higher position in the Tournament Ranking.
                                    </ListItem>
                                </OrderedList>
                            </UnorderedList>
                        </Text>
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.vpCalculationTitle} alt='title13' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section21_1} alt='vp_calculation_1' className="mb-4" />
                        <img src={section21_2} alt='vp_calculation_2' className="mb-4" />
                        <img src={section21_3} alt='vp_calculation_3' />
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.singleElimTitle} alt='title19' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section18} alt='single-elim-18' />
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.doubleElimTitle} alt='title20' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section19} alt='double-elim-19' />
                    </Box>
                </Box>

                <Box>
                    <img src={dashboardAssets.championshipTitle} alt='title21' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section20_1} alt='double-elim-20' style={{ marginBottom: "1vh" }} />
                        <img src={section20_2} alt='double-elim-21' />
                    </Box>
                </Box>

                <Box id='game-goal'>
                    <img src={dashboardAssets.gameGoalTitle} alt='title22' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} alignSelf={"start"}>
                            The objective is to be the first player to earn <strong>Enough Victory Points</strong>.
                            There are various ways of earning these points, as you can see in the following table.
                        </Text>
                        <img src={section1} alt='game-goal-section-1' />
                    </Box>
                </Box>

                <Box id='initial-placement'>
                    <img src={dashboardAssets.initialPlacementTitle} alt='title3' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5} alignSelf={"start"}>
                            At the <strong>start</strong> of the game, players place their <strong>initial settlements and roads</strong> on the board.
                            <br />
                            Each player starts with 2 settlements and 2 roads.
                            <br />
                            The first player begins by placing a settlement at any intersection of three hexes.
                            <br />
                            Then, that player places a road next to that settlement. The process continues with each player in turn.
                        </Text>
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={placingAndSettlementGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='turn-start'>
                    <img src={dashboardAssets.turnStartTitle} alt='title4' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            When each player has placed their <strong>first settlement and road</strong>, the whole process is repeated once again. However, the
                            player who was last in the previous round now goes first.<br />
                            Example: Players A, B, C, and D place their initial settlements in the following order: <strong>A-B-C-D-D-C-B-A.</strong>
                            After everyone has placed two settlements and two roads, <strong>the regular turn-taking begins</strong>
                        </Text>
                        <img src={section2} alt='section-2' className='mb-5' />
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={gamePlayGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='resources'>
                    <img src={dashboardAssets.resourcesTitle} alt='title5' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            At the beginning of your turn, roll the dice. Players then receive resources from hexes corresponding to the
                            rolled number, if they have a building adjacent to them.
                            Settlements yield one resource, while cities yield two.
                            <br />
                            Different tiles produce different resources, as you can see here:
                        </Text>
                        <img src={section3} alt='section-3' className='mb-5' />
                        <Text fontSize={"30px"} mb={3} fontWeight={"700"}>How Collection Happens?</Text>
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={collectionGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='robber'>
                    <img src={dashboardAssets.rollingTitle} alt='title6' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            The <strong>number seven</strong> doesn’t appear on any tile. If a player rolls a seven, a special event occurs. First, all players
                            holding more than 7 cards must discard half of them (rounded down).
                            <br />   <br />
                            Then, the player whose turn it is, moves the Robber to any tile, and steals a random card from a player with a
                            building next to that tile.
                            <br />   <br />
                            A hex with Robber placed on it doesn’t produce any resources, even if its number is rolled. This effect persists until
                            the Robber is moved elsewhere, either by rolling a seven, or using a Knight card.
                        </Text>
                        <img src={section4} alt='section-4' className='mb-5' />
                        <Text p={5} mb={5}>
                            Dice rolled 7, players with equal or more than 8 get ambushed, and must discard
                            half of their cards.
                        </Text>
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={ambushedGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                        <Text p={5} mb={5}>Placing the Robber and stealing from an opponent.</Text>
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={stealingGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                        <Text p={5} mb={5}>
                            The player that moved the robber can steal 1 resource from another player and block resources collection
                        </Text>
                        <img src={section5} alt='section-5' className='mb-5' />
                    </Box>
                </Box>

                <Box id='turn-progression'>
                    <img src={dashboardAssets.turnProgressionTitle} alt='title7' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            Following the dice-rolling phase, the turn proceeds. A player can take any of the following three actions:
                            <br /><br />
                            There is no restriction on the number of actions you can take, provided they are completed within your time limit.
                            These actions can be executed in any order.
                        </Text>
                        <img src={section6} alt='section-6' className='mb-5' />
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={playerProgressGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='building'>
                    <img src={dashboardAssets.buildingTitle} alt='title8' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <img src={section7} alt='section-7' className='mb-5' />
                        <Text p={5} mb={5}>
                            If a player possesses sufficient resources, they can build a road, settlement, upgrade a settlement to a city, or
                            purchase a Development card. The costs are described in the following table:
                            <br /><br />
                            What if you lack the necessary resources? You can either wait or trade.
                        </Text>
                        <img src={section8} alt='section-8' className='mb-5' />
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={upgradeCityGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='trading'>
                    <img src={dashboardAssets.tradingTitle} alt='title9' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            You have the option to trade with other players or with the bank.
                            <br /><br />
                            When you trade with other players you can offer an exchange of any combination of resources. If a player accepts
                            your offer, you can accept it back and the trade will be completed.
                            <br /><br />
                            Whenever you propose a trade, each player has an option to accept it, but you decide from which player you’ll
                            accept the trade. (If someone is close to winning, perhaps you don’t want to trade with them.)
                        </Text>
                        <img src={section9} alt='section-9' className='mb-5' />
                        <video autoPlay loop playsInline>
                            <source src={tradeRequestGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='bank'>
                    <img src={dashboardAssets.tradeBankTitle} alt='title10' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5}>
                            Bank trading works differently. You can get any resource from the bank by trading any four of the same resource.
                            For instance, if you need Ore, but possess an abundance of Wood, you can exchange four Wood for a single Ore.
                            <br /><br />
                            This ratio can be improved by using ports.
                        </Text>
                        <video autoPlay loop playsInline>
                            <source src={bankTradeGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='ports'>
                    <img src={dashboardAssets.portTitle} alt='title11' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            A port is a settlement or city positioned on an edge hex with a 3:1 or 2:1 symbol adjacent to it.
                            <br /> <br />
                            As the name suggests, a 3:1 port allows you to trade three of the same resource for any desired resource. Similarly, a
                            2:1 port further reduces the cost to two resources, but now requires a specific resource, indicated next to the port.
                        </Text>
                        <img src={section10} alt='section-10' className='mb-5' />
                        <video autoPlay loop playsInline>
                            <source src={buildPortGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='dev-cards'>
                    <img src={dashboardAssets.devCardTitle} alt='title12' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={5}>
                            The third action is playing a Development card. You can play only one per turn, and you cannot play a card you’ve
                            just bought. There are five different types of Development cards.
                        </Text>
                        <img src={section11} alt='section-11' className='mb-5' />
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={buyDevCardGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                        <Text p={5} mb={5}>
                            <strong>Knight</strong>
                            <br />
                            When you play a Knight, you move the Robber to another hex. Then you select a player with a settlement or city
                            adjacent next to that hex and steal a random card from them.
                            This is the only card you can play before rolling the dice on your turn.
                            <br />   <br />
                            <strong>Road Building</strong>
                            <br />
                            This card allows you to build two roads for free.
                            <br />   <br />
                            <strong>Year of Plenty</strong>
                            <br />
                            You choose any two resources and receive them from the bank.
                            <br />   <br />
                            <strong>Monopoly</strong>
                            <br />
                            When you play Monopoly, you select a resource, and receive all of that resource from other players.
                            <br />   <br />
                            <strong>Victory Point Card</strong>
                            <br />
                            Each Victory Point card is worth one point. You cannot play these cards. Instead, they are revealed when you reach a
                            total of 10 VP. (Counting points from these cards.) They can be revealed on the turn you bought them, if you’ve
                            reached 10 VP.
                            <br />   <br />
                        </Text>
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={useCardGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>

                <Box id='victory-conditions'>
                    <img src={dashboardAssets.victoryConditionsTitle} alt='title13' />
                    <Box p={5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Text p={5} mb={2}>
                            There are two ways to earn <strong>special victory points</strong>. By attaining the longest road or the largest army, each rewarding
                            2 Victory Points.<br /><br />
                            <strong> Longest Road</strong>
                            <br />
                            A first player to construct five consecutive roads, earns the Longest Road status, worth 2 VPs. However, if another
                            player surpasses this road’s length, they claim the Longest Road status, along with the 2 VPs.
                            <br /><br />
                            <strong>Largest Army</strong>
                            <br />
                            Largest army operates similarly in a similar way, but it is based on the number of Knight cards played. This status is
                            initially granted to a player who plays three Knight cards.
                        </Text>
                        <img src={section12} alt='section-12' className='mb-5' />
                        <video className='mb-5' autoPlay loop playsInline>
                            <source src={playerVictoryGIF} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                        <Text p={5} mb={2} textAlign={"center"}>
                            <strong>More Information</strong>
                            <br />
                            Now you know the basics of the game. If there’s still something bothering you, ask for more information on
                            Pioneers’ official Discord.
                            <br />
                            However, the best way to learn more about the strategy is simply by playing. So, don’t wait any longer and get right
                            to it.
                            <br />  <br />
                            pioneers.game
                        </Text>
                        <Link href='https://discord.gg/7fqNXcarYM' target="_blank">
                            <FaDiscord color='#7289DA' fontSize={"50px"} />
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    )
}