import { useEffect } from "react"
import { SocketType } from "../utils/types/types";
import { useSync } from "../context/SyncContext";

export const RemainingDevCards = (socket: SocketType) => {
    const { setRemaingingDevCards } = useSync()

    // Handle total resources updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TotalAvailableDevCardUpdate") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                setRemaingingDevCards(parsedData.total)
            }
        }
    }, [socket && socket.lastJsonMessage])
}