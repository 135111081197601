import { IconButton, Button, Box, Image } from "@chakra-ui/react"
import { getResponseLabelByIndex } from "../../../utils/assets/getColorByIndex"
import { SocketType, TradeData } from "../../../utils/types/types"
import { useEffect } from 'react'
import { useGame } from '../../../context/GameContext'
import { Timer } from "../Timer/Timer"
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent"

type PlayerTradeResponse = {
    responses: any[];
    trade: TradeData | null;
    socket: SocketType;
    gameID: number;
    players: any[];
    gameData: any;
    myGameData: any;
    onClose: () => void
}

export const PlayerTradeResponse = ({ responses, trade, socket, gameID, players, gameData, myGameData, onClose }: PlayerTradeResponse) => {
    const { gameAssets } = useGame()

    useEffect(() => {
        if (responses && responses.length === gameData.RoomLimit - 1 && responses.every((response) => response.response === false)) {
            const eventData = {
                id: gameID,
                tradeID: trade?.id,
                response: "0",
                counterOffer: false
            }
            SendGameEvent(socket, "TradeComplete", eventData)
        }
    }, [responses, gameData])

    const handleTradeComplete = async (address: any) => {
        const eventData = {
            id: gameID,
            tradeID: trade?.id,
            response: address,
            counterOffer: false
        }
        SendGameEvent(socket, "TradeComplete", eventData)
        onClose()
    }

    const handleTradeCancel = async () => {
        const eventData = {
            id: gameID,
            tradeID: trade?.id,
            response: "0"
        }
        SendGameEvent(socket, "TradeComplete", eventData)
    }

    if (trade && players && myGameData) {
        return (
            <Box
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                w={"25vw"}
            >
                <Box>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        fontSize={"1.5vw"}
                        fontWeight={"bolder"}
                        textShadow={"0.1vw 0.1vw black"}
                        mb={"0.2vw"}
                    >
                        <Box me={"1vw"}>
                            Trade Offer
                        </Box>
                        <Timer activatedTime={trade.time} timeLimit={30} />
                    </Box>

                    <Box
                        display={"flex"}
                        flexDir={"column"}
                        alignItems={"center"}
                    >
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            backgroundColor={"#FDEEDA"}
                            w={"23vw"}
                            mb={"0.2vw"}
                        >
                            <Image
                                src={gameAssets.receiveIcon}
                                alt='receive-icon'
                                width={"8%"}
                                pos={"absolute"}
                                left={"1.5vw"}
                            />
                            <OfferData number={trade.wanted.wood} iconSrc={gameAssets.woodIcon} isGiving={false} />
                            <OfferData number={trade.wanted.brick} iconSrc={gameAssets.brickIcon} isGiving={false} />
                            <OfferData number={trade.wanted.sheep} iconSrc={gameAssets.sheepIcon} isGiving={false} />
                            <OfferData number={trade.wanted.wheat} iconSrc={gameAssets.wheatIcon} isGiving={false} />
                            <OfferData number={trade.wanted.ore} iconSrc={gameAssets.oreIcon} isGiving={false} />
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            backgroundColor={"#FDEEDA"}
                            w={"23vw"}
                        >
                            <Image
                                src={gameAssets.giveIcon}
                                alt='give-icon'
                                width={"8%"}
                                pos={"absolute"}
                                left={"1.5vw"}
                            />
                            <OfferData number={trade.offer.wood} iconSrc={gameAssets.woodIcon} isGiving={true} />
                            <OfferData number={trade.offer.brick} iconSrc={gameAssets.brickIcon} isGiving={true} />
                            <OfferData number={trade.offer.sheep} iconSrc={gameAssets.sheepIcon} isGiving={true} />
                            <OfferData number={trade.offer.wheat} iconSrc={gameAssets.wheatIcon} isGiving={true} />
                            <OfferData number={trade.offer.ore} iconSrc={gameAssets.oreIcon} isGiving={true} />
                        </Box>
                    </Box>

                    <Box
                        fontSize={"1.5vw"}
                        fontWeight={"bolder"}
                        textShadow={"0.1vw 0.1vw black"}
                        mb={"0.2vw"}
                    >
                        Player Responses
                    </Box>

                    <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        alignItems={"center"}
                    >
                        {
                            players.map((player, index) => {
                                if (player.turnIndex !== myGameData.turn_index) {
                                    const playerResponse = responses.find((response) => response.turnIndex === player.turnIndex)
                                    return (
                                        <IconButton
                                            key={index}
                                            className={getResponseLabelByIndex(player.turnIndex)}
                                            w={"3vw"}
                                            h={"3vw"}
                                            isRound={true}
                                            colorScheme={"none"}
                                            aria-label='accept-button'
                                            isDisabled={!playerResponse || (playerResponse && !playerResponse.response)}
                                            isLoading={!playerResponse}
                                            icon={
                                                playerResponse &&
                                                    playerResponse.response ?
                                                    <Image src={gameAssets.acceptIcon} alt='accept-icon' width={"50%"} /> :
                                                    <Image src={gameAssets.refuseIcon} alt='refuse-icon' width={"50%"} />
                                            }
                                            onClick={() => handleTradeComplete(playerResponse && playerResponse.address)}
                                        />
                                    )
                                }
                            })
                        }
                    </Box>
                </Box>
                <Button
                    width={"12vw"}
                    height={"2.25vw"}
                    fontSize={"1.5vw"}
                    backgroundColor={"#e4685d"}
                    border={"0.25vw solid #c23d28"}
                    borderRadius={"0.5vw"}
                    colorScheme={'red'}
                    mb={"0.5vw"}
                    mt={"0.5vw"}
                    onClick={handleTradeCancel}
                >
                    Cancel
                </Button>
            </Box>
        )
    } else {
        return null
    }
}

type OfferDataType = {
    number: number,
    iconSrc: string,
    isGiving: boolean
}

const OfferData = ({ number, iconSrc, isGiving }: OfferDataType) => {
    if (number && number > 0) {

        return (
            <Box
                display={"flex"}
                alignItems={"center"}
            >
                <Box
                    textColor={isGiving ? "#d1523d" : "#77a34b"}
                    fontSize={"2vw"}
                    fontWeight={"700"}
                >
                    {isGiving ? "-" : "+"}
                    {number}
                </Box>
                <Image
                    src={iconSrc}
                    alt={"res-icpn"}
                    width={"3vw"}

                />
            </Box>
        )
    } else {
        return null;
    }
}