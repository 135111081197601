import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext";

export const Balance = ({ balance }: { balance: number }) => {
    const { dashboardAssets } = useGame()

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box textColor={"white"} fontSize={"30px"}>
                Balance
            </Box>
            <Box
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
                backgroundColor={"#534c41"}
                border={"1px solid #1f1c18"}
                height={"50px"}
                width={"125px"}
                borderRadius={"10px"}
                p={"5px"}
            >
                <Image
                    src={dashboardAssets.walletIcon}
                    alt="wallet-icon"
                    w={"30px"}
                />
                <Box
                    textColor={"#f0d63a"}
                    fontWeight={"700"}
                    fontSize={"30px"}
                >
                    $ {balance}
                </Box>
            </Box>
        </Box>
    )
}