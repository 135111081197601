import { useEffect } from "react"
import { getCityAsset } from "../../../../utils/assets/getCityAsset"
import { getSettlementAsset } from "../../../../utils/assets/getSettlementAsset"

export const SettlementEffects = (settlePositions: any[], myGameData: any, assets: any, playersSkinChoices: string[] | null) => {
    useEffect(() => {
        if (settlePositions && settlePositions.length > 0) {
            settlePositions.map((settle) => {
                const houseElement = document.getElementById(`h/${settle.position[0]},${settle.position[1]}`)
                if (houseElement && playersSkinChoices) {
                    if (settle.revenue && settle.revenue === 1) {
                        houseElement.style.backgroundImage = `url(${getSettlementAsset(settle.turnIndex, assets, playersSkinChoices[settle.turnIndex])})`;
                        houseElement.style.backgroundSize = "inherit"
                        houseElement.style.backgroundPosition = "center"
                        houseElement.style.height = "35%"
                        houseElement.style.width = "35%"
                        houseElement.style.zIndex = "3"

                        if (myGameData && settle.turnIndex !== myGameData.turn_index) {
                            houseElement.style.pointerEvents = "none"
                        }

                        if (houseElement.classList.contains("top-house")) {
                            houseElement.style.top = "-25%"
                            houseElement.style.left = "0%"
                            houseElement.style.right = "0%"
                        } else if (houseElement.classList.contains("top-right-house")) {
                            houseElement.style.top = "2.5%"
                            houseElement.style.right = "-15%"
                        } else if (houseElement.classList.contains("top-left-house")) {
                            houseElement.style.top = "-2.5%"
                            houseElement.style.left = "-17.5%"
                        } else if (houseElement.classList.contains("bottom-house")) {
                            houseElement.style.bottom = "-5.5%"
                            houseElement.style.left = "0%"
                            houseElement.style.right = "0%"
                        } else if (houseElement.classList.contains("bottom-right-house")) {
                            houseElement.style.bottom = "17.5%"
                            houseElement.style.right = "-15%"
                        } else if (houseElement.classList.contains("bottom-left-house")) {
                            houseElement.style.bottom = "15%"
                            houseElement.style.left = "-17.5%"
                        }
                    }
                    else if (settle.revenue && settle.revenue === 2) {
                        houseElement.style.backgroundImage = `url(${getCityAsset(settle.turnIndex, assets, playersSkinChoices[settle.turnIndex])})`;
                        houseElement.style.backgroundSize = "inherit"
                        houseElement.style.backgroundPosition = "center"
                        houseElement.style.height = "45%"
                        houseElement.style.width = "40%"
                        houseElement.style.pointerEvents = "none"
                        houseElement.style.zIndex = "3"

                        if (houseElement.classList.contains("top-house")) {
                            houseElement.style.top = "-30%"
                        } else if (houseElement.classList.contains("top-right-house")) {
                            houseElement.style.top = "-4%"
                        } else if (houseElement.classList.contains("top-left-house")) {
                            houseElement.style.top = "-10%"
                            houseElement.style.left = "-20%"
                        }
                    }
                }
            })
        }
    }, [settlePositions, myGameData, playersSkinChoices])
}