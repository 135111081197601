import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Avatar } from '@chakra-ui/react'
import { useGame } from '../../../context/GameContext'
import { getColorByIndex } from '../../../utils/assets/getColorByIndex'
import { useEffect, useState } from 'react'
import { getPlaceCoordsByHexId } from '../../../utils/functions/getPlaceCoordsByHexId'
import { getUserIndexByPlace } from '../../../utils/functions/getUserIndexByPlace'
import { SocketType } from '../../../utils/types/types'
import { useSync } from '../../../context/SyncContext'
import { Timer } from '../Timer/Timer'
import { getAvatarByIndex } from '../../../utils/assets/getAvatarByIndex'
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent'

type RobbedPlayer = {
    isOpen: boolean,
    onClose: () => void,
    socket: SocketType
}

export const RobbedPlayer = ({ isOpen, onClose, socket }: RobbedPlayer) => {
    const { gameID, dashboardAssets } = useGame()
    const { myGameData, players, settlePositions, gameData, robberPosition, totalResCards } = useSync()

    const [robbedPlayerList, setRobbedPlayerList] = useState<any[]>([])

    useEffect(() => {
        if (myGameData && robberPosition > -1 && gameData.Status === 7) {
            const myTurnIndex = myGameData.turn_index

            const places = getPlaceCoordsByHexId(robberPosition)
            const userIndexes = getUserIndexByPlace(settlePositions, places)

            if (userIndexes && userIndexes.length > 0) {
                const distinctNumbers = userIndexes!.filter(
                    (number, index, array) => array.indexOf(number) === index && myTurnIndex !== number
                );
                if (distinctNumbers.length > 1) {
                    setRobbedPlayerList(distinctNumbers)
                }
            }
        } else {
            setRobbedPlayerList([])
        }
    }, [myGameData, robberPosition, gameData])

    const handleRobPlayer = async (playerIndex: number) => {
        const eventData = {
            id: gameID,
            targetIndex: playerIndex
        }
        SendGameEvent(socket, "StealResource", eventData)
        setRobbedPlayerList([])
        onClose()
    }

    if (robbedPlayerList.length > 1) {
        return (
            <Modal
                isCentered
                closeOnOverlayClick={false}
                closeOnEsc={false}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius={"1vw"}
                    background={"linear-gradient(#CDB57A, #9B8152)"}
                    maxW={"max-content"}
                    maxH={"40vh"}
                >
                    <ModalHeader
                        h={"10vh"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        fontSize={"2vw"}
                        borderRadius={"1vw 1vw 0 0"}
                        backgroundColor={"#6D5535"}
                        textColor={"white"}
                    >
                        Pick a victim for the Robber's mischief
                    </ModalHeader>

                    <ModalBody
                        h={"20vh"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        p={"1vw"}
                    >
                        {
                            players.map((player, index) => {
                                const playerIndex = player.turnIndex
                                const playerUsername = player.username

                                if (robbedPlayerList.includes(playerIndex)) {
                                    return (
                                        <Avatar
                                            key={index}
                                            boxSize={"7.5vw"}
                                            name={playerUsername}
                                            src={getAvatarByIndex(playerIndex, dashboardAssets)}
                                            ms={"1vw"}
                                            me={"1vw"}
                                            borderWidth={"0.5vw"}
                                            borderColor={getColorByIndex(playerIndex)}
                                            filter={totalResCards[playerIndex] > 0 ? undefined : "grayscale(100%)"}
                                            _hover={{
                                                borderColor: totalResCards[playerIndex] > 0 ? "white" : undefined,
                                                cursor: totalResCards[playerIndex] > 0 ? "pointer" : "not-allowed"
                                            }}
                                            onClick={() => {
                                                if (totalResCards[playerIndex] > 0) {
                                                    handleRobPlayer(playerIndex)
                                                }
                                            }}
                                        />
                                    )
                                }
                            })
                        }
                    </ModalBody>
                    <ModalFooter
                        h={"10vh"}
                        display={"flex"}
                        justifyContent={"center"}
                        backgroundColor={"#6D5535"}
                        borderRadius={"0 0 1vw 1vw"}
                        textColor={"white"}
                    >
                        <Timer
                            activatedTime={gameData.RobberTime.toString()}
                            timeLimit={20}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    } else {
        return null
    }
}