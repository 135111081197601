import { Image, Box } from "@chakra-ui/react"
import { ResourceNumbers } from "../../../utils/types/types"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"
import { PlaySoundEffect } from "../../../sounds/playSoundEffect"

type PlayerResCardType = {
    iconSrc: string,
    resourceName: string,
    count: number,
    onChange: (value: ResourceNumbers) => void,
    borderColor: string,
    name: "Wood" | "Brick" | "Sheep" | "Wheat" | "Ore"
}

export const PlayerResCard = ({ iconSrc, resourceName, count, onChange, borderColor, name }: PlayerResCardType) => {
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()
    const { resources } = useSync()

    const isDisabled = count >= resources[name]
    const isZero = resources[name] === 0
    const isCountZero = count === 0

    const handleIncreaseNumber = () => {
        if (count + 1 < 10) {
            onChange(prevState => ({
                ...prevState,
                [name]: count + 1
            }));

            if (borderColor === "red") {
                PlaySoundEffect(soundEffects.decreaseCardAmountSFX, sfxVolume, sfxEnabled)
            } else if (borderColor === "green") {
                PlaySoundEffect(soundEffects.increaseCardAmountSFX, sfxVolume, sfxEnabled)
            }
        }
    }

    const handleDecreaseNumber = () => {
        if (count > 0) {
            onChange(prevState => ({
                ...prevState,
                [name]: count - 1
            }));
        }
    }

    return (
        <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} me={"0.5vw"}>
            <Box
                className={`card-chosen-animation-${borderColor}`}
                filter={isZero && borderColor === "red" ? "grayscale(100%)" : undefined}
                pos={"relative"}
                h={isCountZero ? "6vw" : "4vw"}
                w={"3.5vw"}
                backgroundColor={borderColor === "red" ? "#eddeae" : "#a0ad5b"}
                borderRadius={"0.5vw"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={() => {
                    if (borderColor === "green") {
                        handleIncreaseNumber()
                    } else if (!isDisabled) {
                        handleIncreaseNumber()
                    }
                }}
            >
                <Image
                    src={iconSrc}
                    alt={resourceName}
                    width={"2.5vw"}
                />
                <Box
                    className="top-card-number"
                    sx={{
                        WebkitTextStroke: `0.1vw ${borderColor === "red" ? "#801111" : "#195f0c"}`,
                    }}
                >
                    {count}
                </Box>
            </Box>
            {
                count > 0 &&
                <Box
                    _hover={{
                        cursor: "pointer"
                    }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"2.5vw"}
                    height={"1.75vw"}
                    fontSize={"2vw"}
                    mt={"0.25vw"}
                    backgroundColor={"#dc6958"}
                    borderRadius={"0vw 0vw 0.5vw 0.5vw"}
                    border={"0.2vw solid #c65f50"}
                    onClick={handleDecreaseNumber}
                >
                    -
                </Box>
            }
        </Box>
    )
}