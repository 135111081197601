import { Box, Image } from "@chakra-ui/react";
import { useGame } from "../../../../../context/GameContext";

type CardInfoType = {
    CurrentPlayers: number,
    IsChampionship: boolean,
    IsDoubleElim: boolean,
    Prize: number[]
}

export const CardInfo = ({ CurrentPlayers, IsChampionship, IsDoubleElim, Prize }: CardInfoType) => {
    const { dashboardAssets } = useGame()

    return (
        <Box backgroundColor={"#C1A675"} fontSize={"1.25vw"}>
            <Box display={"flex"} textAlign={"center"} backgroundColor={"#63421a"} textColor={"white"} p={"0.5vw"}>
                <Box className="col-3" alignSelf={"center"} zIndex={1}>
                    Players
                </Box>
                <Box className="col-3" alignSelf={"center"} zIndex={1}>
                    Mode
                </Box>
                <Box className="col-3" alignSelf={"center"} zIndex={1}>
                    Games
                </Box>
                <Box className="col-3" alignSelf={"center"} zIndex={1}>
                    Prize Pool
                </Box>
            </Box>
            <Box display={"flex"} textAlign={"center"} backgroundColor={"#efe4d2"} textColor={"#73553f"} p={"0.5vw"}>
                <Box className="col-3 d-flex align-items-center justify-content-center" alignSelf={"center"}>
                    <Image className='me-2' src={dashboardAssets.maxPlayersIcon} w={"1.1vw"} alt='max-players-icon' />
                    {CurrentPlayers}
                </Box>
                <Box className="col-3" alignSelf={"center"} >
                    {
                        IsChampionship ?
                            "Championship" :
                            IsDoubleElim ?
                                "Knockout" :
                                "Single Elim."
                    }
                </Box>
                <Box className="col-3" alignSelf={"center"} >
                    {
                        IsChampionship ?
                            "3-5" :
                            IsDoubleElim ?
                                "1-3" :
                                "1-2"
                    }
                </Box>
                <Box className="col-3 d-flex align-items-center justify-content-center" alignSelf={"center"} fontWeight={"700"}>
                    <Image src={dashboardAssets.coinIcon} width={"1.5vw"} className='me-2' alt='coin-cion' />
                    $
                    {
                        Prize.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                        }, 0)
                    }
                </Box>
            </Box>
        </Box>
    )
}