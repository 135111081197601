export const getPrevCityAsset = (turnIndex: number, skinChoice: string) => {
  if (skinChoice === "Default") {
    if (turnIndex === 0) {
      return "default-red-city-prev";
    } else if (turnIndex === 1) {
      return "default-blue-city-prev";
    } else if (turnIndex === 2) {
      return "default-pink-city-prev";
    } else if (turnIndex === 3) {
      return "default-grey-city-prev";
    }
  } else if (skinChoice === "Halloween") {
    if (turnIndex === 0) {
      return "halloween-red-city-prev";
    } else if (turnIndex === 1) {
      return "halloween-blue-city-prev";
    } else if (turnIndex === 2) {
      return "halloween-pink-city-prev";
    } else if (turnIndex === 3) {
      return "halloween-grey-city-prev";
    }
  } else if (skinChoice === "Viking") {
    if (turnIndex === 0) {
      return "viking-red-city-prev";
    } else if (turnIndex === 1) {
      return "viking-blue-city-prev";
    } else if (turnIndex === 2) {
      return "viking-pink-city-prev";
    } else if (turnIndex === 3) {
      return "viking-grey-city-prev";
    }
  }
};
