import { Box, Tooltip } from '@chakra-ui/react'
import { useGame } from "../../../context/GameContext"

export const TradeManager = () => {
    const { setDisableTrade, disableTrade, gameAssets } = useGame()

    return (
        <Tooltip label={disableTrade ? "Activate trade offers" : "Disable trade offers"} fontSize='md'>
            <Box
                _hover={{
                    cursor: "pointer"
                }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"0.5vw"}
                boxSize={"3vw"}
                me={"1vw"}
                backgroundColor={disableTrade ? "#E94F4F" : "#85D885"}
                aria-label='trade-disable'
                onClick={() => setDisableTrade(!disableTrade)}
            >
                <img src={gameAssets.tradeButton} alt='trade-icon' width={"75%"} />
            </Box>
        </Tooltip>
    )
}