export const PlaySoundEffect = (
  soundEffect: any,
  sfxVolume: number,
  sfxEnabled: boolean
) => {
  if (sfxEnabled) {
    const audio = new Audio(soundEffect);
    audio.volume = sfxVolume;
    audio.play().catch((error) => {
      console.error("Audio play was prevented by the browser:", error);
    });
  }
};
