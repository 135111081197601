import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, useDisclosure, FormControl, FormLabel, ModalFooter, useToast, CheckboxGroup, Stack, Checkbox, Box, RadioGroup, Radio, } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { DeleteMission, GetMissions } from '../../../../requests/AdminRequests'
import { useState } from 'react'

const fetchMissions = async (setMissionList: React.Dispatch<React.SetStateAction<any[]>>) => {
    const response = await GetMissions()
    response && setMissionList(response.missions)
}

export const DeleteMissionModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure(
        {
            onOpen: async () => {
                fetchMissions(setMissionList)
            }
        }
    )

    const toast = useToast()

    const [missionList, setMissionList] = useState<any[]>([])
    const [missionID, setMissionID] = useState<string>("")

    const handleMissionChange = (selectedMissions: string) => {
        setMissionID(selectedMissions);
    };

    const handleDeleteMission = async () => {
        const result = await DeleteMission(missionID)
        if (result && result.message === "mission removed") {

            toast({
                description: "Mission is removed.",
                status: "success",
                duration: 2000
            })
            onClose()
        }
    }
    return (
        <>
            <Button
                colorScheme='red'
                mb={3}
                w={"100%"}
                onClick={onOpen}
            >
                Delete Mission
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Delete Mission</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel htmlFor='deleteMissionsInput'>Missions</FormLabel>
                            <RadioGroup
                                colorScheme='green'
                                onChange={(value) => handleMissionChange(value.toString())}
                            >
                                <Stack direction={"column"}>
                                    {
                                        missionList &&
                                            missionList.length > 0 ?
                                            missionList.map((mission, index) => (
                                                <Radio key={index} value={mission.id}>
                                                    {mission.description}
                                                </Radio>
                                            )) :
                                            <Box textAlign={"center"}>
                                                There is no any mission to delete.
                                            </Box>
                                    }
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            onClick={handleDeleteMission}
                        >
                            Delete Mission
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}