import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"
import { useGame } from '../context/GameContext'
import { useNavigate } from "react-router-dom"
import { useToast } from "@chakra-ui/react"
import { PlaySoundEffect } from "../sounds/playSoundEffect"

export const PlayerList = (socket: SocketType) => {
    const { gameID, soundEffects, setGameID, setIsReconnect } = useGame()
    const { setPlayers, players, setMyGameData, myGameData } = useSync()

    const navigate = useNavigate()
    const toast = useToast()

    // Handle Updated Ready Statement
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomReady") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setPlayers(prevPlayers => {
                        return prevPlayers.map(player => {
                            if (player.address === parsedData.address) {
                                return { ...player, readyStatus: parsedData.response };
                            }
                            return player;
                        });
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle New Player Join
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "UserJoin") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData && parsedData.roomID === gameID) {
                    setPlayers(prevPlayers => {
                        const newPlayer = {
                            address: parsedData.address,
                            isOwner: false,
                            turnIndex: parsedData.turnIndex,
                            username: parsedData.username,
                            isBot: parsedData.isBot,
                            isConnected: true,
                            readyStatus: parsedData.isBot ? true : false,
                            skin: parsedData.skin
                        };
                        const updatedPlayers = [...prevPlayers, newPlayer];

                        updatedPlayers.sort((a, b) => a.turnIndex - b.turnIndex);

                        return updatedPlayers;
                    });

                    PlaySoundEffect(soundEffects.playerJoinSFX, 0.5, true)
                }
            }
        }

    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "UserDisconnect") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    const newList = players.filter((player) => player.address !== parsedData.address);
                    const latestList = parsedData.latestPlayerList;

                    latestList.forEach((address, index) => {
                        const playerToUpdate = newList.find((player) => player.address === address);
                        if (playerToUpdate) {
                            playerToUpdate.turnIndex = index;
                        }
                    });

                    setPlayers(newList);
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);


    // Handle Game Owner Change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "OwnerChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setPlayers(prevPlayers =>
                        prevPlayers.map(player =>
                            player.address === parsedData.ownerAddress
                                ? { ...player, isOwner: true, readyStatus: true }
                                : player
                        )
                    );
                    if (myGameData && parsedData.ownerAddress === myGameData.address) {
                        setMyGameData({ ...myGameData, isOwner: true })
                    }
                }
            }
        }

    }, [socket && socket.lastJsonMessage]);

    // Handle if player leaves the game
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "PlayerLeave") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    const leaverTurnIndex = parsedData.turnIndex
                    const leaveReason = parsedData.exitCause

                    if (myGameData && myGameData.turn_index === leaverTurnIndex && leaveReason === 2) {
                        toast({
                            description: "You are kicked out of the game for not making a move two turns in a row.",
                            status: "info",
                            duration: null,
                            isClosable: true
                        })
                        setGameID(-1)
                        setIsReconnect(false)
                        navigate('/dashboard')
                    } else {
                        setPlayers(prevPlayers =>
                            prevPlayers.map(player =>
                                player.turnIndex === leaverTurnIndex
                                    ? { ...player, isBot: true }
                                    : player
                            )
                        );
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle if player lost the server connection
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "LostConnection") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setPlayers(prevPlayers =>
                        prevPlayers.map(player =>
                            player.turnIndex === parsedData.turnIndex
                                ? { ...player, isConnected: false }
                                : player
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle if player reconnect to the game
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "UserConnect") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setPlayers(prevPlayers =>
                        prevPlayers.map(player =>
                            player.turnIndex === parsedData.turnIndex
                                ? { ...player, isConnected: true }
                                : player
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}