import { Badge, Box, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { HowPointsCalculated } from "./HowPointsCalculated"
import { getOrdinalSuffix } from "../../../../../utils/functions/getOrdinalSuffix"
import { getDateAndTime } from "../../../../../utils/constants/getDateAndTime"

const tableBodyBgColor = "#d9b891"
const tableBodyTextColor = "#754e37"

const qualifiedColor = "#aeedb9"
const checkoutColor = "#ffbaad"

type StandingsTableType = {
    players: any[],
    isQualifyStandings: boolean
}

export const StandingsTable = ({ players, isQualifyStandings }: StandingsTableType) => {
    return (
        <TableContainer overflowY={"auto"} >
            <Box display={"flex"} justifyContent={"space-evenly"} p={1}>
                <HowPointsCalculated />
            </Box>
            <Table variant='simple'>
                <TableCaption mt={0}>
                    <Badge
                        backgroundColor={checkoutColor}
                        fontSize={"18px"}
                        borderRadius={"5px"}
                        mr={"auto"}
                    >
                        Checked-out
                    </Badge>
                    {
                        isQualifyStandings &&
                        <Badge
                            backgroundColor={qualifiedColor}
                            fontSize={"18px"}
                            borderRadius={"5px"}
                            ml={"5px"}
                            mr={"auto"}
                        >
                            Qualified
                        </Badge>
                    }
                </TableCaption>
                <Thead textColor={"white"} >
                    <Tr>
                        <Th textColor={"white"} fontWeight={"800"}></Th>
                        <Th textColor={"white"} fontWeight={"800"}>Player</Th>
                        <Th textColor={"white"} fontWeight={"800"}>Wins</Th>
                        <Th textColor={"white"} fontWeight={"800"}>Points</Th>
                        <Th textColor={"white"} fontWeight={"800"}>Points %</Th>
                        <Th textColor={"white"} fontWeight={"800"}>Games</Th>
                        <Th textColor={"white"} fontWeight={"800"}>Registered At</Th>
                    </Tr>
                </Thead>
                <Tbody backgroundColor={tableBodyBgColor} textColor={tableBodyTextColor}>
                    {
                        players.length > 0 &&
                        players.map((player, index) => {
                            return (
                                <Tr
                                    key={index}
                                    backgroundColor={
                                        player.IsQualified ?
                                            qualifiedColor :
                                            !player.CheckedIn ?
                                                checkoutColor :
                                                ""
                                    }
                                >
                                    <Td>{getOrdinalSuffix(index + 1)}</Td>
                                    <Td>{player.Username}</Td>
                                    <Td>{player.WinCount}</Td>
                                    <Td>{player.TotalVp}</Td>
                                    <Td>{(player.CumulativeVp * 100).toString().slice(0, 4)}%</Td>
                                    <Td>{player.Stage}</Td>
                                    <Td>
                                        {getDateAndTime(player.SignupTime).date},
                                        {getDateAndTime(player.SignupTime).time}
                                    </Td>
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}