export const getIsometricRoadAsset = (
  index: number,
  assets: any,
  skin: string
) => {
  if (skin === "Default") {
    if (index === 0) {
      return assets.defaultIsometricRedRoad;
    } else if (index === 1) {
      return assets.defaultIsometricBlueRoad;
    } else if (index === 2) {
      return assets.defaultIsometricPinkRoad;
    } else if (index === 3) {
      return assets.defaultIsometricGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Halloween") {
    if (index === 0) {
      return assets.halloweenIsometricRedRoad;
    } else if (index === 1) {
      return assets.halloweenIsometricBlueRoad;
    } else if (index === 2) {
      return assets.halloweenIsometricPinkRoad;
    } else if (index === 3) {
      return assets.halloweenIsometricGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Viking") {
    if (index === 0) {
      return assets.vikingIsometricRedRoad;
    } else if (index === 1) {
      return assets.vikingIsometricBlueRoad;
    } else if (index === 2) {
      return assets.vikingIsometricPinkRoad;
    } else if (index === 3) {
      return assets.vikingIsometricGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Air") {
    if (index === 0) {
      return assets.airIsometricRedRoad;
    } else if (index === 1) {
      return assets.airIsometricBlueRoad;
    } else if (index === 2) {
      return assets.airIsometricPinkRoad;
    } else if (index === 3) {
      return assets.airIsometricGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Ember") {
    if (index === 0) {
      return assets.emberIsometricRedRoad;
    } else if (index === 1) {
      return assets.emberIsometricBlueRoad;
    } else if (index === 2) {
      return assets.emberIsometricPinkRoad;
    } else if (index === 3) {
      return assets.emberIsometricGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Pagoda") {
    if (index === 0) {
      return assets.pagodaIsometricRedRoad;
    } else if (index === 1) {
      return assets.pagodaIsometricBlueRoad;
    } else if (index === 2) {
      return assets.pagodaIsometricPinkRoad;
    } else if (index === 3) {
      return assets.pagodaIsometricGreyRoad;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
