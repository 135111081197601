import facebookIcon from '../../assets/Landing/SocialMediaIcons/colorful_facebook.png'
import instagramIcon from '../../assets/Landing/SocialMediaIcons/colorful_instagram.png'
import youtubeIcon from '../../assets/Landing/SocialMediaIcons/colorful_youtube.png'
import xIcon from '../../assets/Landing/SocialMediaIcons/colorful_x.png'
import discordIcon from '../../assets/Landing/SocialMediaIcons/colorful_discord.png'
import pioneersLogo from '../../assets/Landing/pioneers_logo.png'
import { Box, Image } from '@chakra-ui/react'

export const Footer = () => {
    return (
        <Box className="footer-background pb-2 pt-2 mt-0">
            <Box display={"flex"} justifyContent={"center"}>
                <a className="btn btn-lg text-white me-lg-2 d-md-block d-none" href="#" rel="noopener noreferrer">
                    <Image
                        src={pioneersLogo}
                        alt='pion-logo'
                        className='responsive-social-icons'
                    />
                </a>
                <a className="btn btn-lg text-white me-md-2" href="https://discord.gg/7fqNXcarYM" target="_blank" rel="noopener noreferrer">
                    <Image
                        src={discordIcon}
                        alt='discord-icon'
                        className='responsive-social-icons'
                    />
                </a>
                <a className="btn btn-lg text-white me-md-2" href="https://twitter.com/PioneersOfNW" target="_blank" rel="noopener noreferrer">
                    <Image
                        src={xIcon}
                        alt='x-icon'
                        className='responsive-social-icons'
                    />
                </a>
                <a className="btn btn-lg text-white me-md-2" href="https://www.instagram.com/pioneersgame/" target="_blank" rel="noopener noreferrer">
                    <Image
                        src={instagramIcon}
                        alt='insta-icon'
                        className='responsive-social-icons'
                    />
                </a>
            </Box>
        </Box>
    )
}