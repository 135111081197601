import { FaTimes } from "react-icons/fa"
import { CreateRemoveBot } from "../../../../requests/LobbyRequests"
import { useGame } from "../../../../context/GameContext"
import { useSync } from "../../../../context/SyncContext"
import { IconButton } from "@chakra-ui/react"

type KickBotButtonType = {
    isBot: boolean,
    turnIndex: number
}

export const KickBotButton = ({ isBot, turnIndex }: KickBotButtonType) => {
    const { gameID } = useGame()
    const { myGameData } = useSync()

    const handleBotKick = async (turnIndex: number) => {
        await CreateRemoveBot(gameID, turnIndex)
    }

    if (isBot && myGameData && myGameData.isOwner) {
        return (
            <IconButton
                pos={"absolute"}
                maxW={"1.5vw"}
                maxH={"1.5vw"}
                top={"0.5vw"}
                right={"0.5vw"}
                backgroundColor={"#d66656"}
                colorScheme={"red"}
                aria-label={"kick-bot"}
                icon={<FaTimes fontSize={"1.25vw"} />}
                onClick={() => handleBotKick(turnIndex)}
            />
        )
    }

    return null
}