import { Box } from "@chakra-ui/react"
import { useSync } from "../../../../context/SyncContext"

type PlayerUsernameType = {
    username: string,
    turnIndex: number
}

export const PlayerUsername = ({ username, turnIndex }: PlayerUsernameType) => {
    const { gameData } = useSync()

    return (
        <Box className={gameData.WinnerIndex === turnIndex ? 'winner-username' : 'loser-username'}>
            {username}
        </Box>
    )
}