import { useEffect, useState } from "react"
import { RobbedCards } from "./RobbedCards"
import { useDisclosure } from "@chakra-ui/react"
import { RobbedPlayer } from "./RobbedPlayer"
import { SocketType } from "../../../utils/types/types"
import { useSync } from "../../../context/SyncContext"
import { useGame } from "../../../context/GameContext"
import { PlaySoundEffect } from "../../../sounds/playSoundEffect"

export const Thief = ({ socket }: { socket: SocketType }) => {
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()
    const { myGameData, gameData, resources } = useSync()

    const { isOpen: robbedCardIsOpen, onOpen: robbedCardOnOpen, onClose: robbedCardOnClose } = useDisclosure()
    const { isOpen: robbedPlayerIsOpen, onOpen: robbedPlayerOnOpen, onClose: robbedPlayerOnClose } = useDisclosure()

    const [robbedPlayerStage, setRobbedPlayerStage] = useState<boolean>(false)
    const [robbedCardStage, setRobbedCardStage] = useState<boolean>(false)

    // Check if card robber stage
    useEffect(() => {
        if (gameData) {
            const gameStage = gameData.Status
            const is1v1 = gameData.RoomLimit === 2
            const totalRes = resources.Brick +
                resources.Ore +
                resources.Sheep +
                resources.Wheat +
                resources.Wood

            if (gameStage === 5) {
                if (is1v1 ? totalRes > 9 : totalRes > 7) {

                    PlaySoundEffect(soundEffects.needDiscardSFX, sfxVolume, sfxEnabled)

                    setRobbedCardStage(true)
                    robbedCardOnOpen()
                } else {
                    setRobbedCardStage(false)
                }
            } else {
                setRobbedCardStage(false)
            }
        } else {
            setRobbedCardStage(false)
        }
    }, [gameData])

    // Check if player rob stage for more than 1 player
    useEffect(() => {
        if (gameData && myGameData) {
            const gameStage = gameData.Status
            const gameTurnIndex = gameData.TurnIndex
            const myTurnIndex = myGameData.turn_index

            if (gameStage === 7 && gameTurnIndex === myTurnIndex) {
                setRobbedPlayerStage(true)
                robbedPlayerOnOpen()
            } else {
                setRobbedPlayerStage(false)
            }
        } else {
            setRobbedPlayerStage(false)
        }
    }, [gameData, myGameData])

    if (robbedCardStage) {
        return <RobbedCards isOpen={robbedCardIsOpen} onClose={robbedCardOnClose} socket={socket} />
    } else if (robbedPlayerStage) {
        return <RobbedPlayer isOpen={robbedPlayerIsOpen} onClose={robbedPlayerOnClose} socket={socket} />
    } else {
        return null
    }
}