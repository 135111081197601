import { Box, Image } from "@chakra-ui/react"
import { FaCheckCircle } from "react-icons/fa"
import { useGame } from "../../../../../../context/GameContext"

export const MatchStatusBox = ({ Status }: { Status: number }) => {
    const { dashboardAssets } = useGame()

    if (Status === 1) {
        return (
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"4vw"}
                width={"3vw"}
                backgroundColor={"#cb3e50"}
                borderRadius={"0.5vw"}
                fontSize={"2vw"}
                me={"1vw"}
            >
                <Image
                    src={dashboardAssets.registerIcon}
                    alt="register-catan-tournament"
                    width={"2vw"}
                />
            </Box>
        )
    } else {
        return (
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                backgroundColor={"#51ea56"}
                height={"4vw"}
                width={"3vw"}
                borderRadius={"0.5vw"}
                fontSize={"2vw"}
                me={"1vw"}
            >
                <FaCheckCircle />
            </Box>
        )
    }
}