import { useEffect, useState } from "react"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"
import { SocketType } from "../../../utils/types/types"
import { getDiceAnimation, getFlipDiceAnimation } from '../../../utils/assets/getDiceAnimation'
import { logDivElement, logTextDivElement, usernameDivElement } from '../../../utils/gameLogs/gameLogs'
import { getUsernameByIndex } from '../../../utils/functions/getUsernameByIndex'
import { getColorByIndex } from '../../../utils/assets/getColorByIndex'
import { getDiceResult } from '../../../utils/assets/getDiceResult'
import { PlaySoundEffect } from "../../../sounds/playSoundEffect"

export const DiceNotification = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects } = useGame()
    const { gameData, setDice, players } = useSync()

    const [notifActive, setNotifActive] = useState(false)

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "DiceRoll") {
                const diceElement = document.querySelector(".dice-notif")
                if (diceElement) {
                    diceElement.remove()
                }

                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                setDice({ diceOne: parsedData.dice1, diceTwo: parsedData.dice2, turnIndex: gameData.TurnIndex })

                const playerElement = document.getElementById(`player-${gameData.TurnIndex}`)

                if (playerElement) {
                    const divElement = document.createElement("div")
                    divElement.classList.add("player-notification")
                    divElement.classList.add("dice-notif")

                    const childDivElement = document.createElement("div")
                    childDivElement.style.display = "flex"
                    childDivElement.style.justifyContent = "center"
                    childDivElement.style.alignItems = "center"

                    const diceImg1 = document.createElement("img")
                    diceImg1.src = getDiceAnimation(parsedData.dice1, gameAssets)
                    diceImg1.alt = 'dice'
                    diceImg1.style.width = "25%"

                    const diceImg2 = document.createElement("img")
                    diceImg2.src = getFlipDiceAnimation(parsedData.dice2, gameAssets)
                    diceImg2.alt = 'dice'
                    diceImg2.style.width = "25%"

                    childDivElement.appendChild(diceImg1)
                    childDivElement.appendChild(diceImg2)

                    divElement.appendChild(childDivElement)
                    playerElement.appendChild(divElement)
                    setNotifActive(true)
                }

                const gameLogs = document.getElementById(`game-logs`)
                if (gameLogs) {
                    const logDiv = logDivElement()
                    const usernameDiv = usernameDivElement(getUsernameByIndex(players, gameData.TurnIndex), getColorByIndex(gameData.TurnIndex))

                    const logText = logTextDivElement("rolled")

                    const dice1Element = document.createElement("img")
                    dice1Element.src = getDiceResult(parsedData.dice1, gameAssets)
                    dice1Element.alt = "dice1"
                    dice1Element.style.height = "150%"
                    dice1Element.style.marginRight = "0.25vw"

                    const dice2Element = document.createElement("img")
                    dice2Element.src = getDiceResult(parsedData.dice2, gameAssets)
                    dice2Element.alt = "dice2"
                    dice2Element.style.height = "150%"

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)
                    logDiv.appendChild(dice1Element)
                    logDiv.appendChild(dice2Element)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                PlaySoundEffect(soundEffects.rollDiceSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])

    useEffect(() => {
        if (notifActive) {
            const timeout = setTimeout(() => {
                const diceElement = document.querySelector(".dice-notif")
                if (diceElement) {
                    diceElement.remove()
                    setNotifActive(false)
                }
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [notifActive]);
}