import { Button } from "@chakra-ui/react"
import { FaRegClock } from "react-icons/fa"

type OverviewCheckinType = {
    UserCheckedIn: boolean,
    IsUserSignedUp: boolean,
    State: number,
    handleCheckin: () => void,
    handleCheckinCancel: () => void
}

export const OverviewCheckin = ({ UserCheckedIn, IsUserSignedUp, State, handleCheckin, handleCheckinCancel }: OverviewCheckinType) => {
    if (!UserCheckedIn) {
        return (
            <Button
                w={"100%"}
                backgroundColor={"#69be55"}
                border={"2px solid #1C6824"}
                boxShadow={"0px 2px #1C6824"}
                colorScheme={"green"}
                isDisabled={!IsUserSignedUp || State !== 2}
                mb={2}
                onClick={handleCheckin}
            >
                <FaRegClock className='me-2' />Check-in
            </Button>
        )
    } else {
        return (
            <Button
                w={"100%"}
                backgroundColor={"#c97f64"}
                border={"2px solid #A72C01"}
                boxShadow={"0px 2px #A72C01"}
                colorScheme={"red"}
                isDisabled={!IsUserSignedUp || State !== 2}
                mb={2}
                onClick={handleCheckinCancel}
            >
                <FaRegClock className='me-2' />Check-out
            </Button>
        )
    }
}