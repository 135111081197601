import { Box } from '@chakra-ui/react';
import { useSync } from '../../../context/SyncContext';
import { Player } from './Player';
import { SocketType } from '../../../utils/types/types';

export const Players = ({ socket }: { socket: SocketType }) => {
    const { players, totalResCards, armyPoints, longestRoads, points, totalDevCards } = useSync()

    return (
        <Box className="player-list">
            {
                players && players.map((player, index) => {
                    const playerTurnIndex = player.turnIndex
                    const username = player.username
                    const isBot = player.isBot
                    const isConnected = player.isConnected
                    const playerAddress = player.address

                    return (
                        <Player
                            key={index}
                            socket={socket}
                            username={username}
                            victoryPoint={points[playerTurnIndex]}
                            largestArmy={armyPoints[playerTurnIndex]}
                            longestRoad={longestRoads[playerTurnIndex]}
                            devCardNumber={totalDevCards[playerTurnIndex]}
                            resCardNumber={totalResCards[playerTurnIndex]}
                            turnIndex={playerTurnIndex}
                            address={playerAddress}
                            isBot={isBot}
                            isConnected={isConnected}
                        />
                    )
                })
            }
        </Box>
    )
}