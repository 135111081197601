import { Avatar, Box } from "@chakra-ui/react"
import { KickBotButton } from "../WaitingLobbyButtons/KickBotButton"
import { getAvatarByIndex } from "../../../../utils/assets/getAvatarByIndex"
import { getColorByIndex } from "../../../../utils/assets/getColorByIndex"
import { FaRobot, FaCrown, FaCheck } from "react-icons/fa"
import { useGame } from "../../../../context/GameContext"

type PlayerColumnType = {
    order: number,
    username: string,
    turnIndex: number,
    isBot: boolean,
    isOwner: boolean,
    isReady: boolean,
    maximumPlayer: number
}

const playerBackgroundOne = "#e8c190"
const playerBackgroundTwo = "#eccca5"

export const PlayerColumn = ({ order, username, turnIndex, isBot, isOwner, isReady, maximumPlayer }: PlayerColumnType) => {
    const { dashboardAssets } = useGame()

    return (
        <Box
            pos="relative"
            w={maximumPlayer === 3 ? "23.3vw" : "17.5vw"}
            h={"21vw"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundColor={order % 2 === 0 ? playerBackgroundOne : playerBackgroundTwo}
        >
            <Avatar
                src={getAvatarByIndex(turnIndex, dashboardAssets)}
                backgroundColor={getColorByIndex(turnIndex)}
                height={"10vw"}
                width={"10vw"}
                name={username}
                alignSelf={"center"}
                border={"0.25vw solid #8b795d"}
            />

            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box className="lobby-username">
                    <PlayerStatusIcon
                        isOwner={isOwner}
                        isReady={isReady}
                    />
                    {username}
                    {
                        isBot &&
                        <FaRobot className="ms-2" />
                    }
                </Box>
                <KickBotButton
                    isBot={isBot}
                    turnIndex={turnIndex}
                />
            </Box>
        </Box>
    )
}

const PlayerStatusIcon = ({ isOwner, isReady }: { isOwner: boolean, isReady: boolean }) => {
    if (isOwner) {
        return (
            <Box
                fontSize={"1vw"}
                backgroundColor={"#f8bb08"}
                borderRadius={"50%"}
                border={"0.2vw solid #7c500a"}
                textColor={"white"}
                me={"0.5vw"}
                p={"0.25vw"}
            >
                <FaCrown />
            </Box>
        )
    } else {
        if (isReady) {
            return (
                <Box
                    fontSize={"1vw"}
                    backgroundColor={"#86d257"}
                    borderRadius={"50%"}
                    border={"0.2vw solid #2e7a12"}
                    textColor={"white"}
                    me={"0.5vw"}
                    p={"0.25vw"}
                >
                    <FaCheck />
                </Box>
            )
        }
    }
}