import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"

export const CreateGameButton = ({ onOpen }: { onOpen: () => void }) => {
    const { dashboardAssets, reConnectGameID } = useGame()

    if (reConnectGameID === 0) {
        return (
            <Box
                onClick={onOpen}
                className="create-game-modal-panel content-button-effects"
            >
                <Image
                    src={dashboardAssets.createGameIcon}
                    alt='create-game-icon'
                    m={"auto"}
                    width={"60%"}
                />
                <Box className="content-button-title-color">
                    Create Game
                </Box>
            </Box>
        )
    } else {
        return null
    }
}