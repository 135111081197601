import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useAuth } from '../context/AuthContext'
import { useGame } from '../context/GameContext'
import { GameContent } from '../components/Landing/GameContent'
import { Splash } from '../components/Landing/Splash'
import { QuestContent } from '../components/Landing/QuestContent'
import { Footer } from '../components/Landing/Footer'
import { TournamentCashBanner } from '../components/Landing/TournamentCashBanner'
import { TournamentContent } from '../components/Landing/TournamentContent'
import { TournamentQuestBanner } from '../components/Landing/TournamentQuestBanner'
import { SkinsContent } from '../components/Landing/SkinsContent'
import { usePWA } from '../hooks/usePWA'
import { useDeviceOS } from '../hooks/useDeviceOS'
import { PWANotification } from '../components/Dashboard/General/PWANotification'

export const Landing = () => {
    const { setDirectJoinGameID, isMobile } = useGame()
    const { loggedIn } = useAuth()

    const [searchParams] = useSearchParams();

    const toast = useToast()
    const navigate = useNavigate()
    const isPWA = usePWA()
    const deviceOS = useDeviceOS()

    useEffect(() => {
        if (loggedIn) {
            navigate("/dashboard")
        }
    }, [loggedIn])

    useEffect(() => {
        if (searchParams && searchParams.get("error")) {
            const errorMessage = searchParams.get("error");
            const capitalizedErrorMessage = errorMessage!.charAt(0).toUpperCase() + errorMessage!.slice(1);
            toast({
                title: "Error",
                description: capitalizedErrorMessage,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }

        if (searchParams && searchParams.get("joinRoom")) {
            const gameID = searchParams.get("joinRoom");
            setDirectJoinGameID(Number(gameID))
        }
    }, [searchParams])

    useEffect(() => {
        if (!isPWA && deviceOS && isMobile) {
            toast({
                position: "bottom",
                duration: null,
                render: ({ onClose }) => (
                    <PWANotification
                        deviceOS={deviceOS}
                        onClose={onClose}
                    />
                ),
                isClosable: true
            })
        }
    }, [isPWA, deviceOS, isMobile])

    return (
        <>
            <Splash />
            <GameContent />
            <TournamentCashBanner />
            <TournamentContent />
            <TournamentQuestBanner />
            <SkinsContent />
            <QuestContent />
            <Footer />
        </>
    )
}