import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"
import { getUserStatsByIndex } from "../../../../utils/functions/getUserStatsByIndex"

export const PlayerTotalBuildings = ({ turnIndex, userStats }: { turnIndex: number, userStats: any[] }) => {
    const { gameAssets } = useGame()
    return (
        <Box display={"flex"} flexDir={"column"} mb={"0.5vw"} fontSize={"1vw"}>
            <Box fontSize={"1vw"} textColor={"white"}>
                Total Buildings
            </Box>

            <Box display={"flex"} justifyContent={"space-evenly"} color={"white"}>
                <Box display={"flex"} flexDir={"column"} me={"0.25vw"} w={"2vw"}>
                    <Image
                        src={gameAssets.settleNumberIcon}
                        alt='wood-icon'
                        width={"100%"}
                    />
                    <Box mt={"auto"} backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalSettlements}
                    </Box>
                </Box>
                <Box display={"flex"} flexDir={"column"} me={"0.25vw"} w={"2vw"}>
                    <Image
                        src={gameAssets.cityNumberIcon}
                        alt='brick-icon'
                        width={"100%"}
                        mb={"auto"}
                    />
                    <Box mt={"auto"} backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalCities}
                    </Box>
                </Box>
                <Box display={"flex"} flexDir={"column"} w={"2vw"}>
                    <Image
                        src={gameAssets.roadNumberIcon}
                        alt='sheep-icon'
                        width={"100%"}
                        mt={"auto"}
                        mb={"auto"}
                    />
                    <Box mt={"auto"} backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalRoads}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}