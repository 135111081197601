import { Checkbox } from "@chakra-ui/react"

type TaskType = {
    isCompleted: boolean,
    haveBackground: boolean,
    task: string,
    taskProgress: string
}

export const Task = ({ isCompleted, haveBackground, task, taskProgress }: TaskType) => {
    return (
        <Checkbox
            sx={{
                ".chakra-checkbox__control": {
                    borderRadius: "0.5vw",
                    width: "2vw",
                    height: "2vw",
                    bg: "#af967d",
                    border: "none",
                    _checked: {
                        bg: "#af967d",
                        border: "none",
                        svg: {
                            fontSize: "1.25vw"
                        }
                    },
                },
                ".chakra-checkbox__label": {
                    fontSize: "1vw",
                    fontWeight: "500"
                },
            }}
            w={"100%"}
            className={haveBackground ? "daily-quest-odd-background" : undefined}
            p={"0.5vw 2vw"}
            iconColor={"white"}
            spacing={"1vw"}
            iconSize={"15vw"}
            colorScheme={"none"}
            isChecked={isCompleted}
            isReadOnly
        >
            {task + " "}
            {taskProgress}
        </Checkbox>
    )
}