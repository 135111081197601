import { SocketType } from "../../utils/types/types";

export const SendGameEvent = (
  socket: SocketType,
  eventType: string,
  eventData: {}
) => {
  if (socket?.readyState) {
    socket?.sendMessage(
      JSON.stringify({
        type: eventType,
        data: JSON.stringify(eventData),
      })
    );
  } else {
    console.error("Check socket connection...");
  }
};
