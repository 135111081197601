import { Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Portal, IconButton, Box, Button, useDisclosure, Image } from '@chakra-ui/react'
import { useGame } from '../../../context/GameContext'
import { GameTutorial } from '../../Dashboard/Sidebar/Tutorial/GameTutorial'
import { WallpaperManager } from './WallpaperManager'
import { MusicManager } from './MusicManager'
import { SFXManager } from './SFXManager'
import { EmojiManager } from './EmojiManager'
import { TradeManager } from './TradeManager'
import { LeaveAlert } from './LeaveAlert'
import { BuildConfirmManager } from './BuildConfirmManager'

export const Settings = () => {
    const { gameAssets } = useGame()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { isOpen: AlertIsOpen, onOpen: AlertOnOpen, onClose: AlertOnClose } = useDisclosure()

    return (
        <Popover
            placement={"top"}
            preventOverflow
        >
            <PopoverTrigger>
                <IconButton
                    position={"fixed"}
                    left={"1%"}
                    bottom={"1%"}
                    width={"4%"}
                    height={"7%"}
                    colorScheme={"yellow"}
                    background={"transparent"}
                    aria-label='back-menu'
                    icon={<img src={gameAssets.menuButton} alt='menu-button' width={"65%"} />}
                />
            </PopoverTrigger>
            <Portal >
                <PopoverContent
                    w={"20vw"}
                    backgroundColor={"transparent"}
                    border={"none"}
                    textColor={"#825128"}
                >
                    <PopoverHeader
                        textAlign={"center"}
                        color={"white"}
                        fontSize={"3.5vh"}
                        borderRadius={"1vw 1vw 0 0"}
                        border={"none"}
                        background={"linear-gradient(#7C5E36, #5B472D)"}
                    >
                        Settings
                    </PopoverHeader>

                    <PopoverBody
                        backgroundColor={"#E6C795"}
                    >
                        <WallpaperManager />

                        <Image
                            src={gameAssets.settingsDivider}
                            alt='divider'
                        />

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <Box textAlign={"center"} fontSize={"1.25vw"}>
                                Game Settings
                            </Box>
                            <Box display={"flex"} alignItems={"center"} mb={"1vh"}>
                                <TradeManager />
                                <BuildConfirmManager />
                            </Box>
                        </Box>

                        <Image
                            src={gameAssets.settingsDivider}
                            alt='divider'
                        />

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-evenly"}>
                            {/* Music Volume Slider */}
                            <MusicManager />

                            {/* Sound Effect Volume Slider */}
                            <SFXManager />

                            {/* Emoji Effect On/Off Contoller */}
                            <EmojiManager />
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={"1vh"}>
                            <Button
                                w={"10vw"}
                                height={"5vh"}
                                fontSize={"1vw"}
                                colorScheme='orange'
                                backgroundColor={"#EC9E61"}
                                borderRadius={"0.5vw"}
                                border={"0.2vw solid #825128"}
                                mb={"0.5vh"}
                                onClick={onOpen}
                            >
                                Game Guide
                            </Button>
                            <Button
                                w={"10vw"}
                                height={"5vh"}
                                fontSize={"1vw"}
                                colorScheme='orange'
                                backgroundColor={"#EC9E61"}
                                borderRadius={"0.5vw"}
                                border={"0.2vw solid #825128"}
                                mb={"0.5vh"}
                                onClick={AlertOnOpen}
                            >
                                Quit Game
                            </Button>
                        </Box>
                        <GameTutorial
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                            isDashboard={false}
                        />
                        <LeaveAlert
                            isOpen={AlertIsOpen}
                            onClose={AlertOnClose}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}