import { Badge, Tooltip } from "@chakra-ui/react"

interface MarketDockRatio {
    ratio: number | undefined,
    resourceName: string,
    isActive: boolean
}

export const MarketDockRatio = (props: MarketDockRatio) => {
    if (props.isActive) {
        return (
            <Tooltip
                label={`1 Cards Taken : ${props.ratio && props.ratio} ${props.resourceName}`}>
                <Badge
                    p={"0.1vw 0.3vw"}
                    mt={"1vh"}
                    colorScheme={"blue"}
                    fontSize={"1vw"}
                    borderRadius={"0.25vw"}
                >
                    1:{props.ratio && props.ratio}
                </Badge>
            </Tooltip>
        )
    } else {
        return null
    }
}