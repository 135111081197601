export const getCityAsset = (index: number, assets: any, skin: string) => {
  if (skin === "Default") {
    if (index === 0) {
      return assets.defaultCityRed;
    } else if (index === 1) {
      return assets.defaultCityBlue;
    } else if (index === 2) {
      return assets.defaultCityPink;
    } else if (index === 3) {
      return assets.defaultCityGrey;
    } else {
      return "";
    }
  } else if (skin === "Halloween") {
    if (index === 0) {
      return assets.halloweenCityRed;
    } else if (index === 1) {
      return assets.halloweenCityBlue;
    } else if (index === 2) {
      return assets.halloweenCityPink;
    } else if (index === 3) {
      return assets.halloweenCityGrey;
    } else {
      return "";
    }
  } else if (skin === "Viking") {
    if (index === 0) {
      return assets.vikingCityRed;
    } else if (index === 1) {
      return assets.vikingCityBlue;
    } else if (index === 2) {
      return assets.vikingCityPink;
    } else if (index === 3) {
      return assets.vikingCityGrey;
    } else {
      return "";
    }
  } else if (skin === "Air") {
    if (index === 0) {
      return assets.airCityRed;
    } else if (index === 1) {
      return assets.airCityBlue;
    } else if (index === 2) {
      return assets.airCityPink;
    } else if (index === 3) {
      return assets.airCityGrey;
    } else {
      return "";
    }
  } else if (skin === "Ember") {
    if (index === 0) {
      return assets.emberCityRed;
    } else if (index === 1) {
      return assets.emberCityBlue;
    } else if (index === 2) {
      return assets.emberCityPink;
    } else if (index === 3) {
      return assets.emberCityGrey;
    } else {
      return "";
    }
  } else if (skin === "Pagoda") {
    if (index === 0) {
      return assets.pagodaCityRed;
    } else if (index === 1) {
      return assets.pagodaCityBlue;
    } else if (index === 2) {
      return assets.pagodaCityPink;
    } else if (index === 3) {
      return assets.pagodaCityGrey;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
