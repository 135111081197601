export const getHexIdFromPosition = (x: number, y: number) => {
  const hexes = [];

  if (x === 0 && y === 0) {
    hexes.push({ hexID: 0, index: 5 });
  }

  if (x === 1 && y === 0) {
    hexes.push({ hexID: 0, index: 0 });
  }

  if (x === 2 && y === 0) {
    hexes.push({ hexID: 0, index: 1 });
    hexes.push({ hexID: 1, index: 5 });
  }

  if (x === 1 && y === 1) {
    hexes.push({ hexID: 0, index: 4 });
    hexes.push({ hexID: 3, index: 0 });
  }

  if (x === 2 && y === 1) {
    hexes.push({ hexID: 0, index: 3 });
    hexes.push({ hexID: 3, index: 1 });
    hexes.push({ hexID: 4, index: 5 });
  }

  if (x === 3 && y === 1) {
    hexes.push({ hexID: 0, index: 2 });
    hexes.push({ hexID: 1, index: 4 });
    hexes.push({ hexID: 4, index: 0 });
  }

  if (x === 2 && y === 0) {
    hexes.push({ hexID: 0, index: 1 });
    hexes.push({ hexID: 1, index: 5 });
  }

  if (x === 3 && y === 0) {
    hexes.push({ hexID: 1, index: 0 });
  }

  if (x === 4 && y === 0) {
    hexes.push({ hexID: 1, index: 1 });
    hexes.push({ hexID: 2, index: 5 });
  }

  if (x === 4 && y === 1) {
    hexes.push({ hexID: 1, index: 3 });
    hexes.push({ hexID: 4, index: 1 });
    hexes.push({ hexID: 5, index: 5 });
  }

  if (x === 5 && y === 1) {
    hexes.push({ hexID: 1, index: 2 });
    hexes.push({ hexID: 2, index: 4 });
    hexes.push({ hexID: 5, index: 0 });
  }

  if (x === 5 && y === 0) {
    hexes.push({ hexID: 2, index: 0 });
  }

  if (x === 6 && y === 0) {
    hexes.push({ hexID: 2, index: 1 });
  }

  if (x === 6 && y === 1) {
    hexes.push({ hexID: 2, index: 3 });
    hexes.push({ hexID: 6, index: 5 });
    hexes.push({ hexID: 5, index: 1 });
  }

  if (x === 7 && y === 1) {
    hexes.push({ hexID: 2, index: 2 });
    hexes.push({ hexID: 6, index: 0 });
  }

  if (x === 8 && y === 1) {
    hexes.push({ hexID: 6, index: 1 });
  }

  if (x === 0 && y === 1) {
    hexes.push({ hexID: 3, index: 5 });
  }

  if (x === 1 && y === 2) {
    hexes.push({ hexID: 3, index: 4 });
    hexes.push({ hexID: 7, index: 0 });
  }

  if (x === 2 && y === 2) {
    hexes.push({ hexID: 3, index: 3 });
    hexes.push({ hexID: 7, index: 1 });
    hexes.push({ hexID: 8, index: 5 });
  }

  if (x === 3 && y === 2) {
    hexes.push({ hexID: 3, index: 2 });
    hexes.push({ hexID: 4, index: 4 });
    hexes.push({ hexID: 8, index: 0 });
  }

  if (x === 4 && y === 2) {
    hexes.push({ hexID: 4, index: 3 });
    hexes.push({ hexID: 8, index: 1 });
    hexes.push({ hexID: 9, index: 5 });
  }

  if (x === 5 && y === 2) {
    hexes.push({ hexID: 4, index: 2 });
    hexes.push({ hexID: 5, index: 4 });
    hexes.push({ hexID: 9, index: 0 });
  }

  if (x === 6 && y === 2) {
    hexes.push({ hexID: 5, index: 3 });
    hexes.push({ hexID: 9, index: 1 });
    hexes.push({ hexID: 10, index: 5 });
  }

  if (x === 7 && y === 2) {
    hexes.push({ hexID: 5, index: 2 });
    hexes.push({ hexID: 6, index: 4 });
    hexes.push({ hexID: 10, index: 0 });
  }

  if (x === 8 && y === 2) {
    hexes.push({ hexID: 6, index: 3 });
    hexes.push({ hexID: 10, index: 1 });
    hexes.push({ hexID: 11, index: 5 });
  }

  if (x === 9 && y === 2) {
    hexes.push({ hexID: 6, index: 2 });
    hexes.push({ hexID: 11, index: 0 });
  }

  if (x === 10 && y === 2) {
    hexes.push({ hexID: 11, index: 1 });
  }

  if (x === 0 && y === 2) {
    hexes.push({ hexID: 7, index: 5 });
  }

  if (x === 0 && y === 3) {
    hexes.push({ hexID: 7, index: 4 });
  }

  if (x === 1 && y === 3) {
    hexes.push({ hexID: 7, index: 3 });
    hexes.push({ hexID: 12, index: 5 });
  }

  if (x === 2 && y === 3) {
    hexes.push({ hexID: 7, index: 2 });
    hexes.push({ hexID: 8, index: 4 });
    hexes.push({ hexID: 12, index: 0 });
  }

  if (x === 3 && y === 3) {
    hexes.push({ hexID: 8, index: 3 });
    hexes.push({ hexID: 12, index: 1 });
    hexes.push({ hexID: 13, index: 5 });
  }

  if (x === 4 && y === 3) {
    hexes.push({ hexID: 8, index: 2 });
    hexes.push({ hexID: 9, index: 4 });
    hexes.push({ hexID: 13, index: 0 });
  }

  if (x === 5 && y === 3) {
    hexes.push({ hexID: 9, index: 3 });
    hexes.push({ hexID: 13, index: 1 });
    hexes.push({ hexID: 14, index: 5 });
  }

  if (x === 6 && y === 3) {
    hexes.push({ hexID: 9, index: 2 });
    hexes.push({ hexID: 10, index: 4 });
    hexes.push({ hexID: 14, index: 0 });
  }

  if (x === 7 && y === 3) {
    hexes.push({ hexID: 10, index: 3 });
    hexes.push({ hexID: 14, index: 1 });
    hexes.push({ hexID: 15, index: 5 });
  }

  if (x === 8 && y === 3) {
    hexes.push({ hexID: 19, index: 2 });
    hexes.push({ hexID: 11, index: 4 });
    hexes.push({ hexID: 15, index: 0 });
  }

  if (x === 9 && y === 3) {
    hexes.push({ hexID: 11, index: 3 });
    hexes.push({ hexID: 15, index: 1 });
  }

  if (x === 10 && y === 3) {
    hexes.push({ hexID: 11, index: 2 });
  }

  if (x === 0 && y === 4) {
    hexes.push({ hexID: 12, index: 4 });
  }

  if (x === 1 && y === 4) {
    hexes.push({ hexID: 12, index: 3 });
    hexes.push({ hexID: 16, index: 5 });
  }

  if (x === 2 && y === 4) {
    hexes.push({ hexID: 12, index: 2 });
    hexes.push({ hexID: 13, index: 4 });
    hexes.push({ hexID: 16, index: 0 });
  }

  if (x === 3 && y === 4) {
    hexes.push({ hexID: 13, index: 3 });
    hexes.push({ hexID: 16, index: 1 });
    hexes.push({ hexID: 17, index: 5 });
  }

  if (x === 4 && y === 4) {
    hexes.push({ hexID: 13, index: 2 });
    hexes.push({ hexID: 14, index: 4 });
    hexes.push({ hexID: 17, index: 0 });
  }

  if (x === 5 && y === 4) {
    hexes.push({ hexID: 14, index: 3 });
    hexes.push({ hexID: 17, index: 1 });
    hexes.push({ hexID: 18, index: 5 });
  }

  if (x === 6 && y === 4) {
    hexes.push({ hexID: 14, index: 2 });
    hexes.push({ hexID: 15, index: 4 });
    hexes.push({ hexID: 18, index: 0 });
  }

  if (x === 7 && y === 4) {
    hexes.push({ hexID: 15, index: 3 });
    hexes.push({ hexID: 18, index: 1 });
  }

  if (x === 8 && y === 4) {
    hexes.push({ hexID: 15, index: 2 });
  }

  if (x === 0 && y === 5) {
    hexes.push({ hexID: 16, index: 4 });
  }

  if (x === 1 && y === 5) {
    hexes.push({ hexID: 16, index: 3 });
  }

  if (x === 2 && y === 5) {
    hexes.push({ hexID: 16, index: 2 });
    hexes.push({ hexID: 17, index: 4 });
  }

  if (x === 3 && y === 5) {
    hexes.push({ hexID: 17, index: 3 });
  }

  if (x === 4 && y === 5) {
    hexes.push({ hexID: 17, index: 2 });
    hexes.push({ hexID: 18, index: 4 });
  }

  if (x === 5 && y === 5) {
    hexes.push({ hexID: 18, index: 3 });
  }

  if (x === 6 && y === 5) {
    hexes.push({ hexID: 18, index: 2 });
  }

  return hexes;
};
