import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"
import { useGame } from '../context/GameContext'
import { useToast } from "@chakra-ui/react"
import { PlaySoundEffect } from "../sounds/playSoundEffect"

export const GameData = (gameID: number, socket: SocketType) => {
    const { sfxEnabled, sfxVolume, setTournamentDirectJoinGameID, soundEffects } = useGame()
    const { gameData, setGameData, players, setPlayers, myGameData, setMyGameData } = useSync()

    const toast = useToast()

    // Handle game start update
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "GameStart") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData && parsedData.id === gameID) {
                    const isBotList = parsedData.isBot
                    const isConnectionList = parsedData.isConnected

                    const updatedPlayers = players.map((player) => {
                        return {
                            ...player,
                            isBot: isBotList[player.turnIndex],
                            isConnected: isConnectionList[player.turnIndex]
                        };
                    });

                    setPlayers(updatedPlayers);

                    setGameData(prevGameData => ({ ...prevGameData, Status: 1, LastStageTime: new Date() }))

                    setTournamentDirectJoinGameID(null)
                }
            }
        }
    }, [gameID, socket && socket.lastJsonMessage])

    // Handle status change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "StatusChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                // Store previous status
                const prevStatus = gameData.Status;

                if (parsedData.status === 5 || parsedData.status === 7) {
                    setGameData(prevGameData => ({
                        ...prevGameData,
                        PrevStatus: prevStatus,
                        Status: parsedData.status,
                        RobberTime: parsedData.time
                    }));
                } else if (parsedData.time === "0001-01-01T00:00:00Z" || parsedData.status === 8) {
                    setGameData(prevGameData => ({
                        ...prevGameData,
                        PrevStatus: prevStatus,
                        Status: parsedData.status
                    }));
                } else if (parsedData.status === 6) {
                    setGameData(prevGameData => ({
                        ...prevGameData,
                        PrevStatus: prevStatus,
                        Status: parsedData.status,
                        RobberTime: parsedData.time
                    }));
                } else {
                    setGameData(prevGameData => ({
                        ...prevGameData,
                        PrevStatus: prevStatus,
                        Status: parsedData.status,
                        LastStageTime: parsedData.time,
                        KnightBeforeDice: false
                    }));
                }
            }
        }
    }, [socket && socket.lastJsonMessage, gameData.Status]);


    // Handle turnNumber change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TurnNumberChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                setGameData(prevGameData => ({ ...prevGameData, TurnNumber: parsedData.turnNumber }))
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle room limit change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomLimitChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (parsedData) {
                    setGameData(prevGameData => ({ ...prevGameData, RoomLimit: parsedData.newLimit }))

                    const newIndexes = parsedData.indexChanges;

                    if (newIndexes.length > 0) {
                        const updatedPlayers = players.map(player => {
                            const matchingData = newIndexes.find(data => player.turnIndex === data.oldTurnIndex);

                            if (myGameData && myGameData.turn_index === matchingData.oldTurnIndex) {
                                setMyGameData(prevMyGameData => ({ ...prevMyGameData, turn_index: matchingData.newTurnIndex }))
                            }

                            if (matchingData) {
                                return {
                                    ...player,
                                    turnIndex: matchingData.newTurnIndex
                                };
                            }
                            return player;
                        });

                        setPlayers(updatedPlayers);
                    }

                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle gameover
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "Finished") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                setGameData(prevGameData => ({ ...prevGameData, GameOver: true, WinnerIndex: parsedData.winnerIndex, GameOverReason: parsedData.finishReason }))

                if (parsedData.finishReason === 1) {
                    toast({
                        description: "The game is terminated because the opponents abandoned the game.",
                        status: "info",
                        duration: 10000,
                        isClosable: true
                    })
                } else {
                    PlaySoundEffect(soundEffects.gameOverSFX, sfxVolume, sfxEnabled)
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle timer delayed 
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TimerDelayed") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setGameData(prevGameData => ({ ...prevGameData, LastStageTime: new Date() }))
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle status change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TimerSet") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (parsedData) {
                    setGameData(prevGameData => ({ ...prevGameData, LastStageTime: parsedData.time }))
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}
