import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"

export const OverviewBanner = ({ name }: { name: string }) => {
    const { dashboardAssets } = useGame()

    return (
        <Box position={"relative"} textAlign={"center"} w={"102%"} left={"-1%"}>
            <Image
                src={dashboardAssets.tournamentBannerOverview}
                zIndex={1}
                alt='tournament-banner'
            />
            <Box className='tournament-banner-overview-title'>
                {name}
            </Box>
        </Box>
    )
}