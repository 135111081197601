import settleSkins from '../../assets/Landing/skin_banner.png'
import bluredSettleSkins from '../../assets/Landing/blur_skin_banner.png'
import { Box, Image } from "@chakra-ui/react"

export const SkinsContent = () => {
    const goldTextColor = "#ECD96B"
    const skinContentBgColor = "#463827"

    return (
        <Box backgroundColor={skinContentBgColor}>
            <Box
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                className="flex-md-row ps-md-5 pe-md-5 p-2"
            >
                <Box display={"flex"}
                    justifyContent={"center"}
                    className="col-md-6 order-md-1 ps-md-5 pe-md-5 p-2"
                >
                    <Image
                        src={settleSkins}
                        alt='catan-with-skins'
                        width={"90%"}
                    />
                </Box>
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    className="col-md-6 order-md-2 ps-md-5 pe-md-5 pt-md-5 pb-md-5 p-2"
                >
                    <Box as={"h2"} fontSize={"4vw"} textColor={"white"} textAlign={"center"}>
                        New  <span style={{ color: goldTextColor }}>skin feature </span>is
                        coming <span style={{ color: goldTextColor }}> soon!</span>
                    </Box>
                </Box>
            </Box>
            <Image
                src={bluredSettleSkins}
                alt='catan-customization'
                w={"100%"}
                p={"2vw"}
            />
        </Box>
    )
}