import { Box, Text, useDisclosure } from "@chakra-ui/react"
import { ChestCard } from "./ChestCard"
import { useState } from "react"
import { ChestOpenModal } from "./ChestOpenModal"

type ChestsType = {
    myChests: any[],
    myKeys: any[],
    setItems: (value: any[]) => void
}

export const Chests = ({ myChests, myKeys, setItems }: ChestsType) => {
    const chestNumber = myChests.length

    const { isOpen, onClose, onOpen } = useDisclosure()

    const [openData, setOpenData] = useState(null)
    return (
        <Box>
            <Text className='inventory-header-banners'>
                Chests ({chestNumber})
            </Text>
            <Box
                display={"flex"}
                flexDir={"row"}
                overflowX={"auto"}
                w={"100%"}
                minH={"45%"}
                backgroundColor={"#edd5b7"}
                mb={5}
                p={5}
                borderRadius={"25px"}
            >
                {
                    myChests.length > 0 ?
                        myChests.map((chest, key) => {
                            return (
                                <ChestCard
                                    key={key}
                                    chestRowID={chest.row_id}
                                    chestID={chest.id}
                                    chestImage={chest.image}
                                    chestRarity={chest.rarity}
                                    keys={myKeys}
                                    setOpenData={setOpenData}
                                    onOpen={onOpen}
                                />
                            )
                        }) :
                        <Text
                            w={"100%"}
                            textAlign={"center"}
                            fontSize={"2vw"}
                            alignSelf={"center"}
                        >
                            You don't have any chest.
                        </Text>
                }
            </Box>
            {
                openData &&
                <ChestOpenModal
                    isOpen={isOpen}
                    onClose={onClose}
                    chestRowID={openData.chestRowID}
                    chestID={openData.chestID}
                    chestImage={openData.chestImage}
                    keys={openData.relatedKeys}
                    setItems={setItems}
                />
            }
        </Box>
    )
}