import { IconButton, Image, Box } from '@chakra-ui/react'
import { RemainingCards } from '../Game/Bank/RemainingCards'
import { useGame } from '../../context/GameContext'
import { useState } from 'react'

export const SpectatorBank = () => {
    const { gameAssets } = useGame()

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <IconButton
                zIndex={1}
                position={"fixed"}
                left={"6%"}
                bottom={"1%"}
                width={"4%"}
                height={"7%"}
                colorScheme={"yellow"}
                background={"transparent"}
                aria-label='building-costs'
                onClick={toggleDrawer}
                icon={<img src={gameAssets.bankButton} alt='bank-hud' width={"70%"} />}
            />
            <Box className={`bank-modal ${isOpen ? "open" : ""}`}>

                <Header gameAssets={gameAssets} />

                <Box
                    className='trade-box-close-button'
                    position={"absolute"}
                    right={"0%"}
                    top={"0%"}
                    onClick={toggleDrawer}
                />

                <Box
                    borderRadius={"0 0 0.5vw 0"}
                    backgroundColor={"#D6B488"}
                    p={"0.5vw"}
                >
                    <SubHeader headerText={"Remaning Builds & Building Costs"} />

                    <Box className='building-cost-row-container'>
                        <Box className='building-cost-row-one'>
                            <Box alignSelf={"center"}>
                                <VictoryPointLabel point='0' gameAssets={gameAssets} />
                            </Box>
                            <Box alignSelf={"center"} w={"3vw"} me={"1vw"}>
                                <Image src={gameAssets.roadDefault} w={"100%"} />
                            </Box>
                            <Box display={"flex"} alignItems={"center"} position={"relative"} w={"10vw"}>
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.woodCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position="relative"
                                    zIndex={1}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.brickCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position="absolute"
                                    left="15%"
                                    zIndex={2}
                                />
                            </Box>

                        </Box>
                    </Box>

                    <Box className='building-cost-row-container'>
                        <Box className='building-cost-row-two'>
                            <Box alignSelf={"center"}>
                                <VictoryPointLabel point='1' gameAssets={gameAssets} />
                            </Box>
                            <Box alignSelf={"center"} w={"3vw"} me={"1vw"}>
                                <Image src={gameAssets.settleDefault} w={"100%"} />
                            </Box>
                            <Box display={"flex"} alignItems={"center"} position={"relative"} w={"10vw"}>
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.woodCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position="relative"
                                    zIndex={1}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.brickCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"15%"}
                                    zIndex={2}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.wheatCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"30%"}
                                    zIndex={3}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.sheepCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"45%"}
                                    zIndex={3}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='building-cost-row-container'>
                        <Box className='building-cost-row-one'>
                            <Box alignSelf={"center"}>
                                <VictoryPointLabel point='2' gameAssets={gameAssets} />
                            </Box>
                            <Box alignSelf={"center"} w={"3vw"} me={"1vw"}>
                                <Image src={gameAssets.cityDefault} w={"100%"} />
                            </Box>
                            <Box display={"flex"} alignItems={"center"} position={"relative"} w={"10vw"}>
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.wheatCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position="relative"
                                    zIndex={1}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.wheatCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"15%"}
                                    zIndex={2}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.oreCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"30%"}
                                    zIndex={3}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.oreCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"45%"}
                                    zIndex={4}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.oreCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"60%"}
                                    zIndex={5}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='building-cost-row-container mb-2'>
                        <Box className='building-cost-row-two'>
                            <Box alignSelf={"center"}>
                                <VictoryPointLabel point='?' gameAssets={gameAssets} />
                            </Box>
                            <Box alignSelf={"center"} w={"3vw"} me={"1vw"}>
                                <Image src={gameAssets.devCardIcon} w={"100%"} />
                            </Box>
                            <Box display={"flex"} alignItems={"center"} position={"relative"} w={"10vw"}>
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.sheepCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position="relative"
                                    zIndex={1}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.wheatCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"15%"}
                                    zIndex={2}
                                />
                                <Image
                                    className="bounce-effect"
                                    src={gameAssets.oreCard}
                                    w={"25%"}
                                    height={"auto"}
                                    position={"absolute"}
                                    left={"30%"}
                                    zIndex={3}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <SubHeader headerText={"Remaining Resource Cards"} />
                    <RemainingCards />
                </Box>
            </Box>
        </>
    )
}

const Header = ({ gameAssets }: { gameAssets: any }) => {
    return (
        <Box
            backgroundColor={"#D6B488"}
            padding={"0.5vw 0.75vw"}
            borderRadius={"0 1.5vw 0 0"}
        >
            <Image
                src={gameAssets.bankButton}
                alt='bank-hud-icon'
                pos={"absolute"}
                w={"15%"}
                left={"5%"}
                top={"0.75%"}
                zIndex={"2"}
            />
            <Box className='bank-title-banner'>
                Bank
            </Box>
        </Box>
    )
}

export const SubHeader = ({ headerText }: { headerText: string }) => {
    return (
        <Box className='header-title-banner'>
            {headerText}
        </Box>
    )
}


const VictoryPointLabel = ({ point, gameAssets }: { point: string, gameAssets: any }) => {
    return (
        <Box pos={"relative"} textAlign={"center"} w={"3vw"}>
            <Image
                src={gameAssets.vpLabelDefault}
                alt="victory-point"
                width={"100%"}
            />
            <Box
                pos={"absolute"}
                top={"42%"}
                left={"48%"}
                transform={"translate(-50%, -50%)"}
                fontSize={"1.1vw"}
            >
                {point}
            </Box>
        </Box>
    )
}
