import { Box } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"

export const PlayerDiceIcon = ({ turnIndex }: { turnIndex: number }) => {
    const { gameData } = useSync()
    const { gameAssets } = useGame()

    if (gameData.TurnIndex === turnIndex && gameData.Status === 3) {
        return (
            <Box
                backgroundImage={gameAssets.diceIcon}
                className='dice-icon'
            />
        )
    } else {
        return null
    }
}