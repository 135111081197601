import tournamentCashBanner from '../../assets/Landing/tournaments_cash_banner.png'

export const TournamentCashBanner = () => {
    return (
        <img
            src={tournamentCashBanner}
            alt='catan-tournaments'
            width={"100%"}
        />
    )
}