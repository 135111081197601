import { Box, Button, Image, useToast } from "@chakra-ui/react"
import { FaClock } from "react-icons/fa6";
import { CreateTournamentCheckin, CreateTournamentSignup } from "../../../../../requests/TournamentRequests"
import { useGame } from "../../../../../context/GameContext"
import { getDateAndTime } from "../../../../../utils/constants/getDateAndTime";
import { RemainingTime } from "./RemainingTime";

type TournamentType = {
    backgroundColor: string,
    id: number,
    state: number,
    mode: number,
    startTime: string,
    currentPlayers: number,
    maxPlayers: number,
    prizePool: number[],
    isUserRegistered: boolean,
    isUserCheckedin: boolean,
    dailyTicketNumber: number,
    setTournamentNotif: (value: any) => void
}

export const Tournament = (
    {
        backgroundColor,
        id,
        state,
        mode,
        startTime,
        currentPlayers,
        maxPlayers,
        prizePool,
        isUserRegistered,
        isUserCheckedin,
        dailyTicketNumber,
        setTournamentNotif
    }: TournamentType) => {
    const { dashboardAssets } = useGame()

    const toast = useToast()

    const { time } = getDateAndTime(startTime)

    const tournamentMode = mode === 1 ? "Single Elim" : mode === 2 ? "Knockout" : "Championship"

    const remainingTime = RemainingTime(startTime)

    const handleRegister = async () => {
        const response = await CreateTournamentSignup(id)

        if (response && response.message === "success") {
            toast({
                description: "You have successfully registered for the Daily Tournament. You can see the details from Tournament Panel.",
                status: "success",
                duration: 5000,
            })
            setTournamentNotif(prev => {
                if (!Array.isArray(prev)) {
                    prev = [];
                }

                const newTournament = {
                    ...response.resp,
                    game_id: 0,
                    player_state: state,
                    checked_in: state === 2 ? true : false,
                    FreezeTimeEnd: "0001-01-01T00:00:00Z"
                };

                const newArray = [...prev, newTournament];

                newArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

                return newArray;
            });
        }
    }

    const handleCheckin = async () => {
        const response = await CreateTournamentCheckin(id)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-in for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            className={backgroundColor}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-around"}
                fontSize={"1.1vw"}
                textColor={"white"}
            >
                <Box
                    className="gradient-text-color"
                    p={"0.5vw"}
                    textColor={"#fff9e6"}
                    fontWeight={"600"}
                    fontSize={"1.1vw"}
                >
                    {tournamentMode}
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={"0.5vw"}>
                    <FaClock style={{ marginRight: "0.5vw" }} />
                    {time}
                </Box>
            </Box>

            <Box
                display={"flex"}
                justifyContent={"space-around"}
                fontSize={"1.1vw"}
                textColor={"white"}
            >
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={"0.5vw"}>
                    <Image
                        src={dashboardAssets.playerNumberIcon}
                        alt="catan-tournament-player-number-icon"
                        w={"30%"}
                        me={"0.5vw"}
                    />
                    {currentPlayers}/{maxPlayers}
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={"0.5vw"}>
                    <Image
                        src={dashboardAssets.prizePoolIcon}
                        alt="catan-tournament-player-number-icon"
                        w={"40%"}
                        me={"0.5vw"}
                    />
                    ${
                        prizePool.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                        }, 0)
                    }
                </Box>
            </Box>

            <Box
                p={"0.5vw"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                {
                    !isUserRegistered ?
                        <Button
                            backgroundColor={"#75a6d7"}
                            boxShadow={"0 0.25vw #37628b"}
                            colorScheme={"linkedin"}
                            w={"15vw"}
                            height={"2.5vw"}
                            fontSize={"1vw"}
                            textColor={"white"}
                            display={"flex"}
                            flexDir={"column"}
                            alignItems={"center"}
                            isDisabled={isUserRegistered || dailyTicketNumber < 1}
                            onClick={handleRegister}
                        >
                            <Box>
                                Register
                            </Box>
                            <Box textColor={"black"}>
                                starts in {remainingTime}
                            </Box>
                        </Button> :
                        <Button
                            backgroundColor={"#4cc35e"}
                            boxShadow={"0 0.25vw #1d9430"}
                            colorScheme={"green"}
                            w={"15vw"}
                            height={"2.5vw"}
                            fontSize={"1vw"}
                            textColor={"white"}
                            display={"flex"}
                            flexDir={"column"}
                            alignItems={"center"}
                            isDisabled={state !== 2 || isUserCheckedin}
                            onClick={handleCheckin}
                        >
                            <Box>
                                Check-in
                            </Box>
                            <Box textColor={"black"}>
                                starts in {remainingTime}
                            </Box>
                        </Button>
                }
            </Box>
        </Box>
    )
}