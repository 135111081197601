import { Button, Image } from "@chakra-ui/react";
import { useGame } from "../../../../../context/GameContext";

type CardRegisterType = {
    State: number,
    CurrentStage: number,
    IsUserSignedUp: boolean,
    IsPrivate: boolean,
    HaveTicket: boolean,
    handleRegister: () => void;
    handleRegisterCancel: () => void;
}

export const CardRegister = ({ State, CurrentStage, IsUserSignedUp, IsPrivate, HaveTicket, handleRegister, handleRegisterCancel }: CardRegisterType) => {
    const { dashboardAssets } = useGame()

    const renderIcon = () => (
        <Image
            src={IsPrivate ? dashboardAssets.lockIcon : dashboardAssets.registerIcon}
            w={"1.1vw"}
            className="me-2"
            alt="register-icon"
        />
    );


    const renderButton = () => {
        if (IsUserSignedUp) {
            return (
                <Button
                    width={"40%"}
                    height={"40%"}
                    fontSize={"1.1vw"}
                    padding={"0.3vw"}
                    backgroundColor={"#c97f64"}
                    border={"0.15vw solid #A72C01"}
                    boxShadow={"0 0.15vw #A72C01"}
                    colorScheme={"red"}
                    onClick={handleRegisterCancel}
                >
                    {renderIcon()}
                    Cancel Registration
                </Button>
            );
        }

        return (
            <Button
                width={"40%"}
                height={"40%"}
                fontSize={"1.1vw"}
                padding={"0.3vw"}
                backgroundColor={"#72a8da"}
                border={"2px solid #3c77ae"}
                boxShadow={"0px 2px #3c77ae"}
                colorScheme={"blue"}
                isDisabled={IsPrivate && !HaveTicket}
                onClick={handleRegister}
            >
                {renderIcon()}
                Register
            </Button>
        );
    };

    return (State === 1 || State === 2) && CurrentStage < 3 ? renderButton() : null;
}