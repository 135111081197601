import { Box, Button } from "@chakra-ui/react"

export const ReConnectWallet = ({ setShowWalletConnect }: { setShowWalletConnect: (value: boolean) => void }) => {
    return (
        <Box display={"flex"} justifyContent={"center"}>
            <Button
                mb={5}
                backgroundColor={"#7fa631"}
                colorScheme={"green"}
                borderRadius={"25px"}
                border={"2px solid black"}
                onClick={() => setShowWalletConnect(true)}
            >
                Re-Connect Wallet
            </Button>
        </Box>
    )
}