import { Box, ListItem, UnorderedList } from "@chakra-ui/react"
import { MatchStatusBox } from "./MatchStatusBox"
import { KingHoldingTrophy } from "./KingHoldingTrophy"

type TipsType = {
    PlayerState: number,
    Matches: any[],
    TipOne: string,
    TipTwo: string
}

export const Tips = ({ PlayerState, Matches, TipOne, TipTwo }: TipsType) => {
    const ongoingMatches = Matches && Matches.length > 0 ? Matches.filter((match) => match.state === 1).length : 0

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundColor={"#493b2f"}
            borderRadius={"2vw"}
            textColor={"white"}
            w={"90%"}
        >
            <Box
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                textAlign={"center"}
            >
                <Box fontSize={"2vw"} ps={"0.5vw"} pb={"0.5vw"}>
                    {
                        PlayerState === 2 ?
                            "Tournament Tips" :
                            "Semi Final Match Status"
                    }
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mb={"0.5vw"}>
                    {
                        Matches && Matches.length > 0 &&
                        Matches.map((match, index) => {
                            return (
                                <MatchStatusBox
                                    key={index}
                                    Status={match.state} />
                            )
                        })
                    }
                </Box>

                {
                    ongoingMatches > 0 &&
                    <Box fontSize={"1vw"} mt={"0.5vw"}>
                        {ongoingMatches} match left for the next stage
                    </Box>
                }

                <UnorderedList ps={"1vw"} fontSize={"1.25vw"}>
                    <ListItem>
                        {TipOne}
                    </ListItem>
                    <ListItem>
                        {TipTwo}
                    </ListItem>
                </UnorderedList>
            </Box>

            {
                Matches && Matches.length < 8 &&
                <KingHoldingTrophy />
            }
        </Box>
    )
}