import { Box, IconButton } from "@chakra-ui/react"
import { MdChangeCircle } from "react-icons/md";
import { useSync } from "../../../../context/SyncContext"
import { useGame } from "../../../../context/GameContext";
import { CreateChangeRoomLimit } from "../../../../requests/LobbyRequests";

export const ChangeRoomLimitButton = () => {
    const { gameID } = useGame()
    const { myGameData, gameData, players } = useSync()

    const handleLimitChange = async () => {
        const newLimit = gameData.RoomLimit === 3 ? 4 : 3
        await CreateChangeRoomLimit(gameID, newLimit)
    }

    if (myGameData) {
        const is1v1 = gameData.RoomLimit === 2
        const isTournamentGame = gameData.IsTournamentGame
        const amIOwner = myGameData.isOwner
        const roomLimit = gameData.RoomLimit

        const showPlayerLimitChangeButton = !is1v1 && amIOwner && !isTournamentGame

        return (
            <Box
                ml={"auto"}
                mr={"1vw"}
                display={"flex"}
                alignItems={"center"}
            >
                {
                    showPlayerLimitChangeButton &&
                    <IconButton
                        me={"0.75vw"}
                        w={"2vw"}
                        h={"2vw"}
                        fontSize={"2vw"}
                        backgroundColor={"transparent"}
                        color={"#8aa5c9"}
                        colorScheme={"blackAlpha"}
                        aria-label="change-player-limit"
                        icon={<MdChangeCircle />}
                        isDisabled={players.length === 4}
                        onClick={handleLimitChange}
                    />
                }
                <Box whiteSpace={"nowrap"} me={"1vw"}>
                    {roomLimit} Player
                </Box>
                <Box w={"3vw"}>
                    <PlayerNumberIcon RoomLimit={roomLimit} />
                </Box>
            </Box>
        )
    }

    return null
}

const PlayerNumberIcon = ({ RoomLimit }: { RoomLimit: number }) => {
    const { dashboardAssets } = useGame()

    if (RoomLimit === 3) {
        return <img src={dashboardAssets.threePlayerIcon} alt="three-player-icon" width={"100%"} />
    } else if (RoomLimit === 4) {
        return <img src={dashboardAssets.fourPlayerIcon} alt="four-player-icon" width={"100%"} />
    } else {
        return <img src={dashboardAssets.twoPlayerIcon} alt="two-player-icon" width={"100%"} />
    }
}