import { useState, useContext, createContext, ReactNode, useEffect } from "react";
import { useGame } from "./GameContext";
import { GetActiveTrade, GetAuxSyncData, GetBuildData, GetMainSyncData, GetMyGameData, GetMyTradeRatio, GetPlayers, GetRemainingCards, GetResource, GetRoomData, GetTotalResource } from "../requests/GameRequests";
import { DockRatios, TradeData } from "../utils/types/types";

type SyncContextType = {
    gameData: {
        Status: number,
        PrevStatus: number,
        TurnNumber: number,
        TurnIndex: number,
        WinnerIndex: number,
        GameOver: boolean,
        KnightBeforeDice: boolean,
        GameOverReason: string,
        LastStageTime: Date,
        RobberTime: Date,
        RoomLimit: number,
        IsTournamentGame: boolean,
        StartTime: string
    };
    setGameData: (value: any) => void;

    resources: {
        Wood: number,
        Brick: number,
        Sheep: number,
        Wheat: number,
        Ore: number
    };
    setResources: (value: any) => void;

    totalResCards: number[];
    setTotalResCards: (value: any) => void;

    myGameData: any;
    setMyGameData: (value: any) => void;

    players: any[];
    setPlayers: (value: any) => void;

    playersSkinChoice: string[] | null;
    setPlayersSkinChoice: (value: string[] | null) => void;

    remainingRes: number[];
    setRemainingRes: (value: any) => void;

    roadPositions: any[];
    setRoadPositions: (value: any) => void;

    settlePositions: any[];
    setSettlePositions: (value: any) => void;

    armyPoints: number[];
    setArmyPoints: (value: any) => void;

    longestRoads: number[];
    setLongestRoads: (value: any) => void;

    remainingBuilds: {
        road: number;
        settle: number;
        city: number;
    };
    setRemainingBuilds: (value: any) => void;

    robberPosition: number;
    setRobberPosition: (value: any) => void;

    responses: any[];
    setResponses: (value: any) => void;

    trade: TradeData | null;
    setTrade: (value: any) => void;

    counterTrades: TradeData[] | any[];
    setCounterTrades: (value: any) => void;

    points: number[];
    setPoints: (value: any) => void;

    totalDevCards: number[];
    setTotalDevCards: (value: any) => void;

    devCards: any[];
    setDevCards: (value: any) => void;

    remainingDevCards: number;
    setRemaingingDevCards: (value: any) => void;

    dice: { diceOne: number, diceTwo: number, turnIndex: number } | null;
    setDice: (value: any) => void;

    largestArmyOwner: number;
    setLargestArmyOwner: (value: number) => void;

    longestRoadOwner: number;
    setLongestRoadOwner: (value: number) => void;

    stealData: any;
    setStealData: (value: any) => void;

    dockRatios: DockRatios;
    setDockRatios: (value: any) => void;
};

const SyncContext = createContext<SyncContextType>({
    gameData: {
        Status: -1,
        PrevStatus: -1,
        TurnNumber: 0,
        TurnIndex: 0,
        WinnerIndex: -1,
        GameOver: false,
        KnightBeforeDice: false,
        GameOverReason: "",
        LastStageTime: new Date(),
        RobberTime: new Date(),
        RoomLimit: 0,
        IsTournamentGame: false,
        StartTime: ""
    },
    setGameData: () => { },

    resources: {
        Wood: 0,
        Brick: 0,
        Sheep: 0,
        Wheat: 0,
        Ore: 0
    },
    setResources: () => { },

    totalResCards: [0, 0, 0, 0],
    setTotalResCards: () => { },

    myGameData: null,
    setMyGameData: () => { },

    players: [],
    setPlayers: () => { },

    playersSkinChoice: null,
    setPlayersSkinChoice: () => { },

    remainingRes: [19, 19, 19, 19, 19],
    setRemainingRes: () => { },

    roadPositions: [],
    setRoadPositions: () => { },

    settlePositions: [],
    setSettlePositions: () => { },

    armyPoints: [0, 0, 0, 0],
    setArmyPoints: () => { },

    longestRoads: [0, 0, 0, 0],
    setLongestRoads: () => { },

    remainingBuilds: { road: 15, settle: 5, city: 4 },
    setRemainingBuilds: () => { },

    robberPosition: -1,
    setRobberPosition: () => { },

    responses: [],
    setResponses: () => { },

    trade: null,
    setTrade: () => { },

    counterTrades: [],
    setCounterTrades: () => { },

    points: [0, 0, 0, 0],
    setPoints: () => { },

    totalDevCards: [0, 0, 0, 0],
    setTotalDevCards: () => { },

    devCards: [],
    setDevCards: () => { },

    remainingDevCards: 25,
    setRemaingingDevCards: () => { },

    dice: null,
    setDice: () => { },

    largestArmyOwner: -1,
    setLargestArmyOwner: () => { },

    longestRoadOwner: -1,
    setLongestRoadOwner: () => { },

    stealData: null,
    setStealData: () => { },

    dockRatios: { Wood: 4, Brick: 4, Sheep: 4, Wheat: 4, Ore: 4 },
    setDockRatios: () => { }
});

const SyncProvider: React.FC<{ children: ReactNode }> = ({ children, }: { children: ReactNode; }) => {
    const { gameID, isReconnect, isSpectator } = useGame()

    const [gameData, setGameData] = useState({
        Status: -1,
        PrevStatus: -1,
        TurnNumber: 0,
        TurnIndex: 0,
        WinnerIndex: -1,
        GameOver: false,
        KnightBeforeDice: false,
        GameOverReason: "",
        LastStageTime: new Date(),
        RobberTime: new Date(),
        RoomLimit: 0,
        IsTournamentGame: false,
        StartTime: ""
    })
    const [myGameData, setMyGameData] = useState<any>(null)

    const [resources, setResources] = useState({
        Wood: 0,
        Brick: 0,
        Sheep: 0,
        Wheat: 0,
        Ore: 0
    })
    const [devCards, setDevCards] = useState<any[]>([])
    const [totalResCards, setTotalResCards] = useState<number[]>([0, 0, 0, 0])
    const [totalDevCards, setTotalDevCards] = useState<number[]>([0, 0, 0, 0])

    const [dockRatios, setDockRatios] = useState<DockRatios>({ Wood: 4, Brick: 4, Sheep: 4, Wheat: 4, Ore: 4 })

    const [points, setPoints] = useState<number[]>([0, 0, 0, 0])
    const [armyPoints, setArmyPoints] = useState<number[]>([0, 0, 0, 0])
    const [longestRoads, setLongestRoads] = useState<number[]>([0, 0, 0, 0])
    const [largestArmyOwner, setLargestArmyOwner] = useState<number>(-1)
    const [longestRoadOwner, setLongestRoadOwner] = useState<number>(-1)

    const [players, setPlayers] = useState<any[]>([])

    const [playersSkinChoice, setPlayersSkinChoice] = useState<string[] | null>(null)

    const [remainingRes, setRemainingRes] = useState<number[]>([19, 19, 19, 19, 19])
    const [remainingDevCards, setRemaingingDevCards] = useState(25)

    const [roadPositions, setRoadPositions] = useState<any[]>([])
    const [settlePositions, setSettlePositions] = useState<any[]>([])
    const [remainingBuilds, setRemainingBuilds] = useState({ road: 15, settle: 5, city: 4 })

    const [responses, setResponses] = useState<any[]>([])
    const [trade, setTrade] = useState<TradeData | null>(null)

    const [counterTrades, setCounterTrades] = useState<any[]>([])

    const [robberPosition, setRobberPosition] = useState(-1)
    const [dice, setDice] = useState<{ diceOne: number, diceTwo: number, turnIndex: number } | null>(null)

    const [stealData, setStealData] = useState<any>(null)

    // Fetch game data
    useEffect(() => {
        const fetchGameData = async () => {
            const data = await GetRoomData(gameID)
            data && setGameData({
                ...gameData,
                Status: data.Status,
                TurnNumber: data.TurnNumber,
                TurnIndex: data.TurnIndex,
                RoomLimit: data.RoomLimit,
                IsTournamentGame: data.IsTournamentGame,
                StartTime: data.StartTime,
                LastStageTime: data.LastTimerStart
            })
        }

        if (gameID > -1) {
            fetchGameData()
        } else {
            setGameData({
                Status: -1,
                PrevStatus: -1,
                TurnNumber: 0,
                TurnIndex: 0,
                WinnerIndex: -1,
                GameOver: false,
                KnightBeforeDice: false,
                GameOverReason: "",
                LastStageTime: new Date(),
                RobberTime: new Date(),
                RoomLimit: 0,
                IsTournamentGame: false,
                StartTime: ""
            })
        }
    }, [gameID])

    // Fetch my game data
    useEffect(() => {
        const fetchMyData = async () => {
            const data = await GetMyGameData(gameID)
            data && setMyGameData(data)
        }

        if (gameID > -1 && !isSpectator) {
            fetchMyData()
        } else {
            setMyGameData(null)
        }
    }, [gameID])

    // Fetch my resource data
    useEffect(() => {
        const fetchResources = async () => {
            const data = await GetResource(gameID)
            data && setResources(data.resources)
        }

        if (gameID > -1 && isReconnect && !isSpectator) {
            fetchResources()
        } else {
            setResources({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
        }
    }, [gameID, isReconnect])

    // Fetch players total res card number
    useEffect(() => {
        const fetchTotalRes = async () => {
            const total = await GetTotalResource(gameID)
            total && setTotalResCards(total.resources)
        }

        if (gameID > -1 && isReconnect) {
            fetchTotalRes()
        } else {
            setTotalResCards([0, 0, 0, 0, 0])
        }
    }, [gameID, isReconnect]);

    // Fetch players' data
    useEffect(() => {
        const fetchPlayers = async () => {
            const data = await GetPlayers(gameID)

            if (data) {
                const updatedPlayers = data.players.map(player => {
                    if (myGameData && player.address === myGameData.address) {
                        return { ...player, isConnected: true };
                    }
                    return player;
                });
                setPlayers(updatedPlayers);
            }
        }

        if (gameID > -1) {
            fetchPlayers()
        } else {
            setPlayers([])
        }
    }, [gameID, myGameData])

    // Set Players Skin Choice
    useEffect(() => {
        if (players && gameData.RoomLimit !== 0) {
            if (players.length === gameData.RoomLimit) {
                const skinChoices = new Array(gameData.RoomLimit).fill(null);

                players.forEach((player) => {
                    skinChoices[player.turnIndex] = player.skin;
                });

                setPlayersSkinChoice(skinChoices);
            }
        }
    }, [players])

    // Fetch remaining resource card data
    useEffect(() => {
        const fetchRemainingCards = async () => {
            const data = await GetRemainingCards(gameID)

            data && setRemainingRes([
                data.resources.Wood,
                data.resources.Brick,
                data.resources.Sheep,
                data.resources.Wheat,
                data.resources.Ore
            ])
        }

        if (gameID > -1 && isReconnect) {
            fetchRemainingCards()
        } else {
            setRemainingRes([19, 19, 19, 19, 19])
        }
    }, [gameID, isReconnect])

    // Fetch road and settle data
    useEffect(() => {
        const fetchBuilds = async () => {
            const data = await GetBuildData(gameID)

            data && setRoadPositions(data.Roads)
            data && setSettlePositions(data.Settles);
        }

        if (gameID > -1 && isReconnect) {
            fetchBuilds()
        } else {
            setRoadPositions([])
            setSettlePositions([])
        }
    }, [gameID, isReconnect])

    // Fetch Aux Sync Data
    useEffect(() => {
        const fetchAux = async () => {
            const result = await GetAuxSyncData(gameID);

            if (result) {
                setRobberPosition(result.data.thiefPosition)
                result.data.devCardPortfolio && setDevCards(result.data.devCardPortfolio)
                setRemaingingDevCards(result.data.remainingDevCard)
                setLongestRoadOwner(result.data.longestRoad)
                setLargestArmyOwner(result.data.largestArmy)
                result.data.latestDice[0] !== 0 && setDice({ diceOne: result.data.latestDice[0], diceTwo: result.data.latestDice[1], turnIndex: gameData.TurnIndex })
            }
        }

        if (gameID > -1 && isReconnect) {
            fetchAux()
        } else {
            setRobberPosition(-1)
            setDevCards([])
            setRemaingingDevCards(25)
            setLargestArmyOwner(-1)
            setLongestRoadOwner(-1)
        }
    }, [gameID, isReconnect])

    // Fetch Main Sync Data
    useEffect(() => {
        const fetchMain = async () => {
            const result = await GetMainSyncData(gameID);

            if (result) {
                setArmyPoints(result.data.largestArmy)
                setLongestRoads(result.data.longestRoad)
                setTotalDevCards(result.data.totalDevCard)
                setPoints(result.data.victoryPoints)
            }
        }

        if (gameID > -1 && isReconnect) {
            fetchMain()
        } else {
            setArmyPoints([0, 0, 0, 0])
            setLongestRoads([0, 0, 0, 0])
            setTotalDevCards([0, 0, 0, 0])
            setPoints([0, 0, 0, 0])
        }
    }, [gameID, isReconnect])

    //Fetch trade data
    useEffect(() => {
        const fetchTrade = async () => {
            const result = await GetActiveTrade(gameID)

            if (result && result.data !== "No Trade Found") {
                result.data && setTrade(result.data)
                result.data && setResponses(result.data.responses)
                result.data && setCounterTrades(result.data.counterOffers)
            }
        }

        if (gameID > -1 && isReconnect) {
            fetchTrade()
        } else {
            setTrade(null)
            setResponses([])
            setCounterTrades([])
        }
    }, [gameID, isReconnect])

    //Fetch dock ratios
    useEffect(() => {
        const fetchRatio = async () => {
            const data = await GetMyTradeRatio(gameID)
            data && setDockRatios(data.ratios)
        }

        if (gameID > -1 && isReconnect && !isSpectator) {
            fetchRatio()
        } else {
            setDockRatios({ Wood: 4, Brick: 4, Sheep: 4, Wheat: 4, Ore: 4 })
        }
    }, [gameID, isReconnect])

    const results: SyncContextType = {
        gameData,
        setGameData,
        myGameData,
        setMyGameData,
        resources,
        setResources,
        totalResCards,
        setTotalResCards,
        players,
        setPlayers,
        playersSkinChoice,
        setPlayersSkinChoice,
        remainingRes,
        setRemainingRes,
        roadPositions,
        setRoadPositions,
        settlePositions,
        setSettlePositions,
        armyPoints,
        setArmyPoints,
        longestRoads,
        setLongestRoads,
        remainingBuilds,
        setRemainingBuilds,
        robberPosition,
        setRobberPosition,
        responses,
        setResponses,
        trade,
        setTrade,
        counterTrades,
        setCounterTrades,
        points,
        setPoints,
        totalDevCards,
        setTotalDevCards,
        devCards,
        setDevCards,
        remainingDevCards,
        setRemaingingDevCards,
        dice,
        setDice,
        largestArmyOwner,
        setLargestArmyOwner,
        longestRoadOwner,
        setLongestRoadOwner,
        stealData,
        setStealData,
        dockRatios,
        setDockRatios
    };

    return (
        <SyncContext.Provider value={results}>
            {children}
        </SyncContext.Provider>
    );
};

const useSync = () => useContext(SyncContext);

export { SyncProvider, useSync };