type AssetMap = Record<string, string>;

const preloadImage = (
  name: string,
  src: string
): Promise<{ name: string; src: string }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve({ name, src });
    img.onerror = () => {
      console.error(`Failed to load image: ${src}`);
      reject(new Error(`Failed to load image: ${src}`));
    };
  });
};

export const preloadSFX = async (assets: AssetMap): Promise<AssetMap> => {
  const audioContext = new window.AudioContext();
  try {
    const promises = Object.entries(assets).map(async ([name, src]) => {
      const response = await fetch(src);
      const arrayBuffer = await response.arrayBuffer();
      await audioContext.decodeAudioData(arrayBuffer);
      return { name, src };
    });

    const loadedAssets = await Promise.all(promises);
    return loadedAssets.reduce((assetMap, { name, src }) => {
      assetMap[name] = src;
      return assetMap;
    }, {} as AssetMap);
  } catch (error) {
    console.error("Error loading SFX:", error);
    throw error;
  }
};

export const preloadAssets = async (assets: AssetMap): Promise<AssetMap> => {
  try {
    const promises = Object.entries(assets).map(([name, src]) =>
      preloadImage(name, src)
    );
    const loadedAssets = await Promise.all(promises);
    return loadedAssets.reduce((assetMap, { name, src }) => {
      assetMap[name] = src;
      return assetMap;
    }, {} as AssetMap);
  } catch (error) {
    console.error("Error loading assets:", error);
    throw error;
  }
};
