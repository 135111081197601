import boardBanner from '../../assets/Landing/board_banner.png'
import { Box, Text, Image, useDisclosure } from "@chakra-ui/react"
import { SignInModal } from './SignInModal'

export const QuestContent = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    const goldTextColor = "#ECD96B"

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            className="brown-background flex-md-row p-md-2 p-2"
        >
            <Box
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                className="col-md-6 p-md-2 p-2"
            >
                <Image src={boardBanner}
                    alt='earn-rewards'
                    width={"80%"}
                />
            </Box>
            <Box
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                className="col-md-6 p-md-2 p-2"
            >
                <Text as={"h2"} fontSize={"2.75vw"} className='d-md-block d-none' textColor={"white"}>
                    Conquer
                    <span style={{ color: goldTextColor }}> New Worlds </span>
                    to become the ultimate Pioneer!
                </Text>
                <Text as={"h2"} fontSize={"2.75vw"} className='d-md-block d-none' textColor={"white"} >
                    Embark on this thrilling journey toward
                    <span style={{ color: goldTextColor }}> triumph!</span>
                </Text>
                <SignInModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                />
            </Box>
        </Box>
    )
}