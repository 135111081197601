import { Box } from "@chakra-ui/react";

export const OverviewPrizePool = ({ Prize }: { Prize: number[] }) => {
    return (
        <Box display={"flex"} alignItems={"center"} backgroundColor={"#dcb88a"} mt={2} p={3}>
            <Box className="col-6" borderRight={"1px solid black"}>
                <Box textColor={"#392516"} fontSize={"25px"} textAlign={"center"} mb={2}>
                    Prize Pool
                </Box>
                <Box className='tournament-prizes-yellow-text'>
                    ${
                        Prize.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                        }, 0)
                    }
                </Box>
            </Box>

            <Box className="col-6" display={"flex"} justifyContent={"center"} alignItems={"center"} p={3}>
                <Box h={"100%"} w={"75px"}>
                    <Box className='tournament-prizes-pool-yellow-text'>
                        ${Prize[0]}
                    </Box>
                    <Box
                        className='tournament-prizes-pool-yellow-text'
                        borderRadius={"10px 10px 0px 0px"}
                        border={"2px solid #72532f"}
                        backgroundColor={"#ad813e"}
                        height={"100px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        1st
                    </Box>
                </Box>
                <Box h={"100%"} w={"75px"}>
                    <Box className='tournament-prizes-pool-yellow-text' mt={"10px"}>
                        ${Prize[1]}
                    </Box>
                    <Box
                        className='tournament-prizes-pool-yellow-text'
                        borderRadius={"10px 10px 0px 0px"}
                        border={"2px solid #72532f"}
                        backgroundColor={"#866439"}
                        height={"90px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        2nd
                    </Box>
                </Box>
                <Box h={"100%"} w={"75px"}>
                    <Box className='tournament-prizes-pool-yellow-text' mt={"20px"}>
                        ${Prize[2]}
                    </Box>
                    <Box
                        className='tournament-prizes-pool-yellow-text'
                        borderRadius={"10px 10px 0px 0px"}
                        border={"2px solid #72532f"}
                        backgroundColor={"#866439"}
                        height={"80px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        3rd
                    </Box>
                </Box>
                <Box h={"100%"} w={"75px"}>
                    <Box className='tournament-prizes-pool-yellow-text' mt={"30px"}>
                        ${Prize[3]}
                    </Box>
                    <Box
                        className='tournament-prizes-pool-yellow-text'
                        borderRadius={"10px 10px 0px 0px"}
                        border={"2px solid #72532f"}
                        backgroundColor={"#866439"}
                        height={"70px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        4th
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}