import { Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, IconButton, ModalHeader, FormLabel } from '@chakra-ui/react'
import { Input, Button, FormControl, useToast, RadioGroup, Stack, Radio, Image, Box } from '@chakra-ui/react'
import { FaInfoCircle } from 'react-icons/fa';
import { useState } from "react";
import { CreateUsername } from "../../../../requests/AuthRequests";
import { useAuth } from "../../../../context/AuthContext";
import { MdEdit } from 'react-icons/md';
import { DashboardCloseButton } from '../../General/DashboardCloseButton';
import { useGame } from '../../../../context/GameContext';

export const PlayerProfile = () => {
    const { avatarID, setAvatarID, dashboardAssets } = useGame()
    const { setUsername } = useAuth();
    const { isOpen, onClose, onOpen } = useDisclosure()

    const toastMessage = useToast()

    const [usernameInput, setUsernameInput] = useState<string>("")

    const usernameHasSpace = usernameInput.includes(" ");

    const handleCreateUsername = async () => {
        const usernameLength = usernameInput.length;

        if (usernameLength > 2 && usernameLength < 13 && !usernameHasSpace) {
            const response = await CreateUsername(usernameInput)

            if (response && response.message === "success") {
                setUsername(usernameInput)
                toastMessage({
                    description: "Username has been changed.",
                    duration: 2000,
                    status: "success",
                    isClosable: true,
                })
                setUsernameInput("")
                onClose()
            } else if (response && response.error === "username already exists") {
                toastMessage({
                    description: "This username is used by another player.",
                    duration: 2000,
                    status: "error",
                    isClosable: true,
                })
            }
        } else {
            toastMessage({
                description: "Length of the username must be between 3 and 12 character.",
                duration: 2000,
                status: "error",
                isClosable: true,
            })
        }
    }

    const handleAvatarChange = (avatarIndex: string) => {
        localStorage.setItem("avatar", avatarIndex)
        setAvatarID(avatarIndex)
    }

    return (
        <>
            <IconButton
                pos={"absolute"}
                bottom={"-10%"}
                right={"-10%"}
                backgroundColor={"#ACA320"}
                minW={"3vw"}
                height={"3vw"}
                border={"0.25vw solid #413320"}
                borderRadius={"50%"}
                colorScheme='green'
                aria-label="username-edit"
                onClick={onOpen}
                icon={<MdEdit fontSize={"2vw"} />}
            />
            <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset={"slideInBottom"}
                size={"lg"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader className='menu-modal-headers'>
                        Player Profile
                    </ModalHeader>

                    <DashboardCloseButton onClose={onClose} />

                    <ModalBody pb={6}>
                        <FormControl mb={3}>

                            <FormLabel>Avatars</FormLabel>
                            <RadioGroup onChange={(value) => handleAvatarChange(value)} value={avatarID}>
                                <Stack spacing={2} direction='row'>
                                    <Radio colorScheme={"blackAlpha"} value='0'>
                                        <Image
                                            src={dashboardAssets.avatarJean}
                                            alt="avatarJean"
                                            w={"100px"}
                                            borderRadius={"25px"}
                                        />
                                    </Radio>
                                    <Radio colorScheme={"blackAlpha"} value='1'>
                                        <Image
                                            src={dashboardAssets.avatarLin}
                                            alt="avatarLin"
                                            w={"100px"}
                                            borderRadius={"25px"}
                                        />
                                    </Radio>
                                    <Radio colorScheme={"blackAlpha"} value='2'>
                                        <Image
                                            src={dashboardAssets.avatarLouis}
                                            alt="avatarLouis"
                                            w={"100px"}
                                            borderRadius={"25px"}
                                        />
                                    </Radio>
                                    <Radio colorScheme={"blackAlpha"} value='3'>
                                        <Image
                                            src={dashboardAssets.avatarWilliam}
                                            alt="avatarWilliam"
                                            w={"100px"}
                                            borderRadius={"25px"}
                                        />
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                            <Box display={"flex"} justifyContent={"left"} alignItems={"center"} mt={2} textColor={"InfoText"}>
                                <FaInfoCircle className="me-2" />
                                The avatar you choose will not be used in your games.
                            </Box>
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='usernameInput'>Username</FormLabel>
                            <Input
                                id="usernameInput"
                                placeholder='Type your username'
                                onChange={(e) => setUsernameInput(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                            <Box display={"flex"} justifyContent={"left"} alignItems={"center"} mt={2} textColor={"InfoText"}>
                                <FaInfoCircle className="me-2" />
                                Username must be min 3 and max 12 characters.
                            </Box>
                            <Box display={"flex"} justifyContent={"left"} alignItems={"center"} mt={2} textColor={"InfoText"}>
                                <FaInfoCircle className="me-2" />
                                You cannot use spaces in your name!
                            </Box>
                        </FormControl>

                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={usernameInput.length < 3 || usernameInput.length > 12 || usernameHasSpace}
                            onClick={handleCreateUsername}
                        >
                            Change Username
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}