import { Box } from "@chakra-ui/react"
import { OverviewBanner } from "./OverviewBanner";
import { OverviewCost } from "./OverviewCost";
import { OverviewDate } from "./OverviewDate";
import { OverviewPrizePool } from "./OverviewPrizePool";
import { OverviewTournamentMode } from "./OverviewTournamentMode";
import { OverviewRegister } from "./OverviewRegister";
import { OverviewCheckin } from "./OverviewCheckin";
import { OverviewPlayers } from "./OverviewPlayers";
import { OverviewGameInfo } from "./OverviewGameInfo";

type TournamentOverviewType = {
    TicketAsset: string,
    Capacity: number,
    IsDoubleElimination: boolean,
    IsChampionship: boolean,
    CurrentPlayers: number,
    IsUserSignedUp: boolean,
    IsPrivate: boolean,
    Name: string,
    Prize: number[],
    StartTime: string,
    State: number,
    UserCheckedIn: boolean,
    HaveTicket: boolean,
    handleRegister: () => Promise<void>,
    handleRegisterCancel: () => Promise<void>,
    handleCheckin: () => Promise<void>,
    handleCheckinCancel: () => Promise<void>
}

export const TournamentOverview = ({
    TicketAsset,
    Capacity,
    IsDoubleElimination,
    IsChampionship,
    CurrentPlayers,
    IsUserSignedUp,
    IsPrivate,
    Name,
    Prize,
    StartTime,
    State,
    UserCheckedIn,
    HaveTicket,
    handleRegister,
    handleCheckinCancel,
    handleCheckin,
    handleRegisterCancel }: TournamentOverviewType) => {

    return (
        <Box borderRadius={"25px"}>
            <OverviewBanner name={Name} />

            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <OverviewCost isPrivate={IsPrivate} ticketAsset={TicketAsset} />
                <OverviewDate StartTime={StartTime} />
            </Box>

            <OverviewGameInfo
                isDoubleElimination={IsDoubleElimination}
                isChampionship={IsChampionship}
            />

            <OverviewPrizePool Prize={Prize} />

            <Box display={"flex"} mt={2} mb={1}>
                <Box className="col-6" p={3} borderRight={"1px solid black"}>
                    <OverviewTournamentMode
                        IsDoubleElimination={IsDoubleElimination}
                        isChampionship={IsChampionship}
                    />

                    <OverviewRegister
                        IsUserSignedUp={IsUserSignedUp}
                        IsPrivate={IsPrivate}
                        State={State}
                        HaveTicket={HaveTicket}
                        handleRegister={handleRegister}
                        handleRegisterCancel={handleRegisterCancel}
                    />

                    <Box textAlign={"center"} textColor={"#392516"} fontSize={"18px"}>
                        <strong>{CurrentPlayers} player </strong>registered
                    </Box>
                </Box>

                <Box className="col-6" p={3}>
                    <OverviewPlayers Capacity={Capacity} />

                    <OverviewCheckin
                        UserCheckedIn={UserCheckedIn}
                        IsUserSignedUp={IsUserSignedUp}
                        State={State}
                        handleCheckin={handleCheckin}
                        handleCheckinCancel={handleCheckinCancel}
                    />

                    <Box textAlign={"center"} fontSize={"14px"} fontWeight={"600"} textColor={"black"}>
                        Important! 15 minutes before the tournaments starts, you must be online and
                        check-in to confirm your participation.
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}