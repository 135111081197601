import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"

export const TotalResCards = (socket: SocketType) => {
    const { setTotalResCards } = useSync()

    // Handle total resources updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TotalResourceNumber") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setTotalResCards(parsedData.totalResources)
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}