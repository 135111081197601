import { useEffect, useState } from "react"
import { GetTournamentStandings } from "../../../../../requests/TournamentRequests"
import { Box } from "@chakra-ui/react"
import { StandingsTable } from "./StandingsTable"

type StandingsType = {
    ID: number,
    State: number,
    Matches: null | any[]
}

export const Standings = ({ ID, State, Matches }: StandingsType) => {
    const [players, setPlayers] = useState<any[]>([])

    useEffect(() => {
        const fetchStandings = async () => {
            const result = await GetTournamentStandings(ID)
            result && setPlayers(result)
        }

        const isStateCorrect = State === 3 || State === 4 || State === 6

        if (ID && isStateCorrect) {
            fetchStandings()
        }
    }, [ID, State, Matches])


    return (
        <Box
            border={"3px solid black"}
            backgroundColor={"#583a16"}
            borderRadius={"0px 0px 25px 25px"}
        >
            <StandingsTable
                players={players}
                isQualifyStandings={false}
            />
        </Box>
    )
}