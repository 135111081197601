export const getUsernameByIndex = (players: any[], index: number) => {
  if (players.length > 0) {
    const player = players.find((player) => player.turnIndex === index);

    if (player) {
      return player.username.toUpperCase();
    } else {
      return null;
    }
  } else {
    return null;
  }
};
