import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"
import { logDivElement, logTextDivElement, usernameDivElement } from "../utils/gameLogs/gameLogs"
import { getUsernameByIndex } from "../utils/functions/getUsernameByIndex"
import { getColorByIndex } from "../utils/assets/getColorByIndex"
import { getResIcon } from "../utils/assets/getResIcon"
import { useGame } from "../context/GameContext"

export const Resources = (socket: SocketType) => {
    const { gameAssets } = useGame()
    const { setResources, players } = useSync()

    // Handle resource updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "ResourceUpdate") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                setResources(
                    {
                        Wood: parsedData.wood,
                        Brick: parsedData.brick,
                        Sheep: parsedData.sheep,
                        Wheat: parsedData.wheat,
                        Ore: parsedData.ore
                    }
                )
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle resource gather logs
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "ResourceGathered") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    const gatherData: any[] = parsedData.data
                    gatherData.map((data) => {
                        const gameLogs = document.getElementById(`game-logs`)
                        const logDiv = logDivElement()
                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, data.turnIndex), getColorByIndex(data.turnIndex))
                        const logText = logTextDivElement(`got ${data.amount}`)

                        const resourceIcon = document.createElement("img")
                        resourceIcon.src = getResIcon(data.resource, gameAssets)
                        resourceIcon.alt = "resource"
                        resourceIcon.style.height = "150%"

                        logDiv.appendChild(usernameDiv)
                        logDiv.appendChild(logText)
                        logDiv.appendChild(resourceIcon)

                        if (gameLogs?.firstChild) {
                            gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                        } else {
                            gameLogs?.appendChild(logDiv)
                        }
                    })
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}