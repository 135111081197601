import { Box, Image } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, } from '@chakra-ui/react'
import { getOrdinalSuffix } from '../../../../../utils/functions/getOrdinalSuffix'
import { useGame } from "../../../../../context/GameContext"

type BracketTableType = {
    matchId: number,
    players: any[],
    matchStatus: number,
    duration: number,
    marginAmount: string | undefined
}

const tableBgColor = "#75583e"
const tableHeaderBgColor = "#c64700"
const tableBodyBgColor = "#d9b891"
const tableBodyTextColor = "#754e37"

export const TournamentBracketTable = ({ matchId, players, matchStatus, duration, marginAmount }: BracketTableType) => {
    const { dashboardAssets } = useGame()

    return (
        <Box
            backgroundColor={tableBgColor}
            filter={"drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5))"}
            borderRadius={"15px"}
            height={"min-content"}
            w={"300px"}
            mt={marginAmount ? marginAmount : "auto"}
            mb={marginAmount ? marginAmount : "auto"}
        >
            <Box display={"flex"} alignItems={"center"} textColor={"white"} fontSize={"20px"} w={"100%"} pe={5} ps={5} pt={3} pb={2}>
                <Box alignSelf={"start"}>Match {matchId}</Box>
                <Box ml={"auto"} display={"flex"} alignItems={"center"}>
                    <Image src={dashboardAssets.personIcon} me={2} alt='person-icon' w={"40%"} />
                    {players.length}
                </Box>
            </Box>

            <TableContainer pe={"7px"} ps={"7px"}>
                <Table size={"sm"}>
                    <Thead backgroundColor={tableHeaderBgColor} >
                        <Tr>
                            <Th color={"white"}></Th>
                            <Th color={"white"}>Player</Th>
                            <Th color={"white"}>VP</Th>
                        </Tr>
                    </Thead>
                    <Tbody backgroundColor={tableBodyBgColor} textColor={tableBodyTextColor}>
                        {
                            players && players.length > 0 &&
                            players
                                .sort((playerA, playerB) => playerB.vp - playerA.vp)
                                .map((player, index) => {
                                    return (
                                        <Tr key={index}>
                                            <Td>{getOrdinalSuffix(index + 1)}</Td>
                                            <Td>{player.username}</Td>
                                            <Td>{player.vp}</Td>
                                        </Tr>
                                    )
                                })
                        }
                    </Tbody>
                </Table>
                <Box backgroundColor={matchStatus === 1 ? "#72a8da" : "#6A9148"} textAlign={"center"} mb={4}>
                    {matchStatus === 1 && "In-Game"}
                    {matchStatus === 2 && "Completed"}
                </Box>
                {
                    matchStatus === 2 &&
                    <Box textColor={"white"} textAlign={"center"} mt={2} mb={2}>
                        Duration: {duration.toFixed(0)} min
                    </Box>
                }
            </TableContainer>
        </Box>
    )
}