import { Button } from "@chakra-ui/react"
import { VscDebugDisconnect } from "react-icons/vsc";
import { CreateLeaveGame } from "../../../../requests/RoomRequests";
import { useGame } from "../../../../context/GameContext";
import { useNavigate } from "react-router-dom";

export const DisconnectButton = () => {
    const { gameID, setGameID } = useGame()

    const navigate = useNavigate()

    const handleDisconnect = async () => {
        const response = await CreateLeaveGame(gameID)
        if (response) {
            setGameID(-1)
            navigate('/dashboard')
        }
    }

    return (
        <Button
            leftIcon={<VscDebugDisconnect />}
            ml={"1vw"}
            mr={"1vw"}
            width={"15vw"}
            height={"4vw"}
            fontSize={"1.5vw"}
            backgroundColor={"#7f6440"}
            borderRadius={"0.5vw"}
            colorScheme={'red'}
            onClick={handleDisconnect}
        >
            Leave Room
        </Button>
    )
}