import { useEffect } from "react";
import { SocketType } from "../../../utils/types/types";
import { useSync } from "../../../context/SyncContext";
import { logDivElement, logTextDivElement, usernameDivElement } from "../../../utils/gameLogs/gameLogs";
import { getUsernameByIndex } from "../../../utils/functions/getUsernameByIndex";
import { getColorByIndex } from "../../../utils/assets/getColorByIndex";
import { useGame } from "../../../context/GameContext";
import { getResIcon } from "../../../utils/assets/getResIcon";

export const ResourceSteelNotification = (socket: SocketType) => {
    const { gameAssets } = useGame()
    const { setStealData, stealData, players } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "StealResource") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);

            if (parsedData && parsedData.stolenResource !== "") {
                setStealData({
                    stealerIndex: parsedData.stealerIndex,
                    victimIndex: parsedData.stealedIndex,
                    resource: parsedData.stolenResource ? parsedData.stolenResource : undefined
                })

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()
                    const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.stealerIndex), getColorByIndex(parsedData.stealerIndex))

                    const logText = logTextDivElement("steals ")
                    const usernameDiv2 = usernameDivElement(getUsernameByIndex(players, parsedData.stealedIndex), getColorByIndex(parsedData.stealedIndex))
                    const logText2 = logTextDivElement("from")

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)
                    if (parsedData.stolenResource) {
                        const resourceIcon = document.createElement("img");
                        resourceIcon.src = getResIcon(parsedData.stolenResource, gameAssets);
                        resourceIcon.alt = `${parsedData.stolenResource}-icon`;
                        resourceIcon.style.height = "150%";
                        resourceIcon.style.marginRight = "0.25vw"

                        logDiv.appendChild(resourceIcon);
                    }
                    logDiv.appendChild(logText2)
                    logDiv.appendChild(usernameDiv2)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    useEffect(() => {
        if (stealData) {
            const timeout = setTimeout(() => {
                setStealData(null);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [stealData]);
}