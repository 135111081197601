import { Image, Box, IconButton } from '@chakra-ui/react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { useGame } from '../../../../context/GameContext'
import { useSync } from '../../../../context/SyncContext'
import { SocketType } from '../../../../utils/types/types'
import { useEffect } from 'react'
import { getPrevSettleAsset } from '../../../../utils/assets/getPrevSettleAsset'
import { SendGameEvent } from '../../../../socket/sendMessage/SendGameEvent'
import { getSettlementAsset } from '../../../../utils/assets/getSettlementAsset'

type BuildSettlementType = {
    socket: SocketType,
    confirmData: {
        showCost: boolean,
        costType: string,
        settlePosition: {
            xStart: number,
            yStart: number,
            xEnd: number | null,
            yEnd: number | null
        }
    },
    setConfirmData: (value: any) => void,
}

export const BuildSettlement = ({ socket, confirmData, setConfirmData }: BuildSettlementType) => {
    const { gameID, gameAssets, isMobile } = useGame()
    const { myGameData, resources, gameData, playersSkinChoice } = useSync()

    useEffect(() => {
        if (confirmData) {
            const positions = confirmData.settlePosition
            const settleElement = document.getElementById(`h/${positions.xStart},${positions.yStart}`)

            if (settleElement && playersSkinChoice) {
                settleElement.classList.add("settle-confirm-prev")
                settleElement.classList.add(getPrevSettleAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!)

                if (settleElement.classList.contains("top-house")) {
                    settleElement.classList.add("top-house-prev")
                } else if (settleElement.classList.contains("top-right-house")) {
                    settleElement.classList.add("top-right-house-prev")
                } else if (settleElement.classList.contains("top-left-house")) {
                    settleElement.classList.add("top-left-house-prev")
                } else if (settleElement.classList.contains("bottom-house")) {
                    settleElement.classList.add("bottom-house-prev")
                } else if (settleElement.classList.contains("bottom-right-house")) {
                    settleElement.classList.add("bottom-right-house-prev")
                } else if (settleElement.classList.contains("bottom-left-house")) {
                    settleElement.classList.add("bottom-left-house-prev")
                }
            }
        }

        return () => {
            const settleElement = document.querySelector('.settle-confirm-prev')
            if (settleElement && playersSkinChoice) {
                settleElement.classList.remove('settle-confirm-prev')
                settleElement.classList.remove(getPrevSettleAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!)

                if (settleElement.classList.contains("top-house-prev")) {
                    settleElement.classList.remove("top-house-prev")
                } else if (settleElement.classList.contains("top-right-house-prev")) {
                    settleElement.classList.remove("top-right-house-prev")
                } else if (settleElement.classList.contains("top-left-house-prev")) {
                    settleElement.classList.remove("top-left-house-prev")
                } else if (settleElement.classList.contains("bottom-house-prev")) {
                    settleElement.classList.remove("bottom-house-prev")
                } else if (settleElement.classList.contains("bottom-right-house-prev")) {
                    settleElement.classList.remove("bottom-right-house-prev")
                } else if (settleElement.classList.contains("bottom-left-house-prev")) {
                    settleElement.classList.remove("bottom-left-house-prev")
                }
            }
        }
    }, [confirmData])

    const handleSettlementBuilding = () => {
        const eventData = {
            id: gameID,
            position: [
                confirmData.settlePosition.xStart,
                confirmData.settlePosition.yStart
            ]
        }
        if (gameData.Status === 1) {
            SendGameEvent(socket, "SetupSettle", eventData)
        } else {
            SendGameEvent(socket, "BuildSettle", eventData)
        }
        setConfirmData(null)
    }

    return (
        <Box className={`cost-info ${isMobile && 'cost-info-mobile'}`} w={confirmData?.showCost ? "300px" : "225px"}>
            <Box className='header-title-banner'>
                Settlement Build Cost
            </Box>

            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#A58860"}
                w={confirmData?.showCost ? "250px" : "80px"}
                p={2}
                borderRadius={"5px"}
            >
                <Image
                    src={playersSkinChoice && getSettlementAsset(myGameData.turn_index, gameAssets, playersSkinChoice[myGameData.turn_index])}
                    alt='settle'
                    width={"40px"}
                    className={confirmData.showCost ? "me-3" : undefined}
                />
                {
                    confirmData.showCost &&
                    <Box display={"flex"} alignSelf={"center"}>
                        <Image src={gameAssets.woodCard} w={"40px"} filter={resources.Wood > 0 ? undefined : "grayscale(100%)"} me={1} />
                        <Image src={gameAssets.brickCard} w={"40px"} filter={resources.Brick > 0 ? undefined : "grayscale(100%)"} me={1} />
                        <Image src={gameAssets.wheatCard} w={"40px"} filter={resources.Wheat > 0 ? undefined : "grayscale(100%)"} me={1} />
                        <Image src={gameAssets.sheepCard} w={"40px"} filter={resources.Sheep > 0 ? undefined : "grayscale(100%)"} />
                    </Box>
                }
            </Box>

            <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} w={"300px"} mt={"10px"}>
                <IconButton
                    backgroundColor={"#85D885"}
                    colorScheme={"green"}
                    border={"2px solid black"}
                    borderRadius={"50%"}
                    fontSize={"20px"}
                    aria-label='confirm'
                    isDisabled={!(resources.Wood > 0 && resources.Brick > 0 && resources.Sheep > 0 && resources.Wheat > 0) && confirmData.showCost}
                    onClick={handleSettlementBuilding}
                    icon={<FaCheck />}
                />
                <IconButton
                    backgroundColor={"#F74747"}
                    colorScheme={"red"}
                    border={"2px solid black"}
                    borderRadius={"50%"}
                    fontSize={"20px"}
                    aria-label='cancel'
                    onClick={() => {
                        setConfirmData(null)
                    }}
                    icon={<FaTimes />}
                />
            </Box>
        </Box>
    )
}