import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { getPlayerVPLabel } from "../../../utils/assets/getPlayerVPLabel";
import { useGame } from "../../../context/GameContext";
import { PlayerVictoryCards } from "./PlayerVictoryCards";

type PlayerVictoryPointType = {
    turnIndex: number,
    victoryPoint: number
}

export const PlayerVictoryPoint = ({ turnIndex, victoryPoint }: PlayerVictoryPointType) => {
    const { gameAssets } = useGame()

    useEffect(() => {
        if (victoryPoint) {
            const victoryPointText = document.getElementById(`victory-point-text-${turnIndex}`);
            if (victoryPointText) {
                victoryPointText.classList.add('bouncing-rotating-text');
                setTimeout(() => {
                    victoryPointText.classList.remove('bouncing-rotating-text');
                }, 2000);
            }
        }
    }, [victoryPoint]);

    return (
        <Box
            className="victory-point-label"
            backgroundImage={getPlayerVPLabel(turnIndex, gameAssets)}
        >
            <Box
                id={`victory-point-text-${turnIndex}`}
                className='victory-point-text'
            >
                {victoryPoint}
            </Box>

            <PlayerVictoryCards turnIndex={turnIndex} />
        </Box>
    )
}