export const getAvatarByIndex = (index: number, assets: {}) => {
  if (index === 0) {
    return assets.avatarJean;
  } else if (index === 1) {
    return assets.avatarLin;
  } else if (index === 2) {
    return assets.avatarLouis;
  } else if (index === 3) {
    return assets.avatarWilliam;
  } else {
    return "";
  }
};
