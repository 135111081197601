const API_URL = import.meta.env.VITE_API_URL

export const CreateRoom = async (name: string, limit: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ name, limit })
            }
        );

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error creating a room:", error);
        return null;
    }
};

export const CreateJoinGame = async (id: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/join`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id })
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error join game:", error);
        return null;
    }
};

export const GetRooms = async () => {
    try {
        const response = await fetch(
            API_URL + `/room/rooms`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get rooms.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting rooms:", error);
        return null;
    }
};


export const CreateLeaveGame = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/leave/${gameID}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error join game:", error);
        return null;
    }
};