import redCursor from "../../assets/Cursor/cursor_red.png";
import blueCursor from "../../assets/Cursor/cursor_blue.png";
import pinkCursor from "../../assets/Cursor/cursor_pink.png";
import grayCursor from "../../assets/Cursor/cursor_white.png";

export const getCursorColor = (turnIndex: number) => {
  if (turnIndex === 0) {
    return redCursor;
  } else if (turnIndex === 1) {
    return blueCursor;
  } else if (turnIndex === 2) {
    return pinkCursor;
  } else if (turnIndex === 3) {
    return grayCursor;
  } else {
    return "";
  }
};
