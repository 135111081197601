import { Avatar, Box } from "@chakra-ui/react"
import { getAvatarBGColor, getCircularTrackColorByIndex } from "../../../utils/assets/getColorByIndex"
import { getAvatarByIndex } from "../../../utils/assets/getAvatarByIndex"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"
import { PlayerStageTimer } from "./PlayerStageTimer"
import { PlayerClockIcon } from "./PlayerClockIcon"
import { PlayerDiceIcon } from "./PlayerDiceIcon"

type PlayerAvatarType = {
    turnIndex: number,
    username: string,
    isBot: boolean,
    isConnected: boolean
}

export const PlayerAvatar = ({ turnIndex, username, isBot, isConnected }: PlayerAvatarType) => {
    const { gameData, myGameData } = useSync()
    const { dashboardAssets } = useGame()

    return (
        <Box
            className="player-avatar"
            backgroundColor={getAvatarBGColor(turnIndex)}
        >
            <Avatar
                zIndex={1}
                marginLeft={"0.6vw"}
                marginTop={"0.6vw"}
                width={"4.5vw"}
                height={"4.5vw"}
                border={"0.2vw solid black"}
                name={username}
                src={getAvatarByIndex(turnIndex, dashboardAssets)}
                opacity={isBot ? 1 : isConnected ? 1 : 0.5}
            />
            {
                gameData.TurnIndex === turnIndex &&
                <PlayerStageTimer
                    activatedTime={gameData.LastStageTime.toString()}
                    color={getCircularTrackColorByIndex(turnIndex)}
                    gameData={gameData}
                    myGameData={myGameData}
                />
            }
            <PlayerClockIcon turnIndex={turnIndex} />
            <PlayerDiceIcon turnIndex={turnIndex} />
        </Box>
    )
}