import { Modal, ModalContent, ModalBody, Box, ModalOverlay, Checkbox, Image, Alert, AlertIcon } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton';

type QuestCardDetailType = {
    isOpen: boolean,
    onClose: () => void,
    questBannerAsset: string,
    questRewardAsset: string,
    completedTasks: any[],
    notCompletedTasks: any[],
    setQuest: ((value: any[]) => void) | null,
    rewardTitle: string,
}

export const QuestCardDetail = ({
    isOpen,
    onClose,
    questBannerAsset,
    questRewardAsset,
    completedTasks,
    notCompletedTasks,
    setQuest,
    rewardTitle,
}: QuestCardDetailType) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent
                backgroundColor={"#745833"}
                borderRadius={"25px"}
            >
                <DashboardCloseButton onClose={onClose} />

                <ModalBody p={0}>
                    <Box pos={"relative"} textAlign={"center"} w={"100%"}>
                        <Image
                            src={questBannerAsset}
                            alt="quest-image"
                            borderRadius={"25px 25px 0px 0px"}
                            w={"100%"}
                            h={"150px"}
                            objectFit={"cover"}
                        />
                    </Box>
                    {
                        completedTasks &&
                        completedTasks.length > 0 &&
                        completedTasks.map((task, index) => {
                            return (
                                <div key={index} className={`quest-detail-task-row-${index % 2}`}>
                                    <Checkbox
                                        size='lg'
                                        me={"auto"}
                                        ms={10}
                                        colorScheme='green'
                                        isChecked
                                    >
                                        <div className='quest-completed ms-4'>
                                            {task}
                                        </div>
                                    </Checkbox>
                                </div>
                            )
                        })
                    }
                    {
                        notCompletedTasks &&
                        notCompletedTasks.length > 0 &&
                        notCompletedTasks.map((task, index) => {
                            return (
                                <div key={index} className={`quest-detail-task-row-${index % 2}`}>
                                    <Checkbox
                                        size='lg'
                                        me={"auto"}
                                        ms={10}
                                        colorScheme='green'
                                        isDisabled
                                    >
                                        <div className='quest-not-completed ms-4'>
                                            {task.id} ({task.progress})
                                        </div>
                                    </Checkbox>
                                </div>
                            )
                        })
                    }

                    {
                        !setQuest && notCompletedTasks.length === 0 &&
                        <Alert justifyContent={"center"} p={"0.75vw"} status={"success"}>
                            <AlertIcon />
                            You can claim your Ticket from "Quests" Section.
                        </Alert>
                    }

                    <Box
                        fontWeight={"600"}
                        backgroundColor={"#96754B"}
                        borderRadius={"0px 0px 25px 25px"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        pt={3}
                        pb={3}
                    >
                        <h5>Reward: {rewardTitle}</h5>
                        <img
                            src={questRewardAsset}
                            alt='ticket'
                            className='mt-2'
                            width={"20%"}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}