import { useEffect } from "react"
import { useSync } from "../../../context/SyncContext"
import { SocketType } from '../../../utils/types/types'
import { getUsernameByIndex } from '../../../utils/functions/getUsernameByIndex'
import { useGame } from '../../../context/GameContext'
import { logDivElement, logTextDivElement, usernameDivElement } from '../../../utils/gameLogs/gameLogs'
import { getColorByIndex } from '../../../utils/assets/getColorByIndex'
import { PlaySoundEffect } from "../../../sounds/playSoundEffect"

export const LargestArmyNotification = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects } = useGame()
    const { players } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "LargestArmy") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);

            if (parsedData) {
                const username = getUsernameByIndex(players, parsedData.index)
                const turnIndex = parsedData.index

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()
                    const usernameDiv = usernameDivElement(username, getColorByIndex(turnIndex))

                    const logText = logTextDivElement("claims")

                    const largestArmy = document.createElement("img")
                    largestArmy.src = gameAssets.largestArmyLabel
                    largestArmy.alt = "longest-road-icon"
                    largestArmy.style.height = "150%"

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)
                    logDiv.appendChild(largestArmy)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                const divElement = document.createElement("div")
                divElement.classList.add("player-notification")
                divElement.classList.add("largest-army-notif")

                const imgElement = document.createElement("img")
                imgElement.src = gameAssets.largestArmyLabel
                imgElement.alt = "largest-army"
                imgElement.style.width = "15%"
                imgElement.style.marginRight = "0.25vw"

                const textElement = document.createElement("span")
                textElement.textContent = `${username} has the Largest Army!`

                divElement.appendChild(imgElement)
                divElement.appendChild(textElement)

                const playerElement = document.getElementById(`player-${turnIndex}`)

                PlaySoundEffect(soundEffects.takingLeadSFX, sfxVolume, sfxEnabled)

                if (playerElement) {
                    playerElement.appendChild(divElement)

                    const timeout = setTimeout(() => {
                        playerElement.removeChild(divElement)
                    }, 3000);

                    return () => clearTimeout(timeout);
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);
}