import { Box, useDisclosure } from "@chakra-ui/react"
import { CreateGameModal } from "./CreateGame/CreateGameModal"
import { GameListModal } from "./GameList/GameListModal"
import { QuestModal } from "./Quest/QuestModal"
import { TournamentModal } from "./Tournament/TournamentModal"
import { TournamentDirectJoinModal } from "./Tournament/TournamentDirectJoinModal"
import { TournamentJoin } from "../../../socket/TournamentJoin"
import { useGame } from "../../../context/GameContext"
import { SocketType } from "../../../utils/types/types"
import { TournamentWaitingRoom } from "./Tournament/WaitingRoom/TournamentWaitingRoom"
import { SendAnalytiActionEvent } from "../../../socket/sendMessage/SendAnalyticActionEvent"
import { DailyProgress } from "./DailyProgress/DailyProgress"

export const Content = ({ socket }: { socket: SocketType }) => {
    const { tournamentDirectJoinGameID, dashboardAssets } = useGame()

    const { isOpen, onOpen, onClose } = useDisclosure({
        onOpen() {
            SendAnalytiActionEvent(socket, "QuestModalOpen")
        }
    })

    TournamentJoin(socket)

    return (
        <Box
            className="dashboard-content-styles"
            style={{ backgroundImage: `url(${dashboardAssets.dashboardContentBackground})` }}
        >
            <Box display={"grid"} alignItems={"center"} w={"50vw"}>
                <Box
                    display={"flex"}
                    flexDir={"row"}
                    justifyContent={"space-evenly"}
                >
                    <CreateGameModal socket={socket} />
                    <TournamentModal socket={socket} />
                    <TournamentWaitingRoom socket={socket} />
                    {tournamentDirectJoinGameID && <TournamentDirectJoinModal />}
                </Box>

                <Box
                    display={"flex"}
                    flexDir={"row"}
                    justifyContent={"space-evenly"}
                >
                    <GameListModal socket={socket} />
                    <QuestModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onOpen={onOpen}
                        isDashboard={true}
                    />
                </Box>
            </Box>

            <DailyProgress socket={socket} />
        </Box>
    )
}