export const getDiceResult = (value: number, assets: any) => {
  if (value === 1) {
    return assets.dice1Icon;
  } else if (value === 2) {
    return assets.dice2Icon;
  } else if (value === 3) {
    return assets.dice3Icon;
  } else if (value === 4) {
    return assets.dice4Icon;
  } else if (value === 5) {
    return assets.dice5Icon;
  } else if (value === 6) {
    return assets.dice6Icon;
  } else {
    return "";
  }
};
