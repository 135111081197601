import { Box } from "@chakra-ui/react"
import { FaInfoCircle } from "react-icons/fa"

export const InfoMessage = ({ message }: { message: string }) => {
    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={"0.5vw"}
            mb={"0.5vw"}
            fontSize={"1vw"}
            textColor={"InfoText"}
        >
            <FaInfoCircle style={{ marginRight: "1vw" }} />
            {message}
        </Box>
    )
}