import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, CircularProgress } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useGame } from '../../../../context/GameContext'
import { useEffect } from 'react'

export const TournamentDirectJoinModal = () => {
    const { setGameID, tournamentDirectJoinGameID, setIsReconnect } = useGame()

    const { isOpen, onClose } = useDisclosure({ isOpen: true })

    const navigate = useNavigate()

    useEffect(() => {
        const timer = setTimeout(() => {
            const gameID = tournamentDirectJoinGameID
            setGameID(gameID)
            setIsReconnect(true)
            navigate(`/game`)
        }, 3000)

        return () => clearTimeout(timer)
    }, [tournamentDirectJoinGameID])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered={true}
            motionPreset={"slideInBottom"}
            size={"lg"}
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor={"#E6BD89"}
                textColor={"#5B4528"}
                borderRadius={"25px"}
            >
                <ModalHeader className='menu-modal-headers'>
                    Tournament Match
                </ModalHeader>

                <ModalBody textAlign={"center"} fontSize={"20px"}>
                    Your tournament match is ready. You are being directed to the game room
                </ModalBody>

                <ModalFooter display={"flex"} justifyContent={"center"}>
                    <CircularProgress
                        isIndeterminate
                        color="#5B4528"
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}