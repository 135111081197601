import { useEffect, useState } from "react"
import { SocketType } from "../../../utils/types/types"
import { useSync } from "../../../context/SyncContext"
import { getDevCardImgSrcByCardType } from "../../../utils/assets/getDevCardImgSrcByCardType"
import { useGame } from "../../../context/GameContext"
import { logDivElement, logResourceElement, logTextDivElement, usernameDivElement } from "../../../utils/gameLogs/gameLogs"
import { getUsernameByIndex } from "../../../utils/functions/getUsernameByIndex"
import { getColorByIndex } from "../../../utils/assets/getColorByIndex"
import { getResIcon } from "../../../utils/assets/getResIcon"

export const DevCardUsageNotification = (socket: SocketType) => {
    const { gameAssets } = useGame()
    const { players } = useSync()

    const [usageData, setUsageData] = useState<{ cardType: string, username: string, turnIndex: number } | null>(null)

    // Handle dev card usage
    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "DevCardUsed") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);

            if (parsedData && players) {
                const player = players.find((player) => player.turnIndex === parsedData.turnIndex);

                if (player) {
                    setUsageData({
                        cardType: parsedData.card,
                        username: player.username,
                        turnIndex: parsedData.turnIndex
                    });
                }

                if (parsedData.card === "YearOfPlenty") {
                    const usageData = JSON.parse(parsedData.data).resources;

                    const gameLogs = document.getElementById("game-logs")
                    if (gameLogs) {
                        const logDiv = logDivElement()

                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.turnIndex), getColorByIndex(parsedData.turnIndex))

                        const logText = logTextDivElement("gets")

                        logDiv.appendChild(usernameDiv)
                        logDiv.appendChild(logText)

                        logResourceElement(usageData.wood, "wood", usageData.wood, logDiv, gameAssets)
                        logResourceElement(usageData.brick, "brick", usageData.brick, logDiv, gameAssets)
                        logResourceElement(usageData.sheep, "sheep", usageData.sheep, logDiv, gameAssets)
                        logResourceElement(usageData.wheat, "wheat", usageData.wheat, logDiv, gameAssets)
                        logResourceElement(usageData.ore, "ore", usageData.ore, logDiv, gameAssets)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv)
                        }
                    }
                } else if (parsedData.card === "Monopoly") {
                    const usageData = JSON.parse(parsedData.data).resource;

                    const gameLogs = document.getElementById("game-logs")
                    if (gameLogs) {
                        const logDiv = logDivElement()

                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.turnIndex), getColorByIndex(parsedData.turnIndex))

                        const logText = logTextDivElement("stoles ")

                        const resourceIcon = document.createElement("img");
                        resourceIcon.src = getResIcon(usageData, gameAssets);
                        resourceIcon.alt = `${usageData}-icon`;
                        resourceIcon.style.height = "150%";
                        resourceIcon.style.marginRight = "0.25vw"


                        logDiv.appendChild(usernameDiv)
                        logDiv.appendChild(logText)
                        logDiv.appendChild(resourceIcon)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv)
                        }
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (usageData) {
            const player = document.getElementById(`player-${usageData.turnIndex}`);

            if (player) {
                const divElement = document.createElement("div");
                divElement.classList.add("player-notification");
                divElement.classList.add("dev-card-usage-notif");

                const imgElement = document.createElement("img");
                imgElement.src = getDevCardImgSrcByCardType(usageData.cardType, gameAssets);
                imgElement.alt = "dev-card";
                imgElement.style.pointerEvents = "none";
                imgElement.style.width = "40%";

                divElement.appendChild(imgElement);
                player.appendChild(divElement);

                const timeout = setTimeout(() => {
                    player.removeChild(divElement);
                    setUsageData(null);
                }, 5000);

                return () => clearTimeout(timeout);
            }
        }
    }, [usageData]);
}