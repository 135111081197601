import { Box, Tooltip, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'
import { useEffect, useState } from "react"
import { useGame } from "../../../context/GameContext"

export const SFXManager = () => {
    const { sfxEnabled, setSfxEnabled, sfxVolume, setSfxVolume, gameAssets } = useGame()

    const [showTooltipSfx, setShowTooltipSfx] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('SFXPaused') === "true") {
            setSfxEnabled(true);
        } else if (localStorage.getItem('SFXPaused') === "false") {
            setSfxEnabled(false);
        } else {
            setSfxEnabled(true)
        }
    }, [])

    const handleSFX = () => {
        setSfxEnabled(!sfxEnabled)
        if (sfxEnabled) {
            localStorage.setItem('SFXPaused', "false")
        } else {
            localStorage.setItem('SFXPaused', "true")
        }
    }
    return (
        <Box display={"flex"}>
            <Box
                _hover={{
                    cursor: "pointer"
                }}
                width={"3vw"}
                height={"3vw"}
                me={"2.5vw"}
                background={"transparent"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={handleSFX}
            >
                {sfxEnabled ?
                    <img src={gameAssets.effectOn} alt='sfx-button' width={"75%"} /> :
                    <img src={gameAssets.effectOff} alt='sfx-button' width={"75%"} />
                }
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Box textAlign={"center"} fontSize={"1vw"}>Effect Volume</Box>
                <Slider
                    id='musicSlider'
                    value={sfxVolume * 100}
                    min={0}
                    max={100}
                    step={5}
                    w={"10vw"}
                    colorScheme='white'
                    isDisabled={!sfxEnabled}
                    onChange={(v) => setSfxVolume(v / 100)}
                    onMouseEnter={() => setShowTooltipSfx(true)}
                    onMouseLeave={() => setShowTooltipSfx(false)}
                >
                    <SliderTrack backgroundColor={"#4B3B0A"} border={"0.1vw solid #4B3B0A"} height={"1vw"}>
                        <SliderFilledTrack backgroundColor={"white"} />
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg='#4B3B0A'
                        color='white'
                        placement='top'
                        isOpen={showTooltipSfx}
                        label={`${(sfxVolume * 100).toString().slice(0, 4)}%`}
                    >
                        <SliderThumb border={"0.1vw solid #4B3B0A"} height={"1.3vw"} width={"1.3vw"} />
                    </Tooltip>
                </Slider>
            </Box>
        </Box>
    )
}