import { Box, useDisclosure } from '@chakra-ui/react';
import { PlayerAvatar } from "./PlayerProfile/Avatar";
import { LogoutModal } from "./LogoutModal";
import { InventoryModal } from './Inventory/InventoryModal';
import { GameTutorial } from './Tutorial/GameTutorial';
import { Wallet } from "./Wallet/Wallet";
import { TournamentNotification } from '../Content/Tournament/Notification/TournamentNotification';
import { SocketType } from '../../../utils/types/types';
import { AdminModal } from '../Admin/AdminModal';
import { useAuth } from '../../../context/AuthContext';
import { SendAnalytiActionEvent } from '../../../socket/sendMessage/SendAnalyticActionEvent';

export const Sidebar = ({ socket }: { socket: SocketType }) => {
    const { isAdmin } = useAuth()

    const { isOpen, onOpen, onClose } = useDisclosure({
        onOpen() {
            SendAnalytiActionEvent(socket, "TutorialModalOpen")
        }
    })

    return (
        <Box className="side-bar">
            <Wallet socket={socket} />
            <Box className='side-bar-main-content'>
                <PlayerAvatar />

                {isAdmin && <AdminModal />}

                <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    alignItems={"center"}
                >
                    <InventoryModal socket={socket} />
                    <GameTutorial
                        isOpen={isOpen}
                        onClose={onClose}
                        onOpen={onOpen}
                        isDashboard={true}
                    />
                    <LogoutModal />
                </Box>
            </Box>
            <TournamentNotification socket={socket} />
        </Box>
    )
}