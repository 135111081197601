export const getPlayerVPLabel = (turnIndex: number, assets: any) => {
  if (turnIndex === 0) {
    return assets.redVPLabel;
  } else if (turnIndex === 1) {
    return assets.blueVPLabel;
  } else if (turnIndex === 2) {
    return assets.pinkVPLabel;
  } else if (turnIndex === 3) {
    return assets.grayVPLabel;
  } else {
    return null;
  }
};
