import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, TableCaption, Box, Link, Button, Image } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { GetRooms } from '../../../../requests/RoomRequests';
import { getGameStatusById } from '../../../../utils/constants/getGameStatusByID';
import { Room } from '../../../../socket/Room';
import { Quests } from '../../../../socket/Quests'
import { DashboardCloseButton } from '../../General/DashboardCloseButton';
import { JoinButton } from './JoinButton';
import { ReJoinButton } from './ReJoinButton';
import { SpectateButton } from './SpectateButton';
import { SocketType } from '../../../../utils/types/types';
import { useGame } from '../../../../context/GameContext';
import { FaDiscord } from 'react-icons/fa6';
import { SendAnalytiActionEvent } from '../../../../socket/sendMessage/SendAnalyticActionEvent';

export const GameListModal = ({ socket }: { socket: SocketType }) => {
    const { dashboardAssets, isMobile, setReConnectGameID, reConnectGameID } = useGame()

    const { isOpen, onOpen, onClose } = useDisclosure({
        onOpen() {
            SendAnalytiActionEvent(socket, "GameListModalOpen")
        }
    })

    const [rooms, setRooms] = useState<any[]>([])

    // Handle initial fetch
    useEffect(() => {
        const fetchRooms = async () => {
            const data = await GetRooms()
            if (data) {
                setReConnectGameID(data.activeRoomID)
                setRooms(data.rooms)
            }
        }

        fetchRooms()
    }, [])

    Room(socket, rooms, setRooms, reConnectGameID, setReConnectGameID)
    Quests(socket)

    const liveGames = rooms.filter((room) => room.State !== 2)

    return (
        <>
            <Box
                onClick={onOpen}
                className="game-list-modal-panel content-button-effects"
            >
                <Image
                    src={dashboardAssets.roomListIcon}
                    alt='game-list-icon'
                    m={"auto"}
                    width={"60%"}
                />
                {
                    liveGames.length > 0 &&
                    <Box className='red-info-circle'>
                        {liveGames.length}
                    </Box>
                }
                <Box className="content-button-title-color">
                    Room List
                </Box>
            </Box>

            <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#EED6B7"}
                    maxW={isMobile ? "100vw" : "60vw"}
                    minH={isMobile ? "100vh" : "60vh"}
                    borderRadius={"25px"}
                >
                    <ModalHeader className='menu-modal-headers'>
                        Room List
                    </ModalHeader>

                    <DashboardCloseButton onClose={onClose} />

                    <ModalBody textColor={"#5B4528"}>
                        <Tabs isFitted variant='soft-rounded' align={"center"}>

                            <TabList>
                                <Tab
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                >
                                    Free Games
                                </Tab>
                                <Tab
                                    isDisabled
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#5B472E"}
                                >
                                    Token Games
                                </Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <TableContainer
                                        overflowY={"auto"}
                                        height={"50vh"}
                                    >
                                        <Table variant='simple' >
                                            <TableCaption>
                                                <Box mb={"0.3vw"}>
                                                    You can find players from our friendly discord!
                                                </Box>
                                                <Box>
                                                    <Link href={"https://discord.com/invite/DvgwsgTXdp"} isExternal>
                                                        <Button
                                                            size={"xs"}
                                                            backgroundColor={"#5865F2"}
                                                            colorScheme={"blue"}
                                                            border={"1px solid black"}
                                                            borderRadius={"25px"}
                                                        >
                                                            <FaDiscord className='me-2' />
                                                            Join Pioneers Discord
                                                        </Button>
                                                    </Link>
                                                </Box>
                                            </TableCaption>
                                            <Thead >
                                                <Tr >
                                                    <Th>Room Name</Th>
                                                    <Th>Owner</Th>
                                                    <Th>Player #</Th>
                                                    <Th>Game State</Th>
                                                    <Th>Join</Th>
                                                    <Th>Spectate</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    liveGames.map((room, index) => {
                                                        return (
                                                            <Tr key={index}>
                                                                <Td>{room.Name}</Td>
                                                                <Td>{room.OwnerUsername}</Td>
                                                                <Td textColor={room.PlayerCurrent === room.PlayerLimit ? "#d9684f" : ""}>
                                                                    {room.PlayerCurrent} / {room.PlayerLimit}
                                                                </Td>
                                                                <Td textColor={getGameStatusById(room.State) === "Game Started" ? "#7eb374" : ""}>
                                                                    {getGameStatusById(room.State)}
                                                                </Td>
                                                                <Td>
                                                                    {
                                                                        room.joinableStatus === 1 ?
                                                                            <JoinButton roomID={room.ID} disabled={false} /> :
                                                                            room.joinableStatus === 2 ?
                                                                                <ReJoinButton roomID={room.ID} /> :
                                                                                room.joinableStatus === 4 ?
                                                                                    <JoinButton roomID={room.ID} disabled={true} /> :
                                                                                    <></>
                                                                    }
                                                                </Td>
                                                                <Td>
                                                                    <SpectateButton roomID={room.ID} State={room.State} />
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel>
                                    <p>Coming Soon...</p>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>

    )
}