import { Button, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger } from "@chakra-ui/react"

export const HowPointsCalculated = () => {
    return (
        <Popover placement='bottom'>
            <PopoverTrigger>
                <Button
                    backgroundColor={"#7f6440"}
                    colorScheme={"blue"}
                >
                    How Points % Calculated?
                </Button>
            </PopoverTrigger>
            <PopoverContent
                textColor={"#392516"}
                backgroundColor={"#bee3f8"}
            >
                <PopoverHeader fontWeight={"700"} textAlign={"center"} borderColor={"#dcb88a"}>
                    How Points % Calculated?
                </PopoverHeader>

                <PopoverBody whiteSpace={"wrap"}>
                    This is the average of your VP ratios from all the games you have played.
                    You add all the VP ratios together and then divide by the total number of games.
                </PopoverBody>
            </PopoverContent>
        </Popover >
    )
}