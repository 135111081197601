import { Avatar, Box, Button, Image, useDisclosure } from "@chakra-ui/react"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, } from '@chakra-ui/react'
import { getAvatarByIndex } from "../../../../utils/assets/getAvatarByIndex";
import { DashboardCloseButton } from "../../General/DashboardCloseButton";
import { useEffect, useState } from "react";
import { useGame } from "../../../../context/GameContext";
import { Exchange } from './Exchange';
import { TransactionHistory } from './TransactionHistory';
import { GetBalance, GetWalletAddress } from "../../../../requests/WalletRequests";
import { ConnectWallet } from "./ConnectWallet";
import { WalletAddress } from "./WalletAddress";
import { Balance } from "./Balance";
import { ConfirmWallet } from "./ConfirmWallet";
import { ReConnectWallet } from "./ReConnectWallet";
import { SocketType } from "../../../../utils/types/types";
import { SendAnalytiActionEvent } from "../../../../socket/sendMessage/SendAnalyticActionEvent";

export const Wallet = ({ socket }: { socket: SocketType }) => {
    const { avatarID, dashboardAssets } = useGame()

    const { isOpen, onOpen, onClose } = useDisclosure({
        onOpen() {
            SendAnalytiActionEvent(socket, "WalletModalOpen")
        }
    })

    const [showExchange, setShowExchange] = useState(true)
    const [showWalletConnect, setShowWalletConnect] = useState(true)

    const [balance, setBalance] = useState(0)
    const [walletAddress, setWalletAddress] = useState<`0x${string}` | undefined>(undefined)

    useEffect(() => {
        const fetchBalance = async () => {
            const response = await GetBalance()
            response && setBalance(response.balance)
        }

        fetchBalance()
    }, [])

    useEffect(() => {
        const fetchWalletAddress = async () => {
            const response = await GetWalletAddress()
            if (response && response.wallet_address !== null) {
                setWalletAddress(response.wallet_address)
                setShowWalletConnect(false)
            }
        }

        if (isOpen) {
            fetchWalletAddress()
        }
    }, [isOpen])

    return (
        <>
            <Button
                leftIcon={
                    <Image
                        src={dashboardAssets.walletIcon}
                        alt="wallet-icon"
                        h={"2.3vw"}
                    />
                }
                justifyContent={"space-evenly"}
                backgroundColor={"#412f28"}
                textColor={"#f0d63a"}
                colorScheme={"green"}
                height={"2.5vw"}
                width={"10vw"}
                ml={"auto"}
                mr={"auto"}
                mt={"0.5vw"}
                borderRadius={"1vw"}
                fontSize={"2vw"}
                onClick={onOpen}
            >
                $ {balance}
            </Button>

            <Modal
                isCentered
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                scrollBehavior={"inside"}
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius={"25px"}
                    backgroundColor={"#97794f"}
                    maxW={"min-content"}
                    maxH={"100vh"}
                    overflowY={"auto"}
                >
                    <ModalHeader
                        backgroundColor={"#3f3120"}
                        textAlign={"center"}
                        borderRadius={"25px 25px 0px 0px"}
                        textColor={"#e4cfa0"}
                        fontSize={"25px"}
                    >
                        Wallet
                    </ModalHeader>

                    <DashboardCloseButton onClose={onClose} />

                    <ModalBody pb={5}>
                        {/* Avatar and Balance Info */}
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Avatar
                                    src={getAvatarByIndex(Number(avatarID), dashboardAssets)}
                                    height={"100px"}
                                    width={"100px"}
                                    backgroundColor={"#dcd0c5"}
                                    border={"1px solid #211912"}
                                    me={5}
                                />
                            </Box>

                            {/* Show Balance */}
                            <Balance balance={balance} />
                        </Box>

                        {/* Connected Wallet Info */}
                        <WalletAddress walletAddress={walletAddress} />

                        {/* Web3Modal Wallet Connect button */}
                        {
                            showWalletConnect ?
                                <Box
                                    display={"flex"}
                                    flexDir={"column"}
                                    alignItems={"center"}
                                    backgroundColor={"#685235"}
                                    borderRadius={"10px"}
                                    mb={3} mt={3} p={3}
                                >
                                    <ConnectWallet />
                                    <ConfirmWallet
                                        setShowWalletConnect={setShowWalletConnect}
                                        setWalletAddress={setWalletAddress}
                                    />
                                </Box> :
                                <ReConnectWallet
                                    setShowWalletConnect={setShowWalletConnect}
                                />
                        }

                        <Box display={"flex"} justifyContent={"center"} >
                            <Button
                                backgroundColor={"#6e543d"}
                                border={"2px solid #211912"}
                                borderRadius={"25px"}
                                textColor={"white"}
                                colorScheme="blue"
                                onClick={() => setShowExchange(!showExchange)}
                            >
                                {!showExchange ? "Exchange" : "View Transactions History"}
                            </Button>
                        </Box>

                        {
                            showExchange ?
                                <Exchange
                                    balance={balance}
                                    setBalance={setBalance}
                                    walletAddress={walletAddress}
                                /> :
                                <TransactionHistory />
                        }

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}