import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"

type PlayerLargestArmyType = {
    largestArmy: number,
    turnIndex: number
}

export const PlayerLargestArmy = ({ largestArmy, turnIndex }: PlayerLargestArmyType) => {
    const { gameAssets } = useGame()
    return (
        <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
        >
            <Box className='largest-army-icon' id={`player-largest-army-${turnIndex}`}>
                <Image
                    src={gameAssets.largestArmyIcon}
                    alt="largest-army-icon"
                    pos={"relative"}
                    zIndex={1}
                />
            </Box>

            <Box >
                {largestArmy}
            </Box>
        </Box>
    )
}