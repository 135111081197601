import { CircularProgressLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const TimeDifference = ({ futureTime }: { futureTime: string }) => {
    const specifiedTime = new Date(futureTime).getTime();

    const [timeDifference, setTimeDifference] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const difference = (specifiedTime - currentTime) / 1000;
            setTimeDifference(difference);
        }, 1000);

        return () => clearInterval(interval);
    }, [specifiedTime]);

    if (timeDifference > 0) {
        return (
            <CircularProgressLabel
                fontSize={"1.5vw"}
                fontWeight={"bolder"}
            >
                {timeDifference.toFixed(0)}
            </CircularProgressLabel>
        );
    } else {
        return null
    }
}