import { Box } from "@chakra-ui/react"
import { FaCalendarAlt } from "react-icons/fa"
import { getDateAndTime } from "../../../../../utils/constants/getDateAndTime"

export const OverviewDate = ({ StartTime }: { StartTime: string }) => {
    const { time, date } = getDateAndTime(StartTime)
    return (
        <Box w={"50%"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-evenly"}
                backgroundColor={"#a4401a"}
                width={"90%"}
                m={"auto"}
                borderRadius={"0.5vw"}
            >
                <FaCalendarAlt fontSize={"1.75vw"} />
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    alignItems={"center"}
                    fontSize={"1.5vw"}
                >
                    <Box m={0}>{date}</Box>
                    <Box m={0}>{time}</Box>
                </Box>
            </Box>
        </Box>
    )
}