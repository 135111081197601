import { Box } from '@chakra-ui/react'
import { useGame } from '../../../context/GameContext'
import { getGameBackground } from '../../../utils/assets/getGameBackground'

export const WallpaperManager = () => {
    const { gameAssets } = useGame()

    const handleWallpaperChange = (type: string) => {
        if (type) {
            const gameScreen = document.getElementById("game-screen")

            if (gameScreen) {
                gameScreen.style.backgroundImage = `url(${getGameBackground(type, gameAssets)})`
                localStorage.setItem("background", type)
            }
        }
    }

    return (
        <>
            <Box
                fontSize={"1.25vw"}
                textAlign={"center"}
            >
                Game Wallpaper
            </Box>
            <Box display={"flex"} flexDir={"column"}>
                {/* Wallpaper options row 1 */}
                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                    <WallpaperButton
                        buttonAsset={gameAssets.seaButton}
                        buttonName={"Sea"}
                        handleWallpaperChange={() => handleWallpaperChange("sea")}
                    />

                    <WallpaperButton
                        buttonAsset={gameAssets.tableButton}
                        buttonName={"Table"}
                        handleWallpaperChange={() => handleWallpaperChange("table")}
                    />

                    <WallpaperButton
                        buttonAsset={gameAssets.hexButton}
                        buttonName={"Hex"}
                        handleWallpaperChange={() => handleWallpaperChange("hex")}
                    />
                </Box>

                {/* Wallpaper options row 2 */}
                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                    <WallpaperButton
                        buttonAsset={gameAssets.paperButton}
                        buttonName={"Paper"}
                        handleWallpaperChange={() => handleWallpaperChange("paper")}
                    />

                    <WallpaperButton
                        buttonAsset={gameAssets.royalButton}
                        buttonName={"Royal"}
                        handleWallpaperChange={() => handleWallpaperChange("royal")}
                    />
                </Box>
            </Box>
        </>
    )
}

type WallpaperButtonType = {
    buttonAsset: string,
    buttonName: string,
    handleWallpaperChange: () => void,
}

const WallpaperButton = ({ buttonAsset, buttonName, handleWallpaperChange }: WallpaperButtonType) => {
    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"5vw"}>
            <Box
                _hover={{
                    cursor: "pointer"
                }}
                boxSize={"3vw"}
                background={"transparent"}
                onClick={handleWallpaperChange}
            >
                <img src={buttonAsset} alt='wallpaper-button' />
            </Box>
            <Box
                textAlign={"center"}
                fontSize={"1vw"}
            >
                {buttonName}
            </Box>
        </Box>
    )
}