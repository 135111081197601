import { Link, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, IconButton, useMediaQuery } from '@chakra-ui/react'
import { FaDiscord, FaGoogle } from "react-icons/fa";

export const SignInModal = ({ isOpen, onClose, onOpen }: any) => {
    const [is2K] = useMediaQuery('(min-width: 2500px)')
    const [is4K] = useMediaQuery('(min-width: 3800px)')

    return (
        <>
            <Button
                w={is4K ? "900px" : is2K ? "600px" : "350px"}
                padding={is4K ? "75px" : is2K ? "50px" : "25px"}
                fontSize={is4K ? "65" : is2K ? "45px" : "30px"}
                className='green-text-outline'
                backgroundColor={"#91D91E"}
                borderRadius={is4K ? "30px" : is2K ? "20px" : "10px"}
                border={is4K ? "6px solid black" : is2K ? "4px solid black" : "2px solid black"}
                boxShadow={is4K ? "0px 15px 0px 0px #3F7600" : is2K ? "0px 10px 0px 0px #3F7600" : "0px 5px 0px 0px #3F7600"}
                colorScheme={"green"}
                onClick={onOpen}
            >
                PLAY NOW
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    borderRadius={"25px"}
                >
                    <ModalHeader
                        className='menu-modal-headers'
                        padding={"10px"}
                        textAlign={"center"}
                    >
                        Login Options
                        <IconButton
                            position={"absolute"}
                            right={0}
                            top={1}
                            colorScheme={"none"}
                            background={"transparent"}
                            aria-label='close-button'
                            onClick={onClose}
                            icon={<div className='close-button'></div>}
                        />
                    </ModalHeader>

                    <ModalBody display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Link href={import.meta.env.VITE_API_URL + `/auth/login/discord`} mb={5}>
                            <Button
                                borderRadius={"15px"}
                                size={"lg"}
                                boxShadow={"0px 5px 0px 0px #232369"}
                                backgroundColor={"#5865F2"}
                                colorScheme={"blue"}
                            >
                                Discord <FaDiscord className='ms-2' />
                            </Button>
                        </Link>
                        <Link href={import.meta.env.VITE_API_URL + `/auth/login/google`} mb={2}>
                            <Button
                                borderRadius={"15px"}
                                size={"lg"}
                                boxShadow={"0px 5px 0px 0px #3F7600"}
                                textShadow={"1px 1px rgba(0,0,0,0.5)"}
                                backgroundColor={"#34A853"}
                                colorScheme={"green"}
                            >
                                Google <FaGoogle className='ms-2' />
                            </Button>
                        </Link>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}