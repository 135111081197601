import { useEffect } from "react"
import { appendBlackThiefCircle, removeBlackThiefCircle } from "../../../../utils/functions/blackThiefCircle"
import { getPlaceCoordsByHexId } from "../../../../utils/functions/getPlaceCoordsByHexId"
import { getUserIndexByPlace } from "../../../../utils/functions/getUserIndexByPlace"

export const RobberEffects = (desertIndex: number, robberPosition: number, gameData: any, myGameData: any, settlePositions: any[], victoryPoints: number[]) => {
    // Show movable position of the robber
    useEffect(() => {
        if (robberPosition > -1 && myGameData) {
            const gameStage = gameData.Status
            const isOneVOne = gameData.RoomLimit === 2
            const gameTurnIndex = gameData.TurnIndex
            const myTurnIndex = myGameData.turn_index

            if (gameStage === 6 && myTurnIndex === gameTurnIndex) {
                for (let i = 0; i < 19; i++) {
                    const element = document.getElementById(`hex/${i}`)

                    if (isOneVOne) {
                        const places = getPlaceCoordsByHexId(i)
                        const userIndexes = getUserIndexByPlace(settlePositions, places)

                        if (userIndexes && userIndexes.length > 0) {
                            const distinctNumbers = userIndexes!.filter(
                                (number, index, array) => array.indexOf(number) === index
                            );

                            if (distinctNumbers.length > 0) {
                                if (distinctNumbers.length === 1) {
                                    if (victoryPoints[distinctNumbers[0]] > 2) {
                                        if (element && element && i !== robberPosition) {
                                            element.classList.add("hex-div-thief-animation")
                                        }
                                    }
                                } else if (distinctNumbers.length === 2) {
                                    let shouldAddClass = true;

                                    distinctNumbers.forEach((turnIndex) => {
                                        if (victoryPoints[turnIndex] < 3) {
                                            shouldAddClass = false;
                                        }
                                    });

                                    if (shouldAddClass) {
                                        if (element && i !== robberPosition) {
                                            element.classList.add("hex-div-thief-animation");
                                        }
                                    }
                                }
                            } else {
                                if (element && element && i !== robberPosition) {
                                    element.classList.add("hex-div-thief-animation")
                                }
                            }
                        } else {
                            if (element && element && i !== robberPosition) {
                                element.classList.add("hex-div-thief-animation")
                            }
                        }
                    } else {
                        if (element && element && i !== robberPosition) {
                            element.classList.add("hex-div-thief-animation")
                        }
                    }
                }
            } else {
                for (let i = 0; i < 19; i++) {
                    const element = document.getElementById(`hex/${i}`)
                    if (element || element && i !== robberPosition) {
                        element.classList.remove("hex-div-thief-animation")
                    }
                }
            }
        }
    }, [gameData, myGameData, robberPosition, desertIndex])


    // Thief-Robber circle
    useEffect(() => {
        if (robberPosition > -1) {
            const thiefElement = document.getElementById(`thief-${robberPosition}`)
            const hexElement = document.getElementById(`hex/${robberPosition}`)
            const circleOfTile = document.getElementById(`circle-${robberPosition}`)

            if (robberPosition !== desertIndex) {
                appendBlackThiefCircle(robberPosition)

                if (circleOfTile) {
                    circleOfTile.classList.add("thief-white-text-color")
                }
            }

            if (thiefElement) {
                thiefElement.classList.add("thief-circle")
            }

            if (hexElement) {
                hexElement.classList.remove("hex-div")
            }
        }

        return () => {
            if (robberPosition > -1) {
                for (let i = 0; i < 19; i++) {
                    const thiefElement = document.getElementById(`thief-${robberPosition}`)
                    const hexElement = document.getElementById(`hex/${robberPosition}`)
                    const circleOfTile = document.getElementById(`circle-${robberPosition}`)

                    if (robberPosition !== desertIndex) {
                        removeBlackThiefCircle(robberPosition)

                        if (circleOfTile) {
                            circleOfTile.classList.remove("thief-white-text-color")
                        }
                    }

                    if (thiefElement) {
                        thiefElement.classList.remove("thief-circle")
                    }

                    if (hexElement) {
                        hexElement.classList.add("hex-div")
                    }
                }
            }
        }
    }, [robberPosition, desertIndex])
}