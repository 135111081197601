import { useEffect, useState } from "react";

const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const hoursStr = hours > 0 ? `${hours} h ` : "";
    const minutesStr = minutes > 0 ? `${minutes} m ` : "";
    const secondsStr = `${seconds} sec`;

    return `${hoursStr}${minutesStr}${secondsStr}`.trim();
};

export const RemainingTime = (finishTime: string) => {
    const [timeLeft, setTimeLeft] = useState<number | null>(null);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const finish = new Date(finishTime);

            const difference = finish - now;
            if (difference > 0) {
                setTimeLeft(difference);
            } else {
                setTimeLeft(0);
            }
        };

        calculateTimeLeft();
        const intervalId = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(intervalId);
    }, [finishTime]);

    if (timeLeft !== 0) {
        return timeLeft !== null ? formatTime(timeLeft) : null
    }
}