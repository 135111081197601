import { createAppKit } from "@reown/appkit/react";
import { arbitrum } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { QueryClient } from "@tanstack/react-query";

// 0. Setup queryClient
export const queryClient = new QueryClient();

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = import.meta.env.VITE_PROJECT_ID;

const appName = "Pioneers of New World";

// 2. Create wagmiConfig
const metadata = {
  name: appName,
  description: "Wallet connection for Pioneers of New World",
  url: "https://pioneers.game",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

export const wagmiAdapter = new WagmiAdapter({
  networks: [arbitrum],
  projectId,
});

// 3. Create modal
createAppKit({
  adapters: [wagmiAdapter],
  networks: [arbitrum],
  metadata: metadata,
  projectId,
  features: {
    analytics: false,
  },
  themeVariables: {
    "--w3m-z-index": 1500,
    "--w3m-accent": "#7fa631",
  },
});
