import { Box, Button } from "@chakra-ui/react";
import { FaRegClock } from "react-icons/fa";

type CardCheckinType = {
    State: number,
    IsUserSignedUp: boolean,
    UserCheckedIn: boolean,
    handleCheckin: () => void,
    handleCheckinCancel: () => void
}

export const CardCheckin = ({
    State,
    IsUserSignedUp,
    UserCheckedIn,
    handleCheckin,
    handleCheckinCancel,
}: CardCheckinType) => {
    const renderButton = () => {
        if (!UserCheckedIn) {
            return (
                <Button
                    width={"40%"}
                    height={"40%"}
                    fontSize={"1.1vw"}
                    padding={"0.3vw"}
                    backgroundColor={"#69be55"}
                    border={"0.15vw solid #1C6824"}
                    boxShadow={"0 0.15vw #1C6824"}
                    colorScheme={"green"}
                    isDisabled={!IsUserSignedUp}
                    onClick={handleCheckin}
                >
                    <FaRegClock className="me-2" />
                    Check-in
                </Button>
            );
        }

        return (
            <Button
                width={"40%"}
                height={"40%"}
                fontSize={"1.1vw"}
                padding={"0.3vw"}
                backgroundColor={"#c97f64"}
                border={"2px solid #A72C01"}
                boxShadow={"0px 2px #A72C01"}
                colorScheme={"red"}
                isDisabled={!IsUserSignedUp}
                onClick={handleCheckinCancel}
            >
                <FaRegClock className="me-2" />
                Check-out
            </Button>
        );
    };

    return (
        State === 2 && IsUserSignedUp && (
            <Box display={"flex"} justifyContent={"space-evenly"} backgroundColor={"#eed6b7"} p={"0.5vw"}>
                {renderButton()}
            </Box>
        )
    );
};
