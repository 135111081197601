import { Button } from "@chakra-ui/react"
import { FaRobot } from "react-icons/fa6";
import { useSync } from "../../../../context/SyncContext";
import { useGame } from "../../../../context/GameContext";
import { CreateAddBot } from "../../../../requests/LobbyRequests";

export const BotAddButton = () => {
    const { gameID } = useGame()
    const { myGameData } = useSync()

    const handleAddBot = async () => {
        await CreateAddBot(gameID)
    }

    if (myGameData && myGameData.isOwner) {
        return (
            <Button
                rightIcon={<FaRobot />}
                width={"10vw"}
                height={"2.5vw"}
                fontSize={"1.75vw"}
                backgroundColor={"#8aa5c9"}
                border={"0.1vw solid #9d8a75"}
                colorScheme='linkedin'
                onClick={handleAddBot}
            >
                Add Bot
            </Button>
        )
    }

    return null
}