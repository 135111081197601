import { Box } from "@chakra-ui/react"
import { FaTrophy } from "react-icons/fa";
import { TournamentBracketTable } from './TournamentBracketTable'
import { TournamentBracketFinalTable } from "./TournamentBracketFinalTable"
import { useEffect, useState } from "react";
import { GetTournamentBrackets } from "../../../../../requests/TournamentRequests";

type SingleElimBracketType = {
    ID: number,
    State: number,
    Prize: number[],
    CurrentStage: number,
    setMatches: (value: any[]) => void | null
}

const roundTitleColor = "#34362f"

export const SingleElimBracket = ({ ID, State, Prize, CurrentStage, setMatches }: SingleElimBracketType) => {
    const [semiFinal, setSemiFinal] = useState<any[]>([])
    const [final, setFinal] = useState<any>()

    useEffect(() => {
        const fetchBrackets = async () => {
            const response = await GetTournamentBrackets(ID);

            if (response) {
                const roundTwoMatches = response.filter((match) => match.stage === 1);
                setSemiFinal(roundTwoMatches);
                if (setMatches) {
                    setMatches(roundTwoMatches)
                }

                const finalMatch = response.find((match) => match.stage === 2);
                if (finalMatch) {
                    setFinal(finalMatch);
                }
            }
        };

        const isStateCorrect = State === 3 || State === 4 || State === 6

        if (ID && isStateCorrect) {
            fetchBrackets();
        }
    }, [ID, State, CurrentStage]);

    return (
        <Box display={"flex"} alignItems={"stretch"} overflow={"auto"}>
            {/* Semi Final Matches */}
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#edcca3"}
                w={"max-content"}
                ps={"5vw"}
                pe={"5vw"}
            >
                <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <Box textColor={roundTitleColor} fontSize={"40px"}>
                        Semi Final
                    </Box>
                    {
                        semiFinal.length > 0 &&
                        semiFinal.map((bracket, index) => {
                            return (
                                <TournamentBracketTable
                                    key={index}
                                    matchId={index + 1}
                                    players={bracket.Players}
                                    matchStatus={bracket.state}
                                    duration={bracket.duration}
                                    marginAmount={"1vh"}
                                />
                            )
                        })
                    }
                </Box>
            </Box>

            {/* Final Match */}
            <Box
                display={"flex"}
                alignItems={"stretch"}
                justifyContent={"center"}
                backgroundColor={"#f1d4b1"}
                w={"max-content"}
                pe={"5vw"}
                ps={"5vw"}
            >
                <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <Box display={"flex"} alignItems={"center"} justifySelf={"self-start"} textColor={roundTitleColor} fontSize={"40px"}>
                        Final
                        <FaTrophy className="ms-4" />
                    </Box>
                    <TournamentBracketFinalTable
                        players={final && final.Players}
                        matchStatus={final && final.state}
                        prizePool={Prize}
                        duration={final && final.duration}
                    />
                </Box>
            </Box>
        </Box>
    )
}