export const getInventoryChestAsset = (chestRarity: string, assets: any) => {
  if (chestRarity === "Alpha") {
    return assets.inventoryAlphaChest;
  } else if (chestRarity === "Common") {
    return assets.inventoryCommonChest;
  } else if (chestRarity === "Rare") {
    return assets.inventoryRareChest;
  } else if (chestRarity === "Epic") {
    return assets.invetoryEpicChest;
  } else if (chestRarity === "Ancient") {
    return assets.inventoryAncientChest;
  } else {
    return "";
  }
};
