import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, useDisclosure } from '@chakra-ui/react'
import { VscDebugDisconnect } from "react-icons/vsc";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { useRef } from 'react'
import { useGame } from '../../../context/GameContext'
import { useNavigate } from 'react-router-dom'
import { CreateLeaveGame } from '../../../requests/RoomRequests'

type LeaveAlertType = {
    onClose: () => void,
    isOpen: boolean
}

export const LeaveAlert = ({ isOpen, onClose }: LeaveAlertType) => {
    const { gameID, setGameID, setIsReconnect } = useGame()

    const navigate = useNavigate()
    const cancelRef = useRef()

    const handleLeave = async () => {
        const response = await CreateLeaveGame(gameID)

        if (response && response.message === "success") {
            setGameID(-1)
            setIsReconnect(false)
            navigate('/dashboard')
        }
    }

    return (

        <AlertDialog
            motionPreset={'slideInBottom'}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent
                    background={"linear-gradient(#CDB57A, #9B8152)"}
                    borderRadius={"25px"}
                    textColor={"white"}
                >
                    <AlertDialogHeader className='menu-modal-headers'>
                        Leave Game
                    </AlertDialogHeader>

                    <AlertDialogBody fontSize={"18px"} textAlign={"center"} textColor={"black"}>
                        Are you sure? If you leave the game, you will not able to join again!
                    </AlertDialogBody>

                    <AlertDialogFooter
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        borderRadius={"0px 0px 10px 10px"}
                    >
                        <Button
                            backgroundColor={"#40983A"}
                            border={"2px solid black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            onClick={onClose}
                        >
                            <FaArrowRotateLeft className='me-2' />
                            Back to Game
                        </Button>
                        <Button
                            backgroundColor={"#E94F4F"}
                            colorScheme='red'
                            border={"2px solid black"}
                            borderRadius={"10px"}
                            onClick={handleLeave}
                        >
                            <VscDebugDisconnect className='me-2' />
                            Leave the Game
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}