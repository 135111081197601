export const isRoadConnected = (
  myRoadPosition: any[],
  xStart: number,
  yStart: number,
  xEnd: number,
  yEnd: number
) => {
  if (myRoadPosition.length > 0) {
    return (
      myRoadPosition.find((data) => {
        return (
          (data.position[0] === xEnd && data.position[1] === yEnd) ||
          (data.position[2] === xStart && data.position[3] === yStart) ||
          (data.position[0] === xStart && data.position[1] === yStart) ||
          (data.position[2] === xEnd && data.position[3] === yEnd)
        );
      }) !== undefined
    );
  } else {
    return false;
  }
};
