export const appendBlackThiefCircle = (tileID: number) => {
  const tile = document.getElementById(`tile-${tileID}`);

  if (tile) {
    const blackCircle = document.createElement("span");
    blackCircle.classList.add("thief-black-indicator");
    blackCircle.style.height = blackCircle.style.width;

    tile.appendChild(blackCircle);
  }
};

export const removeBlackThiefCircle = (tileID: number) => {
  const tile = document.getElementById(`tile-${tileID}`);

  if (tile) {
    const blackCircle = tile.querySelector(".thief-black-indicator");

    if (blackCircle) {
      tile.removeChild(blackCircle);
    }
  }
};
