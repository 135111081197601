export const getDateAndTime = (gmtTimeString: string) => {
  const date = new Date(gmtTimeString);

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const dateOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
  };

  const timeString = date.toLocaleString("en-US", timeOptions);
  const dateString = date.toLocaleString("en-US", dateOptions);

  return { date: dateString, time: timeString };
};
