import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"

type PlayerLongestRoadType = {
    longestRoad: number,
    turnIndex: number
}

export const PlayerLongestRoad = ({ longestRoad, turnIndex }: PlayerLongestRoadType) => {
    const { gameAssets } = useGame()
    return (
        <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
        >
            <Box className='longest-road-icon' id={`player-longest-road-${turnIndex}`}>
                <Image
                    src={gameAssets.longestRoadIcon}
                    alt="longest-road-icon"
                    pos={"relative"}
                    zIndex={1}
                />
            </Box>

            <Box >
                {longestRoad}
            </Box>
        </Box>
    )
}