import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"
import { useSync } from "../../../../context/SyncContext"

export const PlayerTotalScore = ({ turnIndex }: { turnIndex: number }) => {
    const { gameAssets } = useGame()
    const { armyPoints, longestRoads, totalDevCards } = useSync()

    return (
        <Box mb={"0.1vw"} textColor={"white"} fontSize={"1.25vw"}>
            <Box >
                Total Score
            </Box>
            <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                    <Image
                        src={gameAssets.largestArmyNumberIcon}
                        alt='largest-army'
                        width={"35%"}
                    />
                    <Box >
                        {armyPoints[turnIndex]}
                    </Box>
                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                    <Image
                        src={gameAssets.longestRoadNumberIcon}
                        alt='longest-road'
                        width={"35%"}
                    />
                    <Box >
                        {longestRoads[turnIndex]}
                    </Box>
                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                    <Image
                        src={gameAssets.devCardNumberIcon}
                        alt='dev-card'
                        width={"35%"}
                    />
                    <Box>
                        {totalDevCards[turnIndex]}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}