import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const GetTimeDifference = ({ finishTime }: { finishTime: string }) => {
    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const finish = new Date(finishTime);
            const difference = finish - now;
            if (difference > 0) {
                setTimeLeft(difference);
            } else {
                setTimeLeft(0);
            }
        };

        calculateTimeLeft();
        const intervalId = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(intervalId);
    }, [finishTime]);

    const formatTime = (milliseconds: number) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const hoursStr = hours > 0 ? `${hours} h ` : "";
        const minutesStr = minutes > 0 ? `${minutes} m ` : "";
        const secondsStr = `${seconds} sec`;

        return `${hoursStr}${minutesStr}${secondsStr}`.trim();
    };

    if (timeLeft !== 0) {
        return (
            <Box textColor={"#edd772"} fontSize={"1.25vw"} fontStyle={"italic"}>
                {timeLeft !== null && formatTime(timeLeft)}
            </Box>
        );
    } else {
        return null
    }
}