import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../../context/GameContext"
import { FaCheckCircle, FaClock } from "react-icons/fa"
import { GetTimeDifference } from "../../../Notification/GetTimeDifference"

type QualifyType = {
    CurrentStage: number,
    PlayerState: number,
    StartTime: string,
    FreezeTimeEnd: string | null
}

export const Qualify = ({ CurrentStage, PlayerState, StartTime, FreezeTimeEnd }: QualifyType) => {
    const { gameAssets } = useGame()

    const showSuccessBG = CurrentStage > 2 && FreezeTimeEnd

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Box backgroundColor={showSuccessBG ? "#51ea56" : "#cb3e50"} borderRadius={"50%"} p={"1vw"}>
                {
                    showSuccessBG ?
                        <FaCheckCircle /> :
                        <FaClock />
                }
            </Box>
            <Box textColor={"#e6d1a4"} textDecoration={showSuccessBG ? "line-through" : undefined}>
                Qualify Stage {(CurrentStage === 1 && PlayerState) === 2 ? 0 : CurrentStage > 3 ? 3 : CurrentStage}/3
            </Box>
            <Image
                src={gameAssets.gameLogDivider}
                alt="game-log-divider"
                width={"50%"}
            />
            {
                CurrentStage === 1 && PlayerState === 2 &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Get ready! The tournament will begin soon.
                    <GetTimeDifference finishTime={StartTime} />
                </Box>
            }
            {
                (CurrentStage === 1 || CurrentStage === 2 || CurrentStage === 3) &&
                PlayerState === 5 &&
                !FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Stay Focus! Waiting for other matches to end!
                </Box>
            }
            {
                (CurrentStage === 1 || CurrentStage === 2) && FreezeTimeEnd &&
                <Box fontSize={"1.25vw"} textColor={"#51ea56"} textAlign={"center"}>
                    The next game will start in
                    <GetTimeDifference finishTime={FreezeTimeEnd} />
                </Box>
            }
            {
                CurrentStage > 2 && FreezeTimeEnd &&
                <Box fontSize={"1.25vw"} textColor={"#51ea56"}>
                    You have passed the Qualify Stage.
                </Box>
            }
        </Box>
    )
}