import { FaTrophy } from "react-icons/fa";
import { Box, Card, CardBody, Image, Button, useDisclosure, Avatar, Tooltip } from '@chakra-ui/react'
import { PastTournamentCardDetail } from './PastTournamentCardDetail'
import { getOrdinalSuffix } from '../../../../../utils/functions/getOrdinalSuffix';
import { getAvatarByIndex } from '../../../../../utils/assets/getAvatarByIndex'
import { useGame } from '../../../../../context/GameContext'
import { CardName } from "../Card/CardName";

type PastTournamentCardType = {
    ID: number,
    Class: number,
    CurrentPlayers: number,
    BannerAsset: string,
    IsUserSignedUp: boolean,
    IsPrivate: boolean,
    Name: string,
    Prize: number[],
    StartTime: string,
    State: number,
    Stage: number,
    Rank: number,
    Winners: string[]
}

export const PastTournamentCard = ({
    ID,
    Class,
    CurrentPlayers,
    BannerAsset,
    IsUserSignedUp,
    IsPrivate,
    Name,
    Prize,
    State,
    Stage,
    Rank,
    Winners
}: PastTournamentCardType) => {
    const { dashboardAssets } = useGame()
    const { isOpen, onClose, onOpen } = useDisclosure()

    const isDoubleElimination = Class === 2
    const isExtendedDE = Class === 3
    const isChampionShip = Class === 4

    return (
        <Card
            backgroundColor={"#96754B"}
            textAlign={"center"}
            borderRadius={"25px"}
            border={"3px solid black"}
            height={"fit-content"}
            width={"90%"}
            m={"auto"}
            mb={5}
            mt={5}
        >
            <CardBody p={0} border={"15px solid #7e582a"} borderRadius={"20px"}>
                {/* Tournament Name */}
                <CardName
                    IsPrivate={IsPrivate}
                    Name={Name}
                />

                {/* Tournament Banner - Winner */}
                <Box pos={"relative"} textAlign={"center"}>
                    <Image
                        src={BannerAsset}
                        alt='tournament-banner'
                        borderRadius={"10px 10px 0px 0px"}
                        w={"100%"}
                        h={"100%"}
                        objectFit={"cover"}
                    />
                    {
                        State === 4 &&
                        <Box className='tournament-winner'>
                            <Avatar
                                backgroundColor={"#dcd1c4"}
                                h={"auto"}
                                w={"30%"}
                                border={"3px solid black"}
                                src={getAvatarByIndex(3, dashboardAssets)}
                            />
                            <Box fontSize={"1.5vw"}>
                                {Winners && Winners.length > 0 && Winners[0]}
                            </Box>
                            <Box display={"flex"} alignItems={"center"} fontSize={"1.5vw"}>
                                <Image src={dashboardAssets.coinIcon} width={"1.5vw"} className='me-2' alt='coin-cion' />
                                $ {Prize[0]}
                            </Box>
                        </Box>
                    }
                </Box>

                {/* Tournament Main Information */}
                <Box backgroundColor={"#C1A675"} fontSize={"1vw"}>
                    <Box display={"flex"} textAlign={"center"} backgroundColor={"#63421a"} textColor={"white"} p={"0.5vw"}>
                        <Box className="col-3" alignSelf={"center"} zIndex={1}>
                            <Tooltip label="Your rank in the tournament">
                                Your Rank
                            </Tooltip>
                        </Box>
                        <Box className="col-3" alignSelf={"center"} zIndex={1}>
                            Players
                        </Box>
                        <Box className="col-3" alignSelf={"center"} zIndex={1}>
                            Mode
                        </Box>
                        <Box className="col-3" alignSelf={"center"} zIndex={1}>
                            Prize Pool
                        </Box>
                    </Box>
                    <Box display={"flex"} textAlign={"center"} backgroundColor={"#efe4d2"} textColor={"#73553f"} p={"0.5vw"}>
                        <Box className="col-3" alignSelf={"center"} >
                            {!IsUserSignedUp && "Not Registered"}
                            {IsUserSignedUp && Rank !== 0 && getOrdinalSuffix(Rank)}
                        </Box>
                        <Box className="col-3 d-flex align-items-center justify-content-center" alignSelf={"center"}>
                            <Image className='me-2' src={dashboardAssets.maxPlayersIcon} w={"1.1vw"} alt='max-players-icon' />
                            {CurrentPlayers}
                        </Box>
                        <Box className="col-3" alignSelf={"center"} >
                            {
                                isChampionShip ?
                                    "Championship" :
                                    isExtendedDE || isDoubleElimination ?
                                        "Knockout" :
                                        "Single Elim."
                            }
                        </Box>
                        <Box className="col-3 d-flex align-items-center justify-content-center" alignSelf={"center"}>
                            <Image src={dashboardAssets.coinIcon} width={"1.2vw"} className='me-2' alt='coin-cion' />
                            $
                            {
                                Prize.reduce((accumulator, currentValue) => {
                                    return accumulator + currentValue;
                                }, 0)
                            }
                        </Box>
                    </Box>
                </Box>

                {/* Tournament Card Buttons */}
                <Box display={"flex"} justifyContent={"space-evenly"} backgroundColor={"#eed6b7"} p={"0.5vw"}>
                    {
                        State === 4 &&
                        <Button
                            width={"40%"}
                            height={"40%"}
                            fontSize={"1.1vw"}
                            padding={"0.3vw"}
                            backgroundColor={"#db8242"}
                            border={"2px solid #ae5e48"}
                            boxShadow={"0px 2px #ae5e48"}
                            colorScheme={"orange"}
                            onClick={onOpen}
                        >
                            <FaTrophy className="me-2" />
                            Result Overview
                        </Button>
                    }

                    {
                        State === 5 &&
                        <Box fontSize={"5vh"}>
                            Canceled
                        </Box>
                    }
                </Box>

                <PastTournamentCardDetail
                    isOpen={isOpen}
                    onClose={onClose}
                    ID={ID}
                    Name={Name}
                    Prize={Prize}
                    State={State}
                    Stage={Stage}
                    Winners={Winners}
                    IsDoubleElimination={isDoubleElimination}
                    IsChampionship={isChampionShip}
                />
            </CardBody>
        </Card>
    )
}