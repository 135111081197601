import { useEffect } from "react";
import { SocketType } from "../utils/types/types";
import { useGame } from "../context/GameContext";
import { useToast } from "@chakra-ui/react";
import { useDashboard } from "../context/DashboardContext";
import { PlaySoundEffect } from "../sounds/playSoundEffect";

export const TournamentNotif = (socket: SocketType | null) => {
    const { setTournamentNotif, tournamentNotif } = useDashboard()
    const { soundEffects, sfxEnabled } = useGame()

    const toast = useToast()

    // Handle Tournament FreezeTimeEnd assign
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentWaitingRoomCountSetEvent") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setTournamentNotif(prevStartedMatches => {
                        return prevStartedMatches.map(tournament => {
                            if (tournament.id === parsedData.tournamentID) {
                                return { ...tournament, FreezeTimeEnd: parsedData.finishTime };
                            }
                            return tournament;
                        });
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle Deleting Tournament
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentDeleted") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentNotif(tournamentNotif.filter((tournament) => tournament.id !== parsedData.id));
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    //  Handle Tournaments Status Changes
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentStatusChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (tournamentNotif && parsedData) {
                    const newTournaments = [...tournamentNotif]

                    const tournament = newTournaments?.find((tournament) => tournament.id === parsedData.id);

                    if (tournament) {
                        if (parsedData.newStatus === 2) {
                            tournament.player_state = parsedData.newStatus;
                            setTournamentNotif(newTournaments);

                            PlaySoundEffect(soundEffects.tournamentCheckinSFX, 0.5, true)
                        } else if (parsedData.newStatus === 3) {
                            if (tournament.checked_in) {
                                PlaySoundEffect(soundEffects.tournamentStartedSFX, 0.5, true)
                            } else {
                                setTournamentNotif(tournamentNotif.filter((tournament) => tournament.id !== parsedData.id));
                                toast({
                                    description: "You were removed from the tournament because you didn't check-in.",
                                    status: "info",
                                    duration: null,
                                    isClosable: true
                                })
                            }
                        } else if (parsedData.newStatus === 4) {
                            setTournamentNotif(tournamentNotif.filter((tournament) => tournament.id !== parsedData.id));
                            toast({
                                description: "The tournament has ended because not enough players checked-in.",
                                status: "info",
                                duration: null,
                                isClosable: true
                            })
                        } else if (parsedData.newStatus === 5) {
                            setTournamentNotif(tournamentNotif.filter((tournament) => tournament.id !== parsedData.id));
                            toast({
                                description: "The tournament is cancelled.",
                                status: "info",
                                duration: null,
                                isClosable: true
                            })
                        }
                        else if (parsedData.newStatus === 6) {
                            tournament.player_state = parsedData.newStatus;
                            tournament.checked_in = false
                            setTournamentNotif(newTournaments);
                            toast({
                                description: "The next stage will start in a day.",
                                status: "info",
                                duration: null,
                                isClosable: true
                            })
                        }
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Player Status update
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "PlayerTournamentStatusChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    const newTournaments = [...tournamentNotif]

                    const tournament = newTournaments?.find((tournament) => tournament.id === parsedData.id);

                    if (tournament) {
                        tournament.player_state = parsedData.newStatus;
                        setTournamentNotif(newTournaments);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle eliminated tournament
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "UserEliminated") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentNotif(tournamentNotif.filter((tournament) => tournament.id !== parsedData.tournamentID));
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Registration Cancel
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentSignupCancel") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentNotif(tournamentNotif.filter((tournament) => tournament.id !== parsedData.id))
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Check-out
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentCheckinCancel") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentNotif(prevItems =>
                        prevItems.map(item =>
                            item.id === parsedData.id ? { ...item, checked_in: false } : item
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Check-in
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentCheckin") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentNotif(prevItems =>
                        prevItems.map(item =>
                            item.id === parsedData.id ? { ...item, checked_in: true, player_state: 2 } : item
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Tournament settings change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentSettingsChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setTournamentNotif(prevStartedMatches => {
                        return prevStartedMatches.map(tournament => {
                            if (tournament.id === parsedData.tournamentID) {
                                return { ...tournament, max_stage: 2, };
                            }
                            return tournament;
                        });
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}