export const getTimeStringFromSecond = (seconds: number) => {
  if (seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const secondsString = String(remainingSeconds).padStart(2, "0");

    return `${minutes}:${secondsString}`;
  } else {
    return "";
  }
};
