import tournamentInfoData from '../../../../../data/tournamentInfoData.json'
import { Box, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, IconButton, } from '@chakra-ui/react'
import { FaCircleInfo } from "react-icons/fa6";

type OverviewTournamentModeType = {
    IsDoubleElimination: boolean,
    isChampionship: boolean
}

export const OverviewTournamentMode = ({ IsDoubleElimination, isChampionship }: OverviewTournamentModeType) => {
    return (
        <>
            <Box className="tournament-overview-header-banner">
                Tournament Mode
            </Box>

            <Box
                textAlign={"center"}
                textColor={"#392516"}
                fontSize={"18px"}
                p={2}
            >
                {
                    isChampionship ?
                        "Championship" :
                        IsDoubleElimination ?
                            " Knockout" :
                            " Single Elimination"
                }
                <Popover placement='top'>
                    <PopoverTrigger>
                        <IconButton
                            boxSize={"18px"}
                            ms={"1px"}
                            colorScheme={"none"}
                            textColor={"#392516"}
                            aria-label='show-info'
                            icon={<FaCircleInfo fontSize={"18px"} />}
                        />
                    </PopoverTrigger>
                    <PopoverContent
                        backgroundColor={"#bee3f8"}
                    >
                        <PopoverHeader fontWeight={"700"} borderColor={"#dcb88a"}>
                            {
                                isChampionship ?
                                    " Championship" :
                                    IsDoubleElimination ?
                                        " Knockout" :
                                        " Single Elimination"
                            }
                        </PopoverHeader>

                        <PopoverBody>
                            {
                                isChampionship ?
                                    tournamentInfoData.modes.championship.description :
                                    IsDoubleElimination ?
                                        tournamentInfoData.modes.knockout.description :
                                        tournamentInfoData.modes.single_elimination.description
                            }
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Box>
        </>
    )
}