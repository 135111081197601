import { useEffect } from "react";
import { useSync } from "../../../context/SyncContext";
import { SocketType } from "../../../utils/types/types";
import { getUsernameByIndex } from "../../../utils/functions/getUsernameByIndex";
import { useGame } from "../../../context/GameContext";

export const PlayerLostConnectionNotification = (socket: SocketType) => {
    const { gameAssets } = useGame()
    const { players } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "LostConnection") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);

            if (parsedData) {
                const turnIndex = parsedData.turnIndex
                const username = getUsernameByIndex(players, turnIndex)

                const imgElement = document.createElement("img")
                imgElement.src = gameAssets.playerLostConnectionIcon
                imgElement.alt = "leave-icon"
                imgElement.style.width = "10%"
                imgElement.classList.add("me-2")

                const divElement = document.createElement("div")
                divElement.classList.add("player-notification")
                divElement.classList.add("player-disconnect-notif")

                const textElement = document.createElement("span")
                textElement.textContent = `${username} lost connection`
                textElement.style.textAlign = "center"

                divElement.appendChild(imgElement)
                divElement.appendChild(textElement)

                const playerElement = document.getElementById(`player-${turnIndex}`)

                if (playerElement) {
                    playerElement.appendChild(divElement)

                    const timeout = setTimeout(() => {
                        playerElement.removeChild(divElement)
                    }, 7500);

                    return () => clearTimeout(timeout);
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);
}