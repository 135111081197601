import { useContext, createContext, ReactNode, useState } from "react";


type DashboardContextType = {
    tournamentList: any[];
    setTournamentList: (value: any) => void;
    questList: any[];
    setQuestList: (value: any) => void;
    tournamentNotif: any[];
    setTournamentNotif: (value: any) => void;
    dailyTicketNumber: number;
    setDailyTicketNumber: (value: number) => void;
};

const DashboardContext = createContext<DashboardContextType>({
    tournamentList: [],
    setTournamentList: () => { },
    questList: [],
    setQuestList: () => { },
    tournamentNotif: [],
    setTournamentNotif: () => { },
    dailyTicketNumber: 0,
    setDailyTicketNumber: () => { }
});

const DashboardProvider: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode; }) => {
    const [tournamentList, setTournamentList] = useState<any>([])
    const [questList, setQuestList] = useState<any>([])

    const [tournamentNotif, setTournamentNotif] = useState<any>([])

    const [dailyTicketNumber, setDailyTicketNumber] = useState(0)

    const results: DashboardContextType = {
        tournamentList,
        setTournamentList,
        questList,
        setQuestList,
        tournamentNotif,
        setTournamentNotif,
        dailyTicketNumber,
        setDailyTicketNumber
    };

    return (
        <DashboardContext.Provider value={results}>
            {children}
        </DashboardContext.Provider>
    );
};

const useDashboard = () => useContext(DashboardContext);

export { DashboardProvider, useDashboard };