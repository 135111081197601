import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"
import { FaCalendarAlt, FaRegClock } from "react-icons/fa"
import { getDateAndTime } from "../../../../../utils/constants/getDateAndTime"

type CardBannerType = {
    BannerAsset: string,
    TicketAsset: string,
    StartTime: string,
    IsPrivate: boolean
}

export const CardBanner = ({ BannerAsset, TicketAsset, StartTime, IsPrivate }: CardBannerType) => {
    const { dashboardAssets } = useGame()

    const { time, date } = getDateAndTime(StartTime)

    return (
        <Box pos={"relative"} textAlign={"center"}>
            <Image
                src={BannerAsset}
                alt='tournament-banner'
                borderRadius={"10px 10px 0px 0px"}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
            />
            <Box className="tournament-card-time">
                {time}
                <FaRegClock className='ms-2' />
            </Box>
            <Box className='tournament-card-deadline'>
                {date}
                <FaCalendarAlt className="ms-2" />
            </Box>
            {
                IsPrivate ?
                    <Box className='tournament-card-cost-ticket'>
                        <Image
                            src={TicketAsset}
                            h={"35%"}
                            alt='ticket'
                        />

                    </Box> :
                    <Box className='tournament-card-cost'>
                        <Image
                            src={dashboardAssets.freeJoinBanner}
                            h={"45%"}
                            alt='free-join-banner'
                        />
                    </Box>
            }
        </Box>
    )
}