export const getClassByTileNumber = (value: number) => {
  if (value === 5 || value === 9 || value === 6 || value === 8) {
    return "semi-bold-numbers";
  } else if (value === 4 || value === 10) {
    return "regular-numbers";
  } else if (value === 3 || value === 11) {
    return "thin-numbers";
  } else if (value === 2 || value === 12) {
    return "very-thin-numbers";
  } else {
    return null;
  }
};
