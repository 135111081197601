import { Box } from "@chakra-ui/react"
import { useSync } from "../../../context/SyncContext"
import { Timer } from "../Timer/Timer"

export const ThiefMoveIndicator = () => {
    const { gameData } = useSync()

    if (gameData.Status === 6) {
        return (
            <Box className="custom-progress-bar">
                <Box me={"1vw"}>
                    Thief Move
                </Box>
                <Timer
                    activatedTime={gameData.RobberTime.toString()}
                    timeLimit={20}
                />
            </Box>
        )
    } else {
        return null
    }
}