import { useState, useContext, createContext, ReactNode, useEffect } from "react";
import { requestWakeLock } from "../wakeLock/requestWakeLock";
import { releaseWakeLock } from "../wakeLock/releaseWakeLock";

type GameContextType = {
    gameID: number;
    setGameID: (value: any) => void;
    isMobile: boolean;
    dashboardAssets: any;
    setDashboardAssets: (value: any) => void;
    gameAssets: any;
    setGameAssets: (value: any) => void;
    soundEffects: any;
    setSoundEffects: (value: any) => void;
    animations: any;
    setAnimations: (value: any) => void;
    isAssetLoading: boolean;
    setIsAssetLoading: (value: boolean) => void;
    isReconnect: boolean;
    setIsReconnect: (value: boolean) => void;
    isSpectator: boolean;
    setIsSpectator: (value: boolean) => void;
    sfxEnabled: boolean;
    setSfxEnabled: (value: boolean) => void;
    sfxVolume: number;
    setSfxVolume: (value: number) => void;
    directJoinGameID: number | null;
    setDirectJoinGameID: (value: any) => void;
    emojiSoundEnabled: boolean;
    setEmojiSoundEnabled: (value: boolean) => void;
    disableTrade: boolean;
    setDisableTrade: (value: boolean) => void;
    disableBuildConfirm: boolean;
    setDisableBuildConfirm: (value: boolean) => void;
    enableCounterTrade: boolean;
    setEnableCounterTrade: (value: boolean) => void;
    haveCounterTrade: boolean;
    setHaveCounterTrade: (value: boolean) => void;
    avatarID: string;
    setAvatarID: (value: string) => void;
    tournamentDirectJoinGameID: number | null;
    setTournamentDirectJoinGameID: (value: any) => void;
    reConnectGameID: number;
    setReConnectGameID: (value: number) => void;
    confirmData: { showCost: boolean, costType: string, settlePosition: { xStart: number, yStart: number, xEnd: number | null, yEnd: number | null } } | null;
    setConfirmData: (value: any) => void;
};

const GameContext = createContext<GameContextType>({
    gameID: -1,
    isMobile: false,
    setGameID: () => { },
    dashboardAssets: {},
    setDashboardAssets: () => { },
    gameAssets: {},
    setGameAssets: () => { },
    soundEffects: {},
    setSoundEffects: () => { },
    animations: {},
    setAnimations: () => { },
    isAssetLoading: true,
    setIsAssetLoading: () => { },
    isReconnect: false,
    setIsReconnect: () => { },
    isSpectator: false,
    setIsSpectator: () => { },
    sfxEnabled: true,
    setSfxEnabled: () => { },
    sfxVolume: 0.5,
    setSfxVolume: () => { },
    directJoinGameID: null,
    setDirectJoinGameID: () => { },
    emojiSoundEnabled: true,
    setEmojiSoundEnabled: () => { },
    disableTrade: false,
    setDisableTrade: () => { },
    disableBuildConfirm: false,
    setDisableBuildConfirm: () => { },
    enableCounterTrade: false,
    setEnableCounterTrade: () => { },
    haveCounterTrade: false,
    setHaveCounterTrade: () => { },
    avatarID: "0",
    setAvatarID: () => { },
    tournamentDirectJoinGameID: null,
    setTournamentDirectJoinGameID: () => { },
    reConnectGameID: 0,
    setReConnectGameID: () => { },
    confirmData: null,
    setConfirmData: () => { }
});

const GameProvider: React.FC<{ children: ReactNode }> = ({ children, }: { children: ReactNode; }) => {
    const [gameID, setGameID] = useState<number>(-1);

    const [isMobile, setIsMobile] = useState(false)

    const [dashboardAssets, setDashboardAssets] = useState<any>({})
    const [gameAssets, setGameAssets] = useState<any>({})
    const [soundEffects, setSoundEffects] = useState<any>({})
    const [animations, setAnimations] = useState<any>({})
    const [isAssetLoading, setIsAssetLoading] = useState(true)

    const [isReconnect, setIsReconnect] = useState<boolean>(false)

    const [isSpectator, setIsSpectator] = useState<boolean>(false)

    const [avatarID, setAvatarID] = useState(localStorage.getItem("avatar") || "0")

    const [sfxEnabled, setSfxEnabled] = useState(true)
    const [sfxVolume, setSfxVolume] = useState(0.5)

    const [disableTrade, setDisableTrade] = useState(false)
    const [disableBuildConfirm, setDisableBuildConfirm] = useState(() => {
        const savedValue = localStorage.getItem('disableBuildConfirm');
        return savedValue !== null ? JSON.parse(savedValue) : false;
    })
    const [enableCounterTrade, setEnableCounterTrade] = useState(false)
    const [haveCounterTrade, setHaveCounterTrade] = useState(false)

    const [emojiSoundEnabled, setEmojiSoundEnabled] = useState(true)

    const [directJoinGameID, setDirectJoinGameID] = useState<number | null>(null)
    const [tournamentDirectJoinGameID, setTournamentDirectJoinGameID] = useState<number | null>(null)
    const [reConnectGameID, setReConnectGameID] = useState(0)

    const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

    const [confirmData, setConfirmData] = useState<{
        showCost: boolean,
        costType: string,
        settlePosition: {
            xStart: number,
            yStart: number,
            xEnd: number | null,
            yEnd: number | null
        }
    } | null>(null)

    // Reset some states if user leaves the game screen
    useEffect(() => {
        if (gameID < 0) {
            setConfirmData(null)
            setEnableCounterTrade(false)
            setHaveCounterTrade(false)
        }

        if (disableBuildConfirm) {
            setConfirmData(null)
        }
    }, [gameID, disableBuildConfirm])

    // Detect if user uses mobile device
    useEffect(() => {
        function detectMob() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        }

        setIsMobile(detectMob());
    }, []);

    // Handle if user uses back button or similar method to change root
    useEffect(() => {
        const handlePopState = () => {
            setGameID(-1)
            setIsSpectator(false)
            setIsReconnect(false)
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    // Handle wake lock api usage
    useEffect(() => {
        requestWakeLock(setWakeLock);

        return () => {
            releaseWakeLock(wakeLock, setWakeLock);
        };
    }, [])

    const results: GameContextType = {
        gameID,
        setGameID,
        isMobile,
        dashboardAssets,
        setDashboardAssets,
        gameAssets,
        setGameAssets,
        soundEffects,
        setSoundEffects,
        animations,
        setAnimations,
        isAssetLoading,
        setIsAssetLoading,
        isReconnect,
        setIsReconnect,
        isSpectator,
        setIsSpectator,
        sfxEnabled,
        setSfxEnabled,
        sfxVolume,
        setSfxVolume,
        directJoinGameID,
        setDirectJoinGameID,
        emojiSoundEnabled,
        setEmojiSoundEnabled,
        disableTrade,
        setDisableTrade,
        disableBuildConfirm,
        setDisableBuildConfirm,
        enableCounterTrade,
        setEnableCounterTrade,
        haveCounterTrade,
        setHaveCounterTrade,
        avatarID,
        setAvatarID,
        tournamentDirectJoinGameID,
        setTournamentDirectJoinGameID,
        reConnectGameID,
        setReConnectGameID,
        confirmData,
        setConfirmData
    };

    return (
        <GameContext.Provider value={results}>
            {children}
        </GameContext.Provider>
    );
};

const useGame = () => useContext(GameContext);

export { GameProvider, useGame };