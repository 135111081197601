import { isSettlePosition } from "./isSettlePosition";
// Check if the settle is minimum 2 distance away from the another settlement.

export const isSettleDistanceValid = (
  settlePositions: any[],
  x: number,
  y: number
) => {
  // Check left and right
  if (!settlePositions) {
    return false;
  }
  if (x > 0) {
    if (isSettlePosition(settlePositions, x - 1, y)) {
      return false;
    }
  }

  if (isSettlePosition(settlePositions, x + 1, y)) {
    return false;
  }

  // Check up and down according to coordinate
  if ((y === 0 && (x + y) % 2 === 0) || (y === 1 && (x + y) % 2 === 1)) {
    // Check x+1, y+1
    if (isSettlePosition(settlePositions, x + 1, y + 1)) {
      return false;
    }
  } else if ((y === 1 && (x + y) % 2 === 0) || (y === 2 && (x + y) % 2 === 1)) {
    // Check x-1, y-1
    if (isSettlePosition(settlePositions, x - 1, y - 1)) {
      return false;
    }
  } else if (y === 2 && (x + y) % 2 === 0) {
    // Check x, y+1
    if (isSettlePosition(settlePositions, x, y + 1)) {
      return false;
    }
  } else if (y === 3 && (x + y) % 2 === 1) {
    // Check x, y-1
    if (isSettlePosition(settlePositions, x, y - 1)) {
      return false;
    }
  } else if ((y === 3 && (x + y) % 2 === 0) || (y === 4 && (x + y) % 2 === 1)) {
    // Check x-1, y+1
    if (isSettlePosition(settlePositions, x - 1, y + 1)) {
      return false;
    }
  } else if ((y === 4 && (x + y) % 2 === 0) || (y === 5 && (x + y) % 2 === 1)) {
    // Check x+1, y-1
    if (isSettlePosition(settlePositions, x + 1, y - 1)) {
      return false;
    }
  }

  return true;
};
