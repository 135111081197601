import { useEffect } from "react";
import { SocketType } from "../utils/types/types";
import { useDashboard } from "../context/DashboardContext";

export const Tournament = (socket: SocketType | null,) => {
    const { setTournamentList, tournamentList, setDailyTicketNumber } = useDashboard()
    // Handle creating tournament
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentCreated") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentList(prev => {
                        if (!Array.isArray(prev)) {
                            prev = [];
                        }
                        return [
                            ...prev,
                            {
                                Capacity: parsedData.capacity,
                                Class: parsedData.class,
                                CurrentPlayers: 0,
                                ID: parsedData.id,
                                BannerImage: parsedData.bannerImage,
                                TicketImage: parsedData.ticketImage,
                                IsUserSignedUp: false,
                                MaxStage: parsedData.class === 4 ? 300 : parsedData.class === 2 ? 64 : 16,
                                IsClosed: parsedData.isClosed,
                                Name: parsedData.name,
                                Prize: parsedData.prize,
                                Stage: 1,
                                StartTime: parsedData.start_time,
                                State: 1,
                                UniqueSignupID: null,
                                UserCheckedIn: false,
                                Winners: [],
                                rank: 0
                            }
                        ];
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Deleting Tournament
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentDeleted") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentList(tournamentList.filter((tournament) => tournament.ID !== parsedData.id));
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    //  Handle Tournaments Status Changes
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentStatusChange") {
                if (tournamentList) {
                    const newTournaments = [...tournamentList]
                    const parsedData = JSON.parse(socket.lastJsonMessage.data)

                    const tournament = newTournaments?.find((tournament) => tournament.ID === parsedData.id);

                    if (tournament) {
                        tournament.State = parsedData.newStatus;
                        setTournamentList(newTournaments);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Registration Cancel
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentSignupCancel") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    const tournament = tournamentList.find((tournament) => tournament.ID === parsedData.id)

                    if (tournament && tournament.Name === "Daily Tournament") {
                        setDailyTicketNumber(prevDailyTicketNumber => {
                            const newTicketNumber = prevDailyTicketNumber + 1;

                            setTournamentList(prevItems =>
                                prevItems.map(item =>
                                    item.ID === parsedData.id ?
                                        {
                                            ...item,
                                            IsUserSignedUp: false,
                                            CurrentPlayers: item.CurrentPlayers - 1,
                                            ticket_own: newTicketNumber > 0
                                        } : item.Name === "Daily Tournament" ?
                                            {
                                                ...item,
                                                ticket_own: newTicketNumber > 0
                                            } : item
                                )
                            );

                            return newTicketNumber;
                        });
                    } else {
                        setTournamentList(prevItems =>
                            prevItems.map(item =>
                                item.ID === parsedData.id ?
                                    {
                                        ...item,
                                        IsUserSignedUp: false,
                                        CurrentPlayers: item.CurrentPlayers - 1,
                                        ticket_own: true
                                    } : item
                            )
                        );
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Check-out
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentCheckinCancel") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentList(prevItems =>
                        prevItems.map(item =>
                            item.ID === parsedData.id ? { ...item, UserCheckedIn: false } : item
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Registration
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentSignup") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    const tournament = tournamentList.find((tournament) => tournament.ID === parsedData.id)

                    if (tournament && tournament.Name === "Daily Tournament") {
                        setDailyTicketNumber(prevDailyTicketNumber => {
                            const newTicketNumber = prevDailyTicketNumber - 1;

                            // Update tournament list with the new ticket number
                            setTournamentList(prevItems =>
                                prevItems.map(item =>
                                    item.ID === parsedData.id
                                        ? {
                                            ...item,
                                            IsUserSignedUp: true,
                                            CurrentPlayers: item.CurrentPlayers + 1,
                                            ticket_own: newTicketNumber > 0
                                        }
                                        : item.Name === "Daily Tournament" ?
                                            {
                                                ...item,
                                                ticket_own: newTicketNumber > 0
                                            } : item

                                )
                            );

                            return newTicketNumber;
                        });
                    } else {
                        setTournamentList(prevItems =>
                            prevItems.map(item =>
                                item.ID === parsedData.id
                                    ? {
                                        ...item,
                                        IsUserSignedUp: true,
                                        CurrentPlayers: item.CurrentPlayers + 1,
                                        ticket_own: false
                                    }
                                    : item
                            )
                        );
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Check-in
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentCheckin") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setTournamentList(prevItems =>
                        prevItems.map(item =>
                            item.ID === parsedData.id ? { ...item, UserCheckedIn: true } : item
                        )
                    );
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Tournament settings change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TournamentSettingsChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setTournamentList(prevStartedMatches => {
                        return prevStartedMatches.map(tournament => {
                            if (tournament.id === parsedData.tournamentID) {
                                return { ...tournament, Capacity: 16, Class: 1, MaxStage: 2 };
                            }
                            return tournament;
                        });
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}