import { useSync } from "../context/SyncContext"
import { useGame } from "../context/GameContext"
import { Board } from "../components/Game/Board/Board"
import { Players } from "../components/Game/Players/Players"
import { RobberMoveNotification } from "../components/Game/Notifications/RobberMoveNotification"
import { DevCardUsageNotification } from "../components/Game/Notifications/DevCardUsageNotification"
import { RoadBuildingIndicator } from "../components/Game/Indicators/RoadBuildingIndicator"
import { PlayerTradeNotifications } from "../components/Game/Notifications/PlayerTradeNotifications"
import { MarketTradeNotifications } from "../components/Game/Notifications/MarketTradeNotifications"
import { WinnerLobby } from "../components/Game/Lobby/WinnerLobby/WinnerLobby"
import { ThiefMoveIndicator } from "../components/Game/Indicators/ThiefMoveIndicator"
import { PlayerList } from "../socket/PlayerList"
import { RemainingResources } from "../socket/RemainingResources"
import { Resources } from "../socket/Resources"
import { GameData } from "../socket/GameData"
import { RoadPositions } from "../socket/RoadPositions"
import { SettlePositions } from "../socket/SettlePositions"
import { TotalResCards } from "../socket/TotalResCards"
import { ArmyPoints } from "../socket/ArmyPoints"
import { LongestRoads } from "../socket/LongestRoads"
import { RobberPosition } from "../socket/RobberPosition"
import { Errors } from "../socket/Errors"
import { TradeData } from "../socket/TradeData"
import { LongestRoadNotification } from "../components/Game/Notifications/LongestRoadNotification"
import { VictoryPoints } from "../socket/VictoryPoints"
import { TotalDevCards } from "../socket/TotalDevCards"
import { DevCards } from "../socket/DevCards"
import { Quests } from "../socket/Quests"
import { RemainingDevCards } from "../socket/RemainingDevCards"
import { GameLogs } from "../components/Game/GameLogs/GameLogs"
import { LargestArmyNotification } from "../components/Game/Notifications/LargestArmyNotification"
import { DiceNotification } from "../components/Game/Notifications/DiceNotification"
import { CardDiscardNotification } from "../components/Game/Notifications/CardDiscardNotification"
import { EmojiNotification } from "../components/Game/Notifications/EmojiNotification"
import { VictoryPointAnimation } from "../components/Game/Animations/VictoryPointAnimation"
import { LandscapeWarning } from "../components/Dashboard/General/LandscapeWarning"
import { TurnNotification } from "../components/Game/Notifications/TurnNotification"
import { useSocket } from "../useSocket"
import { SpectatorSettings } from "../components/Spectator/SpectatorSettings"
import { SpectatorBank } from "../components/Spectator/SpectatorBank"
import { getGameBackground } from "../utils/assets/getGameBackground"
import { TournamentJoin } from "../socket/TournamentJoin"
import { PlayerLeaveNotification } from "../components/Game/Notifications/PlayerLeaveNotification"
import { PlayerLostConnectionNotification } from "../components/Game/Notifications/PlayerLostConnectionNotification"
import { Box } from "@chakra-ui/react"

export const Spectator = () => {
    const { gameID, gameAssets } = useGame()
    const { gameData } = useSync()

    const socket = useSocket(gameID)

    GameData(gameID, socket)
    PlayerList(socket)
    RemainingResources(socket)
    RemainingDevCards(socket)
    Resources(socket)
    DevCards(socket)
    RoadPositions(socket)
    VictoryPoints(socket)
    TotalDevCards(socket)
    SettlePositions(socket)
    TotalResCards(socket)
    ArmyPoints(socket)
    LongestRoads(socket)
    DiceNotification(socket)
    TurnNotification(socket)
    DevCardUsageNotification(socket)
    RobberPosition(socket)
    TradeData(socket)
    Errors(socket)
    Quests(socket)
    LargestArmyNotification(socket)
    LongestRoadNotification(socket)
    CardDiscardNotification(socket)
    VictoryPointAnimation(socket)
    EmojiNotification(socket)
    MarketTradeNotifications(socket)
    PlayerTradeNotifications(socket)
    PlayerLeaveNotification(socket)
    PlayerLostConnectionNotification(socket)
    TournamentJoin(socket)

    return (
        <Box
            id="game-screen"
            className="game-background"
            backgroundImage={getGameBackground(localStorage.getItem("background"), gameAssets)}
        >
            <Board socket={socket} />
            {
                !gameData.GameOver &&
                <>
                    <Players socket={socket} />
                    <RobberMoveNotification socket={socket} />
                    <SpectatorBank />
                    <RoadBuildingIndicator />
                    <ThiefMoveIndicator />
                    <GameLogs socket={socket} />
                    <SpectatorSettings />
                </>
            }
            {gameData.GameOver && <WinnerLobby />}
            <LandscapeWarning />
        </Box>
    )
}