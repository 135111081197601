export const getGameBackground = (background: string | null, assets: any) => {
  if (background === "sea") {
    return assets.blueBG;
  } else if (background === "table") {
    return assets.woodBG;
  } else if (background === "hex") {
    return assets.hexaBG;
  } else if (background === "paper") {
    return assets.paperBG;
  } else if (background === "royal") {
    return assets.royalBG;
  } else {
    return assets.blueBG;
  }
};
