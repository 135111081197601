const API_URL = import.meta.env.VITE_API_URL

export const CreateJWT = async (address: `0x${string}` | undefined, signature: `0x${string}` | undefined) => {
    try {
        const response = await fetch(
            API_URL + `/auth/verify`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ address, signature })
            },
        );

        if (!response.ok) {
            throw new Error("Failed to create token.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error creating token:", error);
        return null;
    }
};

export const CreateUsername = async (username: string) => {
    try {
        const response = await fetch(
            API_URL + `/profile/set-username`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ username }),
            },

        );

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error creating username:", error);
        return null;
    }
};

export const GetUsername = async () => {
    try {
        const response = await fetch(
            API_URL + `/profile/get-username`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get username.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting username:", error);
        return null;
    }
};

export const GetVerifyToken = async (abortController: AbortController, deviceType: string) => {
    try {
        const response = await fetch(
            API_URL + `/refresh/valid`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Device-Type": deviceType
                },
                credentials: "include",
                signal: abortController.signal
            },
        );

        if (!response.ok) {
            throw new Error("Failed to verify token.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error verifying token:", error);
        return null;
    }
};


export const GetTokenByRefresh = async (abortController: AbortController) => {
    try {
        const response = await fetch(
            API_URL + `/refresh`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                signal: abortController.signal
            },
        );

        if (!response.ok) {
            throw new Error("Failed to verify token.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error verifying token:", error);
        return null;
    }
};