import { Box, Button, useToast } from "@chakra-ui/react"
import { GetTimeDifference } from "./GetTimeDifference"
import { CreateTournamentCheckin, CreateTournamentCheckinCancel } from "../../../../../requests/TournamentRequests"

type CheckInStateType = {
    IsUserCheckedIn: boolean,
    startTime: string,
    tournamentID: number,
}

export const CheckInState = ({ IsUserCheckedIn, startTime, tournamentID }: CheckInStateType) => {
    const toast = useToast()

    const handleCheckin = async () => {
        const response = await CreateTournamentCheckin(tournamentID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-in for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    const handleCheckinCancel = async () => {
        const response = await CreateTournamentCheckinCancel(tournamentID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-out for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    return (
        <Box>
            <Box p={"0.25vw"}>
                {
                    IsUserCheckedIn ?
                        "Tournament will start in" :
                        "Check-in to confirm your participation"
                }
            </Box>
            <Box p={"0.25vw"}>
                {
                    !IsUserCheckedIn ?
                        <Button
                            w={"10vw"}
                            h={"2vw"}
                            fontSize={"1.5vw"}
                            backgroundColor={"#69be55"}
                            border={"0.2vw solid #1C6824"}
                            colorScheme={"green"}
                            onClick={handleCheckin}
                        >
                            Check-in
                        </Button> :
                        <Button
                            w={"10vw"}
                            height={"2vw"}
                            fontSize={"1.5vw"}
                            backgroundColor={"#c97f64"}
                            border={"0.2vw solid #A72C01"}
                            colorScheme={"red"}
                            onClick={handleCheckinCancel}
                        >
                            Check-out
                        </Button>
                }
            </Box>
            <GetTimeDifference finishTime={startTime} />
        </Box>
    )
}