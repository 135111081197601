import { IconButton } from "@chakra-ui/react"

export const DashboardCloseButton = ({ onClose }: { onClose: () => void }) => {
    return (
        <IconButton
            position={"absolute"}
            right={0}
            top={1}
            zIndex={1}
            alignSelf={"self-end"}
            colorScheme={"none"}
            background={"transparent"}
            aria-label='close-button'
            onClick={onClose}
            icon={<div className='close-button'></div>}
        />
    )
}