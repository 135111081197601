export const getPrevStraightRoadAsset = (
  turnIndex: number,
  skinChoice: string
) => {
  if (skinChoice === "Default") {
    if (turnIndex === 0) {
      return "default-straight-red-road-prev";
    } else if (turnIndex === 1) {
      return "default-straight-blue-road-prev";
    } else if (turnIndex === 2) {
      return "default-straight-pink-road-prev";
    } else if (turnIndex === 3) {
      return "default-straight-grey-road-prev";
    } else {
      return null;
    }
  } else if (skinChoice === "Halloween") {
    if (turnIndex === 0) {
      return "halloween-straight-red-road-prev";
    } else if (turnIndex === 1) {
      return "halloween-straight-blue-road-prev";
    } else if (turnIndex === 2) {
      return "halloween-straight-pink-road-prev";
    } else if (turnIndex === 3) {
      return "halloween-straight-grey-road-prev";
    } else {
      return null;
    }
  } else if (skinChoice === "Viking") {
    if (turnIndex === 0) {
      return "viking-straight-red-road-prev";
    } else if (turnIndex === 1) {
      return "viking-straight-blue-road-prev";
    } else if (turnIndex === 2) {
      return "viking-straight-pink-road-prev";
    } else if (turnIndex === 3) {
      return "viking-straight-grey-road-prev";
    } else {
      return null;
    }
  }
};

export const getPrevIsometricRoadAsset = (
  turnIndex: number,
  skinChoice: string
) => {
  if (skinChoice === "Default") {
    if (turnIndex === 0) {
      return "default-isometric-red-road-prev";
    } else if (turnIndex === 1) {
      return "default-isometric-blue-road-prev";
    } else if (turnIndex === 2) {
      return "default-isometric-pink-road-prev";
    } else if (turnIndex === 3) {
      return "default-isometric-grey-road-prev";
    } else {
      return null;
    }
  } else if (skinChoice === "Halloween") {
    if (turnIndex === 0) {
      return "halloween-isometric-red-road-prev";
    } else if (turnIndex === 1) {
      return "halloween-isometric-blue-road-prev";
    } else if (turnIndex === 2) {
      return "halloween-isometric-pink-road-prev";
    } else if (turnIndex === 3) {
      return "halloween-isometric-grey-road-prev";
    } else {
      return null;
    }
  } else if (skinChoice === "Viking") {
    if (turnIndex === 0) {
      return "viking-isometric-red-road-prev";
    } else if (turnIndex === 1) {
      return "viking-isometric-blue-road-prev";
    } else if (turnIndex === 2) {
      return "viking-isometric-pink-road-prev";
    } else if (turnIndex === 3) {
      return "viking-isometric-grey-road-prev";
    } else {
      return null;
    }
  }
};
