import { Button } from "@chakra-ui/react"
import { CreateStartGame } from "../../../../requests/LobbyRequests"
import { useGame } from "../../../../context/GameContext"
import { FaPlay } from "react-icons/fa";
import { useSync } from "../../../../context/SyncContext";
import { useEffect, useState } from "react";

export const StartButton = () => {
    const { gameID } = useGame()
    const { myGameData, players, gameData } = useSync()

    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (players && gameData?.RoomLimit) {
            const readyCount = players.filter(player => player.readyStatus === true && !player.isOwner).length;
            if (readyCount === gameData.RoomLimit - 1) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(true);
        }
    }, [players, gameData])

    const handleStart = async () => {
        await CreateStartGame(gameID)
    }

    if (myGameData && myGameData.isOwner) {
        return (
            <Button
                leftIcon={<FaPlay />}
                mr={"1vw"}
                ml={"auto"}
                width={"15vw"}
                height={"4vw"}
                fontSize={"1.5vw"}
                backgroundColor={"#8DC222"}
                borderRadius={"0.5vw"}
                border={"0.1vw solid #8fb65d"}
                colorScheme='green'
                isDisabled={disabled}
                onClick={handleStart}
            >
                Start the Game
            </Button>
        )
    }

    return null
}