export const getDeadline = (deadline: string) => {
  const now = new Date();
  const diff = new Date(deadline) - now;

  if (diff <= 0) {
    return "Deadline passed";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return `${days}d ${hours}h ${minutes}m`;
};
