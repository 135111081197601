import { Box, Image } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, } from '@chakra-ui/react'
import { useGame } from "../../../../../context/GameContext"

type BracketTableType = {
    players: any[],
    matchStatus: number,
    prizePool: number[],
    duration: number
}

const tableBgColor = "#75583e"
const tableHeaderBgColor = "#c64700"
const tableBodyBgColor = "#d9b891"
const tableBodyTextColor = "#754e37"
const tablePrizeColBgColor = "#4d983b"

export const TournamentBracketFinalTable = ({ players, matchStatus, prizePool, duration }: BracketTableType) => {
    const { dashboardAssets } = useGame()

    const sortedPlayers = players && players.sort((playerA, playerB) => playerB.vp - playerA.vp)

    return (
        <Box
            backgroundColor={tableBgColor}
            filter={"drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5))"}
            borderRadius={"15px"}
            height={"min-content"}
            w={"350px"}
            mt={"auto"}
            mb={"auto"}
        >
            <Box display={"flex"} alignItems={"center"} fontSize={"20px"} w={"100%"} textColor={"white"} pe={5} ps={5} pt={3} pb={2}>
                <Box alignSelf={"start"}>Match Final</Box>
                <Box ml={"auto"} display={"flex"} alignItems={"center"}>
                    <Image src={dashboardAssets.personIcon} me={2} alt='person-icon' w={"40%"} />
                    {players && players.length}
                </Box>
            </Box>

            <TableContainer pe={"7px"} ps={"7px"}>
                <Table size={"sm"} >
                    <Thead backgroundColor={tableHeaderBgColor} >
                        <Tr>
                            <Th color={"white"}></Th>
                            <Th color={"white"}>Player</Th>
                            <Th color={"white"}>VP</Th>
                            <Th color={"white"} backgroundColor={tablePrizeColBgColor}>Prize</Th>
                        </Tr>
                    </Thead>
                    <Tbody backgroundColor={tableBodyBgColor} textColor={tableBodyTextColor} >
                        <Tr>
                            <Td>1st</Td>
                            <Td>{sortedPlayers && sortedPlayers[0].username}</Td>
                            <Td>{sortedPlayers && sortedPlayers[0].vp}</Td>
                            <Td textColor={tablePrizeColBgColor}>${prizePool && prizePool[0]}</Td>
                        </Tr>
                        <Tr>
                            <Td>2nd</Td>
                            <Td>{sortedPlayers && sortedPlayers[1].username}</Td>
                            <Td>{sortedPlayers && sortedPlayers[1].vp}</Td>
                            <Td textColor={tablePrizeColBgColor}>${prizePool && prizePool[1]}</Td>
                        </Tr>
                        <Tr>
                            <Td>3rd</Td>
                            <Td>{sortedPlayers && sortedPlayers[2].username}</Td>
                            <Td>{sortedPlayers && sortedPlayers[2].vp}</Td>
                            <Td textColor={tablePrizeColBgColor}>${prizePool && prizePool[2]}</Td>
                        </Tr>
                        <Tr>
                            <Td>4th</Td>
                            <Td>{sortedPlayers && sortedPlayers[3].username}</Td>
                            <Td>{sortedPlayers && sortedPlayers[3].vp}</Td>
                            <Td textColor={tablePrizeColBgColor}>${prizePool && prizePool[3]}</Td>
                        </Tr>
                    </Tbody>
                </Table>
                <Box backgroundColor={matchStatus === 1 ? "#72a8da" : "#6A9148"} textAlign={"center"} mb={4}>
                    {matchStatus === 1 && "In-Game"}
                    {matchStatus === 2 && "Completed"}
                </Box>
                {
                    matchStatus === 2 &&
                    <Box textColor={"white"} textAlign={"center"} mt={2} mb={2}>
                        Duration: {duration.toFixed(0)} min
                    </Box>
                }
            </TableContainer>
        </Box>
    )
}