const API_URL = import.meta.env.VITE_API_URL

export const GetBoardConfig = async (roomId: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/board/${roomId}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get board config.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting board config:", error);
        return null;
    }
};

export const GetMyGameData = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/user-own-metadata/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get my game data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting my game data:", error);
        return null;
    }
};

export const GetPlayers = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/player-list/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get players.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting players:", error);
        return null;
    }
};

export const GetRoomData = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/room-data/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get game data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting game data:", error);
        return null;
    }
};

export const GetBuildData = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/game/builds/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get build data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting build data:", error);
        return null;
    }
};

export const GetResource = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/game/resources/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get resource data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting resource data:", error);
        return null;
    }
};

export const GetTotalResource = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/game/resources-total/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get total resource data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting total resource data:", error);
        return null;
    }
};

export const GetRemainingCards = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/game/resources-remaining/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get remaining card data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting remaining card data:", error);
        return null;
    }
};

export const GetMyTradeRatio = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/game/trade-ratio/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get trade ratio data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting trade ratio data:", error);
        return null;
    }
};

export const GetActiveTrade = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/sync/trade/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get active trade data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting active trade data:", error);
        return null;
    }
};

export const GetAuxSyncData = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/sync/aux-data/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get aux data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting aux data:", error);
        return null;
    }
};

export const GetMainSyncData = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/sync/main-data/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get aux data.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting aux data:", error);
        return null;
    }
};

export const GetRoomStats = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/stats-general/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get room stats");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting stats:", error);
        return null;
    }
};

export const GetUserStats = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/stats-users/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get user stats");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting stats:", error);
        return null;
    }
};