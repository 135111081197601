export const getStageTimesInSeconds = (stage: number) => {
  if (stage === 1 || stage === 4) {
    return 120;
  } else if (stage === 2) {
    return 30;
  } else if (stage === 3) {
    return 10;
  } else {
    return 120;
  }
};
