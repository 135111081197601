export const getShipAsset = (
  resourceIndex: number | undefined,
  assets: any
) => {
  if (resourceIndex === 0) {
    return assets.anyPort;
  } else if (resourceIndex === 1) {
    return assets.woodPort;
  } else if (resourceIndex === 2) {
    return assets.brickPort;
  } else if (resourceIndex === 3) {
    return assets.sheepPort;
  } else if (resourceIndex === 4) {
    return assets.wheatPort;
  } else if (resourceIndex === 5) {
    return assets.orePort;
  } else {
    return "";
  }
};
