import { Box } from "@chakra-ui/react"
import { DotProgress } from "../DotProgress";
import { StageTitle } from "../StageTitle";
import { SemiFinal } from "./SemiFinal";
import { Final } from "./Final";
import { Tips } from "../Tips";

type SingleElimStageInfoType = {
    PlayerState: number,
    StartTime: string,
    PrizePool: number[],
    FreezeTimeEnd: string | null,
    Matches: any[]
}

const tipOne = "To qualify for the final game, you need to be in the top four places in the standings table after the Semi Final matches."
const tipTwo = "After all matches in Semi Final are over, you will be automatically directed to the next match's room."

export const SingleElimStageInfo = ({ PlayerState, StartTime, PrizePool, FreezeTimeEnd, Matches }: SingleElimStageInfoType) => {
    const freezeTimeEnd = FreezeTimeEnd === "0001-01-01T00:00:00Z" ? null : FreezeTimeEnd

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
        >
            <StageTitle
                PlayerState={PlayerState}
                FreezeTimeEnd={freezeTimeEnd}
            />

            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                fontSize={"2.75vw"}
                textColor={"white"}
                mb={"1vw"}
            >
                <SemiFinal
                    PlayerState={PlayerState}
                    FreezeTimeEnd={freezeTimeEnd}
                    StartTime={StartTime}
                />

                <DotProgress dotText={".........."} />

                <Final
                    PrizePool={PrizePool}
                    PlayerState={PlayerState}
                    FreezeTimeEnd={freezeTimeEnd}
                />
            </Box>

            <Tips
                PlayerState={PlayerState}
                Matches={Matches}
                TipOne={tipOne}
                TipTwo={tipTwo}
            />
        </Box>
    )
}