export const getInventoryKeyAsset = (keyRarity: string, assets: any) => {
  if (keyRarity === "Alpha") {
    return assets.inventoryAlphaKey;
  } else if (keyRarity === "Common") {
    return assets.inventoryCommonKey;
  } else if (keyRarity === "Rare") {
    return assets.inventoryRareKey;
  } else if (keyRarity === "Epic") {
    return assets.inventoryEpicKey;
  } else if (keyRarity === "Ancient") {
    return assets.inventoryAncientKey;
  } else {
    return "";
  }
};
