import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useGame } from '../../../context/GameContext';
import { useSync } from '../../../context/SyncContext';
import { Box } from '@chakra-ui/react';

const possibleColors = [
    "DodgerBlue",
    "OliveDrab",
    "Gold",
    "Pink",
    "SlateBlue",
    "LightBlue",
    "Gold",
    "Violet",
    "PaleGreen",
    "SteelBlue",
    "SandyBrown",
    "Chocolate",
    "Crimson"
];

function randomFromTo(from: number, to: number): number {
    return Math.floor(Math.random() * (to - from + 1) + from);
}

class ConfettiParticle {
    x: number;
    y: number;
    r: number;
    d: number;
    color: string;
    tilt: number;
    tiltAngleIncremental: number;
    tiltAngle: number;

    constructor(W: number, H: number) {
        this.x = Math.random() * W;
        this.y = Math.random() * H - H;
        this.r = randomFromTo(11, 33);
        this.d = Math.random() * 150 + 11;
        this.color = possibleColors[Math.floor(Math.random() * possibleColors.length)];
        this.tilt = Math.floor(Math.random() * 33) - 11;
        this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
        this.tiltAngle = 0;
    }

    draw(context: CanvasRenderingContext2D) {
        context.beginPath();
        context.lineWidth = this.r / 2;
        context.strokeStyle = this.color;
        context.moveTo(this.x + this.tilt + this.r / 3, this.y);
        context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
        context.stroke();
    }
}

export const ConfettiAnimation: React.FC = () => {
    const { isSpectator } = useGame()
    const { myGameData, gameData } = useSync()

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [particles, setParticles] = useState<ConfettiParticle[]>([]);

    const resizeCanvas = useCallback(() => {
        if (canvasRef.current) {
            canvasRef.current.width = window.innerWidth;
            canvasRef.current.height = window.innerHeight;
        }
    }, []);

    useEffect(() => {
        const W = window.innerWidth;
        const H = window.innerHeight;
        const newParticles: ConfettiParticle[] = [];

        for (let i = 0; i < 150; i++) {
            newParticles.push(new ConfettiParticle(W, H));
        }

        setParticles(newParticles);
        resizeCanvas();

        window.addEventListener('resize', resizeCanvas);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [resizeCanvas]);

    useEffect(() => {
        const context = canvasRef.current?.getContext('2d');

        if (!context) return;

        const draw = () => {
            if (canvasRef.current) {
                context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

                particles.forEach((particle) => {
                    particle.draw(context);

                    particle.tiltAngle += particle.tiltAngleIncremental;
                    particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
                    particle.tilt = Math.sin(particle.tiltAngle - particle.d / 3) * 15;

                    if (particle.y > canvasRef.current!.height) {
                        particle.x = Math.random() * canvasRef.current!.width;
                        particle.y = -30;
                        particle.tilt = Math.floor(Math.random() * 10) - 20;
                    }
                });
            }

            requestAnimationFrame(draw);
        };

        draw();
    }, [particles]);

    if (isSpectator || (myGameData && myGameData.turn_index === gameData.WinnerIndex)) {
        return (
            <Box
                pos={"fixed"}
                w={"100%"}
                h={"100%"}
                top={"0"}
                left={"0"}
                zIndex={"1401"}
                pointerEvents={"none"}
            >
                <canvas ref={canvasRef} />
            </Box>
        )
    } else {
        return null
    }
};
