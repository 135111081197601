import { Box, CircularProgress, CircularProgressLabel } from "@chakra-ui/react"
import { useSync } from "../../../context/SyncContext"

export const RoadBuildingIndicator = () => {
    const { gameData } = useSync()

    const gameStatus = gameData.Status

    if (gameStatus === 9 || gameStatus === 10) {
        return (
            <Box className="custom-progress-bar">
                <Box>
                    Road Building Progress
                    <CircularProgress
                        color={'yellow.500'}
                        trackColor={'#6D5535'}
                        thickness={'0.6vw'}
                        size={"3.5vw"}
                        value={gameStatus === 9 ? 0 : 50}
                        ms={2}
                    >
                        <CircularProgressLabel
                            fontWeight={"bolder"}
                            fontSize={"1.25vw"}
                        >
                            {
                                gameStatus === 9 ? 0 : 1
                            }
                            {"/2"}
                        </CircularProgressLabel>
                    </CircularProgress>
                </Box>
            </Box>
        )
    } else {
        return null
    }
}