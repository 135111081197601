import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../../context/GameContext"
import { FaCheckCircle, FaClock } from "react-icons/fa"
import { GetTimeDifference } from "../../../Notification/GetTimeDifference"

type SemiFinalType = {
    CurrentStage: number,
    PlayerState: number,
    FreezeTimeEnd: string | null
}

export const SemiFinal = ({ CurrentStage, PlayerState, FreezeTimeEnd }: SemiFinalType) => {
    const { gameAssets } = useGame()

    const showSuccessBG = CurrentStage === 4 && FreezeTimeEnd
    const showChillBG = CurrentStage === 3 && FreezeTimeEnd

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Box backgroundColor={(showSuccessBG ? "#51ea56" : undefined) || (showChillBG ? "#cb3e50" : undefined)} borderRadius={"50%"} p={"1vw"}>
                {
                    (CurrentStage < 4 || (CurrentStage === 4 && !FreezeTimeEnd)) &&
                    <FaClock />
                }
                {
                    CurrentStage === 4 && FreezeTimeEnd &&
                    <FaCheckCircle />
                }
            </Box>
            <Box textColor={"#e6d1a4"} textDecoration={CurrentStage === 4 && FreezeTimeEnd ? "line-through" : undefined}>
                Semi Final
            </Box>
            <Image
                src={gameAssets.gameLogDivider}
                alt="game-log-divider"
                width={"50%"}
            />
            {
                CurrentStage === 3 && FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    The semi final matches will begin in
                    <GetTimeDifference finishTime={FreezeTimeEnd} />
                </Box>
            }
            {
                CurrentStage === 4 && PlayerState === 5 && !FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Stay Focus! Waiting for other matches to end!
                </Box>
            }
            {
                CurrentStage === 4 && FreezeTimeEnd &&
                <Box fontSize={"1.25vw"} textColor={"#51ea56"}>
                    You have passed the Semi Final.
                </Box>
            }
        </Box >
    )
}