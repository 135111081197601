import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"
import { getResIcon } from "../../../utils/assets/getResIcon"

export const PlayerStealCircle = ({ turnIndex }: { turnIndex: number }) => {
    const { stealData } = useSync()
    const { gameAssets } = useGame()

    if (stealData && stealData.stealerIndex === turnIndex) {
        return (
            <Box
                className='steal-resource-info'
                backgroundColor={"#2BD01F"}
            >
                <Image
                    src={getResIcon(stealData.resource, gameAssets)}
                    alt='icon'
                    width={"50%"}
                />
                +1
            </Box>
        )
    } else if (stealData && stealData.victimIndex === turnIndex) {
        return (
            <Box
                className='steal-resource-info'
                backgroundColor={"#dc6958"}
            >
                <Image
                    src={getResIcon(stealData.resource, gameAssets)}
                    alt='icon'
                    width={"50%"}
                />
                -1
            </Box>
        )
    } else {
        return null
    }
}