export const getVictoryPointAnimation = (colorIndex: number, assets: any) => {
  if (colorIndex === 0) {
    return assets.redVPIcon;
  } else if (colorIndex === 1) {
    return assets.blueVPIcon;
  } else if (colorIndex === 2) {
    return assets.pinkVPIcon;
  } else if (colorIndex === 3) {
    return assets.greyVPIcon;
  } else {
    return "";
  }
};
