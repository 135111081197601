import { IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Image } from "@chakra-ui/react"
import { PlayerTrade } from "../PlayerTrade/PlayerTrade";
import { SocketType } from "../../../utils/types/types";
import { Drawer, DrawerHeader, DrawerContent, useDisclosure } from '@chakra-ui/react'
import { useGame } from '../../../context/GameContext'

export const CounterTradeBox = ({ socket }: { socket: SocketType }) => {
    const { enableCounterTrade, setEnableCounterTrade, gameAssets } = useGame()

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Drawer
            placement={"bottom"}
            onClose={onClose}
            isOpen={enableCounterTrade}
            closeOnOverlayClick={false}
        >
            <DrawerContent
                backgroundColor={"transparent"}
                width={"25vw"}
                ml={"65vw"}
                mr={"auto"}
                mb={"10vh"}
            >
                <DrawerHeader
                    display={"flex"}
                    justifyContent={"space-between"}
                    textColor={"white"}
                    backgroundColor={"#9B8152"}
                    borderRadius={"0.75vw"}
                    p={"1vh 0vh 1vh 0vh"}
                >
                    <Tabs
                        isFitted
                        align={"center"}
                        variant={"unstyled"}
                        defaultIndex={0}
                        width={"100%"}
                    >
                        <TabList pe={5} ps={5}>
                            <Tab
                                w={"10vw"}
                                h={"6vh"}
                                fontSize={"1.5vw"}
                                backgroundColor={"#493D2F"}
                                borderRadius={"0.75vw 0 0 0"}
                                textColor={"white"}
                                _selected={{ color: 'white', bg: 'blue.500' }}
                                defaultValue={0}
                            >
                                <Image
                                    src={gameAssets.playersHudIcon}
                                    alt='bank-icon'
                                    width={"15%"}
                                    me={"0.5vw"}
                                />
                                Counter Trade
                            </Tab>
                        </TabList>
                        <TabPanels >
                            <TabPanel p={"0.5vw 0 0 0"}>
                                <PlayerTrade socket={socket} onClose={onClose} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs >

                    <IconButton
                        position={"absolute"}
                        right={-5}
                        top={-5}
                        background={"transparent"}
                        colorScheme={"none"}
                        aria-label='close-button'
                        onClick={() => {
                            onClose()
                            setEnableCounterTrade(false)
                        }}
                        icon={<div className='trade-box-close-button'></div>} />
                </DrawerHeader>
            </DrawerContent>
        </Drawer>
    )
}