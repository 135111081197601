import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useGame } from '../../context/GameContext';

export const ProtectedGame = () => {
    const { loggedIn } = useAuth()
    const { gameID } = useGame()

    if (!loggedIn) {
        return <Navigate to="/" />
    }

    if (gameID < 0) {
        return <Navigate to="/" />
    }

    if (loggedIn) {
        return <Outlet />
    }
}