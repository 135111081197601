import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"

export const OverviewPlayers = ({ Capacity }: { Capacity: number }) => {
    const { dashboardAssets } = useGame()

    return (
        <>
            <Box className="tournament-overview-header-banner">
                Min Player - Max Player
            </Box>

            <Box
                display={"flex"}
                fontSize={"18px"}
                textColor={"#392516"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
            >
                <Image
                    src={dashboardAssets.maxPlayersIcon}
                    w={"18px"}
                    alt='max-players-icon'
                    me={2}
                />
                Min {Capacity === 16 ? "6 " : "9 "}
                -
                Max {Capacity}
            </Box>
        </>
    )
}