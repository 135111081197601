export const getEmoji = (type: number, assets: any) => {
  if (type === 0) {
    return assets.happyEmoji;
  } else if (type === 1) {
    return assets.cryingEmoji;
  } else if (type === 2) {
    return assets.evilEmoji;
  } else if (type === 3) {
    return assets.suprisedEmoji;
  } else if (type === 4) {
    return assets.annoyedEmoji;
  } else if (type === 5) {
    return assets.angryEmoji;
  } else {
    return "";
  }
};
