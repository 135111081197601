import { FaPlusCircle, FaInfoCircle } from "react-icons/fa";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Box } from '@chakra-ui/react'
import { useToast, IconButton, Button, FormControl, FormLabel, Input, RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { useState } from "react";
import { CreateTournament } from "../../../../requests/TournamentRequests";

export const CreateTournamentModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast()

    const [tournamentName, setTournamentName] = useState("")
    const [bannerAssetURL, setBannerAssetURL] = useState("")
    const [ticketAssetURL, setTicketAssetURL] = useState("")
    const [tournamentClass, setTournamentClass] = useState(1)
    const [isClosedTournament, setIsClosedTournament] = useState(false)
    const [prizePool, setPrizePool] = useState({ firstPlace: 0, secondPlace: 0, thirdPlace: 0, fourthPlace: 0 })
    const [startTimeGMT, setStartTimeGMT] = useState("")

    const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const localDateTime = event.target.value;

        var localDate = new Date(localDateTime);

        var localTimestamp = localDate.getTime();

        var localTimezoneOffsetMs = localDate.getTimezoneOffset() * 60 * 1000;

        var gmtTimestamp = localTimestamp + localTimezoneOffsetMs;

        var gmtTime = new Date(gmtTimestamp - localTimezoneOffsetMs)
        setStartTimeGMT(gmtTime.toISOString())
    }

    const handleCreate = async () => {
        const prizePoolArray = [prizePool.firstPlace, prizePool.secondPlace, prizePool.thirdPlace, prizePool.fourthPlace]

        const response = await CreateTournament(tournamentName, prizePoolArray, startTimeGMT, isClosedTournament, tournamentClass, bannerAssetURL, ticketAssetURL)

        if (response && response.message === "success") {
            toast({
                description: "The tournament has been created successfully.",
                status: "success",
                duration: 5000
            })
            setTournamentName("")
            setPrizePool({ firstPlace: 0, secondPlace: 0, thirdPlace: 0, fourthPlace: 0 })
            setStartTimeGMT("")
            onClose()
        } else if (response && response.error) {
            toast({
                description: response.error,
                status: "error",
                duration: 5000,
            })
        }
    }

    return (
        <>
            <Button
                m={"auto"}
                mr={"75px"}
                w={"auto"}
                h={"3.5vw"}
                backgroundColor={"#80A732"}
                border={"0.25vw solid black"}
                borderRadius={"1.5vw"}
                fontSize={"1.75vw"}
                colorScheme={'green'}
                onClick={onOpen}
            >
                Create Tournament
                <FaPlusCircle className='ms-2' />
            </Button>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset={"slideInBottom"}
                size={"xl"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader className='menu-modal-headers' >
                        Create Tournament
                        <IconButton
                            position={"absolute"}
                            right={0}
                            top={1}
                            colorScheme={"none"}
                            background={"transparent"}
                            aria-label='close-button'
                            onClick={onClose}
                            icon={<div className='close-button'></div>}
                        />
                    </ModalHeader>
                    <ModalBody pb={6}>
                        {/* Tournament Name */}
                        <FormControl mb={3}>
                            <FormLabel htmlFor="tournamentName">Tournament Name</FormLabel>
                            <Input
                                id="tournamentName"
                                placeholder='Tournament Name'
                                onChange={(e) => setTournamentName(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        {/* Tournament Banner */}
                        <FormControl mb={3}>
                            <FormLabel htmlFor="tournamentBannerURL">Tournament Banner URL</FormLabel>
                            <Input
                                id="tournamentBannerURL"
                                placeholder='Tournament Banner URL'
                                onChange={(e) => setBannerAssetURL(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        {/* Tournament Prize Pool */}
                        <FormControl mb={3}>
                            <FormLabel>Tournament Prize Pool</FormLabel>
                            <Box display={"flex"} justifyContent={"space-evenly"}>
                                <FormLabel htmlFor="prize-1" w={"25%"} me={2} textAlign={"center"}>1st Player</FormLabel>
                                <FormLabel htmlFor="prize-2" w={"25%"} me={2} textAlign={"center"}>2nd Player</FormLabel>
                                <FormLabel htmlFor="prize-3" w={"25%"} me={2} textAlign={"center"}>3rd Player</FormLabel>
                                <FormLabel htmlFor="prize-4" w={"25%"} textAlign={"center"}>4th Player</FormLabel>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-evenly"}>
                                <Input
                                    id="prize-1"
                                    type="number"
                                    placeholder="$"
                                    _placeholder={{ color: "#85776E" }}
                                    onChange={(e) => setPrizePool(prevState => ({ ...prevState, firstPlace: Number(e.target.value) }))}
                                    borderColor={"black"}
                                    backgroundColor={"#544C42"}
                                    textColor={"white"}
                                    w={"25%"}
                                    me={2}
                                />
                                <Input
                                    id="prize-2"
                                    type="number"
                                    placeholder="$"
                                    _placeholder={{ color: "#85776E" }}
                                    onChange={(e) => setPrizePool(prevState => ({ ...prevState, secondPlace: Number(e.target.value) }))}
                                    borderColor={"black"}
                                    backgroundColor={"#544C42"}
                                    textColor={"white"}
                                    w={"25%"}
                                    me={2}
                                />
                                <Input
                                    id="prize-3"
                                    type="number"
                                    placeholder="$"
                                    _placeholder={{ color: "#85776E" }}
                                    onChange={(e) => setPrizePool(prevState => ({ ...prevState, thirdPlace: Number(e.target.value) }))}
                                    borderColor={"black"}
                                    backgroundColor={"#544C42"}
                                    w={"25%"}
                                    textColor={"white"}
                                    me={2}
                                />
                                <Input
                                    id="prize-4"
                                    type="number"
                                    placeholder="$"
                                    _placeholder={{ color: "#85776E" }}
                                    onChange={(e) => setPrizePool(prevState => ({ ...prevState, fourthPlace: Number(e.target.value) }))}
                                    borderColor={"black"}
                                    backgroundColor={"#544C42"}
                                    w={"25%"}
                                    textColor={"white"}
                                />
                            </Box>
                        </FormControl>

                        {/* Tournament Mode */}
                        <FormControl mb={3}>
                            <FormLabel>
                                Tournament Mode
                            </FormLabel>
                            <RadioGroup
                                display={"flex"}
                                justifyContent={"center"}
                                onChange={(value) => setTournamentClass(Number(value))}
                                value={tournamentClass.toString()}
                            >
                                <Stack direction='row' spacing={5}>
                                    <Radio colorScheme={"blackAlpha"} size='lg' value='1'>
                                        Single Elim
                                    </Radio>
                                    <Radio colorScheme={"blackAlpha"} size='lg' value='2'>
                                        Knockout
                                    </Radio>
                                    <Radio colorScheme={"blackAlpha"} size='lg' value='4'>
                                        Championship
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>

                        {/* Tournament Type */}
                        <FormControl mb={3}>
                            <FormLabel>Tournament Type</FormLabel>
                            <RadioGroup
                                className='d-flex justify-content-center'
                                onChange={(value) => setIsClosedTournament(value === "Private")}
                                value={isClosedTournament ? "Private" : "Public"}
                            >
                                <Stack direction='row' spacing={5}>
                                    <Radio colorScheme={"blackAlpha"} size='lg' value='Public'>Public</Radio>
                                    <Radio colorScheme={"blackAlpha"} size='lg' value='Private'>Private</Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>

                        {
                            isClosedTournament &&
                            /* Tournament Banner */
                            <FormControl mb={3}>
                                <FormLabel htmlFor="tournamentTicketURL">Tournament Ticket URL</FormLabel>
                                <Input
                                    id="tournamentTicketURL"
                                    placeholder='Tournament Ticket URL'
                                    onChange={(e) => setTicketAssetURL(e.target.value)}
                                    borderColor={"black"}
                                    backgroundColor={"#544C42"}
                                    textColor={"white"}
                                />
                            </FormControl>
                        }

                        {/* Tournament Start Time */}
                        <FormControl>
                            <FormLabel htmlFor="dateInput">Tournament Start Time</FormLabel>
                            <Input
                                id="dateInput"
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                                placeholder='Select Date and Time'
                                size='md'
                                type='datetime-local'
                                onChange={(e) => handleStartTimeChange(e)}
                            />
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2} textColor={"InfoText"}>
                                <FaInfoCircle className="me-2" />
                                The time represents your local time.
                            </Box>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={tournamentName.length === 0 || startTimeGMT.length === 0}
                            onClick={handleCreate}
                        >
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}