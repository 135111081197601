import { Box } from "@chakra-ui/react"
import { FaTrophy } from "react-icons/fa";
import { TournamentBracketTable } from './TournamentBracketTable'
import { TournamentBracketFinalTable } from "./TournamentBracketFinalTable"
import { useEffect, useState } from "react";
import { GetTournamentBrackets } from "../../../../../requests/TournamentRequests";

type KnockoutBracketType = {
    ID: number,
    State: number,
    Prize: number[],
    CurrentStage: number,
    setMatches: (value: any[]) => void | null
}

const roundTitleColor = "#34362f"

export const KnockoutBracket = ({ ID, State, Prize, CurrentStage, setMatches }: KnockoutBracketType) => {
    const [qualify, setQualify] = useState<any[]>([])
    const [semiFinal, setSemiFinal] = useState<any[]>([])
    const [final, setFinal] = useState<any>()

    useEffect(() => {
        const fetchBrackets = async () => {
            const response = await GetTournamentBrackets(ID);

            if (response) {
                const qualifyMatches = response.filter((match) => match.stage === 1);
                setQualify(qualifyMatches);

                const semiFinalMatches = response.filter((match) => match.stage === 2);
                setSemiFinal(semiFinalMatches)

                if (setMatches && CurrentStage) {
                    setMatches(response.filter((match) => match.stage === CurrentStage))
                }

                const finalMatch = response.find((match) => match.stage === 3);
                if (finalMatch) {
                    setFinal(finalMatch);
                }
            }
        };

        const isStateCorrect = State === 3 || State === 4 || State === 6

        if (ID && isStateCorrect) {
            fetchBrackets();
        }
    }, [ID, State, CurrentStage]);

    return (
        <Box display={"flex"} alignItems={"stretch"} overflow={"auto"}>
            {/* Qualify Matches */}
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#f1d4b1"}
                w={"max-content"}
                ps={"5vw"}
                pe={"5vw"}
            >
                <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <Box textColor={roundTitleColor} fontSize={"40px"}>
                        Qualify Stage
                    </Box>
                    {
                        qualify.length > 0 &&
                        qualify.map((bracket, index) => {
                            return (
                                <TournamentBracketTable
                                    key={index}
                                    matchId={index + 1}
                                    players={bracket.Players}
                                    matchStatus={bracket.state}
                                    duration={bracket.duration}
                                    marginAmount={"1vh"}
                                />
                            )
                        })
                    }
                </Box>
            </Box>

            {/* Semi Final Matches */}
            {
                CurrentStage > 1 &&
                <Box
                    display={"flex"}
                    alignItems={"stretch"}
                    justifyContent={"center"}
                    backgroundColor={"#edcca3"}
                    w={"max-content"}
                    ps={"5vw"}
                    pe={"5vw"}
                >
                    <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                        <Box textColor={roundTitleColor} justifySelf={"self-start"} fontSize={"40px"}>
                            Semi Final
                        </Box>
                        {
                            semiFinal.length > 0 &&
                            semiFinal.map((bracket, index) => {
                                return (
                                    <TournamentBracketTable
                                        key={index}
                                        matchId={index + 1}
                                        players={bracket.Players}
                                        matchStatus={bracket.state}
                                        duration={bracket.duration}
                                        marginAmount={undefined}
                                    />
                                )
                            })
                        }
                    </Box>
                </Box>
            }

            {/* Final Match */}
            {
                final &&
                <Box
                    display={"flex"}
                    alignItems={"stretch"}
                    justifyContent={"center"}
                    backgroundColor={"#f1d4b1"}
                    w={"max-content"}
                    pe={"5vw"}
                    ps={"5vw"}
                >
                    <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                        <Box display={"flex"} alignItems={"center"} textColor={roundTitleColor} fontSize={"40px"}>
                            Final
                            <FaTrophy className="ms-4" />
                        </Box>
                        <TournamentBracketFinalTable
                            players={final && final.Players}
                            matchStatus={final && final.state}
                            prizePool={Prize}
                            duration={final && final.duration}
                        />
                    </Box>
                </Box>
            }
        </Box>
    )
}