import { Box } from "@chakra-ui/react"
import { DotProgress } from "../DotProgress";
import { StageTitle } from "../StageTitle";
import { Final } from "./Final";
import { Qualify } from "./Qualify";
import { SemiFinal } from "./SemiFinal";
import { Tips } from "../Tips";

type KnockoutStageInfoType = {
    CurrentStage: number,
    PlayerState: number,
    StartTime: string,
    PrizePool: number[],
    FreezeTimeEnd: string | null,
    Matches: any[]
}

const tipOne = "The top 4 players in the standings after the 2nd round will qualify for the final table."
const tipTwo = "The top 6 - 16 players in the standings will qualify to the second round depending on how many have initially checked in."

export const KnockoutStageInfo = ({ CurrentStage, PlayerState, StartTime, PrizePool, FreezeTimeEnd, Matches }: KnockoutStageInfoType) => {
    const freezeTimeEnd = FreezeTimeEnd === "0001-01-01T00:00:00Z" ? null : FreezeTimeEnd

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
        >
            <StageTitle
                PlayerState={PlayerState}
                FreezeTimeEnd={freezeTimeEnd}
            />

            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                fontSize={"2.75vw"}
                textColor={"white"}
                mb={"1vw"}
            >
                <Qualify
                    CurrentStage={CurrentStage}
                    PlayerState={PlayerState}
                    StartTime={StartTime}
                    FreezeTimeEnd={freezeTimeEnd}
                />

                <DotProgress dotText={"......."} />

                <SemiFinal
                    CurrentStage={CurrentStage}
                    PlayerState={PlayerState}
                    FreezeTimeEnd={freezeTimeEnd}
                />

                <DotProgress dotText={"......."} />

                <Final
                    PrizePool={PrizePool}
                    CurrentStage={CurrentStage}
                    FreezeTimeEnd={freezeTimeEnd}
                />
            </Box>

            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#493b2f"}
                borderRadius={"25px"}
                textColor={"white"}
                w={"90%"}
            >
                <Tips
                    PlayerState={PlayerState}
                    Matches={Matches}
                    TipOne={tipOne}
                    TipTwo={tipTwo}
                />
            </Box>
        </Box>
    )
}