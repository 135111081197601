const API_URL = import.meta.env.VITE_API_URL

export const CreateTournament = async (name: string, prize: number[], start_time: string, is_closed: boolean, _class: number, banner_image: string, ticket_image: string) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ name, prize, start_time, is_closed, class: _class, banner_image, ticket_image })
            }
        );

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error creating a tournament:", error);
        return null;
    }
};

export const CreateTournamentSignup = async (tournament_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/signup`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ tournament_id })
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error tournament sign-up:", error);
        return null;
    }
};

export const CreateTournamentCheckin = async (tournament_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/signup/checkin`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ tournament_id })
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error tournament check-in:", error);
        return null;
    }
};

export const CreateTournamentCancel = async (tournament_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/cancel`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ tournament_id })
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error tournament cancel :", error);
        return null;
    }
};

export const CreateTournamentSignupCancel = async (tournament_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/signup/cancel`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ tournament_id })
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error tournament signup cancel:", error);
        return null;
    }
};

export const CreateTournamentCheckinCancel = async (tournament_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/signup/cancel-checkin`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ tournament_id })
            }
        );

        const result = await response.json()

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error tournament check-in cancel:", error);
        return null;
    }
};

export const GetTournaments = async () => {
    try {
        const response = await fetch(
            API_URL + `/tournament/get`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get tournaments.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting tournaments:", error);
        return null;
    }
};

export const GetDailyTournaments = async () => {
    try {
        const response = await fetch(
            API_URL + `/tournament/get/daily`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get daily tournaments.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting daily tournaments:", error);
        return null;
    }
};

export const GetTournamentById = async (tournamentID: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/get/tournament/${tournamentID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get tournament.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting tournament:", error);
        return null;
    }
};

export const GetTournamentStandings = async (tournamentID: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/get/rank/${tournamentID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get tournament standings.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting tournaments:", error);
        return null;
    }
};

export const GetTournamentBrackets = async (tournamentID: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/get/matches/${tournamentID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get tournament brackets.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting tournaments:", error);
        return null;
    }
};

export const GetTournamentNotif = async () => {
    try {
        const response = await fetch(
            API_URL + `/tournament/get/notifications`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get tournaments notifications.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting tournament notifications:", error);
        return null;
    }
};

export const GetLastGameStats = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/stats-summary/${gameID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get last game stats.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting last game stats:", error);
        return null;
    }
};