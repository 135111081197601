import { FaRobot } from "react-icons/fa6"
import { Box } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { getPlayerUsernameLabel } from "../../../utils/assets/getPlayerUsernameLabel"
import { PlayerVictoryPoint } from "./PlayerVictoryPoint"

type PlayerUsernameType = {
    turnIndex: number,
    username: string,
    victoryPoint: number,
    isBot: boolean
}

const hasUppercase = (name: string) => {
    return /[A-Z]/.test(name);
}

const hasWideChars = (name: string) => {
    return /[wm8]/.test(name);
}

export const PlayerUsername = ({ turnIndex, username, victoryPoint, isBot }: PlayerUsernameType) => {
    const { gameAssets } = useGame()
    return (
        <Box
            className="player-username"
            backgroundImage={getPlayerUsernameLabel(turnIndex, gameAssets)}
        >
            <Box ml={"2vw"} mr={"0.1vw"} w={"2.25vw"}>
                <PlayerVictoryPoint
                    turnIndex={turnIndex}
                    victoryPoint={victoryPoint}
                />
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                {
                    (hasUppercase(username) || hasWideChars(username)) && username.length > 9 ?
                        username.slice(0, isBot ? 6 : 9) + '...' :
                        username
                }
                {
                    isBot && <FaRobot style={{ marginLeft: "0.5vw" }} />
                }
            </Box>
        </Box>
    )
}