import calendarIcon from '../../assets/Landing/icon_calendar.png'
import cashIcon from '../../assets/Landing/icon_cash.png'
import diceIcon from '../../assets/Landing/icon_dice.png'
import feeIcon from '../../assets/Landing/icon_fee.png'

import { Box, Image } from "@chakra-ui/react"

export const TournamentContent = () => {
    const headerTextColor = "#ffecb5"
    return (
        <Box className="brown-background text-center" pb={"4vw"}>
            <Box as={"h2"} textColor={headerTextColor} fontSize={"3vw"} pt={"3vw"}>
                How to join Pioneers Tournaments?
            </Box>

            <Box as={"h2"} textColor={"white"} fontSize={"2vw"} pt={"1vw"} pb={"3vw"}>
                Challenge yourself in online Catan tournaments and win prizes!
            </Box>

            <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                backgroundColor={"#3f3323"}
                borderRadius={"25px"}
                textColor={"white"}
                width={"75%"}
                p={"1vw"}
                ml={"auto"}
                mr={"auto"}
            >
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"20vw"}>
                    <Image
                        src={calendarIcon}
                        alt='register-to-catan-tournaments'
                    />
                    <Box as={"h3"} fontSize={"1.75vw"} >
                        Register for Tournaments
                    </Box>
                </Box>

                <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"20vw"}>
                    <Image
                        src={feeIcon}
                        alt='free-catan-tournaments'
                    />
                    <Box as={"h3"} fontSize={"1.75vw"}>
                        Join Free or Paid Tournaments
                    </Box>
                </Box>

                <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"20vw"}>
                    <Image
                        src={diceIcon}
                        alt='multiplayer-catan'
                    />
                    <Box as={"h3"} fontSize={"1.75vw"}>
                        Compete with other Players
                    </Box>
                </Box>

                <Box display={"flex"} flexDir={"column"} alignItems={"center"} w={"20vw"}>
                    <Image
                        src={cashIcon}
                        alt='win-cash-by-playing-catan'
                    />
                    <Box as={"h3"} fontSize={"1.75vw"}>
                        Win Tournaments & Withdraw Cash
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}