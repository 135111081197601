export const getVPLabel = (index: number, assets: any) => {
  if (index === 0) {
    return assets.redVPLabel;
  } else if (index === 1) {
    return assets.blueVPLabel;
  } else if (index === 2) {
    return assets.pinkVPLabel;
  } else if (index === 3) {
    return assets.grayVPLabel;
  } else {
    return "";
  }
};
