import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, useDisclosure, FormControl, FormLabel, Input, ModalFooter, useToast, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, CheckboxGroup, Stack, Checkbox, Box, Radio, RadioGroup, } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { CreateAssignTicket, GetMissionTickets } from '../../../../requests/AdminRequests'
import { useState } from 'react'

const fetchRewardItems = async (setTournamenTicket: React.Dispatch<React.SetStateAction<any[]>>) => {
    const response = await GetMissionTickets()
    response && setTournamenTicket(response.tickets)
}

export const AssignTicketModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure(
        {
            onOpen: async () => {
                fetchRewardItems(setTournamentTickets)
            }
        }
    )

    const toast = useToast()

    const [username, setUsername] = useState("")
    const [ticketID, setTicketID] = useState(-1)

    const [tournamentTickets, setTournamentTickets] = useState<any[]>([])

    const handleAssignedTicketChange = (selectedTicketID: number) => {
        setTicketID(selectedTicketID);
    };

    const handleAssignTicket = async () => {
        const result = await CreateAssignTicket(username, ticketID)
        if (result) {
            const responseMessage = result.message

            if (responseMessage === "Ticket assigned to user") {
                toast({
                    description: "Ticket is assigned.",
                    status: "success",
                    duration: 2000
                })
                onClose()
            } else if (responseMessage === "Ticket not found") {
                toast({
                    description: "Ticket cannot be found.",
                    status: "error",
                    duration: 2000
                })
            } else if (responseMessage === "User not found") {
                toast({
                    description: "Player cannot be found.",
                    status: "error",
                    duration: 2000
                })
            } else if (responseMessage === "Ticket already assigned to user") {
                toast({
                    description: "Ticket has already assigned to the Player.",
                    status: "info",
                    duration: 2000
                })
            }
        }
    }
    return (
        <>
            <Button
                colorScheme='green'
                mb={3}
                w={"100%"}
                onClick={onOpen}
            >
                Assign Ticket
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Assign Ticket</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel htmlFor='usernameInput'>Username</FormLabel>
                            <Input
                                id="usernameInput"
                                placeholder='Username'
                                onChange={(e) => setUsername(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='rewardItemInputs'>Ticket</FormLabel>
                            <RadioGroup
                                colorScheme='blue'
                                onChange={(value) => handleAssignedTicketChange(Number(value))}
                            >
                                <Stack direction={"column"}>
                                    {
                                        tournamentTickets &&
                                            tournamentTickets.length > 0 ?
                                            tournamentTickets.map((ticket, index) => (
                                                <Radio key={index} value={ticket.TicketID.toString()}>
                                                    {ticket.Name}
                                                </Radio>
                                            )) :
                                            <Box textAlign={"center"}>
                                                There is no any tournament ticket to assign.
                                            </Box>
                                    }
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={ticketID === -1}
                            onClick={handleAssignTicket}
                        >
                            Assign Ticket
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}