import { useToast } from "@chakra-ui/react"
import { SocketType } from "../utils/types/types"
import { useEffect } from "react"
import { useDashboard } from "../context/DashboardContext"

export const Quests = (socket: SocketType) => {
    const { setQuestList } = useDashboard()
    const toast = useToast()

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "MissionComplete") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                toast({
                    title: "Quest Task Completed",
                    description: parsedData.Description,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            }

        }
    }, [socket && socket.lastJsonMessage])

    // Handle creating tournament
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "QuestCreated") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setQuestList(prev => {
                        return [
                            ...prev,
                            {
                                ...parsedData,
                                completed_tasks: []
                            }
                        ];
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);
}