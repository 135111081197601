import { Button, Box, Tooltip } from "@chakra-ui/react"
import { useState } from "react";
import { useGame } from "../../../context/GameContext";
import { ResourceNumbers, SocketType } from '../../../utils/types/types';
import { MarketTradeInputValidation } from './MarketTradeInputValidation';
import { MarketResCard } from './MarketResCard';
import { useSync } from '../../../context/SyncContext';
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent";
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const MarketTrade = ({ socket, onClose }: { socket: SocketType, onClose: () => void }) => {
    const { gameID, sfxVolume, sfxEnabled, gameAssets, soundEffects } = useGame()
    const { resources, dockRatios } = useSync()

    const [disabled, setDisabled] = useState<boolean>(true)

    const [wantedCards, setWantedCards] = useState<ResourceNumbers>({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })

    const [givenCards, setGivenCards] = useState<ResourceNumbers>({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })

    // Validation for Taken and Given Card inputs
    MarketTradeInputValidation({
        resources,
        dockRatios,
        wantedCards,
        givenCards,
        setDisabled
    })

    const handleTradeOffer = async () => {
        const eventData = {
            id: gameID,
            offered: {
                ...(givenCards.Wood > 0 ? { wood: givenCards.Wood } : {}),
                ...(givenCards.Brick > 0 ? { brick: givenCards.Brick } : {}),
                ...(givenCards.Sheep > 0 ? { sheep: givenCards.Sheep } : {}),
                ...(givenCards.Wheat > 0 ? { wheat: givenCards.Wheat } : {}),
                ...(givenCards.Ore > 0 ? { ore: givenCards.Ore } : {})
            },
            wanted: {
                ...(wantedCards.Wood > 0 ? { wood: wantedCards.Wood } : {}),
                ...(wantedCards.Brick > 0 ? { brick: wantedCards.Brick } : {}),
                ...(wantedCards.Sheep > 0 ? { sheep: wantedCards.Sheep } : {}),
                ...(wantedCards.Wheat > 0 ? { wheat: wantedCards.Wheat } : {}),
                ...(wantedCards.Ore > 0 ? { ore: wantedCards.Ore } : {})
            }
        }
        SendGameEvent(socket, "MarketTrade", eventData)
        PlaySoundEffect(soundEffects.bankTradeConfirmSFX, sfxVolume, sfxEnabled)

        onClose()

        setWantedCards({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
        setGivenCards({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
    }

    return (
        <Box>
            <Box display={"flex"} p={"0.5vw 0.5vw 0.5vw 0vw"} backgroundColor={"#5B472D"}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Tooltip label="Cards that you want">
                        <img src={gameAssets.receiveIcon} alt="receive-icon" width={"50%"} />
                    </Tooltip>
                </Box>
                <MarketResCard
                    iconSrc={gameAssets.woodIcon}
                    resourceName="Wood Card Taken"
                    count={wantedCards.Wood}
                    onChange={setWantedCards}
                    borderColor="green"
                    ratio={dockRatios?.Wood}
                    showRatio={false}
                    name={"Wood"}
                />
                <MarketResCard
                    iconSrc={gameAssets.brickIcon}
                    resourceName="Brick Card Taken"
                    count={wantedCards.Brick}
                    onChange={setWantedCards}
                    borderColor="green"
                    ratio={dockRatios?.Brick}
                    showRatio={false}
                    name={"Brick"}
                />
                <MarketResCard
                    iconSrc={gameAssets.sheepIcon}
                    resourceName="Sheep Card Taken"
                    count={wantedCards.Sheep}
                    onChange={setWantedCards}
                    borderColor="green"
                    ratio={dockRatios?.Sheep}
                    showRatio={false}
                    name={"Sheep"}
                />
                <MarketResCard
                    iconSrc={gameAssets.wheatIcon}
                    resourceName="Wheat Card Taken"
                    count={wantedCards.Wheat}
                    onChange={setWantedCards}
                    borderColor="green"
                    ratio={dockRatios?.Wheat}
                    showRatio={false}
                    name={"Wheat"}
                />
                <MarketResCard
                    iconSrc={gameAssets.oreIcon}
                    resourceName="Ore Card Taken"
                    count={wantedCards.Ore}
                    onChange={setWantedCards}
                    borderColor="green"
                    ratio={dockRatios?.Ore}
                    showRatio={false}
                    name={"Ore"}
                />
            </Box >
            <Box display={"flex"} p={"0.5vw 0.5vw 0.5vw 0vw"} backgroundColor={"#6D5535"}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Tooltip label="Cards that you give">
                        <img src={gameAssets.giveIcon} alt="receive-icon" width={"50%"} />
                    </Tooltip>
                </Box>
                <MarketResCard
                    iconSrc={gameAssets.woodIcon}
                    resourceName="Wood Card Given"
                    count={givenCards.Wood}
                    onChange={setGivenCards}
                    borderColor="red"
                    ratio={dockRatios?.Wood}
                    showRatio={true}
                    name={"Wood"}
                />
                <MarketResCard
                    iconSrc={gameAssets.brickIcon}
                    resourceName="Brick Card Given"
                    count={givenCards.Brick}
                    onChange={setGivenCards}
                    borderColor="red"
                    ratio={dockRatios?.Brick}
                    showRatio={true}
                    name={"Brick"}
                />
                <MarketResCard
                    iconSrc={gameAssets.sheepIcon}
                    resourceName="Sheep Card Given"
                    count={givenCards.Sheep}
                    onChange={setGivenCards}
                    borderColor="red"
                    ratio={dockRatios?.Sheep}
                    showRatio={true}
                    name={"Sheep"}
                />
                <MarketResCard
                    iconSrc={gameAssets.wheatIcon}
                    resourceName="Wheat Card Given"
                    count={givenCards.Wheat}
                    onChange={setGivenCards}
                    borderColor="red"
                    ratio={dockRatios?.Wheat}
                    showRatio={true}
                    name={"Wheat"}
                />
                <MarketResCard
                    iconSrc={gameAssets.oreIcon}
                    resourceName="Ore Card Given"
                    count={givenCards.Ore}
                    onChange={setGivenCards}
                    borderColor="red"
                    ratio={dockRatios?.Ore}
                    showRatio={true}
                    name={"Ore"}
                />
            </Box>
            <Box display={"flex"} justifyContent={"center"} mt={"1vh"}>
                <Button
                    width={"12vw"}
                    height={"5vh"}
                    fontSize={"1.5vw"}
                    backgroundColor={"#73953e"}
                    borderRadius={"0.5vw"}
                    colorScheme='green'
                    isDisabled={disabled}
                    onClick={handleTradeOffer}
                >
                    Confirm Trade
                </Button>
            </Box>
        </Box >
    )
}