import { Box } from "@chakra-ui/react"
import { DotProgress } from "../DotProgress";
import { StageTitle } from "../StageTitle";
import { Qualify } from "./Qualify";
import { SemiFinal } from "./SemiFinal";
import { Final } from "./Final";
import { Tips } from "../Tips";

type ChampionshipStageInfoType = {
    CurrentStage: number,
    PlayerState: number,
    StartTime: string,
    PrizePool: number[],
    FreezeTimeEnd: string | null,
    Matches: any[]
}

const tipOne = "On Day 1, all players compete in 3 qualifier games with random opponents. The top 16 from the standings move on to the Semi-Finals."
const tipTwo = "Day 2 kicks off 24 hours after Day 1. The top 16 battle it out, and the winners of each Semi-Final game advance to the Finals for a shot at victory!"

export const ChampionshipStageInfo = ({ CurrentStage, PlayerState, StartTime, PrizePool, FreezeTimeEnd, Matches }: ChampionshipStageInfoType) => {
    const freezeTimeEnd = FreezeTimeEnd === "0001-01-01T00:00:00Z" ? null : FreezeTimeEnd

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
        >
            <StageTitle
                PlayerState={PlayerState}
                FreezeTimeEnd={freezeTimeEnd}
            />

            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                fontSize={"2.5vw"}
                textColor={"white"}
                mb={"1vw"}
            >
                <Qualify
                    CurrentStage={CurrentStage}
                    PlayerState={PlayerState}
                    StartTime={StartTime}
                    FreezeTimeEnd={freezeTimeEnd}
                />

                <DotProgress dotText={"......."} />

                <SemiFinal
                    CurrentStage={CurrentStage}
                    PlayerState={PlayerState}
                    FreezeTimeEnd={freezeTimeEnd}
                />

                <DotProgress dotText={"......."} />

                <Final
                    PrizePool={PrizePool}
                    CurrentStage={CurrentStage}
                    FreezeTimeEnd={freezeTimeEnd}
                />
            </Box>

            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#493b2f"}
                borderRadius={"25px"}
                textColor={"white"}
                w={"90%"}
            >
                <Tips
                    PlayerState={PlayerState}
                    Matches={Matches}
                    TipOne={tipOne}
                    TipTwo={tipTwo}
                />
            </Box>
        </Box>
    )
}