export const dockPlaces = [
  "0,0",
  "0,1",
  "3,0",
  "4,0",
  "7,1",
  "8,1",
  "10,2",
  "10,3",
  "8,4",
  "7,4",
  "4,5",
  "3,5",
  "0,5",
  "1,5",
  "0,4",
  "1,3",
  "0,1",
  "1,2",
];
