const API_URL = import.meta.env.VITE_API_URL

export const GetWalletAddress = async () => {
    try {
        const response = await fetch(
            API_URL + `/token/get-wallet`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get wallet address.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting wallet address:", error);
        return null;
    }
};

export const GetBalance = async () => {
    try {
        const response = await fetch(
            API_URL + `/token/balance`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get balance.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting balance:", error);
        return null;
    }
};

export const GetNonce = async () => {
    try {
        const response = await fetch(
            API_URL + `/token/nonce`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get nonce.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting nonce:", error);
        return null;
    }
};

export const CreateWalletAddress = async (address: `0x${string}` | undefined, signature: string) => {
    try {
        const response = await fetch(
            API_URL + `/token/wallet`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ address, signature })
            }
        );

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error creating a wallet address:", error);
        return null;
    }
};

export const CreateWithdraw = async (amount: number) => {
    try {
        const response = await fetch(
            API_URL + `/token/withdraw`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ amount })
            }
        );

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error creating a withdraw:", error);
        return null;
    }
};

export const GetTransactions = async () => {
    try {
        const response = await fetch(
            API_URL + `/token/withdraw`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get transactions.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting transactions:", error);
        return null;
    }
};