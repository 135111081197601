import { Box, Image, IconButton } from "@chakra-ui/react"
import { FaCheck, FaTimes } from 'react-icons/fa'
import { SocketType } from "../../../../utils/types/types"
import { useGame } from "../../../../context/GameContext"
import { useSync } from "../../../../context/SyncContext"
import { useEffect } from "react"
import { getPrevIsometricRoadAsset, getPrevStraightRoadAsset } from "../../../../utils/assets/getPrevRoadAsset"
import { SendGameEvent } from "../../../../socket/sendMessage/SendGameEvent"
import { getIsometricRoadAsset } from "../../../../utils/assets/getIsometricRoadAsset"

type BuildRoadType = {
    socket: SocketType,
    confirmData: {
        showCost: boolean,
        costType: string,
        settlePosition: {
            xStart: number,
            yStart: number,
            xEnd: number | null,
            yEnd: number | null
        }
    } | null,
    setConfirmData: (value: any) => void
}

export const BuildRoad = ({ socket, confirmData, setConfirmData }: BuildRoadType) => {
    const { myGameData, resources, gameData, playersSkinChoice } = useSync()
    const { gameID, gameAssets, isMobile } = useGame()

    useEffect(() => {
        if (confirmData) {
            const positions = confirmData.settlePosition
            const roadElement = document.getElementById(`r/${positions.xStart},${positions.yStart}/${positions.xEnd},${positions.yEnd}`)
            if (roadElement && playersSkinChoice) {
                if (roadElement.classList.contains("left-road") || roadElement.classList.contains("right-road")) {
                    roadElement.classList.add(getPrevStraightRoadAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!);
                } else {
                    roadElement.classList.add(getPrevIsometricRoadAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!);
                }

                roadElement.classList.add('road-confirm-prev')
            }
        }

        return () => {
            const roadElement = document.querySelector('.road-confirm-prev')
            if (roadElement && playersSkinChoice) {
                roadElement?.classList.remove('road-confirm-prev')
                if (roadElement.classList.contains("left-road") || roadElement.classList.contains("right-road")) {
                    roadElement.classList.remove(getPrevStraightRoadAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!);
                } else {
                    roadElement.classList.remove(getPrevIsometricRoadAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!);
                }
            }
        }
    }, [confirmData])

    const handleRoadBuilding = () => {
        const eventData = {
            id: gameID,
            position: [
                confirmData?.settlePosition.xStart,
                confirmData?.settlePosition.yStart,
                confirmData?.settlePosition.xEnd,
                confirmData?.settlePosition.yEnd
            ]
        }
        if (gameData.Status === 2) {
            SendGameEvent(socket, "SetupRoad", eventData)
        } else {
            SendGameEvent(socket, "BuildRoad", eventData)
        }
        setConfirmData(null)
    }

    return (
        <Box className={`cost-info ${isMobile && "cost-info-mobile"}`} w={confirmData?.showCost ? "260px" : "200px"}>
            <Box className='header-title-banner'>
                Road Build Cost
            </Box>

            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#A58860"}
                w={confirmData?.showCost ? "220px" : "80px"}
                p={1}
                borderRadius={"5px"}
            >
                <Image
                    src={playersSkinChoice && getIsometricRoadAsset(myGameData.turn_index, gameAssets, playersSkinChoice[myGameData.turn_index])}
                    alt='road'
                    width={"15px"}
                    style={{ transform: "rotateZ(60deg) rotateX(180deg)" }}
                    className={`${confirmData?.showCost && 'me-5'}`}
                />
                {
                    confirmData?.showCost &&
                    <Box display={"flex"} alignSelf={"center"}>
                        <Image
                            src={gameAssets.woodCard}
                            w={"40px"}
                            filter={resources.Wood > 0 ? undefined : "grayscale(100%)"} me={1}
                        />
                        <Image
                            src={gameAssets.brickCard}
                            w={"40px"}
                            filter={resources.Brick > 0 ? undefined : "grayscale(100%)"}
                        />
                    </Box>
                }
            </Box>

            <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} w={"300px"} mt={"10px"}>
                <IconButton
                    backgroundColor={"#85D885"}
                    colorScheme={"green"}
                    border={"2px solid black"}
                    borderRadius={"50%"}
                    fontSize={"20px"}
                    aria-label='confirm'
                    isDisabled={!(resources.Wood > 0 && resources.Brick > 0) && confirmData?.showCost}
                    onClick={handleRoadBuilding}
                    icon={<FaCheck />}
                />
                <IconButton
                    backgroundColor={"#F74747"}
                    colorScheme={"red"}
                    border={"2px solid black"}
                    borderRadius={"50%"}
                    fontSize={"20px"}
                    aria-label='cancel'
                    onClick={() => {
                        setConfirmData(null)
                    }}
                    icon={<FaTimes />}
                />
            </Box>
        </Box >
    )
}