import { Box, Button, Image } from "@chakra-ui/react"
import { FaCheckCircle } from "react-icons/fa";
import { getSettlementAsset } from "../../../../../utils/assets/getSettlementAsset"
import { useGame } from "../../../../../context/GameContext"
import { getIsometricRoadAsset } from "../../../../../utils/assets/getIsometricRoadAsset"
import { getCityAsset } from "../../../../../utils/assets/getCityAsset"
import { CreateChangeSkinChoice } from "../../../../../requests/DashboardRequests"
import { InfoMessage } from "../../../General/InfoMessage";

type SkinSetType = {
    skinName: string,
    settlement: any[],
    road: any[],
    city: any[],
    inUsage: boolean,
    isAvailable: boolean,
    fromChestName: string,
    setDefaultSkinChoice: (value: string) => void
}

export const SkinSet = ({ skinName, settlement, road, city, inUsage, isAvailable, fromChestName, setDefaultSkinChoice }: SkinSetType) => {
    const { gameAssets } = useGame()

    const haveCity = city.length > 0 || skinName === "Default"
    const haveRoad = road.length > 0 || skinName === "Default"
    const haveSettlement = settlement.length > 0 || skinName === "Default"

    const cityNumber = city.length
    const roadNumber = road.length
    const settlementNumber = settlement.length

    const canBeUsed = haveCity && haveRoad && haveSettlement

    const handleSkinChoiceChange = async () => {
        const response = await CreateChangeSkinChoice(skinName)
        if (response) {
            setDefaultSkinChoice(skinName)
        }
    }

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            mb={"2vw"}
        >
            <Box
                className='inventory-header-banners'
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                textColor={inUsage ? "#00ff77" : "white"}
            >
                {skinName}
                {inUsage && <FaCheckCircle style={{ marginLeft: "1vw", marginRight: "1vw" }} />}
                {
                    !isAvailable &&
                    <Box ms={"1vw"}>
                        (Coming Soon)
                    </Box>
                }
            </Box>
            <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                w={"37vw"}
                p={"1vw"}
            >
                <SkinBox
                    imageSrc={getSettlementAsset(0, gameAssets, skinName)}
                    haveSkin={haveSettlement}
                    skinName={skinName + " Settlement"}
                    skinImageWidth={"6vw"}
                    numberOfSkin={settlementNumber}
                />
                <SkinBox
                    imageSrc={getIsometricRoadAsset(0, gameAssets, skinName)}
                    haveSkin={haveRoad}
                    skinName={skinName + " Road"}
                    skinImageWidth={"1.5vw"}
                    numberOfSkin={roadNumber}
                />
                <SkinBox
                    imageSrc={getCityAsset(0, gameAssets, skinName)}
                    haveSkin={haveCity}
                    skinName={skinName + " City"}
                    skinImageWidth={"5vw"}
                    numberOfSkin={cityNumber}
                />
            </Box>
            {
                !canBeUsed && fromChestName && isAvailable &&
                <InfoMessage message={"You can collect pieces of this set from " + fromChestName + " Chest!"} />
            }
            {
                canBeUsed && !inUsage &&
                <Button
                    w={"15vw"}
                    h={"3vw"}
                    fontSize={"1.75vw"}
                    colorScheme={"green"}
                    border={"0.15vw solid #3F7600"}
                    borderRadius={"1vw"}
                    boxShadow={"0 0.15vw 0 0 #3F7600"}
                    onClick={handleSkinChoiceChange}
                >
                    Use Skin Set
                </Button>
            }
        </Box>
    )
}

const SkinBox = ({ imageSrc, haveSkin, skinName, skinImageWidth, numberOfSkin }: { imageSrc: string, haveSkin: boolean, skinName: string, skinImageWidth: string, numberOfSkin: number }) => {
    return (
        <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} w={"11vw"} h={"11vw"} backgroundColor={"#b19573"} borderRadius={"1vw"}>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} w={skinImageWidth} h={"9vw"}>
                <Image
                    src={imageSrc}
                    alt="city-asset"
                    filter={haveSkin ? 'none' : 'grayscale(100%)'}
                    opacity={haveSkin ? '1' : '0.5'}
                />
            </Box>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                w={"11vw"}
                h={"2vw"}
                fontSize={"1vw"}
                textColor={"white"}
                backgroundColor={"#7d6052"}
                borderRadius={"1vw"}
            >
                {skinName.length > 17 ? skinName.slice(0, 17) + '... ' : skinName}
                {numberOfSkin > 1 && ` (${numberOfSkin})`}
            </Box>
        </Box>
    )
}