export const getUsernameByAddress = (
  players: any[],
  address: string | null
) => {
  if (address && players.length > 0) {
    const player = players.find(
      (player) =>
        player.address.toLocaleLowerCase() === address.toLocaleLowerCase()
    );

    if (player) {
      return player.username.toUpperCase();
    } else {
      return null;
    }
  } else {
    return null;
  }
};
