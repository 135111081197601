export const getTurnIndexByAddress = (
  players: any[],
  address: string | null
) => {
  if (address && players.length > 0) {
    const player = players.find((player) => player.address === address);

    if (player) {
      return player.turnIndex;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
