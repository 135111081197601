import { useEffect, useState } from "react";
import { SocketType } from "../../../utils/types/types";
import { getVictoryPointAnimation } from "../../../utils/assets/getVictoryPointAnimation";
import { useGame } from "../../../context/GameContext";

export const VictoryPointAnimation = (socket: SocketType) => {
    const { gameAssets } = useGame()

    const [animationActive, setAnimationActive] = useState(false)

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            const data = socket.lastJsonMessage.data;
            const type = socket.lastJsonMessage.type;
            if (type === "Settle" || type === "City") {
                const parsedData = JSON.parse(data);
                if (parsedData) {

                    //Handle victory point animation
                    const settleElement = document.getElementById(`h/${parsedData.position[0]},${parsedData.position[1]}`)
                    const elements = document.querySelectorAll(`.vp-animation`);

                    if (elements) {
                        elements.forEach(element => element.remove())
                    }

                    if (settleElement) {
                        const animationElement = document.createElement("img")
                        animationElement.src = getVictoryPointAnimation(parsedData.turn, gameAssets)
                        animationElement.alt = "vp-animation"
                        animationElement.className = `vp-animation`

                        settleElement.appendChild(animationElement)
                        setAnimationActive(true)
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (animationActive) {
            const timer = setTimeout(() => {
                const elements = document.querySelectorAll(`.vp-animation`);

                if (elements) {
                    elements.forEach(element => element.remove());
                    setAnimationActive(false)
                }
            }, 2000)

            return () => {
                clearTimeout(timer);
            };
        }
    }, [animationActive])
}