import { SocketType } from "../../utils/types/types";

export const SendAnalytiActionEvent = (socket: SocketType, action: string) => {
  if (socket?.readyState) {
    socket?.sendMessage(
      JSON.stringify({
        type: "AnalyticAction",
        data: action,
      })
    );
  } else {
    console.error("Check socket connection...");
  }
};
