import { useSync } from "../context/SyncContext"
import { useGame } from "../context/GameContext"
import { Settings } from "../components/Game/Settings/Settings"
import { PassTurnButton } from "../components/Game/Buttons/PassTurn"
import { BuildingCost } from "../components/Game/Bank/BuildingCost"
import { WaitingLobby } from "../components/Game/Lobby/GameWaitingLobby/WaitingLobby"
import { Board } from "../components/Game/Board/Board"
import { ResourceCards } from "../components/Game/ResourceCard/ResourceCards"
import { Players } from "../components/Game/Players/Players"
import { TradeBox } from "../components/Game/Trade/TradeBox"
import { DevelopmentCards } from "../components/Game/DevelopmentCard/DevelopmentCards"
import { Thief } from "../components/Game/Robber/Thief"
import { RobberMoveNotification } from "../components/Game/Notifications/RobberMoveNotification"
import { DevCardUsageNotification } from "../components/Game/Notifications/DevCardUsageNotification"
import { RoadBuildingIndicator } from "../components/Game/Indicators/RoadBuildingIndicator"
import { PlayerTradeNotifications } from "../components/Game/Notifications/PlayerTradeNotifications"
import { MarketTradeNotifications } from "../components/Game/Notifications/MarketTradeNotifications"
import { WinnerLobby } from "../components/Game/Lobby/WinnerLobby/WinnerLobby"
import { ThiefMoveIndicator } from "../components/Game/Indicators/ThiefMoveIndicator"
import { PlayerList } from "../socket/PlayerList"
import { RemainingResources } from "../socket/RemainingResources"
import { Resources } from "../socket/Resources"
import { GameData } from "../socket/GameData"
import { RoadPositions } from "../socket/RoadPositions"
import { SettlePositions } from "../socket/SettlePositions"
import { TotalResCards } from "../socket/TotalResCards"
import { ArmyPoints } from "../socket/ArmyPoints"
import { LongestRoads } from "../socket/LongestRoads"
import { RobberPosition } from "../socket/RobberPosition"
import { Errors } from "../socket/Errors"
import { TradeData } from "../socket/TradeData"
import { DrawDevCardButton } from "../components/Game/DevelopmentCard/DrawDevCardButton"
import { LongestRoadNotification } from "../components/Game/Notifications/LongestRoadNotification"
import { VictoryPoints } from "../socket/VictoryPoints"
import { TotalDevCards } from "../socket/TotalDevCards"
import { DevCards } from "../socket/DevCards"
import { Quests } from "../socket/Quests"
import { DiceButton } from "../components/Game/Dice/DiceButton"
import { DevCardDrawAnimation } from "../components/Game/Animations/DevCardDrawAnimation"
import { RemainingDevCards } from "../socket/RemainingDevCards"
import { GameLogs } from "../components/Game/GameLogs/GameLogs"
import { LargestArmyNotification } from "../components/Game/Notifications/LargestArmyNotification"
import { ColorInfoNotification } from "../components/Game/Notifications/ColorInfoNotification"
import { ResourceSteelNotification } from "../components/Game/Notifications/ResourceSteelNotification"
import { SetupNotification } from "../components/Game/Notifications/SetupNotification"
import { DiceNotification } from "../components/Game/Notifications/DiceNotification"
import { YourTurnIndicator } from "../components/Game/Indicators/YourTurnIndicator"
import { CardDiscardNotification } from "../components/Game/Notifications/CardDiscardNotification"
import { Emoji } from "../components/Game/Buttons/Emoji"
import { EmojiNotification } from "../components/Game/Notifications/EmojiNotification"
import { VictoryPointAnimation } from "../components/Game/Animations/VictoryPointAnimation"
import { TradeRatio } from "../socket/TradeRatio"
import { LandscapeWarning } from "../components/Dashboard/General/LandscapeWarning"
import { TurnNotification } from "../components/Game/Notifications/TurnNotification"
import { TournamentLobby } from "../components/Game/Lobby/TournamentLobby"
import { TournamentJoin } from "../socket/TournamentJoin"
import { useSocket } from "../useSocket"
import { getGameBackground } from "../utils/assets/getGameBackground"
import { PlayerLeaveNotification } from "../components/Game/Notifications/PlayerLeaveNotification"
import { PlayerLostConnectionNotification } from "../components/Game/Notifications/PlayerLostConnectionNotification"
import { CounterTradeBox } from "../components/Game/Trade/CounterTradeBox"
import { Box } from "@chakra-ui/react"

export const Game = () => {
    const { gameID, gameAssets } = useGame()
    const { gameData } = useSync()

    const socket = useSocket(gameID)

    GameData(gameID, socket)
    PlayerList(socket)
    RemainingResources(socket)
    RemainingDevCards(socket)
    Resources(socket)
    DevCards(socket)
    RoadPositions(socket)
    VictoryPoints(socket)
    TotalDevCards(socket)
    TradeRatio(socket)
    SettlePositions(socket)
    TotalResCards(socket)
    ArmyPoints(socket)
    LongestRoads(socket)
    DiceNotification(socket)
    TurnNotification(socket)
    DevCardUsageNotification(socket)
    RobberPosition(socket)
    TradeData(socket)
    ResourceSteelNotification(socket)
    Errors(socket)
    Quests(socket)
    LargestArmyNotification(socket)
    LongestRoadNotification(socket)
    CardDiscardNotification(socket)
    VictoryPointAnimation(socket)
    EmojiNotification(socket)
    MarketTradeNotifications(socket)
    PlayerTradeNotifications(socket)
    PlayerLeaveNotification(socket)
    PlayerLostConnectionNotification(socket)
    TournamentJoin(socket)

    return (
        <Box
            id="game-screen"
            className="game-background"
            backgroundImage={getGameBackground(localStorage.getItem("background"), gameAssets)}
        >
            {
                gameData.Status === 0 && !gameData.GameOver && gameData.IsTournamentGame ?
                    <TournamentLobby /> :
                    <WaitingLobby socket={socket} />
            }
            {gameData.Status > 0 && <Board socket={socket} />}
            {
                gameData.Status > 0 && !gameData.GameOver &&
                <>
                    <Players socket={socket} />
                    <DevelopmentCards socket={socket} />
                    <DrawDevCardButton socket={socket} />
                    <Thief socket={socket} />
                    <TradeBox socket={socket} />
                    <CounterTradeBox socket={socket} />
                    <DiceButton socket={socket} />
                    <PassTurnButton socket={socket} />
                    <DevCardDrawAnimation socket={socket} />
                    <Emoji socket={socket} />
                    <RobberMoveNotification socket={socket} />
                    <GameLogs socket={socket} />
                    <ResourceCards />
                    <BuildingCost />
                    <SetupNotification />
                    <RoadBuildingIndicator />
                    <ThiefMoveIndicator />
                    <ColorInfoNotification />
                    <YourTurnIndicator />
                    <BottomPanel />
                    <Settings />
                </>
            }
            {gameData.GameOver && <WinnerLobby />}
            <LandscapeWarning />
        </Box>
    )
}

const BottomPanel = () => {
    return <Box className="bottom-panel" />
}