import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"
import { getUsernameByIndex } from '../utils/functions/getUsernameByIndex'
import { useGame } from '../context/GameContext'
import { logDivElement, logTextDivElement, usernameDivElement } from '../utils/gameLogs/gameLogs'
import { getColorByIndex } from '../utils/assets/getColorByIndex'
import { PlaySoundEffect } from "../sounds/playSoundEffect"
import { getIsometricRoadAsset } from "../utils/assets/getIsometricRoadAsset"

export const RoadPositions = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects } = useGame()
    const { setRoadPositions, players, playersSkinChoice } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "Road") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()
                    const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.turn), getColorByIndex(parsedData.turn))

                    const logText = logTextDivElement("built")

                    const road = document.createElement("img")
                    road.src = playersSkinChoice && getIsometricRoadAsset(parsedData.turn, gameAssets, playersSkinChoice[parsedData.turn])
                    road.alt = "road-icon"
                    road.style.height = "150%"
                    road.style.transform = "rotateZ(-60deg)"
                    road.style.marginLeft = "1vw"

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)
                    logDiv.appendChild(road)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                if (parsedData) {
                    setRoadPositions(current =>
                        [
                            ...current,
                            {
                                position: parsedData.position,
                                owner: parsedData.owner,
                                turnIndex: parsedData.turn
                            }
                        ]
                    );
                }

                PlaySoundEffect(soundEffects.buildRoadSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])
}