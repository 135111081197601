import { Modal, ModalOverlay, ModalContent, ModalBody, Image, Box, Button } from '@chakra-ui/react'
import { useGame } from '../../../../../context/GameContext'
import { DashboardCloseButton } from '../../../General/DashboardCloseButton'
import { CreateOpenChest } from '../../../../../requests/DashboardRequests'
import { useState } from 'react'
import { PlaySoundEffect } from '../../../../../sounds/playSoundEffect'

type ChestOpenModalType = {
    isOpen: boolean,
    onClose: () => void,
    chestRowID: number,
    chestID: number,
    chestImage: string,
    keys: any[],
    setItems: (value: any[]) => void
}

const skinNameBackground = "linear-gradient(90deg, rgba(187,18,2,0.5018382352941176) 0%, rgba(187,18,2,0.7035189075630253) 10%, rgba(187,18,2,0.804359243697479) 25%, rgba(187,18,2,1) 50%, rgba(187,18,2,0.8015581232492998) 75%, rgba(187,18,2,0.6979166666666667) 90%, rgba(187,18,2,0.5018382352941176) 100%);"

export const ChestOpenModal = ({ isOpen, onClose, chestRowID, chestID, chestImage, keys, setItems }: ChestOpenModalType) => {
    const { gameAssets, dashboardAssets, soundEffects } = useGame()

    const [isClosable, setIsClosable] = useState(true)

    const [skinData, setSkinData] = useState<{
        ID: number,
        Name: string,
        Description: string,
        Rarity: string,
        Type: string,
        ImageUrl: string,
        GetFromChest: boolean
    } | null>(null)

    const handleOpenChest = async () => {
        if (keys.length > 0) {
            const key = keys[0]
            const response = await CreateOpenChest(chestID, key.id)

            if (response) {
                setIsClosable(false)
                PlaySoundEffect(soundEffects.chestOpenSFX, 0.5, true)

                const chestElement = document.getElementById("chest-" + chestID)
                if (chestElement) {
                    chestElement.classList.remove("shake-animation")
                    chestElement.classList.add("shake-open-animation")
                }

                setTimeout(() => {
                    setSkinData(response.item)
                    PlaySoundEffect(soundEffects.dropItemSFX, 0.5, true)
                    setIsClosable(true)
                }, 3000);
            }
        }
    }

    const handleCloseModal = () => {
        if (skinData && keys.length > 0) {
            const key = keys[0]
            // Add new item to item state
            setItems(prevState =>
                [...prevState,
                {
                    id: skinData.ID,
                    name: skinData.Name,
                    description: skinData.Description,
                    rarity: skinData.Rarity,
                    type: skinData.Type,
                    image: skinData.ImageUrl
                }
                ])
            // Remove used chest and key from item state
            setItems(prevState => prevState.filter(item => item.row_id !== chestRowID && item.row_id !== key.row_id))
            setSkinData(null)
        }
        isClosable && onClose()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleCloseModal}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered={true}
        >
            <ModalOverlay />

            <ModalContent
                maxW={"50vw"}
                h={"40vw"}
                borderRadius={"3vw"}
                backgroundImage={gameAssets.royalBG}
                backgroundRepeat={"no-repeat"}
                backgroundSize={"cover"}
            >

                <ModalBody
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    p={"0"}
                >
                    <Box
                        display={"flex"}
                        flexDir={"column"}
                        alignItems={"center"}
                        justifyContent={"space-evenly"}
                        border={"0.5vw solid #715428"}
                        borderRadius={"3vw"}
                        w={"49vw"}
                        h={"39vw"}
                    >
                        <DashboardCloseButton onClose={handleCloseModal} />

                        <Box textColor={"#dcc89c"} fontSize={"3vw"} fontWeight={"700"}>
                            {
                                skinData ?
                                    "You got" :
                                    "Open Chest"
                            }
                        </Box>

                        <Image
                            src={dashboardAssets.inventoryShineEffect}
                            alt='shine-effect'
                            zIndex={"0"}
                            pos={"absolute"}
                        />

                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            w={"12vw"}
                        >
                            {
                                skinData ?
                                    <Image
                                        src={skinData.ImageUrl}
                                        alt='skin'
                                        className='slow-shake-animation'
                                        zIndex={"1"}
                                    />
                                    :
                                    <Image
                                        src={chestImage}
                                        alt='chest'
                                        id={"chest-" + chestID}
                                        className='shake-animation'
                                    />
                            }
                        </Box>

                        {
                            skinData ?
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    fontSize={"2.5vw"}
                                    textColor={"white"}
                                    background={skinNameBackground}
                                    w={"25vw"}
                                    h={"7vw"}
                                >
                                    {skinData.Name} {skinData.Type}
                                </Box> :
                                <Button
                                    w={"20vw"}
                                    h={"3vw"}
                                    fontSize={"2vw"}
                                    colorScheme={"green"}
                                    border={"0.25vw solid #3a8c3b"}
                                    boxShadow={"0 0.25vw #3a8c3b"}
                                    backgroundColor={"#44c44c"}
                                    isDisabled={!isClosable}
                                    onClick={handleOpenChest}
                                >
                                    <Image
                                        src={dashboardAssets.inventoryKeyIcon}
                                        alt='key-icon'
                                        pos={"absolute"}
                                        w={"2.5vw"}
                                        marginRight={"10vw"}
                                    />
                                    Open
                                </Button>
                        }
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}