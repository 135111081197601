import { Box, IconButton, Image } from "@chakra-ui/react"
import { getResponseLabelByIndex } from "../../../utils/assets/getColorByIndex"
import { useSync } from "../../../context/SyncContext"
import { useGame } from "../../../context/GameContext"

type PlayerTradeAnswerType = {
    turnIndex: number,
    address: string
}

export const PlayerTradeAnswer = ({ turnIndex, address }: PlayerTradeAnswerType) => {
    const { gameAssets } = useGame()
    const { responses, trade } = useSync()

    const playerResponse = responses.find((response) => response.turnIndex === turnIndex)
    const isTradeOwner = trade && trade.offerer === address

    if (trade) {
        return (
            <Box
                className={getResponseLabelByIndex(turnIndex)}
                pos={"absolute"}
                marginLeft={"0.75vw"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                w={"4.5vw"}
                h={"4.5vw"}
                borderRadius={"50%"}
                zIndex={2}
            >
                <IconButton
                    backgroundColor={"transparent"}
                    colorScheme={"none"}
                    aria-label='accept-button'
                    isLoading={!playerResponse && !isTradeOwner}
                    icon={
                        isTradeOwner ?
                            <Image src={gameAssets.tradeButton} alt='trade-owner-icon' width={"75%"} /> :
                            playerResponse &&
                                playerResponse.response ?
                                <Image src={gameAssets.acceptIcon} alt='accept-icon' width={"75%"} /> :
                                <Image src={gameAssets.refuseIcon} alt='refuse-icon' width={"75%"} />
                    }
                />
            </Box>
        )
    }
}