// Game Backgrounds
import blueBG from "../assets/Backgrounds/BG_blue.png";
import hexaBG from "../assets/Backgrounds/BG_hexa.png";
import paperBG from "../assets/Backgrounds/BG_paper.png";
import woodBG from "../assets/Backgrounds/BG_wood.png";
import royalBG from "../assets/Backgrounds/BG_Royal.png";

// Build Skins
import defaultCityBlue from "../assets/Items/Skins/Default/City_Lv2_Blue.png";
import defaultCityGrey from "../assets/Items/Skins/Default/City_Lv2_Grey.png";
import defaultCityPink from "../assets/Items/Skins/Default/City_Lv2_Pink.png";
import defaultCityRed from "../assets/Items/Skins/Default/City_Lv2_Red.png";
import defaultSettleBlue from "../assets/Items/Skins/Default/Settlement_Lv1_Blue.png";
import defaultSettleGrey from "../assets/Items/Skins/Default/Settlement_Lv1_Grey.png";
import defaultSettlePink from "../assets/Items/Skins/Default/Settlement_Lv1_Pink.png";
import defaultSettleRed from "../assets/Items/Skins/Default/Settlement_Lv1_Red.png";
import defaultIsometricBlueRoad from "../assets/Items/Skins/Default/Road_Diagonal_Blue.png";
import defaultIsometricGreyRoad from "../assets/Items/Skins/Default/Road_Diagonal_Grey.png";
import defaultIsometricPinkRoad from "../assets/Items/Skins/Default/Road_Diagonal_Pink.png";
import defaultIsometricRedRoad from "../assets/Items/Skins/Default/Road_Diagonal_Red.png";
import defaultStraightBlueRoad from "../assets/Items/Skins/Default/Road_Straight_Blue.png";
import defaultStraightGreyRoad from "../assets/Items/Skins/Default/Road_Straight_Grey.png";
import defaultStraightPinkRoad from "../assets/Items/Skins/Default/Road_Straight_Pink.png";
import defaultStraightRedRoad from "../assets/Items/Skins/Default/Road_Straight_Red.png";

import halloweenCityBlue from "../assets/Items/Skins/Halloween/Halloween_City_Lv2_Blue.png";
import halloweenCityGrey from "../assets/Items/Skins/Halloween/Halloween_City_Lv2_Grey.png";
import halloweenCityPink from "../assets/Items/Skins/Halloween/Halloween_City_Lv2_Pink.png";
import halloweenCityRed from "../assets/Items/Skins/Halloween/Halloween_City_Lv2_Red.png";
import halloweenSettleBlue from "../assets/Items/Skins/Halloween/Halloween_Settlement_Lv1_Blue.png";
import halloweenSettleGrey from "../assets/Items/Skins/Halloween/Halloween_Settlement_Lv1_Grey.png";
import halloweenSettlePink from "../assets/Items/Skins/Halloween/Halloween_Settlement_Lv1_Pink.png";
import halloweenSettleRed from "../assets/Items/Skins/Halloween/Halloween_Settlement_Lv1_Red.png";
import halloweenIsometricBlueRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Diagonal_Blue.png";
import halloweenIsometricGreyRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Diagonal_Grey.png";
import halloweenIsometricPinkRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Diagonal_Pink.png";
import halloweenIsometricRedRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Diagonal_Red.png";
import halloweenStraightBlueRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Straight_Blue.png";
import halloweenStraightGreyRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Straight_Grey.png";
import halloweenStraightPinkRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Straight_Pink.png";
import halloweenStraightRedRoad from "../assets/Items/Skins/Halloween/Halloween_Road_Straight_Red.png";

import airCityBlue from "../assets/Items/Skins/Air/Air_City_Lv2_Blue.png";
import airCityGrey from "../assets/Items/Skins/Air/Air_City_Lv2_Grey.png";
import airCityPink from "../assets/Items/Skins/Air/Air_City_Lv2_Pink.png";
import airCityRed from "../assets/Items/Skins/Air/Air_City_Lv2_Red.png";
import airSettleBlue from "../assets/Items/Skins/Air/Air_Settlement_Lv1_Blue.png";
import airSettleGrey from "../assets/Items/Skins/Air/Air_Settlement_Lv1_Grey.png";
import airSettlePink from "../assets/Items/Skins/Air/Air_Settlement_Lv1_Pink.png";
import airSettleRed from "../assets/Items/Skins/Air/Air_Settlement_Lv1_Red.png";
import airIsometricBlueRoad from "../assets/Items/Skins/Air/Air_Road_Diagonal_Blue.png";
import airIsometricGreyRoad from "../assets/Items/Skins/Air/Air_Road_Diagonal_Grey.png";
import airIsometricPinkRoad from "../assets/Items/Skins/Air/Air_Road_Diagonal_Pink.png";
import airIsometricRedRoad from "../assets/Items/Skins/Air/Air_Road_Diagonal_Red.png";
import airStraightBlueRoad from "../assets/Items/Skins/Air/Air_Road_Straight_Blue.png";
import airStraightGreyRoad from "../assets/Items/Skins/Air/Air_Road_Straight_Grey.png";
import airStraightPinkRoad from "../assets/Items/Skins/Air/Air_Road_Straight_Pink.png";
import airStraightRedRoad from "../assets/Items/Skins/Air/Air_Road_Straight_Red.png";

import emberCityBlue from "../assets/Items/Skins/Ember/Ember_City_Lv2_Blue.png";
import emberCityGrey from "../assets/Items/Skins/Ember/Ember_City_Lv2_Grey.png";
import emberCityPink from "../assets/Items/Skins/Ember/Ember_City_Lv2_Pink.png";
import emberCityRed from "../assets/Items/Skins/Ember/Ember_City_Lv2_Red.png";
import emberSettleBlue from "../assets/Items/Skins/Ember/Ember_Settlement_Lv1_Blue.png";
import emberSettleGrey from "../assets/Items/Skins/Ember/Ember_Settlement_Lv1_Grey.png";
import emberSettlePink from "../assets/Items/Skins/Ember/Ember_Settlement_Lv1_Pink.png";
import emberSettleRed from "../assets/Items/Skins/Ember/Ember_Settlement_Lv1_Red.png";
import emberIsometricBlueRoad from "../assets/Items/Skins/Ember/Ember_Road_Diagonal_Blue.png";
import emberIsometricGreyRoad from "../assets/Items/Skins/Ember/Ember_Road_Diagonal_Grey.png";
import emberIsometricPinkRoad from "../assets/Items/Skins/Ember/Ember_Road_Diagonal_Pink.png";
import emberIsometricRedRoad from "../assets/Items/Skins/Ember/Ember_Road_Diagonal_Red.png";
import emberStraightBlueRoad from "../assets/Items/Skins/Ember/Ember_Road_Straight_Blue.png";
import emberStraightGreyRoad from "../assets/Items/Skins/Ember/Ember_Road_Straight_Grey.png";
import emberStraightPinkRoad from "../assets/Items/Skins/Ember/Ember_Road_Straight_Pink.png";
import emberStraightRedRoad from "../assets/Items/Skins/Ember/Ember_Road_Straight_Red.png";

import pagodaCityBlue from "../assets/Items/Skins/Pagoda/Pagoda_City_Lv2_Blue.png";
import pagodaCityGrey from "../assets/Items/Skins/Pagoda/Pagoda_City_Lv2_Grey.png";
import pagodaCityPink from "../assets/Items/Skins/Pagoda/Pagoda_City_Lv2_Pink.png";
import pagodaCityRed from "../assets/Items/Skins/Pagoda/Pagoda_City_Lv2_Red.png";
import pagodaSettleBlue from "../assets/Items/Skins/Pagoda/Pagoda_Settlement_Lv1_Blue.png";
import pagodaSettleGrey from "../assets/Items/Skins/Pagoda/Pagoda_Settlement_Lv1_Grey.png";
import pagodaSettlePink from "../assets/Items/Skins/Pagoda/Pagoda_Settlement_Lv1_Pink.png";
import pagodaSettleRed from "../assets/Items/Skins/Pagoda/Pagoda_Settlement_Lv1_Red.png";
import pagodaIsometricBlueRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Diagonal_Blue.png";
import pagodaIsometricGreyRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Diagonal_Grey.png";
import pagodaIsometricPinkRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Diagonal_Pink.png";
import pagodaIsometricRedRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Diagonal_Red.png";
import pagodaStraightBlueRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Straight_Blue.png";
import pagodaStraightGreyRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Straight_Grey.png";
import pagodaStraightPinkRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Straight_Pink.png";
import pagodaStraightRedRoad from "../assets/Items/Skins/Pagoda/Pagoda_Road_Straight_Red.png";

import vikingCityBlue from "../assets/Items/Skins/Viking/Viking_City_Lv2_Blue.png";
import vikingCityGrey from "../assets/Items/Skins/Viking/Viking_City_Lv2_Grey.png";
import vikingCityPink from "../assets/Items/Skins/Viking/Viking_City_Lv2_Pink.png";
import vikingCityRed from "../assets/Items/Skins/Viking/Viking_City_Lv2_Red.png";
import vikingSettleBlue from "../assets/Items/Skins/Viking/Viking_Settlement_Lv1_Blue.png";
import vikingSettleGrey from "../assets/Items/Skins/Viking/Viking_Settlement_Lv1_Grey.png";
import vikingSettlePink from "../assets/Items/Skins/Viking/Viking_Settlement_Lv1_Pink.png";
import vikingSettleRed from "../assets/Items/Skins/Viking/Viking_Settlement_Lv1_Red.png";
import vikingIsometricBlueRoad from "../assets/Items/Skins/Viking/Viking_Road_Diagonal_Blue.png";
import vikingIsometricGreyRoad from "../assets/Items/Skins/Viking/Viking_Road_Diagonal_Grey.png";
import vikingIsometricPinkRoad from "../assets/Items/Skins/Viking/Viking_Road_Diagonal_Pink.png";
import vikingIsometricRedRoad from "../assets/Items/Skins/Viking/Viking_Road_Diagonal_Red.png";
import vikingStraightBlueRoad from "../assets/Items/Skins/Viking/Viking_Road_Straight_Blue.png";
import vikingStraightGreyRoad from "../assets/Items/Skins/Viking/Viking_Road_Straight_Grey.png";
import vikingStraightPinkRoad from "../assets/Items/Skins/Viking/Viking_Road_Straight_Pink.png";
import vikingStraightRedRoad from "../assets/Items/Skins/Viking/Viking_Road_Straight_Red.png";

// Dev cards
import devCardKnight from "../assets/DevCards/DevCard_Knight.png";
import devCardMonoply from "../assets/DevCards/DevCard_Monopoly.png";
import devCardRoadBuilding from "../assets/DevCards/DevCard_Road_Building.png";
import devCardVictoryPoint from "../assets/DevCards/DevCard_VictoryPoint.png";
import devCardYearOfPlenty from "../assets/DevCards/DevCard_Year_of_Plenty.png";

// Emojis
import angryEmoji from "../assets/Emojis/emoji_angry.png";
import annoyedEmoji from "../assets/Emojis/emoji_annoyed.png";
import cryingEmoji from "../assets/Emojis/emoji_crying.png";
import evilEmoji from "../assets/Emojis/emoji_evil.png";
import happyEmoji from "../assets/Emojis/emoji_happy.png";
import suprisedEmoji from "../assets/Emojis/emoji_surprised.png";

import woodCard from "../assets/ResCards/Card_Wood.png";
import brickCard from "../assets/ResCards/Card_Brick.png";
import sheepCard from "../assets/ResCards/Card_Sheep.png";
import wheatCard from "../assets/ResCards/Card_Wheat.png";
import oreCard from "../assets/ResCards/Card_Ore.png";

import woodIcon from "../assets/ResourceIcons/icon_resource_wood.png";
import brickIcon from "../assets/ResourceIcons/icon_resource_brick.png";
import sheepIcon from "../assets/ResourceIcons/icon_resource_sheep.png";
import wheatIcon from "../assets/ResourceIcons/icon_resource_wheat.png";
import oreIcon from "../assets/ResourceIcons/icon_resource_ore.png";

import woodTile from "../assets/Tiles/Resources/wood.png";
import brickTile from "../assets/Tiles/Resources/brick.png";
import sheepTile from "../assets/Tiles/Resources/sheep.png";
import wheatTile from "../assets/Tiles/Resources/wheat.png";
import oreTile from "../assets/Tiles/Resources/ore.png";
import desertTile from "../assets/Tiles/Resources/desert.png";

import woodPort from "../assets/Ships/Trade_Boat_WoodRatio.png";
import brickPort from "../assets/Ships/Trade_Boat_BrickRatio.png";
import sheepPort from "../assets/Ships/Trade_Boat_SheepRatio.png";
import wheatPort from "../assets/Ships/Trade_Boat_WheatRatio.png";
import orePort from "../assets/Ships/Trade_Boat_OreRatio.png";
import anyPort from "../assets/Ships/Trade_Boat_Trade.png";

import dice1 from "../animations/Dices/dice1.gif";
import dice2 from "../animations/Dices/dice2.gif";
import dice3 from "../animations/Dices/dice3.gif";
import dice4 from "../animations/Dices/dice4.gif";
import dice5 from "../animations/Dices/dice5.gif";
import dice6 from "../animations/Dices/dice6.gif";
import diceFlip1 from "../animations/Dices/dice1_flip.gif";
import diceFlip2 from "../animations/Dices/dice2_flip.gif";
import diceFlip3 from "../animations/Dices/dice3_flip.gif";
import diceFlip4 from "../animations/Dices/dice4_flip.gif";
import diceFlip5 from "../animations/Dices/dice5_flip.gif";
import diceFlip6 from "../animations/Dices/dice6_flip.gif";

import blueVPIcon from "../assets/VictoryPoints/score_blue.png";
import redVPIcon from "../assets/VictoryPoints/score_red.png";
import pinkVPIcon from "../assets/VictoryPoints/score_pink.png";
import greyVPIcon from "../assets/VictoryPoints/score_grey.png";

import oreGatherIcon from "../assets/ResourceGather/ore_gather.png";
import woodGatherIcon from "../assets/ResourceGather/wood_gather.png";
import brickGatherIcon from "../assets/ResourceGather/brick_gather.png";
import sheepGatherIcon from "../assets/ResourceGather/sheep_gather.png";
import wheatGatherIcon from "../assets/ResourceGather/wheat_gather.png";

import blueVPLabel from "../assets/HUD/PlayerHUD/score_blue.png";
import redVPLabel from "../assets/HUD/PlayerHUD/score_red.png";
import pinkVPLabel from "../assets/HUD/PlayerHUD/score_pink.png";
import grayVPLabel from "../assets/HUD/PlayerHUD/score_grey.png";

import blueNameLabel from "../assets/HUD/PlayerHUD/label_name_blue.png";
import redNameLabel from "../assets/HUD/PlayerHUD/label_name_red.png";
import pinkNameLabel from "../assets/HUD/PlayerHUD/label_name_pink.png";
import grayNameLabel from "../assets/HUD/PlayerHUD/label_name_grey.png";

import largestArmyIcon from "../assets/HUD/PlayerHUD/icon_knight.png";
import longestRoadIcon from "../assets/HUD/PlayerHUD/icon_long_road.png";
import totalDevCardIcon from "../assets/HUD/PlayerHUD/icon_card_dev.png";

import dice1Icon from "../assets/HUD/PlayerHUD/Dices/1_dice.png";
import dice2Icon from "../assets/HUD/PlayerHUD/Dices/2_dice.png";
import dice3Icon from "../assets/HUD/PlayerHUD/Dices/3_dice.png";
import dice4Icon from "../assets/HUD/PlayerHUD/Dices/4_dice.png";
import dice5Icon from "../assets/HUD/PlayerHUD/Dices/5_dice.png";
import dice6Icon from "../assets/HUD/PlayerHUD/Dices/6_dice.png";

import cityDefault from "../assets/HUD/Bank_Panel/City_Default.png";
import settleDefault from "../assets/HUD/Bank_Panel/Settlement_Default.png";
import roadDefault from "../assets/HUD/Bank_Panel/Road_Default.png";
import vpLabelDefault from "../assets/HUD/Bank_Panel/Vp_Label_Default.png";

import backButton from "../assets/HUD/BottomHUD/Back_Button.png";
import bankButton from "../assets/HUD/BottomHUD/Bank_Button.png";
import playersHudIcon from "../assets/HUD/Trader_Panel/player_icons.png";
import buyDevCardButton from "../assets/HUD/BottomHUD/Buy_DevCard_Button.png";
import disableDiceIcon from "../assets/HUD/BottomHUD/Disabled_Dice_Button.png";
import settingsDivider from "../assets/HUD/Menu_Panel/settings_divider.png";
import gameLogDivider from "../assets/HUD/BottomHUD/Divider.png";
import emojiButton from "../assets/HUD/BottomHUD/Emoji_Button.png";
import gameLogButton from "../assets/HUD/BottomHUD/Game_Log_Button.png";
import devCardIcon from "../assets/HUD/BottomHUD/DevCard_Icon.png";
import menuButton from "../assets/HUD/BottomHUD/Menu_Button.png";
import passButton from "../assets/HUD/BottomHUD/Pass_Button.png";
import rollDiceButton from "../assets/HUD/BottomHUD/Roll_Dice_Button.png";
import tradeButton from "../assets/HUD/BottomHUD/Trade_Button.png";
import yourTurnButton from "../assets/HUD/BottomHUD/Your_Turn_Button.png";
import receiveIcon from "../assets/HUD/Player_TradeRequest/arrow_get.png";
import giveIcon from "../assets/HUD/Player_TradeRequest/arrow_give.png";
import acceptIcon from "../assets/HUD/Trader_Panel/accepted_icon.png";
import refuseIcon from "../assets/HUD/Trader_Panel/refuse_icon.png";
import clockIcon from "../assets/HUD/PlayerHUD/icon_clock.png";
import diceIcon from "../assets/HUD/PlayerHUD/icon_dice.png";

import bottomRightSingleDock from "../assets/Tiles/Dock/Bottom_Right_Single_Dock.png";
import bottomDualDock from "../assets/Tiles/Dock/Bottom_Dual_Dock.png";
import bottomLeftDualDock from "../assets/Tiles/Dock/Bottom_Left_Dual_Dock.png";
import topRightDualDock from "../assets/Tiles/Dock/Top_Right_Dual_Dock.png";
import leftSingleDock from "../assets/Tiles/Dock/Left_Single_Dock.png";
import bottomRightDualDock from "../assets/Tiles/Dock/Bottom_Right_Dual_Dock.png";
import topDualDock from "../assets/Tiles/Dock/Top_Dual_Dock.png";
import topRightSingleDock from "../assets/Tiles/Dock/Top_Right_Single_Dock.png";
import topLeftDualDock from "../assets/Tiles/Dock/Top_Left_Dual_Dock.png";

import topDualBeach from "../assets/Tiles/Beach/Top_Dual_Beach.png";
import leftBottomDualBeach from "../assets/Tiles/Beach/Left_Bottom_Dual_Beach.png";
import leftSingleBeach from "../assets/Tiles/Beach/Left_Single_Beach.png";
import leftBottomSingleBeach from "../assets/Tiles/Beach/Left_Bottom_Single_Beach.png";

import emojiOn from "../assets/HUD/Menu_Panel/emojiFX_ON.png";
import emojiOff from "../assets/HUD/Menu_Panel/emojiFX_OFF.png";
import musicOn from "../assets/HUD/Menu_Panel/music_ON.png";
import musicOf from "../assets/HUD/Menu_Panel/music_OFF.png";
import effectOn from "../assets/HUD/Menu_Panel/soundFX_ON.png";
import effectOff from "../assets/HUD/Menu_Panel/soundFX_OFF.png";
import seaButton from "../assets/HUD/Menu_Panel/wallpaper_sea_button.png";
import tableButton from "../assets/HUD/Menu_Panel/wallpaper_table_button.png";
import paperButton from "../assets/HUD/Menu_Panel/wallpaper_paper_button.png";
import hexButton from "../assets/HUD/Menu_Panel/wallpaper_green_button.png";
import royalButton from "../assets/HUD/Menu_Panel/wallpaper_royal_button.png";

import discardIcon from "../assets/HUD/Indicators/icon_arrow_Down.png";
import knightArt from "../assets/HUD/Indicators/knight_art.png";
import knightIcon from "../assets/HUD/Indicators/knight_icon.png";
import lostResourceIcon from "../assets/HUD/Indicators/icon_arrow_Down.png";
import largestArmyLabel from "../assets/HUD/Indicators/army_icon.png";
import longestRoadLabel from "../assets/HUD/Indicators/road_icon.png";
import leaderLabel from "../assets/HUD/PlayerHUD/label_winning.png";
import thiefIcon from "../assets/HUD/Indicators/icon_robber.png";
import hammerIcon from "../assets/HUD/Indicators/build_icon.png";
import yourTurnNotifIcon from "../assets/HUD/Indicators/icon_arrow_up.png";
import moveRobberNotif from "../assets/HUD/Indicators/move_robber_notif.png";
import playerLeaveIcon from "../assets/HUD/Indicators/leave_icon.png";
import playerLostConnectionIcon from "../assets/HUD/Indicators/lost_connection_icon.png";

import questButton from "../assets/HUD/WinnerPanel/icon_mission_asset.png";
import largeDivider from "../assets/HUD/Indicators/large_divider.png";
import largestArmyNumberIcon from "../assets/HUD/PlayerHUD/icon_knight.png";
import longestRoadNumberIcon from "../assets/HUD/PlayerHUD/icon_long_road.png";
import devCardNumberIcon from "../assets/HUD/PlayerHUD/icon_card_dev.png";
import settleNumberIcon from "../assets/HUD/WinnerPanel/icon_settlements.png";
import cityNumberIcon from "../assets/HUD/WinnerPanel/icon_cities.png";
import roadNumberIcon from "../assets/HUD/WinnerPanel/icon_roads.png";

export const gameAssetsList: any = {
  blueBG: blueBG,
  hexaBG: hexaBG,
  paperBG: paperBG,
  woodBG: woodBG,
  royalBG: royalBG,
  defaultCityBlue: defaultCityBlue,
  defaultCityGrey: defaultCityGrey,
  defaultCityPink: defaultCityPink,
  defaultCityRed: defaultCityRed,
  defaultSettleBlue: defaultSettleBlue,
  defaultSettleGrey: defaultSettleGrey,
  defaultSettlePink: defaultSettlePink,
  defaultSettleRed: defaultSettleRed,
  defaultIsometricBlueRoad: defaultIsometricBlueRoad,
  defaultIsometricGreyRoad: defaultIsometricGreyRoad,
  defaultIsometricPinkRoad: defaultIsometricPinkRoad,
  defaultIsometricRedRoad: defaultIsometricRedRoad,
  defaultStraightBlueRoad: defaultStraightBlueRoad,
  defaultStraightGreyRoad: defaultStraightGreyRoad,
  defaultStraightPinkRoad: defaultStraightPinkRoad,
  defaultStraightRedRoad: defaultStraightRedRoad,
  halloweenCityBlue: halloweenCityBlue,
  halloweenCityGrey: halloweenCityGrey,
  halloweenCityPink: halloweenCityPink,
  halloweenCityRed: halloweenCityRed,
  halloweenSettleBlue: halloweenSettleBlue,
  halloweenSettleGrey: halloweenSettleGrey,
  halloweenSettlePink: halloweenSettlePink,
  halloweenSettleRed: halloweenSettleRed,
  halloweenIsometricBlueRoad: halloweenIsometricBlueRoad,
  halloweenIsometricGreyRoad: halloweenIsometricGreyRoad,
  halloweenIsometricPinkRoad: halloweenIsometricPinkRoad,
  halloweenIsometricRedRoad: halloweenIsometricRedRoad,
  halloweenStraightBlueRoad: halloweenStraightBlueRoad,
  halloweenStraightGreyRoad: halloweenStraightGreyRoad,
  halloweenStraightPinkRoad: halloweenStraightPinkRoad,
  halloweenStraightRedRoad: halloweenStraightRedRoad,
  airCityBlue: airCityBlue,
  airCityGrey: airCityGrey,
  airCityPink: airCityPink,
  airCityRed: airCityRed,
  airSettleBlue: airSettleBlue,
  airSettleGrey: airSettleGrey,
  airSettlePink: airSettlePink,
  airSettleRed: airSettleRed,
  airIsometricBlueRoad: airIsometricBlueRoad,
  airIsometricGreyRoad: airIsometricGreyRoad,
  airIsometricPinkRoad: airIsometricPinkRoad,
  airIsometricRedRoad: airIsometricRedRoad,
  airStraightBlueRoad: airStraightBlueRoad,
  airStraightGreyRoad: airStraightGreyRoad,
  airStraightPinkRoad: airStraightPinkRoad,
  airStraightRedRoad: airStraightRedRoad,
  emberCityBlue: emberCityBlue,
  emberCityGrey: emberCityGrey,
  emberCityPink: emberCityPink,
  emberCityRed: emberCityRed,
  emberSettleBlue: emberSettleBlue,
  emberSettleGrey: emberSettleGrey,
  emberSettlePink: emberSettlePink,
  emberSettleRed: emberSettleRed,
  emberIsometricBlueRoad: emberIsometricBlueRoad,
  emberIsometricGreyRoad: emberIsometricGreyRoad,
  emberIsometricPinkRoad: emberIsometricPinkRoad,
  emberIsometricRedRoad: emberIsometricRedRoad,
  emberStraightBlueRoad: emberStraightBlueRoad,
  emberStraightGreyRoad: emberStraightGreyRoad,
  emberStraightPinkRoad: emberStraightPinkRoad,
  emberStraightRedRoad: emberStraightRedRoad,
  pagodaCityBlue: pagodaCityBlue,
  pagodaCityGrey: pagodaCityGrey,
  pagodaCityPink: pagodaCityPink,
  pagodaCityRed: pagodaCityRed,
  pagodaSettleBlue: pagodaSettleBlue,
  pagodaSettleGrey: pagodaSettleGrey,
  pagodaSettlePink: pagodaSettlePink,
  pagodaSettleRed: pagodaSettleRed,
  pagodaIsometricBlueRoad: pagodaIsometricBlueRoad,
  pagodaIsometricGreyRoad: pagodaIsometricGreyRoad,
  pagodaIsometricPinkRoad: pagodaIsometricPinkRoad,
  pagodaIsometricRedRoad: pagodaIsometricRedRoad,
  pagodaStraightBlueRoad: pagodaStraightBlueRoad,
  pagodaStraightGreyRoad: pagodaStraightGreyRoad,
  pagodaStraightPinkRoad: pagodaStraightPinkRoad,
  pagodaStraightRedRoad: pagodaStraightRedRoad,
  vikingCityBlue: vikingCityBlue,
  vikingCityGrey: vikingCityGrey,
  vikingCityPink: vikingCityPink,
  vikingCityRed: vikingCityRed,
  vikingSettleBlue: vikingSettleBlue,
  vikingSettleGrey: vikingSettleGrey,
  vikingSettlePink: vikingSettlePink,
  vikingSettleRed: vikingSettleRed,
  vikingIsometricBlueRoad: vikingIsometricBlueRoad,
  vikingIsometricGreyRoad: vikingIsometricGreyRoad,
  vikingIsometricPinkRoad: vikingIsometricPinkRoad,
  vikingIsometricRedRoad: vikingIsometricRedRoad,
  vikingStraightBlueRoad: vikingStraightBlueRoad,
  vikingStraightGreyRoad: vikingStraightGreyRoad,
  vikingStraightPinkRoad: vikingStraightPinkRoad,
  vikingStraightRedRoad: vikingStraightRedRoad,
  devCardKnight: devCardKnight,
  devCardMonoply: devCardMonoply,
  devCardRoadBuilding: devCardRoadBuilding,
  devCardVictoryPoint: devCardVictoryPoint,
  devCardYearOfPlenty: devCardYearOfPlenty,
  angryEmoji: angryEmoji,
  annoyedEmoji: annoyedEmoji,
  cryingEmoji: cryingEmoji,
  evilEmoji: evilEmoji,
  happyEmoji: happyEmoji,
  suprisedEmoji: suprisedEmoji,
  woodCard: woodCard,
  brickCard: brickCard,
  sheepCard: sheepCard,
  wheatCard: wheatCard,
  oreCard: oreCard,
  woodIcon: woodIcon,
  brickIcon: brickIcon,
  sheepIcon: sheepIcon,
  wheatIcon: wheatIcon,
  oreIcon: oreIcon,
  woodTile: woodTile,
  brickTile: brickTile,
  sheepTile: sheepTile,
  wheatTile: wheatTile,
  oreTile: oreTile,
  desertTile: desertTile,
  woodPort: woodPort,
  brickPort: brickPort,
  sheepPort: sheepPort,
  wheatPort: wheatPort,
  orePort: orePort,
  anyPort: anyPort,
  dice1: dice1,
  dice2: dice2,
  dice3: dice3,
  dice4: dice4,
  dice5: dice5,
  dice6: dice6,
  diceFlip1: diceFlip1,
  diceFlip2: diceFlip2,
  diceFlip3: diceFlip3,
  diceFlip4: diceFlip4,
  diceFlip5: diceFlip5,
  diceFlip6: diceFlip6,
  blueVPIcon: blueVPIcon,
  redVPIcon: redVPIcon,
  pinkVPIcon: pinkVPIcon,
  greyVPIcon: greyVPIcon,
  oreGatherIcon: oreGatherIcon,
  woodGatherIcon: woodGatherIcon,
  brickGatherIcon: brickGatherIcon,
  sheepGatherIcon: sheepGatherIcon,
  wheatGatherIcon: wheatGatherIcon,
  blueVPLabel: blueVPLabel,
  redVPLabel: redVPLabel,
  pinkVPLabel: pinkVPLabel,
  grayVPLabel: grayVPLabel,
  blueNameLabel: blueNameLabel,
  redNameLabel: redNameLabel,
  pinkNameLabel: pinkNameLabel,
  grayNameLabel: grayNameLabel,
  largestArmyIcon: largestArmyIcon,
  longestRoadIcon: longestRoadIcon,
  totalDevCardIcon: totalDevCardIcon,
  dice1Icon: dice1Icon,
  dice2Icon: dice2Icon,
  dice3Icon: dice3Icon,
  dice4Icon: dice4Icon,
  dice5Icon: dice5Icon,
  dice6Icon: dice6Icon,
  cityDefault: cityDefault,
  settleDefault: settleDefault,
  roadDefault: roadDefault,
  vpLabelDefault: vpLabelDefault,
  backButton: backButton,
  bankButton: bankButton,
  playersHudIcon: playersHudIcon,
  buyDevCardButton: buyDevCardButton,
  disableDiceIcon: disableDiceIcon,
  settingsDivider: settingsDivider,
  gameLogDivider: gameLogDivider,
  emojiButton: emojiButton,
  gameLogButton: gameLogButton,
  devCardIcon: devCardIcon,
  menuButton: menuButton,
  passButton: passButton,
  rollDiceButton: rollDiceButton,
  tradeButton: tradeButton,
  yourTurnButton: yourTurnButton,
  receiveIcon: receiveIcon,
  giveIcon: giveIcon,
  acceptIcon: acceptIcon,
  refuseIcon: refuseIcon,
  clockIcon: clockIcon,
  diceIcon: diceIcon,
  bottomRightSingleDock: bottomRightSingleDock,
  bottomDualDock: bottomDualDock,
  bottomLeftDualDock: bottomLeftDualDock,
  topRightDualDock: topRightDualDock,
  leftSingleDock: leftSingleDock,
  bottomRightDualDock: bottomRightDualDock,
  topDualDock: topDualDock,
  topRightSingleDock: topRightSingleDock,
  topLeftDualDock: topLeftDualDock,
  topDualBeach: topDualBeach,
  leftBottomDualBeach: leftBottomDualBeach,
  leftSingleBeach: leftSingleBeach,
  leftBottomSingleBeach: leftBottomSingleBeach,
  emojiOn: emojiOn,
  emojiOff: emojiOff,
  musicOn: musicOn,
  musicOf: musicOf,
  effectOn: effectOn,
  effectOff: effectOff,
  seaButton: seaButton,
  tableButton: tableButton,
  paperButton: paperButton,
  hexButton: hexButton,
  royalButton: royalButton,
  discardIcon: discardIcon,
  knightArt: knightArt,
  knightIcon: knightIcon,
  lostResourceIcon: lostResourceIcon,
  largestArmyLabel: largestArmyLabel,
  longestRoadLabel: longestRoadLabel,
  leaderLabel: leaderLabel,
  thiefIcon: thiefIcon,
  hammerIcon: hammerIcon,
  yourTurnNotifIcon: yourTurnNotifIcon,
  moveRobberNotif: moveRobberNotif,
  playerLeaveIcon: playerLeaveIcon,
  playerLostConnectionIcon: playerLostConnectionIcon,
  questButton: questButton,
  largeDivider: largeDivider,
  largestArmyNumberIcon: largestArmyNumberIcon,
  longestRoadNumberIcon: longestRoadNumberIcon,
  devCardNumberIcon: devCardNumberIcon,
  settleNumberIcon: settleNumberIcon,
  cityNumberIcon: cityNumberIcon,
  roadNumberIcon: roadNumberIcon,
};
