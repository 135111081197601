import { Box, Button, IconButton, Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { Timer } from "../Timer/Timer"
import { FaCheck, FaTimes } from "react-icons/fa"
import { SocketType } from "../../../utils/types/types"
import { useEffect, useState } from "react"
import { useSync } from "../../../context/SyncContext"
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent"

export const PlayerCounterTradeOffer = ({ socket, address }: { socket: SocketType, address: string }) => {
    const { resources, myGameData, counterTrades, trade } = useSync()
    const { gameAssets, gameID, isSpectator } = useGame()

    const [disable, setDisable] = useState(true)

    const counterTrade = counterTrades.find((counterTrade) => counterTrade.offerer === address)

    // Validation for offer accepts
    useEffect(() => {
        if (counterTrade) {
            const cardsGiven = {
                brick: counterTrade.wanted.brick ? counterTrade.wanted.brick : 0,
                ore: counterTrade.wanted.ore ? counterTrade.wanted.ore : 0,
                sheep: counterTrade.wanted.sheep ? counterTrade.wanted.sheep : 0,
                wheat: counterTrade.wanted.wheat ? counterTrade.wanted.wheat : 0,
                wood: counterTrade.wanted.wood ? counterTrade.wanted.wood : 0,
            }

            if (resources.Brick >= cardsGiven.brick &&
                resources.Ore >= cardsGiven.ore &&
                resources.Sheep >= cardsGiven.sheep &&
                resources.Wheat >= cardsGiven.wheat &&
                resources.Wood >= cardsGiven.wood) {
                setDisable(false)
            } else {
                setDisable(true)
            }
        } else {
            setDisable(true)
        }
    }, [counterTrade, resources])

    // Give response to active trade offer
    const handleOffer = async (response: boolean) => {
        const eventData = {
            id: gameID,
            tradeID: counterTrade?.id,
            response: response,
            counterOffer: true
        }
        SendGameEvent(socket, "TradeRespond", eventData)
    }

    const handleCancelCT = async () => {
        const eventData = {
            id: gameID,
            tradeID: counterTrade?.id,
            response: "0",
            counterOffer: true
        }
        SendGameEvent(socket, "TradeComplete", eventData)
    }

    const amIMainOfferer = myGameData && trade && trade.offerer === myGameData.address
    const amIOfferer = myGameData && counterTrade && counterTrade.offerer === myGameData.address

    if (counterTrade && counterTrade.offerer === address) {
        return (
            <Box className="trade-notification player-counter-trade-offer-notif">
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"space-around"}
                >
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-evenly"}
                        backgroundColor={"#FDEEDA"}
                        w={"16.5vw"}
                        mb={"0.5vw"}
                        pl={"12%"}
                    >
                        <Image
                            src={gameAssets.receiveIcon}
                            alt='receive-icon'
                            width={"8%"}
                            pos={"absolute"}
                            left={"0.5vw"}
                        />
                        <OfferData number={amIOfferer ? counterTrade.wanted.wood : counterTrade.offer.wood} iconSrc={gameAssets.woodIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? counterTrade.wanted.brick : counterTrade.offer.brick} iconSrc={gameAssets.brickIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? counterTrade.wanted.sheep : counterTrade.offer.sheep} iconSrc={gameAssets.sheepIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? counterTrade.wanted.wheat : counterTrade.offer.wheat} iconSrc={gameAssets.wheatIcon} isGiving={false} />
                        <OfferData number={amIOfferer ? counterTrade.wanted.ore : counterTrade.offer.ore} iconSrc={gameAssets.oreIcon} isGiving={false} />
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-evenly"}
                        backgroundColor={"#FDEEDA"}
                        w={"16.5vw"}
                        pl={"12%"}
                    >
                        <Image
                            src={gameAssets.giveIcon}
                            alt='give-icon'
                            width={"8%"}
                            pos={"absolute"}
                            left={"0.5vw"}
                        />
                        <OfferData number={amIOfferer ? counterTrade.offer.wood : counterTrade.wanted.wood} iconSrc={gameAssets.woodIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? counterTrade.offer.brick : counterTrade.wanted.brick} iconSrc={gameAssets.brickIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? counterTrade.offer.sheep : counterTrade.wanted.sheep} iconSrc={gameAssets.sheepIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? counterTrade.offer.wheat : counterTrade.wanted.wheat} iconSrc={gameAssets.wheatIcon} isGiving={true} />
                        <OfferData number={amIOfferer ? counterTrade.offer.ore : counterTrade.wanted.ore} iconSrc={gameAssets.oreIcon} isGiving={true} />
                    </Box>
                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} w={"17.5vw"} p={"0.25vw"}>
                    <Timer activatedTime={counterTrade.time} timeLimit={30} />
                    {
                        amIMainOfferer && !isSpectator &&
                        <>
                            <IconButton
                                minW={"2.5vw"}
                                maxH={"2.5vw"}
                                fontSize={"1vw"}
                                backgroundColor={"#52d55b"}
                                colorScheme={"green"}
                                border={"0.25vw solid #408053"}
                                borderRadius={"0.5vw"}
                                aria-label='accept-offer'
                                isDisabled={disable}
                                onClick={() => handleOffer(true)}
                                icon={<FaCheck />} />
                            <IconButton
                                minW={"2.5vw"}
                                maxH={"2.5vw"}
                                fontSize={"1vw"}
                                backgroundColor={"#e4685d"}
                                border={"0.25vw solid #c23d28"}
                                colorScheme={"red"}
                                borderRadius={"0.5vw"}
                                aria-label='reject-offer'
                                onClick={() => handleOffer(false)}
                                icon={<FaTimes />} />
                        </>
                    }
                    {
                        amIOfferer &&
                        <Button
                            w={"7vw"}
                            h={"2vw"}
                            fontSize={"1.5vw"}
                            backgroundColor={"#E94F4F"}
                            border={"0.25vw solid #c23d28"}
                            borderRadius={"0.5vw"}
                            colorScheme={'red'}
                            onClick={handleCancelCT}
                        >
                            Cancel
                        </Button>
                    }
                </Box>
            </Box>
        )
    } else {
        return null
    }
}

type OfferDataType = {
    number: number,
    iconSrc: string,
    isGiving: boolean
}

const OfferData = ({ number, iconSrc, isGiving }: OfferDataType) => {
    if (number && number > 0) {
        return (
            <Box
                display={"flex"}
                alignItems={"center"}
            >
                <Box
                    textColor={isGiving ? "#d1523d" : "#77a34b"}
                    fontSize={"1.5vw"}
                    fontWeight={"700"}
                >
                    {isGiving ? "-" : "+"}
                    {number}
                </Box>
                <Image
                    src={iconSrc}
                    alt={"res-icpn"}
                    width={"2vw"}
                />
            </Box>
        )
    } else {
        return null;
    }
}