import { IconButton } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useGame } from "../../../context/GameContext";
import { SocketType } from "../../../utils/types/types";
import { useSync } from "../../../context/SyncContext";
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent";
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const PassTurnButton = ({ socket }: { socket: SocketType }) => {
    const { gameID, sfxVolume, sfxEnabled, gameAssets, soundEffects } = useGame()
    const { myGameData, gameData } = useSync()

    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        if (myGameData && gameData.Status === 4 && myGameData.turn_index === gameData.TurnIndex) {
            const timer = setTimeout(() => {
                setShowButton(true);
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            setShowButton(false)
        }
    }, [gameData, myGameData])

    const handlePass = async () => {
        const eventData = {
            id: gameID,
        }
        SendGameEvent(socket, "EndTurn", eventData)
        PlaySoundEffect(soundEffects.clickPassTurnSFX, sfxVolume, sfxEnabled)
    }

    if (showButton) {
        return (
            <IconButton
                zIndex={1}
                position={"fixed"}
                right={"2%"}
                bottom={"2%"}
                width={"6%"}
                height={"10%"}
                colorScheme={"yellow"}
                background={"transparent"}
                aria-label='pass-turn'
                icon={<img src={gameAssets.passButton} alt='pass-turn' width={"70%"} />}
                onClick={handlePass}
            />
        )
    }

    return null
}
