import { Box } from "@chakra-ui/react"

type OverviewGameInfoType = {
    isDoubleElimination: boolean,
    isChampionship: boolean,
}

export const OverviewGameInfo = ({ isDoubleElimination, isChampionship }: OverviewGameInfoType) => {
    return (
        <>
            <Box
                className="tournament-overview-header-banner"
                w={"50%"}
                m={"auto"}
                mt={"2"}
            >
                Min Games - Max Games
            </Box>

            <Box
                display={"flex"}
                fontSize={"18px"}
                textColor={"#392516"}
                alignItems={"center"}
                justifyContent={"center"}
                p={1}
            >
                {
                    isChampionship ?
                        "3-5" :
                        isDoubleElimination ?
                            "1-3" :
                            "1-2"
                }
            </Box>
        </>
    )
}