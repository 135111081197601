import { Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Portal, IconButton, Box, Button } from '@chakra-ui/react'
import { useGame } from '../../context/GameContext'
import { useNavigate } from 'react-router-dom'
import { WallpaperManager } from '../Game/Settings/WallpaperManager'
import { MusicManager } from '../Game/Settings/MusicManager'
import { SFXManager } from '../Game/Settings/SFXManager'

export const SpectatorSettings = () => {
    const { setGameID, setIsReconnect, setIsSpectator, gameAssets } = useGame()

    const navigate = useNavigate()

    const handleLeave = async () => {
        setIsSpectator(false)
        setGameID(-1)
        setIsReconnect(false)
        navigate('/dashboard')
    }

    return (
        <Popover
            placement={"top"}
            preventOverflow
        >
            <PopoverTrigger>
                <IconButton
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Menu"
                    position={"fixed"}
                    left={"1%"}
                    bottom={"1%"}
                    width={"4%"}
                    height={"7%"}
                    colorScheme={"yellow"}
                    background={"transparent"}
                    aria-label='back-menu'
                    icon={<img src={gameAssets.menuButton} alt='menu-button' width={"65%"} />}
                />
            </PopoverTrigger>
            <Portal >
                <PopoverContent
                    w={"20vw"}
                    backgroundColor={"transparent"}
                    border={"none"}
                    textColor={"#825128"}
                >
                    <PopoverHeader
                        textAlign={"center"}
                        color={"white"}
                        fontSize={"3.5vh"}
                        borderRadius={"1vw 1vw 0 0"}
                        border={"none"}
                        background={"linear-gradient(#7C5E36, #5B472D)"}
                    >
                        Settings
                    </PopoverHeader>
                    <PopoverBody backgroundColor={"#E6C795"}>
                        <WallpaperManager />

                        <img src={gameAssets.settingsDivider} alt='divider' className='mt-1 mb-1' />

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-evenly"}>
                            {/* Music Volume Slider */}
                            <MusicManager />

                            {/* Sound Effect Volume Slider */}
                            <SFXManager />
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={2}>
                            <Button
                                w={"10vw"}
                                height={"5vh"}
                                fontSize={"1vw"}
                                colorScheme='orange'
                                backgroundColor={"#EC9E61"}
                                borderRadius={"0.5vw"}
                                border={"0.2vw solid #825128"}
                                mb={"0.5vh"}
                                onClick={handleLeave}
                            >
                                Quit Game
                            </Button>
                        </Box>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}