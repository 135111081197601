import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
    Button, useDisclosure, FormControl, FormLabel, Input, ModalFooter, useToast, Stack, CheckboxGroup, Checkbox,
    Box
} from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { CreateQuest, GetMissions, GetMissionTickets } from '../../../../requests/AdminRequests'
import { useState } from 'react'

const fetchMissions = async (setMissionList: React.Dispatch<React.SetStateAction<any[]>>) => {
    const response = await GetMissions()
    response && setMissionList(response.missions)
}

const fetchRewardItems = async (setTournamentList: React.Dispatch<React.SetStateAction<any[]>>) => {
    const response = await GetMissionTickets()
    response && setTournamentList(response.tickets)
}

export const CreateQuestModal = () => {

    const { isOpen, onOpen, onClose } = useDisclosure(
        {
            onOpen: async () => {
                fetchMissions(setMissionList)
                fetchRewardItems(setTournamentList)
            }
        }
    )

    const toast = useToast()

    const [name, setName] = useState("")
    const [bannerAssetURL, setBannerAssetURL] = useState("")
    const [rewardItemURL, setRewardItemURL] = useState("")
    const [rewardText, setRewardText] = useState("")
    const [requiredMissions, setRequiredMissions] = useState<string[]>([])
    const [rewardItems, setRewardItems] = useState<string[]>([])
    const [endTime, setEndTime] = useState("")

    const [missionList, setMissionList] = useState<any[]>([])
    const [tournamentList, setTournamentList] = useState<any[]>([])


    const handleMissionChange = (selectedMissions: string[]) => {
        setRequiredMissions(selectedMissions);
    };

    const handleRewardChange = (selectedItems: string[]) => {
        setRewardItems(selectedItems);
    };

    const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const localDateTime = event.target.value;

        var localDate = new Date(localDateTime);

        var localTimestamp = localDate.getTime();

        var localTimezoneOffsetMs = localDate.getTimezoneOffset() * 60 * 1000;

        var gmtTimestamp = localTimestamp + localTimezoneOffsetMs;

        var gmtTime = new Date(gmtTimestamp - localTimezoneOffsetMs)
        setEndTime(gmtTime.toISOString())
    }

    const handleCreateQuest = async () => {
        const result = await CreateQuest(name, bannerAssetURL, rewardItemURL, rewardText, requiredMissions, rewardItems, endTime)

        if (result && result.message === "quest added") {
            toast({
                description: "Quest is added.",
                status: "success",
                duration: 2000
            })
            onClose()
        }
    }
    return (
        <>
            <Button
                colorScheme='green'
                mb={3}
                w={"100%"}
                onClick={onOpen}
            >
                Create Quest
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Create Quest</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel htmlFor='questNameInput'>Quest Name</FormLabel>
                            <Input
                                id="questNameInput"
                                placeholder='Quest Name'
                                onChange={(e) => setName(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='questBannerURLInput'>Banner URL</FormLabel>
                            <Input
                                id="questBannerURLInput"
                                placeholder='Banner URL'
                                onChange={(e) => setBannerAssetURL(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='questRewardItemURLInput'>Reward Item URL</FormLabel>
                            <Input
                                id="questRewardItemURLInput"
                                placeholder='Reward Item URL'
                                onChange={(e) => setRewardItemURL(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='questRewardTextInput'>Quest Reward Text</FormLabel>
                            <Input
                                id="questRewardTextInput"
                                placeholder='Quest Reward'
                                onChange={(e) => setRewardText(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='questMissionsInput'>Missions</FormLabel>
                            <CheckboxGroup
                                colorScheme='green'
                                defaultValue={requiredMissions}
                                onChange={handleMissionChange}
                            >
                                <Stack direction={"column"}>
                                    {
                                        missionList &&
                                            missionList.length > 0 ?
                                            missionList.map((mission, index) => (
                                                <Checkbox key={index} value={mission.id}>
                                                    {mission.description}
                                                </Checkbox>
                                            )) :
                                            <Box textAlign={"center"}>
                                                There is no any mission to add.
                                            </Box>
                                    }
                                </Stack>
                            </CheckboxGroup>
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='rewardItemInputs'>Reward Items</FormLabel>
                            <CheckboxGroup
                                colorScheme='blue'
                                defaultValue={rewardItems}
                                onChange={handleRewardChange}
                            >
                                <Stack direction={"column"}>
                                    {
                                        tournamentList &&
                                            tournamentList.length > 0 ?
                                            tournamentList.map((tournament, index) => (
                                                <Checkbox key={index} value={tournament.TicketID.toString()}>
                                                    {tournament.Name}
                                                </Checkbox>
                                            )) :
                                            <Box textAlign={"center"}>
                                                There is no any tournament ticket to add.
                                            </Box>
                                    }
                                </Stack>
                            </CheckboxGroup>
                        </FormControl>

                        <FormControl>
                            <FormLabel htmlFor="dateInput">Quest End Time</FormLabel>
                            <Input
                                id="dateInput"
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                                placeholder='Select Date and Time'
                                size='md'
                                type='datetime-local'
                                onChange={(e) => handleStartTimeChange(e)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={rewardItems.length === 0 || requiredMissions.length === 0}
                            onClick={handleCreateQuest}
                        >
                            Create Quest
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}