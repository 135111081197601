export const getDotsFromNumber = (value: number) => {
  if (value === 2) {
    return ".";
  } else if (value === 3) {
    return "..";
  } else if (value === 4) {
    return "...";
  } else if (value === 5) {
    return "....";
  } else if (value === 6) {
    return ".....";
  } else if (value === 8) {
    return ".....";
  } else if (value === 9) {
    return "....";
  } else if (value === 10) {
    return "...";
  } else if (value === 11) {
    return "..";
  } else if (value === 12) {
    return ".";
  }
};
