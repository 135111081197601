import { Box } from "@chakra-ui/react"
import { BotAddButton } from "../WaitingLobbyButtons/BotAddButton"

type PlayerWaitingColumnType = {
    order: number,
    maximumPlayer: number
}

const playerBackgroundOne = "#e8c190"
const playerBackgroundTwo = "#eccca5"

export const PlayerWaitingColumn = ({ order, maximumPlayer }: PlayerWaitingColumnType) => {
    return (
        <Box
            pos="relative"
            w={maximumPlayer === 3 ? "23.3vw" : "17.5vw"}
            h={"21vw"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundColor={order % 2 === 0 ? playerBackgroundOne : playerBackgroundTwo}
        >
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box className="lobby-username">
                    Waiting...
                </Box>
            </Box>
            <BotAddButton />
        </Box>
    )
}