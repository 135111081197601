import { useNavigate } from "react-router-dom"
import { useGame } from "../../../../context/GameContext"
import { Button } from "@chakra-ui/react"

export const ReJoinButton = ({ roomID }: { roomID: number }) => {
    const { setGameID, setIsReconnect } = useGame()

    const navigate = useNavigate()

    const handleReJoin = async (id: number) => {
        setGameID(id)
        setIsReconnect(true)
        navigate(`/game`)
    }

    return (
        <Button
            colorScheme='yellow'
            backgroundColor={"#fda315"}
            borderWidth={"2px"}
            borderColor={"black"}
            borderRadius={"10px"}
            onClick={() => handleReJoin(roomID)}
        >
            Re-Join
        </Button>
    )
}