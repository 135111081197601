export const isSettlePosition = (
  settlePositions: any[],
  x: number,
  y: number
) => {
  if (settlePositions) {
    return (
      settlePositions.find((data) => {
        return data.position[0] === x && data.position[1] === y;
      }) !== undefined
    );
  } else {
    return false;
  }
};
