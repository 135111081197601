import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"
import { getUsernameByAddress } from "../utils/functions/getUsernameByAddress"
import { getTurnIndexByAddress } from "../utils/functions/getTurnIndexByAddress"
import { useGame } from '../context/GameContext'
import { logDivElement, usernameDivElement } from '../utils/gameLogs/gameLogs'
import { getColorByIndex } from '../utils/assets/getColorByIndex'
import { PlaySoundEffect } from "../sounds/playSoundEffect"

export const TradeData = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects, setEnableCounterTrade, setHaveCounterTrade } = useGame()
    const { setTrade, responses, setResponses, players, setCounterTrades, counterTrades, myGameData } = useSync()

    // Trade Offer
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TradeOffer") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                parsedData && setTrade(parsedData)

                PlaySoundEffect(soundEffects.getTradeOfferSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Counter Trade Offer
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "CounterTradeOffer") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    setCounterTrades(prevState => [
                        ...prevState,
                        parsedData
                    ])
                }

                PlaySoundEffect(soundEffects.getTradeOfferSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Trade Offer Delete
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TradeOfferDelete") {
                setHaveCounterTrade(false)
                setEnableCounterTrade(false)
                setCounterTrades([])
                setTrade(null)
                setResponses([])
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Counter Trade Offer Delete
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "CounterTradeOfferDelete") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    const counterTrade = myGameData && counterTrades.find((ct) => ct.id === parsedData.id && ct.offerer === myGameData.address)
                    if (counterTrade) {
                        setHaveCounterTrade(false)
                        setEnableCounterTrade(false)
                    }
                    setCounterTrades(counterTrades.filter((trade) => trade.id !== parsedData.id));
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Trade Offer Response
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TradeRespond") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);

                if (parsedData) {
                    const responseCheck = responses && responses.length > 0 && responses.filter((response) => response.turnIndex === parsedData.turnIndex)

                    if (responseCheck && responseCheck.length > 0) {
                        setResponses(prevResponses =>
                            prevResponses.map(response =>
                                response.turnIndex === parsedData.turnIndex ?
                                    { ...response, response: parsedData.respond } :
                                    response
                            )
                        )
                    } else if (responseCheck !== undefined) {
                        setResponses(prevResponses => [
                            ...prevResponses,
                            {
                                address: parsedData.address,
                                response: parsedData.respond,
                                turnIndex: parsedData.turnIndex,
                                tradeID: parsedData.id,
                            }
                        ]);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Trade Offer Achieve
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TradeAchieved") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                setHaveCounterTrade(false)
                setEnableCounterTrade(false)
                setCounterTrades([])
                setTrade(null)
                setResponses([])

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()

                    const usernameDiv = usernameDivElement(getUsernameByAddress(players, parsedData.from), getColorByIndex(getTurnIndexByAddress(players, parsedData.from)))

                    const trade = document.createElement("img")
                    trade.src = gameAssets.tradeButton
                    trade.alt = "trade-icon"
                    trade.style.width = "30px"
                    trade.classList.add("me-2")

                    const usernameDiv2 = usernameDivElement(getUsernameByAddress(players, parsedData.to), getColorByIndex(getTurnIndexByAddress(players, parsedData.to)))

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(trade)
                    logDiv.appendChild(usernameDiv2)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                PlaySoundEffect(soundEffects.playerTradeConfirmSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Counter Trade Offer Achieve
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "CounterTradeAchieved") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                setHaveCounterTrade(false)
                setEnableCounterTrade(false)
                setCounterTrades([])
                setTrade(null)
                setResponses([])

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()

                    const usernameDiv = usernameDivElement(getUsernameByAddress(players, parsedData.from), getColorByIndex(getTurnIndexByAddress(players, parsedData.from)))

                    const trade = document.createElement("img")
                    trade.src = gameAssets.tradeButton
                    trade.alt = "trade-icon"
                    trade.style.width = "30px"
                    trade.classList.add("me-2")

                    const usernameDiv2 = usernameDivElement(getUsernameByAddress(players, parsedData.to), getColorByIndex(getTurnIndexByAddress(players, parsedData.to)))

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(trade)
                    logDiv.appendChild(usernameDiv2)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                PlaySoundEffect(soundEffects.playerTradeConfirmSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])
}