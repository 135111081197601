import { Box } from "@chakra-ui/react"

export const TotalTurns = ({ roomStats }: { roomStats: any }) => {
    return (
        <Box display={"flex"} flexDir={"column"} fontSize={"1.5vw"}>
            <Box>
                Total Turns
            </Box>
            <Box>
                {roomStats && roomStats.numberOfTurns}
            </Box>
        </Box>
    )
}