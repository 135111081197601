import { Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"

export const OverviewCost = ({ isPrivate, ticketAsset }: { isPrivate: boolean, ticketAsset: string }) => {
    const { dashboardAssets } = useGame()

    if (isPrivate) {
        return (
            <Image
                src={ticketAsset}
                alt='ticket'
                w={"15%"}
                m={"auto"}
            />
        )
    } else {
        return (
            <Image
                src={dashboardAssets.freeJoinBanner}
                alt='free-join-banner'
                w={"12.5%"}
                m={"auto"}
            />
        )
    }
}