export const getSettlementAsset = (
  index: number,
  assets: any,
  skin: string
) => {
  if (skin === "Default") {
    if (index === 0) {
      return assets.defaultSettleRed;
    } else if (index === 1) {
      return assets.defaultSettleBlue;
    } else if (index === 2) {
      return assets.defaultSettlePink;
    } else if (index === 3) {
      return assets.defaultSettleGrey;
    } else {
      return "";
    }
  } else if (skin === "Halloween") {
    if (index === 0) {
      return assets.halloweenSettleRed;
    } else if (index === 1) {
      return assets.halloweenSettleBlue;
    } else if (index === 2) {
      return assets.halloweenSettlePink;
    } else if (index === 3) {
      return assets.halloweenSettleGrey;
    } else {
      return "";
    }
  } else if (skin === "Viking") {
    if (index === 0) {
      return assets.vikingSettleRed;
    } else if (index === 1) {
      return assets.vikingSettleBlue;
    } else if (index === 2) {
      return assets.vikingSettlePink;
    } else if (index === 3) {
      return assets.vikingSettleGrey;
    } else {
      return "";
    }
  } else if (skin === "Air") {
    if (index === 0) {
      return assets.airSettleRed;
    } else if (index === 1) {
      return assets.airSettleBlue;
    } else if (index === 2) {
      return assets.airSettlePink;
    } else if (index === 3) {
      return assets.airSettleGrey;
    } else {
      return "";
    }
  } else if (skin === "Ember") {
    if (index === 0) {
      return assets.emberSettleRed;
    } else if (index === 1) {
      return assets.emberSettleBlue;
    } else if (index === 2) {
      return assets.emberSettlePink;
    } else if (index === 3) {
      return assets.emberSettleGrey;
    } else {
      return "";
    }
  } else if (skin === "Pagoda") {
    if (index === 0) {
      return assets.pagodaSettleRed;
    } else if (index === 1) {
      return assets.pagodaSettleBlue;
    } else if (index === 2) {
      return assets.pagodaSettlePink;
    } else if (index === 3) {
      return assets.pagodaSettleGrey;
    } else {
      return "";
    }
  } else {
    return null;
  }
};
