import { Box, Avatar } from "@chakra-ui/react"
import { useAuth } from "../../../../context/AuthContext"
import { useGame } from "../../../../context/GameContext"
import { getAvatarByIndex } from "../../../../utils/assets/getAvatarByIndex"
import { PlayerProfile } from "./PlayerProfile"

export const PlayerAvatar = () => {
    const { username } = useAuth()
    const { avatarID, dashboardAssets } = useGame()

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mb={"1vw"}>
            <Box className='avatar-background'>
                <Avatar
                    backgroundColor={"#dcd1c4"}
                    h={"9vw"}
                    w={"9vw"}
                    border={"0.3vw solid black"}
                    name={username ? username : ""}
                    src={getAvatarByIndex(Number(avatarID), dashboardAssets)}
                >
                    <PlayerProfile />
                </Avatar>
            </Box>
            <Box className="username-label-background">
                {username}
            </Box>
        </Box>
    )
}
