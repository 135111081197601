import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, useDisclosure, FormControl, FormLabel, Input, ModalFooter, useToast, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, CheckboxGroup, Stack, Checkbox, Box, Radio, RadioGroup, } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { CreateBanUserFromTournament } from '../../../../requests/AdminRequests'
import { useState } from 'react'
import { GetRooms } from '../../../../requests/RoomRequests'

const fetchLiveMatches = async (setLiveGames: React.Dispatch<React.SetStateAction<any[]>>) => {
    const response = await GetRooms()
    if (response) {
        const liveTournamentMatches = response.rooms.filter((game: any) => game.Name.includes("Tournament"))
        setLiveGames(liveTournamentMatches)
    }
}

export const BanPlayerFromTournamentModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure(
        {
            onOpen: async () => {
                fetchLiveMatches(setLiveGames)
            }
        }
    )

    const toast = useToast()

    const [username, setUsername] = useState("")
    const [gameID, setGameID] = useState(-1)

    const [liveGames, setLiveGames] = useState<any[]>([])

    const handleGameIDChange = (selectedGameID: number) => {
        setGameID(selectedGameID);
    };

    const handleBanPlayer = async () => {
        const result = await CreateBanUserFromTournament(username, gameID)
        if (result) {
            const responseMessage = result.message

            if (responseMessage === "User banned from tournament") {
                toast({
                    description: "Player is banned from the tournament.",
                    status: "success",
                    duration: 2000
                })
                onClose()
            } else if (responseMessage === "User not found") {
                toast({
                    description: "Player cannot be found.",
                    status: "error",
                    duration: 2000
                })
            }
        }
    }
    return (
        <>
            <Button
                colorScheme='blue'
                mb={3}
                w={"100%"}
                onClick={onOpen}
            >
                Ban Player From Tournament
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Ban Player From Tournament</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel htmlFor='usernameInput'>Username</FormLabel>
                            <Input
                                id="usernameInput"
                                placeholder='Username'
                                onChange={(e) => setUsername(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>

                        <FormControl mb={3}>
                            <FormLabel htmlFor='rewardItemInputs'>The game that the player in</FormLabel>
                            <RadioGroup
                                colorScheme='blue'
                                onChange={(value) => handleGameIDChange(Number(value))}
                            >
                                <Stack direction={"column"}>
                                    {
                                        liveGames &&
                                            liveGames.length > 0 ?
                                            liveGames.map((game, index) => (
                                                <Radio key={index} value={game.ID}>
                                                    {game.Name}
                                                </Radio>
                                            )) :
                                            <Box textAlign={"center"}>
                                                There is no any live match.
                                            </Box>
                                    }
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={gameID === -1}
                            onClick={handleBanPlayer}
                        >
                            Ban Player from Tournament
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}