import { useEffect } from "react";
import { SocketType } from "../utils/types/types";
import { useSync } from "../context/SyncContext";
import { getUsernameByIndex } from '../utils/functions/getUsernameByIndex';
import { useGame } from '../context/GameContext';
import { isDockPlace } from '../utils/functions/isDockPlace';
import { logDivElement, logTextDivElement, usernameDivElement } from '../utils/gameLogs/gameLogs';
import { getColorByIndex } from '../utils/assets/getColorByIndex';
import { PlaySoundEffect } from "../sounds/playSoundEffect";
import { getCityAsset } from "../utils/assets/getCityAsset";
import { getSettlementAsset } from "../utils/assets/getSettlementAsset";

export const SettlePositions = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects } = useGame()
    const { setSettlePositions, players, playersSkinChoice } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            const data = socket.lastJsonMessage.data;
            const type = socket.lastJsonMessage.type;

            if (type === "Settle" || type === "City") {
                const parsedData = JSON.parse(data);
                if (parsedData) {

                    const gameLogs = document.getElementById("game-logs")
                    if (gameLogs) {
                        const logDiv = logDivElement()
                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.turn), getColorByIndex(parsedData.turn))

                        if (type === "Settle") {
                            const logText = logTextDivElement("built")

                            const settle = document.createElement("img")
                            settle.src = playersSkinChoice && getSettlementAsset(parsedData.turn, gameAssets, playersSkinChoice[parsedData.turn])
                            settle.alt = "settle-icon"
                            settle.style.height = "150%"

                            logDiv.appendChild(usernameDiv)
                            logDiv.appendChild(logText)
                            logDiv.appendChild(settle)

                            if (gameLogs.firstChild) {
                                gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                            } else {
                                gameLogs.appendChild(logDiv)
                            }
                        } else if (type === "City") {
                            const logText = logTextDivElement("upgraded")

                            const city = document.createElement("img")
                            city.src = playersSkinChoice && getCityAsset(parsedData.turn, gameAssets, playersSkinChoice[parsedData.turn])
                            city.alt = "city-icon"
                            city.style.height = "150%"

                            logDiv.appendChild(usernameDiv)
                            logDiv.appendChild(logText)
                            logDiv.appendChild(city)

                            if (gameLogs.firstChild) {
                                gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                            } else {
                                gameLogs.appendChild(logDiv)
                            }
                        }
                    }

                    setSettlePositions(current => {
                        const existingPositionIndex = current.findIndex(settle =>
                            settle.position[0] === parsedData.position[0] && settle.position[1] === parsedData.position[1]
                        );

                        if (existingPositionIndex !== -1) {
                            current[existingPositionIndex].revenue = type === "City" ? 2 : 1;
                            return [...current];
                        } else {
                            return [
                                ...current,
                                {
                                    position: parsedData.position,
                                    owner: parsedData.owner,
                                    turnIndex: parsedData.turn,
                                    revenue: type === "City" ? 2 : 1
                                }
                            ];
                        }
                    });
                }

                if (type === "Settle") {
                    if (isDockPlace(parsedData.position[0], parsedData.position[1])) {
                        PlaySoundEffect(soundEffects.buildSettleDockSFX, sfxVolume, sfxEnabled)
                    } else {
                        PlaySoundEffect(soundEffects.buildSettleSFX, sfxVolume, sfxEnabled)
                    }
                } else if (type === "City") {
                    PlaySoundEffect(soundEffects.buildCitySFX, sfxVolume, sfxEnabled)
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);
};
