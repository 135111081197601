import { Box } from "@chakra-ui/react"
import { getTimeFromMinute } from "../../../../utils/constants/getTimeFromMinute"

export const GameTime = ({ roomStats }: { roomStats: any }) => {
    return (
        <Box display={"flex"} flexDir={"column"} fontSize={"1.5vw"} >
            <Box >
                Game Time
            </Box>
            <Box>
                {roomStats && getTimeFromMinute(roomStats.gameTime)}
            </Box>
        </Box>
    )
}