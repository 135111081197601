import { FaHistory } from "react-icons/fa";
import { TbTournament } from "react-icons/tb";
import { Modal, ModalContent, ModalBody, useDisclosure, ModalOverlay, Text, Box, Image } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { TournamentCard } from './Card/TournamentCard'
import { useEffect } from 'react'
import { GetTournaments } from '../../../../requests/TournamentRequests'
import { CreateTournamentModal } from './CreateTournamentModal';
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { useAuth } from '../../../../context/AuthContext'
import { PastTournamentCard } from './Past/PastTournamentCard'
import { Tournament } from '../../../../socket/Tournament';
import { SocketType } from '../../../../utils/types/types';
import { useGame } from "../../../../context/GameContext";
import { SendAnalytiActionEvent } from "../../../../socket/sendMessage/SendAnalyticActionEvent";
import { useDashboard } from "../../../../context/DashboardContext";

const fetchTournaments = async (setTournamentList: (value: any[]) => void) => {
    const result = await GetTournaments()
    result && setTournamentList(result)
}

export const TournamentModal = ({ socket }: { socket: SocketType }) => {
    const { tournamentList, setTournamentList } = useDashboard()
    const { dashboardAssets, isMobile } = useGame()
    const { isAdmin } = useAuth()

    const { isOpen, onOpen, onClose } = useDisclosure({
        onOpen: async () => {
            fetchTournaments(setTournamentList)
            SendAnalytiActionEvent(socket, "TournamentModalOpen")
        }
    })

    useEffect(() => {
        fetchTournaments(setTournamentList)
    }, [])

    Tournament(socket)

    const availableTournaments: any[] = [];
    const pastTournaments: any[] = [];

    tournamentList.forEach((tournament) => {
        if (tournament.State !== 4) {
            availableTournaments.push(tournament);
        } else {
            pastTournaments.push(tournament);
        }
    });

    return (
        <>
            <Box onClick={onOpen} className="tournament-modal-panel content-button-effects">
                <Image
                    src={dashboardAssets.tournamentModalIcon}
                    alt='tournament-icon'
                    m={"auto"}
                    width={"60%"}
                />
                {
                    availableTournaments.length > 0 &&
                    <Box className='red-info-circle'>
                        {availableTournaments.length}
                    </Box>
                }
                <Box className="content-button-title-color">
                    Tournaments
                </Box>
            </Box>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                scrollBehavior={"inside"}
                isCentered
            >
                <ModalOverlay
                    zIndex={1298}
                    backgroundColor={"rgba(0,0,0,0.6)"}
                />
                <ModalContent
                    containerProps={{
                        zIndex: 1299
                    }}
                    backgroundColor={"#745833"}
                    textColor={"white"}
                    borderRadius={"25px"}
                    maxW={isMobile ? "100vw" : "75vw"}
                    maxH={isMobile ? "100vh" : "90vh"}
                    minH={isMobile ? "100vh" : "90vh"}
                >
                    <ModalBody
                        className='scroll-bar-with-margin'
                        backgroundColor={"#EAC28D"}
                        p={0}
                        borderRadius={"25px"}
                        overflowY={"auto"}
                        overflowX={"hidden"}
                    >
                        <DashboardCloseButton onClose={onClose} />

                        <Tabs variant={"soft-rounded"}>
                            <TabList borderRadius={"25px 25px 0px 0px"} backgroundColor={"#745833"} >
                                <Tab
                                    borderRadius={"20px 0px 0px 0px"}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    <TbTournament className='me-2' />
                                    Available ({availableTournaments.length})
                                </Tab>
                                <Tab
                                    borderRadius={0}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    <FaHistory className='me-2' />
                                    Completed ({pastTournaments.length})
                                </Tab>
                                {isAdmin && <CreateTournamentModal />}
                            </TabList>
                            <TabPanels>
                                <TabPanel className='tournament-container'>
                                    {
                                        availableTournaments.length > 0 ?
                                            availableTournaments.map((tournament, index) => {
                                                return (
                                                    <TournamentCard
                                                        key={index}
                                                        ID={tournament.ID}
                                                        Capacity={tournament.Capacity}
                                                        Class={tournament.Class}
                                                        CurrentPlayers={tournament.CurrentPlayers}
                                                        BannerAsset={tournament.BannerImage}
                                                        TicketAsset={tournament.TicketImage}
                                                        IsUserSignedUp={tournament.IsUserSignedUp}
                                                        IsPrivate={tournament.IsClosed}
                                                        Name={tournament.Name}
                                                        Prize={tournament.Prize}
                                                        StartTime={tournament.StartTime}
                                                        State={tournament.State}
                                                        CurrentStage={tournament.Stage}
                                                        HaveTicket={tournament.ticket_own}
                                                        UserCheckedIn={tournament.UserCheckedIn}
                                                        QuestID={tournament.quest_id}
                                                        onCloseTournamentModal={onClose}
                                                    />
                                                )
                                            }) :
                                            <Text
                                                position={"absolute"}
                                                left={0}
                                                right={0}
                                                fontSize={"25px"}
                                                textColor={"#53422b"}
                                                textAlign={"center"}
                                            >
                                                There is no active tournament!
                                            </Text>
                                    }
                                </TabPanel>
                                <TabPanel className='tournament-container'>
                                    {
                                        pastTournaments.length > 0 ?
                                            pastTournaments.map((tournament, index) => {
                                                return (
                                                    <PastTournamentCard
                                                        key={index}
                                                        ID={tournament.ID}
                                                        Class={tournament.Class}
                                                        CurrentPlayers={tournament.CurrentPlayers}
                                                        BannerAsset={tournament.BannerImage}
                                                        IsUserSignedUp={tournament.IsUserSignedUp}
                                                        IsPrivate={tournament.IsClosed}
                                                        Name={tournament.Name}
                                                        Prize={tournament.Prize}
                                                        StartTime={tournament.StartTime}
                                                        State={tournament.State}
                                                        Stage={tournament.Stage}
                                                        Rank={tournament.rank}
                                                        Winners={tournament.Winners}
                                                    />
                                                )
                                            }) :
                                            <Text
                                                position={"absolute"}
                                                left={0}
                                                right={0}
                                                fontSize={"25px"}
                                                textColor={"#53422b"}
                                                textAlign={"center"}
                                            >
                                                There is no completed tournament!
                                            </Text>
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    )
}