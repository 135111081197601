import { FaEye, FaTable } from "react-icons/fa";
import { TbTournament } from "react-icons/tb";
import { Modal, ModalContent, ModalBody, ModalOverlay } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { DashboardCloseButton } from "../../../General/DashboardCloseButton";
import { PastTournamentOverview } from "./PastTournamentOverview";
import { SingleElimBracket } from "../Bracket/SingleElimBracket";
import { KnockoutBracket } from "../Bracket/KnockoutBracket";
import { ChampionshipBracket } from "../Bracket/ChampionshipBracket";
import { ChampionshipStandings } from "../Standings/ChampionshipStandings";
import { Standings } from "../Standings/Standings";

type PastTournamentCardDetailType = {
    isOpen: boolean,
    onClose: () => void,
    ID: number,
    Name: string,
    Prize: number[],
    State: number,
    Stage: number,
    Winners: string[],
    IsDoubleElimination: boolean,
    IsChampionship: boolean,
}

export const PastTournamentCardDetail = ({
    isOpen,
    onClose,
    ID,
    Name,
    Prize,
    State,
    Stage,
    Winners,
    IsDoubleElimination,
    IsChampionship }: PastTournamentCardDetailType) => {
    const isTabsAllowed = State !== 4

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            motionPreset={"slideInBottom"}
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor={"#edcca2"}
                textColor={"white"}
                borderRadius={"25px"}
                maxW={"min-content"}
                height={"min-content"}
            >
                <ModalBody
                    backgroundColor={"#EAC28D"}
                    p={0}
                    borderRadius={"25px"}
                >
                    <DashboardCloseButton onClose={onClose} />

                    <Tabs variant={"soft-rounded"}>
                        <TabList borderRadius={"25px 25px 0px 0px"} backgroundColor={"#745833"} pe={"50px"}>
                            <Tab
                                borderRadius={"20px 0px 0px 0px"}
                                _selected={{ bg: "#53422b" }}
                                textColor={"#efdebc"}
                                p={"1.5vw"}
                                fontSize={"2vw"}
                            >
                                <FaEye className="me-2" />
                                Overview
                            </Tab>
                            <Tab
                                borderRadius={0}
                                _selected={{ bg: "#53422b" }}
                                textColor={"#efdebc"}
                                p={"1.5vw"}
                                fontSize={"2vw"}
                                isDisabled={isTabsAllowed}
                            >
                                <FaTable className='me-2' />
                                Standings
                            </Tab>
                            <Tab
                                borderRadius={0}
                                _selected={{ bg: "#53422b" }}
                                textColor={"#efdebc"}
                                p={"1.5vw"}
                                fontSize={"2vw"}
                                isDisabled={isTabsAllowed}
                            >
                                <TbTournament className='me-2' />
                                Brackets
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel p={0}>
                                <PastTournamentOverview
                                    Name={Name}
                                    Prize={Prize}
                                    Winners={Winners}
                                />
                            </TabPanel>
                            <TabPanel p={0}>
                                {
                                    IsChampionship ?
                                        <ChampionshipStandings
                                            ID={ID}
                                            State={State}
                                            CurrentStage={Stage}
                                            Matches={null}
                                        /> :
                                        <Standings
                                            ID={ID}
                                            State={State}
                                            Matches={null}
                                        />
                                }
                            </TabPanel>
                            <TabPanel p={0}>
                                {IsChampionship ?
                                    <ChampionshipBracket
                                        ID={ID}
                                        State={State}
                                        Prize={Prize}
                                        CurrentStage={Stage}
                                        setMatches={null}
                                    /> :
                                    IsDoubleElimination ?
                                        <KnockoutBracket
                                            ID={ID}
                                            State={State}
                                            Prize={Prize}
                                            CurrentStage={Stage}
                                            setMatches={null}
                                        /> :
                                        <SingleElimBracket
                                            ID={ID}
                                            State={State}
                                            Prize={Prize}
                                            CurrentStage={Stage}
                                            setMatches={null}
                                        />
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}