import { Box } from "@chakra-ui/react"
import { useSync } from "../../../context/SyncContext"

type PlayerResCardsType = {
    resCardNumber: number
}

export const PlayerResCards = ({ resCardNumber }: PlayerResCardsType) => {
    const { gameData } = useSync()

    return (
        <Box className='res-card-number-icon'>
            <Box className={`res-card-number-text ${getCardDiscardLimit(gameData.RoomLimit, resCardNumber) && "red-res-card-number-text"}`}>
                {resCardNumber}
            </Box>
        </Box>
    )
}

const getCardDiscardLimit = (playerLimit: number, resCardNumber: number) => {
    if (playerLimit > 2 && resCardNumber > 7) {
        return true
    } else if (playerLimit === 2 && resCardNumber > 9) {
        return true
    } else {
        return false
    }
}