import { Alert, AlertIcon, Box, Button } from "@chakra-ui/react"

type CardTicketInfoType = {
    IsPrivate: boolean,
    IsUserSignedUp: boolean,
    HaveTicket: boolean,
    QuestID: number,
    handleQuestDataFetch: () => void
}

export const CardTicketInfo = ({ IsPrivate, IsUserSignedUp, HaveTicket, QuestID, handleQuestDataFetch }: CardTicketInfoType) => {
    if (IsPrivate && !IsUserSignedUp) {
        return (
            <Alert
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                p={"0.5vw"}
                fontSize={"1.25vw"}
                status={HaveTicket ? "success" : "info"}
            >
                <Box display={"flex"} alignItems={"center"}>
                    <AlertIcon />
                    {
                        HaveTicket ?
                            "You have the tournament ticket." :
                            "You don't have the tournament ticket."
                    }
                </Box>
                {
                    !HaveTicket && QuestID !== 0 &&
                    <Button
                        width={"40%"}
                        height={"40%"}
                        fontSize={"1.1vw"}
                        padding={"0.3vw"}
                        backgroundColor={"#72a8da"}
                        border={"0.15vw solid #3c77ae"}
                        boxShadow={"0 0.15vw #3c77ae"}
                        colorScheme={"blue"}
                        onClick={handleQuestDataFetch}
                    >
                        Show Ticket Quest
                    </Button>
                }
            </Alert>
        )
    } else {
        return null
    }
}