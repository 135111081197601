import { Box } from "@chakra-ui/react"

export const DiceMoves = ({ roomStats }: { roomStats: any }) => {
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box fontSize={"1.5vw"}>
                Dice Moves
            </Box>
            <Box display={"flex"} justifyContent={"space-evenly"}>
                {
                    roomStats && roomStats.dicePercantage && roomStats.dicePercantage.length > 0 &&
                    roomStats.dicePercantage.map((dice, index) => {
                        return (
                            <Box
                                key={index}
                                display={"flex"}
                                alignItems={"center"}
                                flexDir={"column"}
                                me={"0.5vw"}
                            >
                                <Box fontSize={"0.75vw"}>
                                    % {(dice * 100).toString().slice(0, 4)}
                                </Box>
                                <Box
                                    className='stats-dice'
                                >
                                    {index + 2}
                                </Box>
                                <Box fontSize={"1.25vw"}>
                                    {roomStats.diceCount[index]}
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}