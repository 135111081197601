import { Box, Text } from "@chakra-ui/react"
import { TicketCard } from "./TicketCard"

type Tickets = {
    myTickets: any[]
}

export const Tickets = ({ myTickets }: Tickets) => {
    return (
        <>
            <Text className='inventory-header-banners'>
                Tournament Tickets
            </Text>
            <Box
                display={"flex"}
                flexDir={"row"}
                overflowX={"auto"}
                w={"100%"}
                minH={"25%"}
                backgroundColor={"#edd5b7"}
                mb={5}
                p={5}
                borderRadius={"25px"}
            >
                {
                    myTickets.length > 0 ?
                        myTickets.map((ticket, index) => {
                            return (
                                <TicketCard
                                    key={index}
                                    ticketName={ticket.name}
                                    ticketAsset={ticket.image}
                                    tournamentID={ticket.tournament_id}
                                    ticketClaimedAt={ticket.acquisition_time}
                                />
                            )
                        }) :
                        <Text
                            w={"100%"}
                            textAlign={"center"}
                            fontSize={"2vw"}
                        >
                            You don't have any tickets.
                        </Text>
                }
            </Box>
        </>
    )
}