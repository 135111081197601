export const getColorByIndex = (index: number) => {
  if (index === 0) {
    return "#F51313";
  } else if (index === 1) {
    return "#2490FC";
  } else if (index === 2) {
    return "#FE5FF9";
  } else if (index === 3) {
    return "#808080";
  } else {
    return "";
  }
};

export const getColorNameForPlayerUIByIndex = (index: number) => {
  if (index === 0) {
    return "red";
  } else if (index === 1) {
    return "blue";
  } else if (index === 2) {
    return "pink";
  } else if (index === 3) {
    return "white";
  } else {
    return "";
  }
};

export const getResponseLabelByIndex = (index: number) => {
  if (index === 0) {
    return "trade-answer-red";
  } else if (index === 1) {
    return "trade-answer-blue";
  } else if (index === 2) {
    return "trade-answer-pink";
  } else if (index === 3) {
    return "trade-answer-gray";
  } else {
    return "";
  }
};

export const getCircularTrackColorByIndex = (index: number) => {
  if (index === 0) {
    return "#FE71A0 ";
  } else if (index === 1) {
    return "#24F6FD";
  } else if (index === 2) {
    return "#9D69FF";
  } else if (index === 3) {
    return "#808080";
  } else {
    return "";
  }
};

export const getAvatarBGColor = (index: number) => {
  if (index === 0) {
    return "#F51313";
  } else if (index === 1) {
    return "#2490FC";
  } else if (index === 2) {
    return "#FE5FF9";
  } else if (index === 3) {
    return "#92b2bb";
  } else {
    return "";
  }
};
