import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Portal, IconButton, Box, PopoverArrow, useDisclosure, Button } from '@chakra-ui/react'
import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from 'react'
import { useSync } from '../../../context/SyncContext'
import { SocketType } from '../../../utils/types/types'
import { useGame } from '../../../context/GameContext'
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent';

export const DrawDevCardButton = ({ socket }: { socket: SocketType }) => {
    const { gameID, gameAssets } = useGame()
    const { myGameData, gameData, resources } = useSync()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const [isDrawDisabled, setIsDrawDisabled] = useState(true)

    // Draw Development Card button validation
    useEffect(() => {
        if (gameData && myGameData) {
            const numShepp = resources.Sheep
            const numWheat = resources.Wheat
            const numOre = resources.Ore

            const stage = gameData.Status
            const gameTurnIndex = gameData.TurnIndex
            const myTurnIndex = myGameData.turn_index

            let stateCheck = true
            let turnCheck = true
            let resourceCheck = true

            if (stage === 4) {
                stateCheck = false
            } else {
                stateCheck = true
            }

            if (myTurnIndex === gameTurnIndex) {
                turnCheck = false
            } else {
                turnCheck = true
            }

            if (numShepp >= 1 && numWheat >= 1 && numOre >= 1) {
                resourceCheck = false
            } else {
                resourceCheck = true
            }

            setIsDrawDisabled(stateCheck || turnCheck || resourceCheck)
        }
    }, [resources, gameData, myGameData])

    const drawDevCard = async () => {
        const eventData = {
            id: gameID,
        }
        SendGameEvent(socket, "DrawDevCard", eventData)
    }

    return (
        <Popover
            placement={"top"}
            isOpen={isOpen}
            onClose={onClose}
        >
            <PopoverTrigger>
                <IconButton
                    position={"fixed"}
                    backgroundColor={"transparent"}
                    zIndex={1}
                    left={"78%"}
                    bottom={"1%"}
                    width={"5%"}
                    height={"7%"}
                    colorScheme={"yellow"}
                    aria-label="draw-dev-card"
                    isDisabled={isDrawDisabled}
                    onClick={onToggle}
                    icon={<img src={gameAssets.buyDevCardButton} alt="buy-dev-card" width={"90%"} />}
                />
            </PopoverTrigger>
            <Portal>
                <PopoverContent
                    w={"15vw"}
                    background={"linear-gradient(rgba(61, 51, 25,1), rgba(61, 51, 25,0.75))"}
                    border={"0.25vw solid #A58E54"}
                    borderRadius={"0.5vw"}
                    textColor={"#825128"}
                    maxW={"max-content"}
                >
                    <PopoverArrow />
                    <PopoverBody
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        borderRadius={"0.5vw"}
                    >
                        <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} mb={"0.5vw"}>
                            <img src={gameAssets.sheepIcon} alt='sheep-icon' width={"35%"} />
                            <img src={gameAssets.wheatIcon} alt='wheat-icon' width={"35%"} />
                            <img src={gameAssets.oreIcon} alt='ore-icon' width={"35%"} />
                        </Box>
                        <Button
                            w={"12.5vw"}
                            height={"3vw"}
                            fontSize={"1.5vw"}
                            backgroundColor={"#7fc660"}
                            boxShadow={"0 0.25vw #3a572a"}
                            borderRadius={"0.5vw"}
                            colorScheme={"green"}
                            mb={"0.5vw"}
                            onClick={() => {
                                drawDevCard()
                                onToggle()
                            }}
                        >
                            <FaCheck fontSize={"1.75vw"} style={{ marginRight: "1vw" }} />
                            Confirm
                        </Button>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}