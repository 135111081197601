import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"
import { getUserStatsByIndex } from "../../../../utils/functions/getUserStatsByIndex"

export const PlayerTotalResGather = ({ turnIndex, userStats }: { turnIndex: number, userStats: any[] }) => {
    const { gameAssets } = useGame()

    return (
        <Box mb={"0.5vw"} textColor={"white"} fontSize={"1vw"}>
            <Box>
                Total Resource Gathering
            </Box>
            <Box display={"flex"} color={"white"}>
                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                    <Image src={gameAssets.woodIcon} alt='wood-icon' width={"100%"} />
                    <Box backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalWood}
                    </Box>
                </Box>
                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                    <Image src={gameAssets.brickIcon} alt='brick-icon' width={"100%"} />
                    <Box backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalBrick}
                    </Box>
                </Box>
                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                    <Image src={gameAssets.sheepIcon} alt='sheep-icon' width={"100%"} />
                    <Box backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalSheep}
                    </Box>
                </Box>
                <Box display={"flex"} flexDir={"column"} me={"0.25vw"}>
                    <Image src={gameAssets.wheatIcon} alt='wheat-icon' width={"100%"} />
                    <Box backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalWheat}
                    </Box>
                </Box>
                <Box display={"flex"} flexDir={"column"}>
                    <Image src={gameAssets.oreIcon} alt='ore-icon' width={"100%"} />
                    <Box backgroundColor={"#4C3921"}>
                        {getUserStatsByIndex(userStats, turnIndex)?.totalOre}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}