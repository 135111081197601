import { Box } from '@chakra-ui/react'
import { useGame } from "../../../context/GameContext"

export const EmojiManager = () => {
    const { setEmojiSoundEnabled, emojiSoundEnabled, gameAssets } = useGame()

    const handleEmojiSound = () => {
        setEmojiSoundEnabled(!emojiSoundEnabled)
    }

    return (
        <Box display={"flex"}>
            <Box
                _hover={{
                    cursor: "pointer"
                }}
                width={"3vw"}
                height={"3vw"}
                me={"2.5vw"}
                background={"transparent"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={handleEmojiSound}
            >
                {
                    emojiSoundEnabled ?
                        <img src={gameAssets.emojiOn} alt='emoji-button' width={"75%"} /> :
                        <img src={gameAssets.emojiOff} alt='emoji-button' width={"75%"} />
                }
            </Box>
            <Box w={"10vw"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box
                    textAlign={"center"}
                    fontSize={"1vw"}
                >
                    Emoji Sounds
                </Box>
            </Box>
        </Box>
    )
}