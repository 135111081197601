import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"
import { useGame } from '../context/GameContext'
import { getUsernameByIndex } from '../utils/functions/getUsernameByIndex'
import { getColorByIndex } from '../utils/assets/getColorByIndex'
import { logDivElement, logTextDivElement, usernameDivElement } from '../utils/gameLogs/gameLogs'
import { PlaySoundEffect } from "../sounds/playSoundEffect"

export const DevCards = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()
    const { myGameData, setDevCards, devCards, gameData, players } = useSync()

    // Handle dev cards updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "DevCardDrawn") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setDevCards(current =>
                        [
                            ...current,
                            {
                                Type: parsedData.card,
                                Buyturn: parsedData.buyTurn
                            }
                        ]
                    );
                }

                PlaySoundEffect(soundEffects.drawDevCardSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Handle dev cards usage
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "DevCardUsed") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {

                    if (gameData.PrevStatus === 3 && parsedData.card === "Knight") {
                        gameData.KnightBeforeDice = true
                    }

                    const gameLogs = document.getElementById("game-logs")
                    if (gameLogs) {
                        const logDiv = logDivElement()

                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.turnIndex), getColorByIndex(parsedData.turnIndex))

                        const logText = logTextDivElement("used")

                        const cardTypeText = document.createElement("span")
                        cardTypeText.style.color = getColorByIndex(parsedData.turnIndex)
                        cardTypeText.innerHTML = parsedData.card

                        logDiv.appendChild(usernameDiv)
                        logDiv.appendChild(logText)
                        logDiv.appendChild(cardTypeText)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv)
                        }
                    }

                    if (parsedData.card === "Knight") {
                        PlaySoundEffect(soundEffects.knightCardUsed, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "Monopoly") {
                        PlaySoundEffect(soundEffects.monopolySFX, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "YearOfPlenty") {
                        PlaySoundEffect(soundEffects.yearOfPlentySFX, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "RoadBuilding") {
                        PlaySoundEffect(soundEffects.roadBuildingSFX, sfxVolume, sfxEnabled)
                    }

                    if (myGameData && myGameData.turn_index === gameData.TurnIndex) {
                        const count = devCards.filter((card) => card.Type === parsedData.card).length

                        if (count === 1) {
                            setDevCards(devCards.filter((card) => card.Type !== parsedData.card))
                        } else {
                            const indexToRemove = devCards.findIndex((card) => card.Type === parsedData.card);
                            if (indexToRemove !== -1) {
                                const updatedDevCards = [...devCards];
                                updatedDevCards.splice(indexToRemove, 1);
                                setDevCards(updatedDevCards);
                            }
                        }
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}