import { useEffect, useState } from "react"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"
import { SocketType } from "../../../utils/types/types"
import { IconButton } from "@chakra-ui/react"
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent"

export const DiceButton = ({ socket }: { socket: SocketType }) => {
    const { gameID, gameAssets } = useGame()
    const { myGameData, gameData } = useSync()

    const [disable, setDisable] = useState<boolean>(true)

    useEffect(() => {
        if (myGameData) {
            const isMyTurn = gameData.TurnIndex === myGameData.turn_index
            const gameStage = gameData.Status

            if (isMyTurn && gameStage === 3) {
                setDisable(false);
            } else {
                setDisable(true)
            }
        } else {
            setDisable(true)
        }
    }, [gameData, myGameData])

    const handleDice = async () => {
        const eventData = {
            id: gameID,
        }
        SendGameEvent(socket, "RollDice", eventData)
    }

    if (!disable) {
        return (
            <IconButton
                className="pulse"
                zIndex={1}
                position={"fixed"}
                right={"2%"}
                bottom={"2%"}
                width={"6%"}
                height={"10%"}
                colorScheme={"blue"}
                background={"transparent"}
                aria-label='roll-dice'
                onClick={handleDice}
                icon={<img src={gameAssets.rollDiceButton} alt='dice-hud' width={"100%"} />}
            />
        )
    } else if (myGameData && myGameData.turn_index !== gameData.TurnIndex) {
        return <IconButton
            zIndex={1}
            position={"fixed"}
            right={"2%"}
            bottom={"2%"}
            width={"6%"}
            height={"9%"}
            colorScheme={"yellow"}
            background={"transparent"}
            isDisabled={true}
            aria-label='roll-dice'
            icon={<img src={gameAssets.disableDiceIcon} alt='dice-hud' />}
        />
    } else {
        return null
    }
}