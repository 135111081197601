import { Box, Button } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"
import { useNavigate } from "react-router-dom"

export const JoinLobbyStage = ({ rejoinGameID }: { rejoinGameID: number }) => {
    const { setGameID, setIsReconnect } = useGame()

    const navigate = useNavigate()

    const handleReJoin = async (id: number) => {
        setGameID(id)
        setIsReconnect(true)
        navigate(`/game`)
    }

    return (
        <Box pb={"0.5vw"}>
            <Box p={"0.25vw"}>
                Your match is ready! Join to your game lobby
            </Box>
            <Button
                w={"10vw"}
                h={"2vw"}
                fontSize={"1.5vw"}
                colorScheme='yellow'
                backgroundColor={"#fda315"}
                border={"0.2vw solid black"}
                onClick={() => handleReJoin(rejoinGameID)}
            >
                Join Lobby
            </Button>
        </Box>
    )
}