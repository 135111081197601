import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, TableCaption, Tooltip } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { GetTransactions } from '../../../../requests/WalletRequests'

export const TransactionHistory = () => {
    const [transactions, setTransactions] = useState<any[]>([])

    useEffect(() => {
        const fetchTransactions = async () => {
            const result = await GetTransactions()
            result && result.withdraws && setTransactions(result.withdraws)
        }

        fetchTransactions()
    }, [])

    return (
        <Box backgroundColor={"#e9d2b3"} w={"100%"} borderRadius={"10px"} mt={3}>
            <Box backgroundColor={"#685235"} textColor={"#e3cd9e"} borderRadius={"10px 10px 0px 0px"} textAlign={"center"} fontSize={"20px"} p={3}>
                Transaction History
            </Box>
            <TableContainer textColor={"#74553f"}>
                <Table variant={"none"}>
                    {transactions.length === 0 && <TableCaption>There is no any transaction.</TableCaption>}
                    <Thead>
                        <Tr>
                            <Th>Status</Th>
                            <Th>Amount</Th>
                            <Th>Type</Th>
                            <Th>Wallet</Th>
                            <Th>Completed At</Th>
                        </Tr>
                    </Thead>
                    <Tbody backgroundColor={"#d7bf9a"}>
                        {
                            transactions && transactions.length > 0 &&
                            transactions.map((data, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td textColor={data.Status === 0 ? "#3b71ca" : "#14a44d"}>
                                            {data.Status === 0 ? "In Progress" : "Completed"}
                                        </Td>
                                        <Td fontWeight={"800"}>$ {data.Amount}</Td>
                                        <Td>USDC</Td>
                                        <Td>
                                            <Tooltip label={data.WalletAddress}>
                                                <span>
                                                    {data.WalletAddress.toString().slice(0, 11)}...
                                                </span>
                                            </Tooltip>
                                        </Td>
                                        <Td>{data.CompletedAt === null ? "Not Completed" : data.CompletedAt}</Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}