import { Button, useToast } from "@chakra-ui/react"
import { CreateWalletAddress, GetNonce } from "../../../../requests/WalletRequests"
import { useAccount, useSignMessage } from "wagmi"

type ConfirmWalletType = {
    setShowWalletConnect: (value: boolean) => void,
    setWalletAddress: (value: `0x${string}` | undefined) => void
}

export const ConfirmWallet = ({ setShowWalletConnect, setWalletAddress }: ConfirmWalletType) => {
    const { signMessage } = useSignMessage()
    const account = useAccount()

    const toast = useToast()

    const handleConfirmWallet = async () => {
        const nonceRes = await GetNonce()
        if (nonceRes) {
            signMessage(
                {
                    message: nonceRes.nonce.toString()
                },
                {
                    onSuccess: async (data, { }) => {
                        const response = await CreateWalletAddress(account.address, data)
                        if (response && response.message) {
                            toast({
                                description: "Wallet is succesfully connected.",
                                status: "success",
                                duration: 2000
                            })
                            setWalletAddress(account.address)
                            setShowWalletConnect(false)
                        }
                    }
                })
        }
    }

    if (account.isConnected) {
        return (
            <Button
                mt={2}
                backgroundColor={"#7fa631"}
                colorScheme={"green"}
                borderRadius={"25px"}
                border={"2px solid black"}
                onClick={handleConfirmWallet}
            >
                Confirm Wallet
            </Button>
        )
    } else {
        return null
    }
}