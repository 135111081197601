import { FaRegClock } from "react-icons/fa";
import { useDisclosure, Box, Card, CardBody, Image, Progress, ProgressLabel, Button, useToast } from '@chakra-ui/react'
import { getDeadline } from '../../../../utils/constants/getDeadline'
import { QuestCardDetail } from "./QuestCardDetail";
import { CreateClaimReward } from "../../../../requests/DashboardRequests";
import { useDashboard } from "../../../../context/DashboardContext";

type QuestCardType = {
    questID: number,
    questTitle: string,
    rewardTitle: string,
    totalTaskNumber: number,
    completedTasks: any[],
    notCompletedTasks: any[],
    questBannerAsset: string,
    questRewardAsset: string,
    questDeadline: string,
    isQuestClaimed: boolean,
    isQuestCompleted: boolean
}

export const QuestCard = ({
    questID,
    questTitle,
    rewardTitle,
    totalTaskNumber,
    completedTasks,
    notCompletedTasks,
    questBannerAsset,
    questRewardAsset,
    questDeadline,
    isQuestClaimed,
    isQuestCompleted
}: QuestCardType) => {
    const { setQuestList, setDailyTicketNumber } = useDashboard()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast()

    const handleClaim = async () => {
        const response = await CreateClaimReward(questID)
        if (response && response.message === "quest claimed") {
            toast({
                title: "Reward Claimed",
                description: "Reward is succesfully claimed. You can reach the reward from your inventory.",
                status: "success",
                duration: 5000,
            })
            setQuestList((prevState: any[]) => {
                return prevState.map(item => {
                    if (item.id === questID) {
                        return { ...item, is_quest_claimed: true };
                    }
                    return item;
                });
            });
            if (questTitle === "Daily Quest") {
                setDailyTicketNumber(prevDailyTicketNumber => prevDailyTicketNumber + 1)
            }
            onClose()
        }
    }

    return (
        <Card
            backgroundColor={"#96754B"}
            textAlign={"center"}
            borderRadius={"2vw"}
            height={"fit-content"}
            width={"95%"}
            mb={"1.5vw"}
            me={"auto"}
            ms={"auto"}
        >
            <CardBody p={0}>
                <Box pos={"relative"} textAlign={"center"}>
                    <Image
                        src={questBannerAsset}
                        alt='quest-banner'
                        borderRadius={"2vw 2vw 0 0"}
                        w={"100%"}
                        h={"100%"}
                        objectFit={"cover"}
                    />
                    <span className='quest-detail-card-time'>
                        <FaRegClock className='me-2' />
                        {getDeadline(questDeadline)}
                    </span>
                </Box>
                <Box color={"#F2E9D6"} mt={"0.25vw"} mb={0} fontSize={"1.5vw"}>
                    {questTitle}
                </Box>
                <Box color={"white"} mt={"0.25vw"} mb={1} fontSize={"1vw"}>
                    {rewardTitle}
                </Box>
                <Box mb={2}>
                    <Progress
                        m={"auto"}
                        w={"75%"}
                        backgroundColor={"#634D39"}
                        colorScheme={"green"}
                        borderRadius={"2vw"}
                        border={"0.2vw solid #533b25"}
                        height={"1.75vw"}
                        value={completedTasks.length}
                        max={totalTaskNumber}
                    >
                        <ProgressLabel fontSize={"1vw"}>
                            {completedTasks.length}/{totalTaskNumber}
                        </ProgressLabel>
                    </Progress>
                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"}>
                    <Button
                        width={"40%"}
                        height={"auto"}
                        boxShadow={"0 0.15vw 0 0 orange"}
                        border={"0.1vw solid orange"}
                        colorScheme={'orange'}
                        fontSize={"1.3vw"}
                        mb={"1vw"}
                        onClick={onOpen}
                    >
                        Details
                    </Button>

                    {
                        !isQuestClaimed &&
                        <Button
                            width={"40%"}
                            height={"auto"}
                            backgroundColor={"#ACA320"}
                            border={"0.1vw solid #413320"}
                            boxShadow={"0 0.15vw 0 0 #413320"}
                            colorScheme={"green"}
                            fontSize={"1.3vw"}
                            mb={"1vw"}
                            isDisabled={!isQuestCompleted}
                            onClick={handleClaim}
                        >
                            Claim
                        </Button>
                    }
                </Box>


                <QuestCardDetail
                    questBannerAsset={questBannerAsset}
                    questRewardAsset={questRewardAsset}
                    completedTasks={completedTasks}
                    notCompletedTasks={notCompletedTasks}
                    setQuest={setQuestList}
                    rewardTitle={rewardTitle}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </CardBody>
        </Card>
    )
}