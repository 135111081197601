import { Box } from "@chakra-ui/react"

type StageTitleType = {
    PlayerState: number,
    FreezeTimeEnd: string | null
}

export const StageTitle = ({ PlayerState, FreezeTimeEnd }: StageTitleType) => {
    return (
        <Box
            textAlign={"center"}
            fontSize={"2.5vw"}
            textColor={"#e6d1a4"}
            mb={"0.5vw"}
            mt={"0.5vw"}
        >
            {
                !FreezeTimeEnd && PlayerState === 2 &&
                "Waiting for the tournament to begin!"
            }
            {
                !FreezeTimeEnd && PlayerState === 5 &&
                "Waiting for other matches to end!"
            }
            {
                FreezeTimeEnd &&
                "The next stage will begin soon!"
            }
        </Box>
    )
}