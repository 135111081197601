export const getPlaceCoordsByHexId = (hexId: number) => {
  const places: { x: number; y: number }[] = [];

  // Row1
  if (hexId === 0 || hexId === 1 || hexId === 2) {
    places.push({ x: hexId * 2, y: 0 });
    places.push({ x: hexId * 2 + 1, y: 1 });
    places.push({ x: hexId * 2 + 1, y: 0 });
    places.push({ x: hexId * 2 + 2, y: 1 });
    places.push({ x: hexId * 2 + 2, y: 0 });
    places.push({ x: hexId * 2 + 3, y: 1 });
  }
  // Row2
  else if (
    hexId - 3 === 0 ||
    hexId - 3 === 1 ||
    hexId - 3 === 2 ||
    hexId - 3 === 3
  ) {
    places.push({ x: (hexId - 3) * 2, y: 1 });
    places.push({ x: (hexId - 3) * 2 + 1, y: 2 });
    places.push({ x: (hexId - 3) * 2 + 1, y: 1 });
    places.push({ x: (hexId - 3) * 2 + 2, y: 2 });
    places.push({ x: (hexId - 3) * 2 + 2, y: 1 });
    places.push({ x: (hexId - 3) * 2 + 3, y: 2 });
  }
  // Row 3
  else if (
    hexId - 7 === 0 ||
    hexId - 7 === 1 ||
    hexId - 7 === 2 ||
    hexId - 7 === 3 ||
    hexId - 7 === 4
  ) {
    places.push({ x: (hexId - 7) * 2, y: 2 });
    places.push({ x: (hexId - 7) * 2, y: 3 });
    places.push({ x: (hexId - 7) * 2 + 1, y: 2 });
    places.push({ x: (hexId - 7) * 2 + 1, y: 3 });
    places.push({ x: (hexId - 7) * 2 + 2, y: 2 });
    places.push({ x: (hexId - 7) * 2 + 2, y: 3 });
  }
  // Row 4
  else if (
    hexId - 12 === 0 ||
    hexId - 12 === 1 ||
    hexId - 12 === 2 ||
    hexId - 12 === 3
  ) {
    places.push({ x: (hexId - 12) * 2 + 1, y: 3 });
    places.push({ x: (hexId - 12) * 2, y: 4 });
    places.push({ x: (hexId - 12) * 2 + 2, y: 3 });
    places.push({ x: (hexId - 12) * 2 + 1, y: 4 });
    places.push({ x: (hexId - 12) * 2 + 3, y: 3 });
    places.push({ x: (hexId - 12) * 2 + 2, y: 4 });
  }
  // Row 5
  else if (hexId - 16 === 0 || hexId - 16 === 1 || hexId - 16 === 2) {
    places.push({ x: (hexId - 16) * 2 + 1, y: 4 });
    places.push({ x: (hexId - 16) * 2, y: 5 });
    places.push({ x: (hexId - 16) * 2 + 2, y: 4 });
    places.push({ x: (hexId - 16) * 2 + 1, y: 5 });
    places.push({ x: (hexId - 16) * 2 + 3, y: 4 });
    places.push({ x: (hexId - 16) * 2 + 2, y: 5 });
  }

  return places;
};
