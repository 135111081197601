import { useEffect } from "react";
import { ResourceNumbers } from "../../../utils/types/types";

interface PlayerTradeInputValidation {
  resources: ResourceNumbers;
  wantedCards: ResourceNumbers;
  givenCards: ResourceNumbers;
  setDisabled: (value: boolean) => void;
}

export const PlayerTradeInputValidation = (
  props: PlayerTradeInputValidation
) => {
  const myResCards = props.resources;
  const wantedCards = props.wantedCards;
  const givenCards = props.givenCards;
  const setDisabled = props.setDisabled;

  // Validation for Taken and Given Card inputs
  useEffect(() => {
    if (myResCards) {
      let resTypeCheck = true;
      let enoughRes = true;
      let zeroCheck = true;

      const totalTaken =
        wantedCards.Wood +
        wantedCards.Brick +
        wantedCards.Sheep +
        wantedCards.Wheat +
        wantedCards.Ore;
      const totalGiven =
        givenCards.Wood +
        givenCards.Brick +
        givenCards.Sheep +
        givenCards.Wheat +
        givenCards.Ore;

      if (givenCards.Brick > 0 && wantedCards.Brick > 0) {
        resTypeCheck = false;
      } else if (givenCards.Ore > 0 && wantedCards.Ore > 0) {
        resTypeCheck = false;
      } else if (givenCards.Sheep > 0 && wantedCards.Sheep > 0) {
        resTypeCheck = false;
      } else if (givenCards.Wheat > 0 && wantedCards.Wheat > 0) {
        resTypeCheck = false;
      } else if (givenCards.Wood > 0 && wantedCards.Wood > 0) {
        resTypeCheck = false;
      }

      if (
        myResCards.Brick >= givenCards.Brick &&
        myResCards.Ore >= givenCards.Ore &&
        myResCards.Sheep >= givenCards.Sheep &&
        myResCards.Wheat >= givenCards.Wheat &&
        myResCards.Wood >= givenCards.Wood
      ) {
        enoughRes = false;
      } else {
        enoughRes = true;
      }

      if (totalGiven === 0 || totalTaken === 0) {
        zeroCheck = true;
      } else {
        zeroCheck = false;
      }

      setDisabled(enoughRes || zeroCheck || !resTypeCheck);
    } else {
      setDisabled(true);
    }
  }, [myResCards, wantedCards, givenCards]);
};
