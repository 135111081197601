import { useGame } from '../../../../context/GameContext';
import { Modal, ModalContent, ModalBody, ModalOverlay, Image, Button } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton';
import { TutorialTabList } from './TutorialTabList';
import { TutorialContent } from './TutorialContent';

export const GameTutorial = ({ isOpen, onOpen, onClose, isDashboard }: { isOpen: boolean, onOpen: () => void, onClose: () => void, isDashboard: boolean }) => {
    const { dashboardAssets, isMobile } = useGame()

    return (
        <>
            {
                isDashboard &&
                <Button
                    flexDir={"column"}
                    width={"7.5vw"}
                    height={"7.5vw"}
                    fontSize={"1.25vw"}
                    borderRadius={"1vw"}
                    backgroundColor={"#AC8358"}
                    colorScheme={"orange"}
                    onClick={onOpen}
                >
                    <Image src={dashboardAssets.guideIcon} alt="game-guide-icon" />
                    Guide
                </Button>
            }

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                size={"xl"}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#745833"}
                    borderRadius={"25px"}
                    maxW={isMobile ? "100%" : "90%"}
                    height={isMobile ? "100%" : "95%"}
                >
                    <ModalBody
                        p={0}
                        backgroundColor={"#EAC28D"}
                        borderRadius={"25px"}
                        height={"95%"}
                        display={"flex"}
                    >
                        <DashboardCloseButton onClose={onClose} />

                        <TutorialTabList />
                        <TutorialContent />

                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    )
}