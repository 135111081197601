import { BuildRoad } from './BuildRoad';
import { BuildCity } from './BuildCity';
import { BuildSettlement } from './BuildSettlement';
import { SocketType } from '../../../../utils/types/types';
import { useSync } from '../../../../context/SyncContext';
import { useGame } from '../../../../context/GameContext';
import { useEffect } from 'react';

type CostInfoType = {
    socket: SocketType
}

export const BuildConfirmModal = ({ socket }: CostInfoType) => {
    const { confirmData, setConfirmData } = useGame()
    const { myGameData, gameData } = useSync()

    useEffect(() => {
        if (myGameData && myGameData.turn_index !== gameData.TurnIndex) {
            setConfirmData(null)
        }
    }, [myGameData, gameData])

    if (confirmData && myGameData) {
        if (confirmData.costType === "road") {
            return (
                <BuildRoad
                    socket={socket}
                    confirmData={confirmData}
                    setConfirmData={setConfirmData}
                />
            )
        } else if (confirmData.costType === "settle") {
            return (
                <BuildSettlement
                    socket={socket}
                    confirmData={confirmData}
                    setConfirmData={setConfirmData}
                />
            )
        } else if (confirmData.costType === "city") {
            return (
                <BuildCity
                    socket={socket}
                    confirmData={confirmData}
                    setConfirmData={setConfirmData}
                />
            )
        }
    } else {
        return null
    }
}