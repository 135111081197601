import { IoIosPhoneLandscape } from "react-icons/io";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Box } from '@chakra-ui/react'
import { useEffect, useState } from "react";

export const LandscapeWarning = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation : landscape)").matches);

    const updateOrientation = () => {
        setIsLandscape(window.matchMedia("(orientation : landscape)").matches)
    }

    useEffect(() => {
        const mediaQuery = window.matchMedia("(orientation : landscape)")

        mediaQuery.addEventListener('change', updateOrientation)

        return () => {
            mediaQuery.addEventListener('change', updateOrientation)
        }
    }, []);

    if (!isLandscape) {
        return (
            <Modal
                isOpen={true}
                onClose={() => { }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                isCentered
            >
                <ModalOverlay backgroundColor={"rgba(0,0,0,0.9)"} />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    borderRadius={"25px"}
                >
                    <ModalHeader
                        className='menu-modal-headers'
                        textAlign={"center"}
                    >
                        Warning
                    </ModalHeader>
                    <ModalBody
                        p={5}
                        color={"dark"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                    >
                        <Box textAlign={"center"}>
                            For better experience,
                            please hold the device in <strong>Landscape Mode</strong>!
                        </Box>
                        <IoIosPhoneLandscape fontSize={"25vw"} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    } else {
        return null
    }
}