import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../context/GameContext"
import { useDashboard } from "../../../../../context/DashboardContext"
import { Quest } from "./Quest"

export const DailyQuest = () => {
    const { questList } = useDashboard()
    const { dashboardAssets } = useGame()

    const dailyQuests = questList.filter((quest) => quest.title === "Daily Quest")

    return (
        <Box
            className="daily-quest-background"
            w={"20vw"}
            height={"47.5vh"}
            mt={"auto"}
            overflowY={"auto"}
        >
            <Box
                pos={"sticky"}
                zIndex={"1"}
                top={"0"}
                backgroundColor={"#2c3945"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Image
                    src={dashboardAssets.questModalIcon}
                    alt="quest-icon"
                    w={"15%"}
                    me={"0.5vw"}
                />
                <Box className="gradient-text-color">
                    Daily Quest
                </Box>
            </Box>

            {
                dailyQuests.length > 0 ?
                    dailyQuests.map((quest, index) => {
                        return (
                            <Quest
                                key={index}
                                questID={quest.id}
                                questIndex={index}
                                completedTasks={quest.completed_tasks}
                                notCompletedTasks={quest.not_completed_tasks}
                                isQuestClaimed={quest.is_quest_claimed}
                            />
                        )
                    }) :
                    <Box
                        textColor={"white"}
                        textAlign={"center"}
                        fontSize={"1.25vw"}
                        mt={"1.25vw"}
                    >
                        There is no daily quest!
                    </Box>
            }
        </Box>
    )
}