import { useEffect, useState } from "react";
import { useSync } from "../../../context/SyncContext";
import { useGame } from '../../../context/GameContext';
import { SocketType } from '../../../utils/types/types';
import { getUsernameByIndex } from '../../../utils/functions/getUsernameByIndex';
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const TurnNotification = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects } = useGame()
    const { myGameData, players, setGameData } = useSync()

    const [showTurnNotif, setShowTurnNotif] = useState(false)

    // Handle turnIndex change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "TurnIndexChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                setGameData(prevGameData => ({ ...prevGameData, TurnIndex: parsedData.index }))

                const divElement = document.createElement("div")
                divElement.classList.add("player-notification")
                divElement.classList.add("turn-notif")

                const imgElement = document.createElement("img")
                imgElement.src = gameAssets.yourTurnNotifIcon
                imgElement.alt = "turn-icon"
                imgElement.style.width = "10%"
                imgElement.classList.add("me-2")

                const username = document.createElement("span")
                username.textContent = `It's ${getUsernameByIndex(players, parsedData.index)} Turn!`

                divElement.appendChild(imgElement)
                divElement.appendChild(username)

                const playerElement = document.getElementById(`player-${parsedData.index}`)

                if (playerElement) {
                    playerElement.appendChild(divElement)

                    if (myGameData && parsedData.index === myGameData.turn_index) {
                        PlaySoundEffect(soundEffects.yourTurnSFX, sfxVolume, sfxEnabled)
                    } else {
                        PlaySoundEffect(soundEffects.turnChangeSFX, sfxVolume, sfxEnabled)
                    }
                    setShowTurnNotif(true)
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    useEffect(() => {
        if (showTurnNotif) {
            const timeout = setTimeout(() => {
                const turnElement = document.querySelector(".turn-notif")
                if (turnElement) {
                    turnElement.remove()
                    setShowTurnNotif(false)
                }
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [showTurnNotif])
}