import blueAxeSettle from "../animations/Characters/Blue/blue_1char_axe.gif";
import blueAxeCity from "../animations/Characters/Blue/blue_2char_axe.gif";
import blueSheepSettle from "../animations/Characters/Blue/blue_1char_wool.gif";
import blueSheepCity from "../animations/Characters/Blue/blue_2char_wool.gif";
import blueMineSettle from "../animations/Characters/Blue/blue_1char_mining.gif";
import blueMineCity from "../animations/Characters/Blue/blue_2char_mining.gif";
import blueWheatSettle from "../animations/Characters/Blue/blue_1char_wheat.gif";
import blueWheatCity from "../animations/Characters/Blue/blue_2char_wheat.gif";

import redAxeSettle from "../animations/Characters/Red/red_1char_axe.gif";
import redAxeCity from "../animations/Characters/Red/red_2char_axe.gif";
import redSheepSettle from "../animations/Characters/Red/red_1char_wool.gif";
import redSheepCity from "../animations/Characters/Red/red_2char_wool.gif";
import redMineSettle from "../animations/Characters/Red/red_1char_mining.gif";
import redMineCity from "../animations/Characters/Red/red_2char_mining.gif";
import redWheatSettle from "../animations/Characters/Red/red_1char_wheat.gif";
import redWheatCity from "../animations/Characters/Red/red_2char_wheat.gif";

import pinkAxeSettle from "../animations/Characters/Pink/pink_1char_axe.gif";
import pinkAxeCity from "../animations/Characters/Pink/pink_2char_axe.gif";
import pinkSheepSettle from "../animations/Characters/Pink/pink_1char_wool.gif";
import pinkSheepCity from "../animations/Characters/Pink/pink_2char_wool.gif";
import pinkMineSettle from "../animations/Characters/Pink/pink_1char_mining.gif";
import pinkMineCity from "../animations/Characters/Pink/pink_2char_mining.gif";
import pinkWheatSettle from "../animations/Characters/Pink/pink_1char_wheat.gif";
import pinkWheatCity from "../animations/Characters/Pink/pink_2char_wheat.gif";

import grayAxeSettle from "../animations/Characters/Grey/grey_1char_axe.gif";
import grayAxeCity from "../animations/Characters/Grey/grey_2char_axe.gif";
import graySheepSettle from "../animations/Characters/Grey/grey_1char_wool.gif";
import graySheepCity from "../animations/Characters/Grey/grey_2char_wool.gif";
import grayMineSettle from "../animations/Characters/Grey/grey_1char_mining.gif";
import grayMineCity from "../animations/Characters/Grey/grey_2char_mining.gif";
import grayWheatSettle from "../animations/Characters/Grey/grey_1char_wheat.gif";
import grayWheatCity from "../animations/Characters/Grey/grey_2char_wheat.gif";

export const animationAssetList: any = {
  blueAxeSettle: blueAxeSettle,
  blueAxeCity: blueAxeCity,
  blueSheepSettle: blueSheepSettle,
  blueSheepCity: blueSheepCity,
  blueMineSettle: blueMineSettle,
  blueMineCity: blueMineCity,
  blueWheatSettle: blueWheatSettle,
  blueWheatCity: blueWheatCity,
  redAxeSettle: redAxeSettle,
  redAxeCity: redAxeCity,
  redSheepSettle: redSheepSettle,
  redSheepCity: redSheepCity,
  redMineSettle: redMineSettle,
  redMineCity: redMineCity,
  redWheatSettle: redWheatSettle,
  redWheatCity: redWheatCity,
  pinkAxeSettle: pinkAxeSettle,
  pinkAxeCity: pinkAxeCity,
  pinkSheepSettle: pinkSheepSettle,
  pinkSheepCity: pinkSheepCity,
  pinkMineSettle: pinkMineSettle,
  pinkMineCity: pinkMineCity,
  pinkWheatSettle: pinkWheatSettle,
  pinkWheatCity: pinkWheatCity,
  grayAxeSettle: grayAxeSettle,
  grayAxeCity: grayAxeCity,
  graySheepSettle: graySheepSettle,
  graySheepCity: graySheepCity,
  grayMineSettle: grayMineSettle,
  grayMineCity: grayMineCity,
  grayWheatSettle: grayWheatSettle,
  grayWheatCity: grayWheatCity,
};
