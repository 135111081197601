import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../../context/GameContext"
import { FaCheckCircle, FaClock } from "react-icons/fa"
import { GetTimeDifference } from "../../../Notification/GetTimeDifference"

type QualifyType = {
    CurrentStage: number,
    PlayerState: number,
    StartTime: string,
    FreezeTimeEnd: string | null
}

export const Qualify = ({ CurrentStage, PlayerState, StartTime, FreezeTimeEnd }: QualifyType) => {
    const { gameAssets } = useGame()

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Box backgroundColor={PlayerState === 2 ? "#cb3e50" : "#51ea56"} borderRadius={"50%"} p={"1vw"}>
                {
                    CurrentStage === 1 && !FreezeTimeEnd &&
                    <FaClock />
                }
                {
                    ((CurrentStage === 2 || CurrentStage === 3) || (CurrentStage === 1 && FreezeTimeEnd)) &&
                    <FaCheckCircle />
                }
            </Box>
            <Box textColor={"#e6d1a4"} textDecoration={PlayerState === 5 ? "line-through" : undefined}>
                Qualify Stage
            </Box>
            <Image
                src={gameAssets.gameLogDivider}
                alt="game-log-divider"
                width={"50%"}
            />
            {
                CurrentStage === 1 && PlayerState === 2 &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Get ready! The tournament will begin soon.
                    <GetTimeDifference finishTime={StartTime} />
                </Box>
            }
            {
                CurrentStage === 1 && PlayerState === 5 && !FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Stay Focus! Waiting for other matches to end!
                </Box>
            }
            {
                ((CurrentStage === 1 && FreezeTimeEnd) || (CurrentStage > 1)) &&
                <Box fontSize={"1.25vw"} textColor={"#51ea56"}>
                    You have passed the Qualify Stage.
                </Box>
            }
        </Box>
    )
}