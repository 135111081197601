import { FaEye, FaTable } from "react-icons/fa";
import { TbTournament } from "react-icons/tb";
import { Modal, ModalContent, ModalBody, ModalOverlay } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { TournamentOverview } from '../Overview/TournamentOverview'
import { ChampionshipStandings } from '../Standings/ChampionshipStandings'
import { DashboardCloseButton } from "../../../General/DashboardCloseButton";
import { KnockoutBracket } from "../Bracket/KnockoutBracket";
import { SingleElimBracket } from "../Bracket/SingleElimBracket";
import { ChampionshipBracket } from "../Bracket/ChampionshipBracket";
import { Standings } from "../Standings/Standings";

type TournamentCardDetailType = {
    isOpen: boolean,
    onClose: () => void,
    ID: number,
    TicketAsset: string,
    Capacity: number,
    IsDoubleElimination: boolean,
    IsChampionship: boolean,
    CurrentPlayers: number,
    IsUserSignedUp: boolean,
    IsPrivate: boolean,
    Name: string,
    Prize: number[],
    StartTime: string,
    State: number,
    CurrentStage: number,
    UserCheckedIn: boolean,
    HaveTicket: boolean,
    handleRegister: () => Promise<void>,
    handleRegisterCancel: () => Promise<void>,
    handleCheckin: () => Promise<void>,
    handleCheckinCancel: () => Promise<void>
}

export const TournamentCardDetail = ({
    isOpen,
    onClose,
    ID,
    TicketAsset,
    Capacity,
    IsDoubleElimination,
    IsChampionship,
    CurrentPlayers,
    IsUserSignedUp,
    IsPrivate,
    Name,
    Prize,
    StartTime,
    State,
    CurrentStage,
    UserCheckedIn,
    HaveTicket,
    handleRegister,
    handleCheckinCancel,
    handleCheckin,
    handleRegisterCancel }: TournamentCardDetailType) => {
    const isTabsAllowed = !(State === 3 || State === 4 || State === 6)

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            motionPreset={"slideInBottom"}
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor={"#edcca2"}
                textColor={"white"}
                borderRadius={"25px"}
                maxW={"min-content"}
                height={"min-content"}
            >
                <ModalBody
                    backgroundColor={"#EAC28D"}
                    p={0}
                    borderRadius={"25px"}
                >
                    <DashboardCloseButton onClose={onClose} />

                    <Tabs variant={"soft-rounded"}>
                        <TabList borderRadius={"25px 25px 0px 0px"} backgroundColor={"#745833"} pe={"50px"}>
                            <Tab
                                borderRadius={"20px 0px 0px 0px"}
                                _selected={{ bg: "#53422b" }}
                                textColor={"#efdebc"}
                                p={"1.5vw"}
                                fontSize={"2vw"}
                            >
                                <FaEye className="me-2" />
                                Overview
                            </Tab>
                            <Tab
                                borderRadius={0}
                                _selected={{ bg: "#53422b" }}
                                textColor={"#efdebc"}
                                p={"1.5vw"}
                                fontSize={"2vw"}
                                isDisabled={isTabsAllowed}
                            >
                                <FaTable className='me-2' />
                                Standings
                            </Tab>
                            <Tab
                                borderRadius={0}
                                _selected={{ bg: "#53422b" }}
                                textColor={"#efdebc"}
                                p={"1.5vw"}
                                fontSize={"2vw"}
                                isDisabled={isTabsAllowed}
                            >
                                <TbTournament className='me-2' />
                                Brackets
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel p={0}>
                                <TournamentOverview
                                    TicketAsset={TicketAsset}
                                    Capacity={Capacity}
                                    IsDoubleElimination={IsDoubleElimination}
                                    IsChampionship={IsChampionship}
                                    CurrentPlayers={CurrentPlayers}
                                    IsUserSignedUp={IsUserSignedUp}
                                    IsPrivate={IsPrivate}
                                    Name={Name}
                                    Prize={Prize}
                                    StartTime={StartTime}
                                    State={State}
                                    UserCheckedIn={UserCheckedIn}
                                    HaveTicket={HaveTicket}
                                    handleCheckin={handleCheckin}
                                    handleRegister={handleRegister}
                                    handleCheckinCancel={handleCheckinCancel}
                                    handleRegisterCancel={handleRegisterCancel}
                                />
                            </TabPanel>
                            <TabPanel p={0}>
                                {
                                    IsChampionship ?
                                        <ChampionshipStandings
                                            ID={ID}
                                            State={State}
                                            CurrentStage={CurrentStage}
                                            Matches={null}
                                        /> :
                                        <Standings
                                            ID={ID}
                                            State={State}
                                            Matches={null}
                                        />
                                }
                            </TabPanel>
                            <TabPanel p={0}>
                                {
                                    IsChampionship ?
                                        <ChampionshipBracket
                                            ID={ID}
                                            State={State}
                                            Prize={Prize}
                                            CurrentStage={CurrentStage}
                                            setMatches={null}
                                        /> :
                                        IsDoubleElimination ?
                                            <KnockoutBracket
                                                ID={ID}
                                                State={State}
                                                Prize={Prize}
                                                CurrentStage={CurrentStage}
                                                setMatches={null}
                                            /> :
                                            <SingleElimBracket
                                                ID={ID}
                                                State={State}
                                                Prize={Prize}
                                                CurrentStage={CurrentStage}
                                                setMatches={null}
                                            />
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}