export const getDiceAnimation = (value: number, assets: any) => {
  if (value === 1) {
    return assets.dice1;
  } else if (value === 2) {
    return assets.dice2;
  } else if (value === 3) {
    return assets.dice3;
  } else if (value === 4) {
    return assets.dice4;
  } else if (value === 5) {
    return assets.dice5;
  } else if (value === 6) {
    return assets.dice6;
  } else {
    return "";
  }
};

export const getFlipDiceAnimation = (value: number, assets: any) => {
  if (value === 1) {
    return assets.diceFlip1;
  } else if (value === 2) {
    return assets.diceFlip2;
  } else if (value === 3) {
    return assets.diceFlip3;
  } else if (value === 4) {
    return assets.diceFlip4;
  } else if (value === 5) {
    return assets.diceFlip5;
  } else if (value === 6) {
    return assets.diceFlip6;
  } else {
    return "";
  }
};
