import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, Image, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ResourceNumbers, SocketType } from '../../../utils/types/types'
import { useGame } from '../../../context/GameContext';
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent';
import { PlaySoundEffect } from '../../../sounds/playSoundEffect';

type YearOfPlenty = {
    isOpen: boolean,
    onClose: () => void,
    gameID: number,
    socket: SocketType,
    setDevCardUsed: (value: boolean) => void;
}

export const YearOfPlenty = ({ isOpen, onClose, gameID, socket, setDevCardUsed }: YearOfPlenty) => {
    const { gameAssets } = useGame()

    const [disabled, setDisabled] = useState<boolean>(true)

    const [takenCards, setTakenCards] = useState<ResourceNumbers>({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })

    const totalCards = takenCards.Wood + takenCards.Brick + takenCards.Wheat + takenCards.Sheep + takenCards.Ore

    // Validation for Taken and Given Card inputs
    useEffect(() => {
        if (totalCards === 2) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }, [totalCards])

    const handlePOYCard = async () => {
        const resources = {
            ...(takenCards.Wood > 0 ? { wood: takenCards.Wood } : {}),
            ...(takenCards.Brick > 0 ? { brick: takenCards.Brick } : {}),
            ...(takenCards.Sheep > 0 ? { sheep: takenCards.Sheep } : {}),
            ...(takenCards.Wheat > 0 ? { wheat: takenCards.Wheat } : {}),
            ...(takenCards.Ore > 0 ? { ore: takenCards.Ore } : {})
        }

        const eventData = {
            id: gameID,
            card: "YearOfPlenty",
            data: JSON.stringify(
                { resources }
            )
        }

        SendGameEvent(socket, "UseDevCard", eventData)
        setTakenCards({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
        setDevCardUsed(true)
        onClose()
    }

    return (
        <Modal
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent
                background={"linear-gradient(#CDB57A, #9B8152)"}
                maxW={"max-content"}
                borderRadius={"1vw"}
            >
                <ModalHeader
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    maxH={"10vh"}
                    fontSize={"2vw"}
                    borderRadius={"1vw 1vw 0 0"}
                    backgroundColor={"#6D5535"}
                    textColor={"white"}
                >
                    Claim 2 resource from Bank
                </ModalHeader>

                <ModalBody display={"flex"} justifyContent={"space-evenly"} p={"1vw"}>
                    <ResourceCard
                        name={"Wood"}
                        imageSrc={gameAssets.woodIcon}
                        imageAlt={"woodCard"}
                        count={takenCards.Wood}
                        onChange={setTakenCards}
                        color={"green"}
                        maxValue={2}
                        totalValue={totalCards}
                    />
                    <ResourceCard
                        name={"Brick"}
                        imageSrc={gameAssets.brickIcon}
                        imageAlt={"brickCard"}
                        count={takenCards.Brick}
                        onChange={setTakenCards}
                        color={"green"}
                        maxValue={2}
                        totalValue={totalCards}
                    />
                    <ResourceCard
                        name={"Sheep"}
                        imageSrc={gameAssets.sheepIcon}
                        imageAlt={"sheepCard"}
                        count={takenCards.Sheep}
                        onChange={setTakenCards}
                        color={"green"}
                        maxValue={2}
                        totalValue={totalCards}
                    />
                    <ResourceCard
                        name={"Wheat"}
                        imageSrc={gameAssets.wheatIcon}
                        imageAlt={"wheatCard"}
                        count={takenCards.Wheat}
                        onChange={setTakenCards}
                        color={"green"}
                        maxValue={2}
                        totalValue={totalCards}
                    />
                    <ResourceCard
                        name={"Ore"}
                        imageSrc={gameAssets.oreIcon}
                        imageAlt={"oreCard"}
                        count={takenCards.Ore}
                        onChange={setTakenCards}
                        color={"green"}
                        maxValue={2}
                        totalValue={totalCards}
                    />
                </ModalBody>

                <ModalFooter
                    maxH={"10vh"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    backgroundColor={"#6D5535"}
                    borderRadius={"0 0 1vw 1vw"}
                    textColor={"white"}
                >
                    <Button
                        w={"10vw"}
                        h={"7vh"}
                        fontSize={"1.5vw"}
                        backgroundColor={"#E94F4F"}
                        colorScheme='red'
                        border={"0.25vw solid #B40000"}
                        borderRadius={"0.5vw"}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        w={"10vw"}
                        h={"7vh"}
                        fontSize={"1.5vw"}
                        backgroundColor={"#85D885"}
                        border={"0.25 solid #083D00"}
                        borderRadius={"0.5vw"}
                        colorScheme='green'
                        isDisabled={disabled}
                        onClick={handlePOYCard}
                    >
                        Use
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export type ResourceCard = {
    imageSrc: string,
    imageAlt: string,
    count: number,
    onChange: (value: ResourceNumbers) => void,
    name: string,
    color: string,
    maxValue: number,
    totalValue: number,
}

export const ResourceCard = ({ imageSrc, imageAlt, count, onChange, name, color, maxValue, totalValue }: ResourceCard) => {
    const { sfxVolume, sfxEnabled, soundEffects } = useGame()

    const isCountZero = count === 0

    const handleIncrease = () => {
        if (totalValue < maxValue) {
            onChange(prevState => ({
                ...prevState,
                [name]: count + 1
            }));

            PlaySoundEffect(soundEffects.decreaseCardAmountSFX, sfxVolume, sfxEnabled)
        }
    }

    const handleDecrease = () => {
        if (count > 0) {
            onChange(prevState => ({
                ...prevState,
                [name]: count - 1
            }));
        }
    }

    return (
        <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"}>
            <Box
                className={`card-chosen-animation-${color}`}
                pos={"relative"}
                h={isCountZero ? "6vw" : "4vw"}
                w={"3.5vw"}
                backgroundColor={"#a0ad5b"}
                borderRadius={"0.5vw"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={handleIncrease}
            >
                <Image
                    src={imageSrc}
                    alt={imageAlt}
                    width={"2.5vw"}
                />
                <Box
                    className="top-card-number"
                    sx={{
                        WebkitTextStroke: "0.1vw #195f0c",
                    }}
                >
                    {count}
                </Box>
            </Box>
            {
                count > 0 &&
                <Box
                    _hover={{
                        cursor: "pointer"
                    }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"2.5vw"}
                    height={"1.75vw"}
                    fontSize={"2vw"}
                    mt={"0.25vw"}
                    backgroundColor={"#dc6958"}
                    textColor={"white"}
                    borderRadius={"0vw 0vw 0.5vw 0.5vw"}
                    border={"0.2vw solid #c65f50"}
                    onClick={handleDecrease}
                >
                    -
                </Box>
            }
        </Box>
    )
}