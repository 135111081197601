import { useEffect } from "react"
import { useSync } from "../context/SyncContext"
import { SocketType } from "../utils/types/types"

export const ArmyPoints = (socket: SocketType) => {
    const { setArmyPoints, setLargestArmyOwner } = useSync()

    // Update Army Points
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "DevCardUsed") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData && parsedData.card === "Knight") {
                    setArmyPoints(prevState => {
                        const newPoints = [...prevState];
                        newPoints[parsedData.turnIndex] = newPoints[parsedData.turnIndex] + 1;
                        return newPoints
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    // Update Army Points
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "LargestArmy") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setLargestArmyOwner(parsedData.index)
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}