import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, Image, Box } from '@chakra-ui/react'
import { SocketType } from '../../../utils/types/types'
import { useGame } from '../../../context/GameContext';
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent';

type Monoply = {
    isOpen: boolean,
    onClose: () => void,
    gameID: number
    socket: SocketType,
    setDevCardUsed: (value: boolean) => void;
}

export const Monoploy = ({ isOpen, onClose, gameID, socket, setDevCardUsed }: Monoply) => {
    const { gameAssets } = useGame()

    const handleMonoplyCard = async (resource: string) => {
        const eventData = {
            id: gameID,
            card: "Monopoly",
            data: JSON.stringify(
                { resource }
            )
        }
        SendGameEvent(socket, "UseDevCard", eventData)
        setDevCardUsed(true)
        onClose()
    }

    return (
        <Modal
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW={"max-content"}
                maxH={"min-content"}
                background={"linear-gradient(#CDB57A, #9B8152)"}
                borderRadius={"1vw"}
            >
                <ModalHeader
                    maxW={"40vw"}
                    maxH={"10vh"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"2vw"}
                    borderRadius={"1vw 1vw 0 0"}
                    backgroundColor={"#6D5535"}
                    textColor={"white"}
                >
                    Steal resource from other players
                </ModalHeader>

                <ModalBody display={"flex"} justifyContent={"space-evenly"} maxH={"20vh"}>
                    <ResourceCard imageSrc={gameAssets.woodIcon} imageAlt={"woodCard"} cardType={"wood"} onClick={handleMonoplyCard} />
                    <ResourceCard imageSrc={gameAssets.brickIcon} imageAlt={"brickCard"} cardType={"brick"} onClick={handleMonoplyCard} />
                    <ResourceCard imageSrc={gameAssets.sheepIcon} imageAlt={"sheepCard"} cardType={"sheep"} onClick={handleMonoplyCard} />
                    <ResourceCard imageSrc={gameAssets.wheatIcon} imageAlt={"wheatCard"} cardType={"wheat"} onClick={handleMonoplyCard} />
                    <ResourceCard imageSrc={gameAssets.oreIcon} imageAlt={"oreCard"} cardType={"ore"} onClick={handleMonoplyCard} />
                </ModalBody>

                <ModalFooter
                    maxW={"40vw"}
                    maxH={"10vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    backgroundColor={"#6D5535"}
                    borderRadius={"0 0 1vw 1vw"}
                    textColor={"white"}
                >
                    <Button
                        w={"15vw"}
                        height={"7vh"}
                        fontSize={"1.5vw"}
                        backgroundColor={"#E94F4F"}
                        colorScheme={'red'}
                        border={"0.25vw solid #B40000"}
                        borderRadius={"1vw"}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

type ResourceCard = {
    imageSrc: string,
    imageAlt: string,
    onClick: (value: string) => void,
    cardType: string
}

const ResourceCard = (props: ResourceCard) => {
    return (
        <Box
            className='card-chosen-animation-green'
            backgroundColor={"#a0ad5b"}
            w={"5vw"}
            height={"15vh"}
            borderRadius={"0.5vw"}
            me={"0.5vw"}
            ms={"0.5vw"}
            cursor={"pointer"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Image
                src={props.imageSrc}
                alt={props.imageAlt}
                width={"4vw"}
                onClick={() => props.onClick(props.cardType)}
            />
        </Box>
    )
}