import freePlayIcon from '../../assets/Landing/free_to_play_feature.png'
import turnBasedIcon from '../../assets/Landing/turn_based_feature.png'
import multiplayerIcon from '../../assets/Landing/multiplayer_feature.png'
import customizationIcon from '../../assets/Landing/customization_feature.png'
import gameFeatureBanner from '../../assets/Landing/game_feature_banner.png'
import { Box, Text, Image } from '@chakra-ui/react'

export const GameContent = () => {
    const goldTextColor = "#ECD96B"

    return (
        <Box
            id='gameContent'
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            className="brown-background p-md-4 p-2"
        >
            <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                textAlign={"center"}
                mb={"3vh"}
                mt={"3vh"}
            >
                <Box w={"20%"}>
                    <Image
                        src={freePlayIcon}
                        alt='free-to-play-catan'
                        w={"10vw"}
                        m={"auto"}
                    />
                    <Box as={"h1"} textColor={goldTextColor} fontSize={"2vw"} w={"75%"} m={"auto"}>
                        Free to Play Online
                    </Box>
                    <Box as={"h2"} textColor={"white"} fontSize={"1.25vw"}>
                        Accessible experience on mobile and desktop browsers
                    </Box>
                </Box>
                <Box w={"20%"}>
                    <Image
                        src={turnBasedIcon}
                        alt='turn-based-catan'
                        w={"10vw"}
                        m={"auto"}
                    />
                    <Box as={"h1"} textColor={goldTextColor} fontSize={"2vw"} w={"75%"} m={"auto"}>
                        Turn Based Strategy
                    </Box>
                    <Box as={"h2"} textColor={"white"} fontSize={"1.25vw"}>
                        Roll dice, gather resources and develop your colony
                    </Box>
                </Box>
                <Box w={"20%"}>
                    <Image
                        src={multiplayerIcon}
                        alt='multiplayer-catan'
                        w={"10vw"}
                        m={"auto"}
                    />
                    <Box as={"h1"} textColor={goldTextColor} fontSize={"2vw"} w={"75%"} m={"auto"}>
                        Competitive Multiplayer
                    </Box>
                    <Box as={"h2"} textColor={"white"} fontSize={"1.25vw"}>
                        Join to tournaments with friends for exciting prizes
                    </Box>
                </Box>
                <Box w={"20%"}>
                    <Image
                        src={customizationIcon}
                        alt='catan-with-customization'
                        w={"10vw"}
                        m={"auto"}
                    />
                    <Box as={"h1"} textColor={goldTextColor} fontSize={"2vw"} w={"75%"} m={"auto"}>
                        Immersive Customization
                    </Box>
                    <Box as={"h2"} textColor={"white"} fontSize={"1.25vw"}>
                        Show your style by collecting exclusive in-game pieces
                    </Box>
                </Box>
            </Box>

            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Box className="col-md-6 d-flex justify-content-center p-md-3 p-2" w={"45%"}>
                    <Image
                        src={gameFeatureBanner}
                        alt='pioneers-catan-board'
                        w={"90%"}
                    />
                </Box>
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    alignItems={"centers"}
                    backgroundColor={"#704d44"}
                    borderRadius={"25px"}
                    style={{ filter: "drop-shadow(0 0 0.75rem black)" }}
                    className="col-md-6 p-md-4 p-2 m-3"
                    w={"45%"}
                >
                    <Text as={"h3"} fontSize={"2.5vw"} textColor={"white"}>
                        A brand new
                        <span style={{ color: goldTextColor }}> turn-based strategy </span>
                        game, inspired by
                        <span style={{ color: goldTextColor }}> Settlers of Catan </span>
                        with thrilling competitions and extensive customization options!
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}