import loadingBackground from '../../../assets/Menu/Loading/loading_background.jpg'
import sandWatchIcon from '../../../assets/Menu/Loading/sand_watch_icon.png'
import { Box, Image, Text, Progress } from "@chakra-ui/react"

export const AssetLoading = () => {
    return (
        <Box
            backgroundImage={loadingBackground}
            backgroundPosition={"center"}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            h={"100vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box flexDir={"column"} alignItems={"center"} w={"30vw"}>
                <Image
                    src={sandWatchIcon}
                    h={"10vh"}
                    ml={"auto"}
                    mr={"auto"}
                    mb={"1vh"}
                />
                <Text
                    textAlign={"center"}
                    textColor={"#fee792"}
                    textShadow={"0.5vh 0.5vh 0.5vh black"}
                    css={{
                        WebkitTextStrokeColor: "black",
                        WebkitTextStrokeWidth: "0.3vh"
                    }}
                    letterSpacing={"-0.1vh"}
                    fontSize={"5vh"}
                    fontWeight={"bold"}
                    ml={"auto"}
                    mr={"auto"}
                    mb={"0.5vh"}
                >
                    LOADING NEW WORLD
                </Text>
                <Progress
                    backgroundColor={"#a06a5a"}
                    colorScheme={"yellow"}
                    height={"3vh"}
                    border={"0.5vh solid black"}
                    borderRadius={"25px"}
                    isIndeterminate
                />
            </Box>
        </Box>
    )
}