import { useEffect, useState } from "react"
import { useSync } from "../../../context/SyncContext"
import { getColorByIndex } from '../../../utils/assets/getColorByIndex'
import { SocketType } from '../../../utils/types/types'
import { getUsernameByIndex } from '../../../utils/functions/getUsernameByIndex'
import { useGame } from "../../../context/GameContext"
import { Box } from "@chakra-ui/react"

export const RobberMoveNotification = ({ socket }: { socket: SocketType }) => {
    const { gameAssets, isSpectator } = useGame()
    const { gameData, players, myGameData } = useSync()

    const [showAnimation, setShowAnimation] = useState(false)

    // Handle status change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "StatusChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                const playerIndex = gameData.TurnIndex

                if (parsedData.status === 6) {
                    if ((myGameData && playerIndex !== myGameData.turn_index) || isSpectator) {
                        setShowAnimation(true)
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (showAnimation) {
            const timeout = setTimeout(() => {
                setShowAnimation(false)
            }, 3500);

            return () => clearTimeout(timeout);
        }
    }, [showAnimation])

    if (showAnimation) {
        return (
            <Box backgroundImage={gameAssets.moveRobberNotif} id='moveThiefIndicator'>
                <img
                    src={gameAssets.thiefIcon}
                    alt='thief-icon'
                    width={"3%"}
                    className='me-3'
                />
                <span className="me-2" style={{ color: getColorByIndex(gameData.TurnIndex) }}>
                    {getUsernameByIndex(players, gameData.TurnIndex)}
                </span>
                <span>
                    is moving Robber!
                </span>
            </Box>
        )
    } else {
        return null
    }
}