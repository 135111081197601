
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, Image, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useGame } from '../../../context/GameContext'
import { ResourceNumbers, SocketType } from '../../../utils/types/types'
import { useSync } from '../../../context/SyncContext'
import { Timer } from '../Timer/Timer';
import { SendGameEvent } from '../../../socket/sendMessage/SendGameEvent'
import { PlaySoundEffect } from '../../../sounds/playSoundEffect'

type RobbedCards = {
    isOpen: boolean,
    onClose: () => void,
    socket: SocketType
}

export const RobbedCards = ({ isOpen, onClose, socket }: RobbedCards) => {
    const { gameID, gameAssets } = useGame()
    const { resources, gameData } = useSync()

    const [disabled, setDisabled] = useState<boolean>(true)

    const [givenCards, setGivenCards] = useState<ResourceNumbers>({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })

    const totalGiven = givenCards.Wood + givenCards.Brick + givenCards.Wheat + givenCards.Sheep + givenCards.Ore
    const numGivenCard = Math.floor((resources.Brick + resources.Ore + resources.Sheep + resources.Wheat + resources.Wood) / 2)

    // Validation for Taken and Given Card inputs
    useEffect(() => {
        const myTotalResCard = resources.Brick + resources.Ore + resources.Sheep + resources.Wheat + resources.Wood

        if (
            resources.Brick >= givenCards.Brick &&
            resources.Ore >= givenCards.Ore &&
            resources.Sheep >= givenCards.Sheep &&
            resources.Wheat >= givenCards.Wheat &&
            resources.Wood >= givenCards.Wood
        ) {
            if (totalGiven === Math.floor(myTotalResCard / 2)) {
                setDisabled(false)
            }
            else {
                setDisabled(true)
            }
        } else {
            setDisabled(true)
        }

    }, [totalGiven])

    const handlePOYCard = async () => {
        const resources = {
            ...(givenCards.Wood !== 0 && { wood: givenCards.Wood }),
            ...(givenCards.Brick !== 0 && { brick: givenCards.Brick }),
            ...(givenCards.Sheep !== 0 && { sheep: givenCards.Sheep }),
            ...(givenCards.Wheat !== 0 && { wheat: givenCards.Wheat }),
            ...(givenCards.Ore !== 0 && { ore: givenCards.Ore }),
        };

        const eventData = {
            id: gameID,
            discard: resources
        }

        SendGameEvent(socket, "DiscardResources", eventData)

        onClose()
        setGivenCards({ Wood: 0, Brick: 0, Sheep: 0, Wheat: 0, Ore: 0 })
    }

    return (
        <Modal
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent
                borderRadius={"1vw"}
                background={"linear-gradient(#CDB57A, #9B8152)"}
                maxW={"35vw"}
                maxH={"40vh"}
            >
                <Image
                    src={gameAssets.knightArt}
                    alt='knight-art'
                    width={"25%"}
                    pos={"absolute"}
                    top={"-25vh"}
                    left={"0"}
                    right={"0"}
                    m={"auto"}
                />

                <Box id="discardCardIndicator">
                    7 Rolled, You got Ambushed!
                </Box>

                <ModalHeader
                    maxH={"10vh"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"1vw 1vw 0 0"}
                    backgroundColor={"#6D5535"}
                    textColor={"white"}
                    fontSize={"1.5vw"}
                >
                    <Image
                        src={gameAssets.discardIcon}
                        alt='discard'
                        me={"2vw"}
                        w={"10%"}
                    />
                    Select {numGivenCard} resource cards to discard
                </ModalHeader>

                <ModalBody display={"flex"} justifyContent={"space-evenly"} p={"1vw"}>
                    <ResourceCard
                        name={"Wood"}
                        imageSrc={gameAssets.woodIcon}
                        imageAlt={"woodCard"}
                        count={givenCards.Wood}
                        onChange={setGivenCards}
                        totalValue={totalGiven}
                        maxValue={numGivenCard}
                    />
                    <ResourceCard
                        name={"Brick"}
                        imageSrc={gameAssets.brickIcon}
                        imageAlt={"brickCard"}
                        count={givenCards.Brick}
                        onChange={setGivenCards}
                        totalValue={totalGiven}
                        maxValue={numGivenCard}
                    />
                    <ResourceCard
                        name={"Sheep"}
                        imageSrc={gameAssets.sheepIcon}
                        imageAlt={"sheepCard"}
                        count={givenCards.Sheep}
                        onChange={setGivenCards}
                        totalValue={totalGiven}
                        maxValue={numGivenCard}
                    />
                    <ResourceCard
                        name={"Wheat"}
                        imageSrc={gameAssets.wheatIcon}
                        imageAlt={"wheatCard"}
                        count={givenCards.Wheat}
                        onChange={setGivenCards}
                        totalValue={totalGiven}
                        maxValue={numGivenCard}
                    />
                    <ResourceCard
                        name={"Ore"}
                        imageSrc={gameAssets.oreIcon}
                        imageAlt={"oreCard"}
                        count={givenCards.Ore}
                        onChange={setGivenCards}
                        totalValue={totalGiven}
                        maxValue={numGivenCard}
                    />
                </ModalBody>

                <ModalFooter
                    maxH={"10vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    backgroundColor={"#6D5535"}
                    borderRadius={"0 0 1vw 1vw"}
                    textColor={"white"}
                >
                    <Button
                        w={"10vw"}
                        height={"6vh"}
                        fontSize={"2vw"}
                        ms={"auto"}
                        me={"auto"}
                        backgroundColor={"#E94F4F"}
                        colorScheme={'red'}
                        border={"0.25vw solid #B40000"}
                        borderRadius={"0.5vw"}
                        isDisabled={disabled}
                        onClick={handlePOYCard}
                    >
                        Discard
                    </Button>
                    <Box
                        pos={"absolute"}
                        right={"0.5vw"}
                    >
                        <Timer
                            activatedTime={gameData.RobberTime.toString()}
                            timeLimit={20}
                        />
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export type ResourceCard = {
    imageSrc: string,
    imageAlt: string,
    count: number,
    onChange: (value: ResourceNumbers) => void,
    name: "Wood" | "Brick" | "Sheep" | "Wheat" | "Ore",
    maxValue: number,
    totalValue: number,
}

export const ResourceCard = ({ imageSrc, imageAlt, count, onChange, name, maxValue, totalValue }: ResourceCard) => {
    const { resources } = useSync()
    const { sfxVolume, sfxEnabled, soundEffects } = useGame()

    const isDisabled = count >= resources[name]
    const isZero = resources[name] === 0
    const isCountZero = count === 0

    const handleIncrease = () => {
        if (totalValue < maxValue) {
            onChange(prevState => ({
                ...prevState,
                [name]: count + 1
            }));

            PlaySoundEffect(soundEffects.decreaseCardAmountSFX, sfxVolume, sfxEnabled)
        }
    }

    const handleDecrease = () => {
        if (count > 0) {
            onChange(prevState => ({
                ...prevState,
                [name]: count - 1
            }));
        }
    }

    return (
        <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"}>
            <Box
                className={`card-chosen-animation-red`}
                filter={isZero ? "grayscale(100%)" : undefined}
                pos={"relative"}
                h={isCountZero ? "7vw" : "5vw"}
                w={"4vw"}
                backgroundColor={"#eddeae"}
                borderRadius={"0.5vw"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={() => !isDisabled && handleIncrease()}
            >
                <Image
                    src={imageSrc}
                    alt={imageAlt}
                    width={"3vw"}
                />
                <Box
                    className="top-card-number"
                    sx={{
                        WebkitTextStroke: "0.1vw #801111",
                    }}
                >
                    {count}
                </Box>
            </Box>
            {
                count > 0 &&
                <Box
                    _hover={{
                        cursor: "pointer"
                    }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"3vw"}
                    height={"2vw"}
                    fontSize={"2vw"}
                    mt={"0.5vw"}
                    backgroundColor={"#dc6958"}
                    textColor={"white"}
                    borderRadius={"0vw 0vw 0.5vw 0.5vw"}
                    border={"0.2vw solid #c65f50"}
                    onClick={handleDecrease}
                >
                    -
                </Box>
            }
        </Box>
    )
}