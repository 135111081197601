export const getMySettlePositions = (
  settlePositions: any[],
  address: string
) => {
  if (settlePositions.length > 0) {
    const myPositions = settlePositions.filter(
      (position) => position.owner === address && position.revenue === 1
    );
    return myPositions;
  }

  return [];
};
