export const getUserIndexByPlace = (
  settlementPositions: any[] | undefined,
  places: { x: number; y: number }[]
) => {
  const userIndexes: number[] = [];

  if (settlementPositions) {
    places.forEach((place) => {
      const matchingPosition = settlementPositions.find(
        (position) =>
          position.position[0] === place.x && position.position[1] === place.y
      );

      if (matchingPosition) {
        userIndexes.push(matchingPosition.turnIndex);
      }
    });

    return userIndexes;
  } else {
    return null;
  }
};
