import { Box, CloseButton } from "@chakra-ui/react"
import { BsThreeDotsVertical } from "react-icons/bs"
import { IoShareOutline } from "react-icons/io5"

export const PWANotification = ({ deviceOS, onClose }: { deviceOS: string | null, onClose: () => void }) => {
    return (
        <Box
            pos={"relative"}
            color={"black"}
            p={"3"}
            pe={"10"}
            ps={"10"}
            bg={"lightgray"}
            borderRadius={"md"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            fontSize={"15px"}
        >
            <CloseButton
                pos={"absolute"}
                right={"0"}
                top={"0"}
                onClick={onClose}
            />
            <Box>
                Install this game on your
                {deviceOS === "iOS" && " iPhone or iPad"}
                {deviceOS === "Android" && " Android"}
                :
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                tap
                {deviceOS === "iOS" && <IoShareOutline className='ms-2 me-2' />}
                {deviceOS === "Android" && <BsThreeDotsVertical className='ms-2 me-2' />}
                and then Add to Home Screen
            </Box>
        </Box>
    )
}