import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useGame } from '../../../context/GameContext';
import { useSync } from '../../../context/SyncContext';

export const Timer = ({ activatedTime, timeLimit }: { activatedTime: string, timeLimit: number }) => {
    const { gameData, myGameData } = useSync()
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()

    const audioRef = useRef<HTMLAudioElement>(null);

    const [timeDiff, setTimeDiff] = useState<number | null>(null);
    const [playAudio, setPlayAudio] = useState<boolean>(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date();
            const activatedDate = new Date(activatedTime);

            const diffInSeconds = Math.floor(Math.abs((currentTime.getTime() - activatedDate.getTime()) / 1000));

            if (diffInSeconds > timeLimit) {
                setTimeDiff(timeLimit);
            } else {
                setTimeDiff(timeLimit - diffInSeconds);
                if (sfxEnabled && myGameData && timeLimit - diffInSeconds < 7 && timeLimit - diffInSeconds > 1 && gameData.TurnIndex === myGameData.turn_index) {
                    setPlayAudio(true);
                } else {
                    setPlayAudio(false)
                }
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            if (playAudio) {
                audioRef.current.volume = sfxVolume;
                audioRef.current?.play().catch((error) => {
                    console.error("Audio play was prevented by the browser:", error);
                });
            } else {
                audioRef.current?.pause();
                audioRef.current!.currentTime = 0;
            }
        }
    }, [playAudio]);

    return (
        <CircularProgress
            isIndeterminate
            size={"3vw"}
            thickness={'0.75vw'}
            color={'#ede1d0'}
            trackColor={'#6D5535'}
        >
            <audio ref={audioRef} src={soundEffects.timeUpSFX} preload="auto" />
            <CircularProgressLabel
                fontSize={"1.25vw"}
                fontWeight={"bolder"}
            >
                {timeDiff}
            </CircularProgressLabel>
        </CircularProgress>
    );
};

