import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../../context/GameContext"
import { GetTimeDifference } from "../../../Notification/GetTimeDifference"

type FinalType = {
    PrizePool: number[],
    PlayerState: number,
    FreezeTimeEnd: string | null
}

export const Final = ({ PrizePool, PlayerState, FreezeTimeEnd }: FinalType) => {
    const { dashboardAssets, gameAssets } = useGame()

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Image
                src={dashboardAssets.registerIcon}
                alt="register-icon"
                width={"3vw"}
            />
            <Box textColor={"#e6d1a4"}>
                Final
            </Box>
            <Image
                src={gameAssets.gameLogDivider}
                alt="game-log-divider"
                width={"50%"}
            />
            <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"2vw"}>
                Win {PrizePool && PrizePool.length > 0 && PrizePool[0]}$
            </Box>
            {
                PlayerState === 5 && FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    The final match will begin in
                    <GetTimeDifference finishTime={FreezeTimeEnd} />
                </Box>
            }
        </Box>
    )
}