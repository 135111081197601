import { Image, Box, IconButton } from '@chakra-ui/react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { useGame } from '../../../../context/GameContext'
import { useSync } from '../../../../context/SyncContext'
import { SocketType } from '../../../../utils/types/types'
import { useEffect } from 'react'
import { getPrevCityAsset } from '../../../../utils/assets/getPrevCityAsset'
import { SendGameEvent } from '../../../../socket/sendMessage/SendGameEvent'
import { getCityAsset } from '../../../../utils/assets/getCityAsset'

type BuildCityType = {
    socket: SocketType,
    confirmData: {
        showCost: boolean,
        costType: string,
        settlePosition: {
            xStart: number,
            yStart: number,
            xEnd: number | null,
            yEnd: number | null
        }
    },
    setConfirmData: (value: any) => void
}

export const BuildCity = ({ socket, confirmData, setConfirmData }: BuildCityType) => {
    const { gameAssets, gameID, isMobile } = useGame()
    const { myGameData, resources, playersSkinChoice } = useSync()

    useEffect(() => {
        if (confirmData) {
            const positions = confirmData.settlePosition
            const settleElement = document.getElementById(`h/${positions.xStart},${positions.yStart}`)

            if (settleElement && playersSkinChoice) {
                settleElement.classList.add("city-confirm-prev")
                settleElement.classList.add(getPrevCityAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!)

                if (settleElement.classList.contains("top-house")) {
                    settleElement.classList.add("top-city-prev")
                } else if (settleElement.classList.contains("top-right-house")) {
                    settleElement.classList.add("top-right-city-prev")
                } else if (settleElement.classList.contains("top-left-house")) {
                    settleElement.classList.add("top-left-city-prev")
                }
            }
        }

        return () => {
            const settleElement = document.querySelector('.city-confirm-prev')
            if (settleElement && playersSkinChoice) {
                settleElement.classList.remove('city-confirm-prev')
                settleElement.classList.remove(getPrevCityAsset(myGameData.turn_index, playersSkinChoice[myGameData.turn_index])!)

                if (settleElement.classList.contains("top-city-prev")) {
                    settleElement.classList.remove("top-city-prev")
                } else if (settleElement.classList.contains("top-right-city-prev")) {
                    settleElement.classList.remove("top-right-city-prev")
                } else if (settleElement.classList.contains("top-left-city-prev")) {
                    settleElement.classList.remove("top-left-city-prev")
                }
            }
        }
    }, [confirmData])

    const handleCityBuilding = () => {
        const eventData = {
            id: gameID,
            position: [
                confirmData.settlePosition.xStart,
                confirmData.settlePosition.yStart
            ]
        }
        SendGameEvent(socket, "BuildCity", eventData)
        setConfirmData(null)
    }

    return (
        <Box className={`cost-info ${isMobile && 'cost-info-mobile'}`}>
            <Box className='header-title-banner'>
                City Build Cost
            </Box>

            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} backgroundColor={"#A58860"} w={"240px"} p={2} borderRadius={"5px"}>
                <Image
                    pos={"absolute"}
                    left={"30px"}
                    src={playersSkinChoice && getCityAsset(myGameData.turn_index, gameAssets, playersSkinChoice[myGameData.turn_index])}
                    alt='city'
                    width={"40px"}
                    className={confirmData.showCost ? "me-3" : undefined}
                />
                {
                    confirmData.showCost &&
                    <Box display={"flex"} alignSelf={"center"} w={"120px"}>
                        <Image src={gameAssets.wheatCard} marginLeft={"20px"} w={"40px"} filter={resources.Wheat > 0 ? undefined : "grayscale(100%)"} me={1} />
                        <Image pos={"absolute"} marginLeft={"40px"} src={gameAssets.wheatCard} w={"40px"} filter={resources.Wheat > 1 ? undefined : "grayscale(100%)"} me={1} />
                        <Image pos={"absolute"} marginLeft={"90px"} src={gameAssets.oreCard} w={"40px"} filter={resources.Ore > 0 ? undefined : "grayscale(100%)"} me={1} />
                        <Image pos={"absolute"} marginLeft={"110px"} src={gameAssets.oreCard} w={"40px"} filter={resources.Ore > 1 ? undefined : "grayscale(100%)"} me={1} />
                        <Image pos={"absolute"} marginLeft={"130px"} src={gameAssets.oreCard} w={"40px"} filter={resources.Ore > 2 ? undefined : "grayscale(100%)"} />
                    </Box>
                }
            </Box>

            <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} w={"240px"} mt={"10px"}>
                <IconButton
                    backgroundColor={"#85D885"}
                    colorScheme={"green"}
                    border={"2px solid black"}
                    borderRadius={"50%"}
                    fontSize={"20px"}
                    aria-label='confirm'
                    isDisabled={!(resources.Ore > 2 && resources.Wheat > 1)}
                    onClick={handleCityBuilding}
                    icon={<FaCheck />}
                />
                <IconButton
                    backgroundColor={"#F74747"}
                    colorScheme={"red"}
                    border={"2px solid black"}
                    borderRadius={"50%"}
                    fontSize={"20px"}
                    aria-label='cancel'
                    onClick={() => {
                        setConfirmData(null)
                    }}
                    icon={<FaTimes />}
                />
            </Box>
        </Box>
    )
}