import { Box, Link } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"

export const TutorialTabList = () => {
    const { dashboardAssets } = useGame()

    return (
        <>
            {/* ************** Tab List **************** */}
            <Box
                className='scroll-bar-with-margin'
                width={"25%"}
                height={"100%"}
                backgroundColor={"#3F352C"}
                borderRadius={"25px 0px 0px 25px"}
                textColor={"white"}
                display={"flex"}
                flexDirection={"column"}
                overflowY={"auto"}
                scrollBehavior={"smooth"}
            >
                <Box display={"flex"} justifyContent={"center"} mt={3}>
                    <img src={dashboardAssets.guideTitle} alt='guide-title' width={"50%"} />
                </Box>

                <Link
                    href='#what-is-pioneers'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.pioneersLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    What is Pioneers
                </Link>
                <Link
                    href='#game-modes'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.gameModesLogo} alt='game-modes-logo' width={"15%"} className='me-3' />
                    Game Modes
                </Link>
                <Link
                    href='#tournaments'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.tournamentLogo} alt='tournaments-logo' width={"15%"} className='me-3' />
                    Tournaments
                </Link>
                <Link
                    href='#game-goal'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.goalLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Game Goal
                </Link>
                <Link
                    href='#initial-placement'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.placementLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Initial Placement
                </Link>
                <Link
                    href='#turn-start'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.turnLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Turn Start
                </Link>
                <Link
                    href='#resources'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.resourcesLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Resources
                </Link>
                <Link
                    href='#robber'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.robberLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Rolling a 7 & Robber
                </Link>
                <Link
                    href='#turn-progression'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.progressionLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Turn Progression
                </Link>
                <Link
                    href='#building'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.buildingLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Building
                </Link>
                <Link
                    href='#trading'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.tradingLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Trading
                </Link>
                <Link
                    href='#bank'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.bankLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Trade with Bank
                </Link>
                <Link
                    href='#ports'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.tradeLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Ports
                </Link>
                <Link
                    href='#dev-cards'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.developmentCardLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Development Cards
                </Link>
                <Link
                    href='#victory-conditions'
                    display={"flex"}
                    justifyContent={"start"}
                    fontSize={"1.25vw"}
                    alignItems={"center"}
                    ml={5}
                >
                    <img src={dashboardAssets.victoryLogo} alt='pioneers-logo' width={"15%"} className='me-3' />
                    Victory Conditions
                </Link>
            </Box>
        </>
    )
}