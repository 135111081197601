import { getResIcon } from "../assets/getResIcon";

export const logDivElement = () => {
  const logDiv = document.createElement("div");
  logDiv.style.display = "flex";
  logDiv.style.alignItems = "center";
  logDiv.style.height = "1.75vw";
  logDiv.style.padding = "0.25vw";
  logDiv.style.marginBottom = "0.25vw";
  logDiv.style.fontSize = "1vw";
  logDiv.style.textShadow = "0.05vw 0.05vw black";
  logDiv.style.fontWeight = "500";
  logDiv.style.color = "#FFFFFF";

  return logDiv;
};

export const usernameDivElement = (username: string, color: string) => {
  const usernameDiv = document.createElement("span");
  usernameDiv.style.color = color;
  usernameDiv.textContent = username;
  usernameDiv.style.marginRight = "0.25vw";

  return usernameDiv;
};

export const logTextDivElement = (text: string) => {
  const logTextDiv = document.createElement("span");
  logTextDiv.textContent = text;
  logTextDiv.style.textWrap = "nowrap";
  logTextDiv.style.marginRight = "0.25vw";

  return logTextDiv;
};

export const logResourceElement = (
  resource: any,
  resourceName: string,
  resourceNumber: number,
  childElement: HTMLDivElement,
  gameAssets: any
) => {
  if (resource !== undefined) {
    const number = logTextDivElement(resourceNumber.toString());
    childElement.appendChild(number);

    const resourceIcon = document.createElement("img");
    resourceIcon.src = getResIcon(resourceName, gameAssets);
    resourceIcon.alt = `${resourceName}-icon`;
    resourceIcon.style.height = "100%";
    resourceIcon.style.marginRight = "0.25vw";

    childElement.appendChild(resourceIcon);
  }
};
