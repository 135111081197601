import { useEffect } from "react";
import { SocketType } from "../utils/types/types";

export const Room = (
    socket: SocketType | null,
    rooms: any[],
    setRooms: (value: any) => void,
    reConnectGameID: number,
    setReConnectGameID: (value: number) => void
) => {
    // Handle New Created Game Updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomCreate") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (parsedData) {
                    setRooms(prev => {
                        if (!Array.isArray(prev)) {
                            prev = [];
                        }
                        return [
                            ...prev,
                            {
                                ID: parsedData.id,
                                Name: parsedData.name,
                                Owner: parsedData.owner,
                                OwnerUsername: parsedData.ownerUsername,
                                State: 0,
                                PlayerCurrent: parsedData.currentPlayers ? parsedData.currentPlayers : 1,
                                PlayerLimit: parsedData.limit,
                                joinableStatus: parsedData.isTournament ? 4 : 1
                            }
                        ];
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle Deleting Room
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomDelete") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (parsedData) {
                    if (parsedData.id === reConnectGameID) {
                        setReConnectGameID(0)
                    }
                    setRooms(rooms.filter((room) => room.ID !== parsedData.id));
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    //  Handle Game Joins Updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomJoin") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (rooms && parsedData) {
                    const newRooms = [...rooms]

                    const room = rooms?.find((room) => room.ID === parsedData.id);

                    if (room) {
                        room.PlayerCurrent = parsedData.count;
                        if (parsedData.count === room.PlayerLimit) {
                            room.joinableStatus = 4
                        }
                        setRooms(newRooms);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    //  Handle Game Disconnect Updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomDisconnect") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                console.log(parsedData)
                if (rooms && parsedData) {
                    if (parsedData.id === reConnectGameID) {
                        setReConnectGameID(0)
                    }
                    const newRooms = [...rooms]

                    const room = rooms?.find((room) => room.ID === parsedData.id);

                    if (room) {
                        room.PlayerCurrent = parsedData.count;
                        room.joinableStatus = 1
                        setRooms(newRooms);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    // Handle room limit change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomLimitChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (rooms && parsedData) {
                    const newRooms = [...rooms]
                    const parsedData = JSON.parse(socket.lastJsonMessage.data)

                    const room = rooms?.find((room) => room.ID === parsedData.roomID);

                    if (room) {
                        room.PlayerLimit = parsedData.newLimit;
                        setRooms(newRooms);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    //  Handle Game Owner Change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "OwnerChange") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (rooms && parsedData) {
                    const newRooms = [...rooms]

                    const room = rooms?.find((room) => room.ID === parsedData.id);

                    if (room) {
                        room.Owner = parsedData.ownerAddress
                        room.OwnerUsername = parsedData.ownerUsername;
                        setRooms(newRooms);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    //  Handle Game Start Status Change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomStart") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)

                if (rooms && parsedData) {
                    const newRooms = [...rooms]

                    const room = rooms?.find((room) => room.ID === parsedData.id);

                    if (room) {
                        room.State = 1
                        setRooms(newRooms);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    //  Handle Game Finish Status Change
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "RoomFinish") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                if (rooms && parsedData) {
                    if (parsedData.id === reConnectGameID) {
                        setReConnectGameID(0)
                    }

                    const newRooms = [...rooms]

                    const room = rooms?.find((room) => room.ID === parsedData.id);

                    if (room) {
                        room.State = 2
                        setRooms(newRooms);
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);
}