const API_URL = import.meta.env.VITE_API_URL

export const CreateStartGame = async (id: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/start`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to start the game.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error starting game:", error);
        return null;
    }
};

export const CreateAddBot = async (gameID: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/bot-add`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ room_id: gameID })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to add a bot.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error adding bot:", error);
        return null;
    }
}

export const CreateRemoveBot = async (gameID: number, turnIndex: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/bot-remove`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ room_id: gameID, turnIndex: turnIndex })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to remove a bot.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error removing bot:", error);
        return null;
    }
}

export const CreateChangeRoomLimit = async (gameID: number, newLimit: number) => {
    try {
        const response = await fetch(
            API_URL + `/room/change-limit`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id: gameID, limit: newLimit })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to change room limit.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error changing room limit:", error);
        return null;
    }
}