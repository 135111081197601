import { Text, Box } from '@chakra-ui/react'
import { KeyCard } from './KeyCard'

type Keys = {
    myKeys: any[]
}

export const Keys = ({ myKeys }: Keys) => {
    const keyNumber = myKeys.length
    return (
        <>
            <Text className='inventory-header-banners'>
                Keys ({keyNumber})
            </Text>
            <Box
                display={"flex"}
                overflowX={"auto"}
                w={"100%"}
                minH={"45%"}
                backgroundColor={"#edd5b7"}
                mb={5}
                borderRadius={"25px"}
                p={5}
            >
                {
                    myKeys.length > 0 ?
                        myKeys.map((key, index) => {
                            return (
                                <KeyCard
                                    key={index}
                                    rarity={key.rarity}
                                />
                            )
                        }) :
                        <Text
                            w={"100%"}
                            textAlign={"center"}
                            fontSize={"2vw"}
                            alignSelf={"center"}
                        >
                            You don't have any key.
                        </Text>
                }
            </Box>
        </>
    )
}