import { Box, Tooltip } from '@chakra-ui/react'
import { useGame } from "../../../context/GameContext"

export const BuildConfirmManager = () => {
    const { setDisableBuildConfirm, disableBuildConfirm, gameAssets } = useGame()

    return (
        <Tooltip label={disableBuildConfirm ? "Activate build confirmation" : "Disable build confirmation"} fontSize='md'>
            <Box
                _hover={{
                    cursor: "pointer"
                }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"0.5vw"}
                boxSize={"3vw"}
                backgroundColor={disableBuildConfirm ? "#E94F4F" : "#85D885"}
                aria-label='trade-disable'
                onClick={() => {
                    setDisableBuildConfirm(!disableBuildConfirm)
                    localStorage.setItem('disableBuildConfirm', JSON.stringify(!disableBuildConfirm));
                }}
            >
                <img src={gameAssets.settleDefault} alt='trade-icon' width={"75%"} />
            </Box>
        </Tooltip>
    )
}