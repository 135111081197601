import dashboardContentBackground from "../assets/Menu/Background_Blur.png";

import createGameIcon from "../assets/Menu/LargeButtonIcons/creategame_icon.png";
import roomListIcon from "../assets/Menu/LargeButtonIcons/roomlist_icon.png";
import tournamentModalIcon from "../assets/Menu/LargeButtonIcons/tournament_icon.png";
import questModalIcon from "../assets/Menu/LargeButtonIcons/quests_icon.png";
import rejoinButtonBG from "../assets/Menu/LargeButtonIcons/rejoin-button-background.png";

import avatarJean from "../assets/Avatars/jean.png";
import avatarLin from "../assets/Avatars/lin.png";
import avatarLouis from "../assets/Avatars/louis.png";
import avatarWilliam from "../assets/Avatars/william.png";

import inventoryIcon from "../assets/Menu/Sidebar/icon_treasure@2x.png";
import guideIcon from "../assets/Menu/Sidebar/icon_game_guide@2x.png";
import logoutIcon from "../assets/Menu/Sidebar/icon_logout@2x.png";
import walletIcon from "../assets/Menu/Sidebar/wallet-icon.png";

import guideTitle from "../assets/Menu/GuideModal/Icons/Guide.png";
import pioneersLogo from "../assets/Menu/GuideModal/Icons/1_Pioneers.png";
import goalLogo from "../assets/Menu/GuideModal/Icons/2_Goal.png";
import placementLogo from "../assets/Menu/GuideModal/Icons/3_Placements.png";
import turnLogo from "../assets/Menu/GuideModal/Icons/4_Start.png";
import resourcesLogo from "../assets/Menu/GuideModal/Icons/5_Resources.png";
import robberLogo from "../assets/Menu/GuideModal/Icons/6_Robber.png";
import progressionLogo from "../assets/Menu/GuideModal/Icons/7_Progression.png";
import buildingLogo from "../assets/Menu/GuideModal/Icons/8_Building.png";
import tradingLogo from "../assets/Menu/GuideModal/Icons/9_Trading.png";
import bankLogo from "../assets/Menu/GuideModal/Icons/10_Bank.png";
import developmentCardLogo from "../assets/Menu/GuideModal/Icons/11_Developments_Card.png";
import tradeLogo from "../assets/Menu/GuideModal/Icons/12_Trade.png";
import victoryLogo from "../assets/Menu/GuideModal/Icons/14_Victory.png";
import gameModesLogo from "../assets/Menu/GuideModal/Icons/16_Game_Modes.png";
import tournamentLogo from "../assets/Menu/GuideModal/Icons/17_Tournaments.png";

import whatPioneersTitle from "../assets/Menu/GuideModal/Titles/1_Whats_Pioneers.png";
import gameGoalTitle from "../assets/Menu/GuideModal/Titles/2_Game_Goal.png";
import initialPlacementTitle from "../assets/Menu/GuideModal/Titles/3_Initial Placements.png";
import turnStartTitle from "../assets/Menu/GuideModal/Titles/4_Turn_Start.png";
import resourcesTitle from "../assets/Menu/GuideModal/Titles/5_Resources_2.png";
import rollingTitle from "../assets/Menu/GuideModal/Titles/6_Rolling_a_7.png";
import turnProgressionTitle from "../assets/Menu/GuideModal/Titles/7_Turn_Progression.png";
import buildingTitle from "../assets/Menu/GuideModal/Titles/8_Building_2.png";
import tradingTitle from "../assets/Menu/GuideModal/Titles/9_Trading_4.png";
import tradeBankTitle from "../assets/Menu/GuideModal/Titles/10_Trade_bank.png";
import portTitle from "../assets/Menu/GuideModal/Titles/11_Ports.png";
import devCardTitle from "../assets/Menu/GuideModal/Titles/12_Devlopment_cards.png";
import victoryConditionsTitle from "../assets/Menu/GuideModal/Titles/13_Victory_Conditions.png";
import twoPlayerTitle from "../assets/Menu/GuideModal/Titles/14_Two_Player.png";
import threePlayerTitle from "../assets/Menu/GuideModal/Titles/15_Three_Player.png";
import fourPlayerTitle from "../assets/Menu/GuideModal/Titles/16_Four_Player.png";
import tournamentTitle from "../assets/Menu/GuideModal/Titles/17_Tournaments.png";
import standingsTitle from "../assets/Menu/GuideModal/Titles/18_Standings.png";
import singleElimTitle from "../assets/Menu/GuideModal/Titles/19_Single_Elimination.png";
import doubleElimTitle from "../assets/Menu/GuideModal/Titles/20_Knockout.png";
import championshipTitle from "../assets/Menu/GuideModal/Titles/21_Championship.png";
import vpCalculationTitle from "../assets/Menu/GuideModal/Titles/22_VP_Calculation.png";

import inventoryCommonChest from "../assets/Items/Chests/Common_treasure.png";
import inventoryRareChest from "../assets/Items/Chests/Rare_treasure.png";
import inventoryAlphaChest from "../assets/Items/Chests/Alpha_treasure.png";
import invetoryEpicChest from "../assets/Items/Chests/Epic_treasure.png";
import inventoryAncientChest from "../assets/Items/Chests/Ancient_treasure.png";

import inventoryLockIcon from "../assets/Items/Chests/lock.png";
import inventorySkinBanner from "../assets/Items/Skins/Banner_inventory.png";
import inventoryKeyIcon from "../assets/Items/Chests/key_icon.png";
import inventoryShineEffect from "../assets/Items/Chests/shine_effect.png";

import inventoryCommonKey from "../assets/Items/Keys/Common_key.png";
import inventoryAlphaKey from "../assets/Items/Keys/Alpha_key.png";
import inventoryAncientKey from "../assets/Items/Keys/Ancient_key.png";
import inventoryEpicKey from "../assets/Items/Keys/Epic_key.png";
import inventoryRareKey from "../assets/Items/Keys/Rare_key.png";

import tournamentBanner from "../assets/Menu/Tournament/Card/banner_title.png";
import overviewtournamentBanner from "../assets/Menu/Tournament/Overview/banner_title_overview.png";
import standingstournamentBanner from "../assets/Menu/Tournament/Standings/banner_title_standings.png";
import freeJoinBanner from "../assets/Menu/Tournament/Card/free_entry.png";
import registerIcon from "../assets/Menu/Tournament/Card/register_icon.png";
import coinIcon from "../assets/Menu/Tournament/Card/coin_icon.png";
import maxPlayersIcon from "../assets/Menu/Tournament/Card/max_players_icon.png";
import playersNumberIcon from "../assets/Menu/Tournament/Card/players_icon.png";
import lockIcon from "../assets/Menu/Tournament/Card/lock_icon.png";
import personIcon from "../assets/Menu/Tournament/Card/players_icon.png";
import kingHoldingTrophy from "../assets/Menu/Tournament/WaitingRoom/Waiting_Room_King.png";

import instagramIcon from "../assets/Landing/SocialMediaIcons/colorful_instagram.png";
import xIcon from "../assets/Landing/SocialMediaIcons/colorful_x.png";
import tournamentBannerOverview from "../assets/Menu/Tournament/Overview/banner_title_overview.png";

import discordAsset from "../assets/Menu/discord_asset.png";
import halloweenBanner from "../assets/Menu/halloween_banner.png";

import twoPlayerIcon from "../assets/Menu/CreateGame/player2_icons.png";
import threePlayerIcon from "../assets/Menu/CreateGame/player3_icons.png";
import fourPlayerIcon from "../assets/Menu/CreateGame/player4_icons.png";
import freeModeIcon from "../assets/Menu/CreateGame/mode_icon_free.png";
import tokenModeIcon from "../assets/Menu/CreateGame/mode_icon_token.png";

import playerNumberIcon from "../assets/Menu/DailyProgress/player_number_icon.png";
import prizePoolIcon from "../assets/Menu/DailyProgress/prize_pool_icon.png";

export const dashboardAssetsList: any = {
  dashboardContentBackground: dashboardContentBackground,
  createGameIcon: createGameIcon,
  roomListIcon: roomListIcon,
  tournamentModalIcon: tournamentModalIcon,
  questModalIcon: questModalIcon,
  rejoinButtonBG: rejoinButtonBG,
  avatarJean: avatarJean,
  avatarLin: avatarLin,
  avatarLouis: avatarLouis,
  avatarWilliam: avatarWilliam,
  inventoryIcon: inventoryIcon,
  guideIcon: guideIcon,
  logoutIcon: logoutIcon,
  walletIcon: walletIcon,
  guideTitle: guideTitle,
  pioneersLogo: pioneersLogo,
  goalLogo: goalLogo,
  placementLogo: placementLogo,
  turnLogo: turnLogo,
  resourcesLogo: resourcesLogo,
  robberLogo: robberLogo,
  progressionLogo: progressionLogo,
  buildingLogo: buildingLogo,
  tradingLogo: tradingLogo,
  bankLogo: bankLogo,
  developmentCardLogo: developmentCardLogo,
  tradeLogo: tradeLogo,
  victoryLogo: victoryLogo,
  gameModesLogo: gameModesLogo,
  tournamentLogo: tournamentLogo,
  whatPioneersTitle: whatPioneersTitle,
  gameGoalTitle: gameGoalTitle,
  initialPlacementTitle: initialPlacementTitle,
  turnStartTitle: turnStartTitle,
  resourcesTitle: resourcesTitle,
  rollingTitle: rollingTitle,
  turnProgressionTitle: turnProgressionTitle,
  buildingTitle: buildingTitle,
  tradingTitle: tradingTitle,
  tradeBankTitle: tradeBankTitle,
  portTitle: portTitle,
  devCardTitle: devCardTitle,
  victoryConditionsTitle: victoryConditionsTitle,
  twoPlayerTitle: twoPlayerTitle,
  threePlayerTitle: threePlayerTitle,
  fourPlayerTitle: fourPlayerTitle,
  tournamentTitle: tournamentTitle,
  standingsTitle: standingsTitle,
  singleElimTitle: singleElimTitle,
  doubleElimTitle: doubleElimTitle,
  championshipTitle: championshipTitle,
  vpCalculationTitle: vpCalculationTitle,
  inventoryCommonChest: inventoryCommonChest,
  inventoryRareChest: inventoryRareChest,
  inventoryAlphaChest: inventoryAlphaChest,
  invetoryEpicChest: invetoryEpicChest,
  inventoryAncientChest: inventoryAncientChest,
  inventoryLockIcon: inventoryLockIcon,
  inventorySkinBanner: inventorySkinBanner,
  inventoryKeyIcon: inventoryKeyIcon,
  inventoryShineEffect: inventoryShineEffect,
  inventoryCommonKey: inventoryCommonKey,
  inventoryAlphaKey: inventoryAlphaKey,
  inventoryAncientKey: inventoryAncientKey,
  inventoryEpicKey: inventoryEpicKey,
  inventoryRareKey: inventoryRareKey,
  tournamentBanner: tournamentBanner,
  overviewtournamentBanner: overviewtournamentBanner,
  standingstournamentBanner: standingstournamentBanner,
  freeJoinBanner: freeJoinBanner,
  registerIcon: registerIcon,
  coinIcon: coinIcon,
  maxPlayersIcon: maxPlayersIcon,
  playersNumberIcon: playersNumberIcon,
  lockIcon: lockIcon,
  personIcon: personIcon,
  kingHoldingTrophy: kingHoldingTrophy,
  instagramIcon: instagramIcon,
  xIcon: xIcon,
  tournamentBannerOverview: tournamentBannerOverview,
  discordAsset: discordAsset,
  halloweenBanner: halloweenBanner,
  twoPlayerIcon: twoPlayerIcon,
  threePlayerIcon: threePlayerIcon,
  fourPlayerIcon: fourPlayerIcon,
  freeModeIcon: freeModeIcon,
  tokenModeIcon: tokenModeIcon,
  playerNumberIcon: playerNumberIcon,
  prizePoolIcon: prizePoolIcon,
};
