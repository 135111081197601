import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, useDisclosure, Center, Image } from '@chakra-ui/react'
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../context/AuthContext';
import { useGame } from '../../../context/GameContext';

export const LogoutModal = () => {
    const { dashboardAssets } = useGame()
    const { logout } = useAuth()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate = useNavigate()

    const handleLogout = () => {
        logout()
        navigate("/")
    }

    return (
        <>
            <Button
                flexDir={"column"}
                width={"7.5vw"}
                height={"7.5vw"}
                fontSize={"1.25vw"}
                borderRadius={"1vw"}
                backgroundColor={"#AC8358"}
                colorScheme={"orange"}
                onClick={onOpen}
            >
                <Image src={dashboardAssets.logoutIcon} alt="logout-icon" />
                Logout
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
                <ModalOverlay />

                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader className='menu-modal-headers'>
                        Logout
                    </ModalHeader>

                    <ModalBody>
                        <Center mb={5}>
                            <AiOutlineLogout fontSize={35} />
                        </Center>
                        <Center>
                            Are you sure you want to logout ?
                        </Center>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            colorScheme='blue'
                            backgroundColor={"#836743"}
                            borderRadius={"10px"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            mr={3}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            w={"100%"}
                            colorScheme='red'
                            backgroundColor={"#836743"}
                            borderRadius={"10px"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}