import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { SocketType } from "./utils/types/types";

export const useSocket = (gameID: number) => {
    const [disconnect, setDisconnect] = useState(false)
    const [connect, setConnect] = useState(false)

    const toast = useToast()

    const { sendMessage, readyState, lastJsonMessage } = useWebSocket(import.meta.env.VITE_SOCKET_URL + gameID, {
        onOpen: () => {
            if (gameID === 0) {
                console.log("You are connected to the LOBBY.")
            } else {
                console.log("You are connected to the GAME.")
            }

            setConnect(true)
            setDisconnect(false)
        },
        onClose: () => {
            if (gameID === 0) {
                console.log("You are disconnected from the LOBBY.")
            } else {
                console.log("You are disconnected from the GAME.")
            }

            setDisconnect(true)
            setConnect(false)
        },
        shouldReconnect: () => false,
        share: true,
    })

    useEffect(() => {
        let intervalId = null;

        if (disconnect && !connect) {
            intervalId = setInterval(() => {
                toast({
                    title: "Connection Issue",
                    description: "You've lost connection. Please refresh the page to reconnect.",
                    status: "error",
                    duration: 10000,
                    isClosable: true
                });
            }, 5000);
        }

        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
        };
    }, [disconnect, connect]);

    const socket: SocketType = { sendMessage, readyState, lastJsonMessage }

    return socket
}