import { useEffect } from "react";
import { SocketType } from "../../../utils/types/types"
import { getUsernameByAddress } from "../../../utils/functions/getUsernameByAddress";
import { useSync } from "../../../context/SyncContext";
import { getTurnIndexByAddress } from '../../../utils/functions/getTurnIndexByAddress';
import { useGame } from '../../../context/GameContext';
import { logDivElement, logTextDivElement, usernameDivElement } from '../../../utils/gameLogs/gameLogs';
import { getColorByIndex } from '../../../utils/assets/getColorByIndex';
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const LongestRoadNotification = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, gameAssets, soundEffects } = useGame()
    const { players, setLongestRoadOwner } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "LongestRoad") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                const username = getUsernameByAddress(players, parsedData.address)
                const turnIndex = getTurnIndexByAddress(players, parsedData.address)

                setLongestRoadOwner(turnIndex)

                PlaySoundEffect(soundEffects.takingLeadSFX, sfxVolume, sfxEnabled)

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()
                    const usernameDiv = usernameDivElement(username, getColorByIndex(turnIndex))

                    const logText = logTextDivElement("claims")

                    const longestRoad = document.createElement("img")
                    longestRoad.src = gameAssets.longestRoadLabel
                    longestRoad.alt = "longest-road-icon"
                    longestRoad.style.height = "150%"

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)
                    logDiv.appendChild(longestRoad)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                const divElement = document.createElement("div")
                divElement.classList.add("player-notification")
                divElement.classList.add("longest-road-notif")

                const imgElement = document.createElement("img")
                imgElement.src = gameAssets.longestRoadLabel
                imgElement.alt = "longest-road"
                imgElement.style.width = "15%"
                imgElement.style.marginRight = "0.25vw"

                const textElement = document.createElement("span")
                textElement.textContent = `${username} has the Longest Road!`

                divElement.appendChild(imgElement)
                divElement.appendChild(textElement)

                const playerElement = document.getElementById(`player-${turnIndex}`)

                if (playerElement) {
                    playerElement.appendChild(divElement)

                    const timeout = setTimeout(() => {
                        playerElement.removeChild(divElement)
                    }, 3000);

                    return () => clearTimeout(timeout);
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);
}