import { useEffect, useState } from "react"
import { useSync } from "../../../context/SyncContext"
import { Box, IconButton, Image, useDisclosure } from "@chakra-ui/react"
import { getDevCardImgSrcByCardType } from "../../../utils/assets/getDevCardImgSrcByCardType"
import { getDevCardNameByType } from "../../../utils/constants/getDevCardNameByType"
import { SocketType } from "../../../utils/types/types"
import { useGame } from "../../../context/GameContext"
import { YearOfPlenty } from "./YearOfPlenty"
import { Monoploy } from "./Monoply"
import { SendGameEvent } from "../../../socket/sendMessage/SendGameEvent"

export const DevelopmentCards = ({ socket }: { socket: SocketType }) => {
    const { gameID, gameAssets } = useGame()
    const { devCards, gameData, myGameData, remainingBuilds } = useSync()

    const [devCardUsed, setDevCardUsed] = useState<boolean>(false)

    const [knightCardDisabled, setKnightCardDisabled] = useState<boolean>(true)
    const [yopCardDisabled, setYopCardDisabled] = useState<boolean>(true)
    const [monopolyCardDisabled, setMonopolyCardDisabled] = useState<boolean>(true)
    const [roadBuildingCardDisabled, setRoadBuildingCardDisabled] = useState<boolean>(true)

    const { isOpen: isOpenYOP, onOpen: onOpenYOP, onClose: onCloseYOP } = useDisclosure()
    const { isOpen: isOpenMonoply, onOpen: onOpenMonoply, onClose: onCloseMonoply } = useDisclosure()

    const knightCards = devCards.filter((card) => card.Type === "Knight")
    const yearOfPlenty = devCards.filter((card) => card.Type === "YearOfPlenty")
    const monopoly = devCards.filter((card) => card.Type === "Monopoly")
    const roadBuilding = devCards.filter((card) => card.Type === "RoadBuilding")
    const victoryPoints = devCards.filter((card) => card.Type === "VictoryPoint")

    useEffect(() => {
        if (devCardUsed) {
            if (gameData.TurnIndex !== myGameData.turn_index) {
                setDevCardUsed(false)
            }
        }
    }, [gameData, devCardUsed, myGameData])

    useEffect(() => {
        if (gameData && myGameData) {
            const { TurnNumber: turn, Status: stage, TurnIndex: gameTurnIndex } = gameData;
            const myTurnIndex = myGameData.turn_index;

            const disableCard = (cards: any[], setCardDisabled: (value: boolean) => void, isKnight: boolean, isRoadBuilding: boolean) => {
                if (devCardUsed) {
                    setCardDisabled(true);
                } else {
                    const cardAvailable = cards.some((card) => card.Buyturn < turn);
                    const isMyTurn = myTurnIndex === gameTurnIndex
                    const stageCheck = (isKnight && stage === 3) || stage === 4

                    if (isRoadBuilding) {
                        const haveEnoughRoad = remainingBuilds.road > 1
                        setCardDisabled(!(cardAvailable && isMyTurn && stageCheck && haveEnoughRoad));
                    } else {
                        setCardDisabled(!(cardAvailable && isMyTurn && stageCheck));
                    }
                }
            };

            disableCard(knightCards, setKnightCardDisabled, true, false);
            disableCard(yearOfPlenty, setYopCardDisabled, false, false);
            disableCard(monopoly, setMonopolyCardDisabled, false, false);
            disableCard(roadBuilding, setRoadBuildingCardDisabled, false, true);
        }
    }, [gameData, myGameData, devCardUsed, remainingBuilds]);

    const handleCardUse = async (cardType: string | null) => {
        if (cardType === "Knight") {
            const eventData = {
                id: gameID,
                card: "Knight"
            }
            SendGameEvent(socket, "UseDevCard", eventData)
            setDevCardUsed(true)
        } else if (cardType === "Monopoly") {
            onOpenMonoply()
        } else if (cardType === "YearOfPlenty") {
            onOpenYOP()
        } else if (cardType == "RoadBuilding") {
            const eventData = {
                id: gameID,
                card: "RoadBuilding"
            }
            SendGameEvent(socket, "UseDevCard", eventData)
            setDevCardUsed(true)
        }
    }

    return (
        <Box className="dev-card-box">
            <Box className="dev-card-slot">
                {
                    knightCards.length > 0 &&
                    <Box className="dev-card">
                        <IconButton
                            icon={
                                <Image
                                    className={!knightCardDisabled ? 'dev-card-choosen-animation' : ''}
                                    src={getDevCardImgSrcByCardType("Knight", gameAssets)!}
                                    alt={getDevCardNameByType("Knight")!}
                                    borderRadius={"2vh"}
                                />
                            }
                            colorScheme={"none"}
                            height={"12vh"}
                            aria-label='use-dev-card'
                            isDisabled={knightCardDisabled}
                            onClick={() => handleCardUse("Knight")}
                        />
                        {
                            knightCards.length > 1 &&
                            <Box className="top-dev-card-number">
                                {knightCards.length}
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Box className="dev-card-slot">
                {
                    yearOfPlenty.length > 0 &&
                    <Box className="dev-card">
                        <IconButton
                            icon={
                                <Image
                                    className={!yopCardDisabled ? 'dev-card-choosen-animation' : ''}
                                    src={getDevCardImgSrcByCardType("YearOfPlenty", gameAssets)!}
                                    alt={getDevCardNameByType("YearOfPlenty")!}
                                    borderRadius={"2vh"}
                                />
                            }
                            colorScheme={"none"}
                            height={"12vh"}
                            aria-label='use-dev-card'
                            isDisabled={yopCardDisabled}
                            onClick={() => handleCardUse("YearOfPlenty")}
                        />
                        {
                            yearOfPlenty.length > 1 &&
                            <Box className="top-dev-card-number">
                                {yearOfPlenty.length}
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Box className="dev-card-slot">
                {
                    monopoly.length > 0 &&
                    <Box className="dev-card">
                        <IconButton
                            icon={
                                <Image
                                    className={!monopolyCardDisabled ? 'dev-card-choosen-animation' : ''}
                                    src={getDevCardImgSrcByCardType("Monopoly", gameAssets)!}
                                    alt={getDevCardNameByType("Monopoly")!}
                                    borderRadius={"2vh"}
                                />
                            }
                            colorScheme={"none"}
                            height={"12vh"}
                            aria-label='use-dev-card'
                            isDisabled={monopolyCardDisabled}
                            onClick={() => handleCardUse("Monopoly")}
                        />
                        {
                            monopoly.length > 1 &&
                            <Box className="top-dev-card-number">
                                {monopoly.length}
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Box className="dev-card-slot">
                {
                    roadBuilding.length > 0 &&
                    <Box className="dev-card">
                        <IconButton
                            icon={
                                <Image
                                    className={!roadBuildingCardDisabled ? 'dev-card-choosen-animation' : ''}
                                    src={getDevCardImgSrcByCardType("RoadBuilding", gameAssets)!}
                                    alt={getDevCardNameByType("RoadBuilding")!}
                                    borderRadius={"2vh"}
                                />
                            }
                            colorScheme={"none"}
                            height={"12vh"}
                            aria-label='use-dev-card'
                            onClick={() => handleCardUse("RoadBuilding")}
                            isDisabled={roadBuildingCardDisabled}

                        />
                        {
                            roadBuilding.length > 1 &&
                            <Box className="top-dev-card-number">
                                {roadBuilding.length}
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Box className="dev-card-slot">
                {
                    victoryPoints.length > 0 &&
                    <Box className="dev-card">
                        <IconButton
                            icon={
                                <Image
                                    className='dev-card-choosen-animation'
                                    src={getDevCardImgSrcByCardType("VictoryPoint", gameAssets)!}
                                    alt={getDevCardNameByType("VictoryPoint")!}
                                    borderRadius={"2vh"}
                                />
                            }
                            colorScheme={"none"}
                            height={"12vh"}
                            aria-label='use-dev-card'
                            _hover={{ cursor: "default" }}
                        />
                        {
                            victoryPoints.length > 1 &&
                            <Box className="top-dev-card-number">
                                {victoryPoints.length}
                            </Box>
                        }
                    </Box>

                }
            </Box>
            <YearOfPlenty
                isOpen={isOpenYOP}
                onClose={onCloseYOP}
                gameID={gameID}
                socket={socket}
                setDevCardUsed={setDevCardUsed}
            />
            <Monoploy
                isOpen={isOpenMonoply}
                onClose={onCloseMonoply}
                gameID={gameID}
                socket={socket}
                setDevCardUsed={setDevCardUsed}
            />
        </Box>
    )
}