import { useEffect, useState } from 'react'
import { Image } from '@chakra-ui/react'
import { useSync } from '../../../context/SyncContext'
import { useGame } from '../../../context/GameContext'

export const YourTurnIndicator = () => {
    const { gameAssets } = useGame()
    const { gameData, myGameData } = useSync()

    const [showIcon, setShowIcon] = useState(false)

    useEffect(() => {
        if (myGameData) {
            const isSetupStage = gameData.TurnNumber === 0 || gameData.TurnNumber === 1
            const isMyTurn = gameData.TurnIndex === myGameData.turn_index

            if (isSetupStage && isMyTurn) {
                setShowIcon(true)
            } else {
                setShowIcon(false)
            }
        } else {
            setShowIcon(false)
        }
    }, [gameData, myGameData])

    if (showIcon) {
        return (
            <Image
                src={gameAssets.yourTurnButton}
                alt='your-turn-indicator'
                pos={"fixed"}
                right={"1%"}
                bottom={"2%"}
                width={"6%"}
                zIndex={1}
            />
        )
    } else {
        return null
    }
}