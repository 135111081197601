import { useEffect, useState } from "react";
import { SocketType } from "../../../utils/types/types"
import { getDevCardImgSrcByCardType } from "../../../utils/assets/getDevCardImgSrcByCardType";
import { useGame } from '../../../context/GameContext';
import { PlaySoundEffect } from "../../../sounds/playSoundEffect";

export const DevCardDrawAnimation = ({ socket }: { socket: SocketType }) => {
    const { sfxVolume, sfxEnabled, gameAssets, soundEffects } = useGame()

    const [card, setCard] = useState<string | null>(null)

    // Handle dev cards updates
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "DevCardDrawn") {
                setCard(null)
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setCard(parsedData.card)

                    if (parsedData.card === "VictoryPoint") {
                        PlaySoundEffect(soundEffects.victoryPointSFX, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "Knight") {
                        PlaySoundEffect(soundEffects.knightSFX, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "Monopoly") {
                        PlaySoundEffect(soundEffects.monopolySFX, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "YearOfPlenty") {
                        PlaySoundEffect(soundEffects.yearOfPlentySFX, sfxVolume, sfxEnabled)
                    } else if (parsedData.card === "RoadBuilding") {
                        PlaySoundEffect(soundEffects.roadBuildingSFX, sfxVolume, sfxEnabled)
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage])

    useEffect(() => {
        if (card) {
            const timeout = setTimeout(() => {
                setCard(null);
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [card]);

    if (card) {
        return (
            <div className="dev-card-animation">
                <img src={getDevCardImgSrcByCardType(card, gameAssets)!} alt="card" />
            </div>
        )
    } else {
        return null
    }
}