import { Button } from "@chakra-ui/react"
import { useGame } from "../../../../context/GameContext"
import { RiCheckFill } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import { SocketType } from "../../../../utils/types/types";
import { useSync } from "../../../../context/SyncContext";
import { SendGameEvent } from "../../../../socket/sendMessage/SendGameEvent";

export const ReadyButton = ({ socket }: { socket: SocketType }) => {
    const { myGameData, players } = useSync()
    const { gameID } = useGame()

    const amIReady = myGameData && players.find((player) => player.address === myGameData.address)

    const handleReady = async () => {
        const eventData = {
            id: gameID,
            ready: !amIReady.readyStatus
        }
        SendGameEvent(socket, "ReadyChange", eventData)
    }

    if (amIReady && myGameData && !myGameData.isOwner) {
        const readyStatus = amIReady.readyStatus

        return (
            <Button
                leftIcon={!readyStatus ? <RiCheckFill /> : <FaTimes />}
                mr={"1vw"}
                ml={"auto"}
                width={"15vw"}
                height={"4vw"}
                fontSize={"1.5vw"}
                colorScheme={!readyStatus ? "green" : "red"}
                aria-label={"ready-button"}
                backgroundColor={!readyStatus ? "#8DC222" : "#E94F4F"}
                borderRadius={"0.5vw"}
                onClick={handleReady}
            >
                {
                    !readyStatus ?
                        "Ready" :
                        "Not Ready"
                }
            </Button>
        )
    }

    return null
}