import { Box } from "@chakra-ui/react"
import { useSync } from "../../../context/SyncContext"

export const PlayerVictoryCards = ({ turnIndex }: { turnIndex: number }) => {
    const { devCards, myGameData } = useSync()

    const myVPCards = devCards.filter((card) => card.Type === "VictoryPoint")

    const showVPCards = myVPCards && myVPCards.length > 0 && myGameData && myGameData.turn_index === turnIndex

    if (showVPCards) {
        return (
            <Box className='victory-point-card-label'>
                + {myVPCards.length}
            </Box>
        )
    } else {
        return null
    }
}