import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Button, useDisclosure, FormControl, FormLabel, Input, ModalFooter, useToast, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, CheckboxGroup, Stack, Checkbox, Box, Radio, RadioGroup, } from '@chakra-ui/react'
import { DashboardCloseButton } from '../../General/DashboardCloseButton'
import { useState } from 'react'
import { CreateAddPlayerToBlacklist } from '../../../../requests/AdminRequests'

export const AddPlayerToBlacklistModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast()

    const [username, setUsername] = useState("")

    const handleAddPlayerToBlacklist = async () => {
        const result = await CreateAddPlayerToBlacklist(username)
        if (result) {
            const responseMessage = result.message

            if (responseMessage === "User blacklisted from tournament") {
                toast({
                    description: "Player is added to Blacklist.",
                    status: "success",
                    duration: 2000
                })
                onClose()
            } else if (responseMessage === "User not found") {
                toast({
                    description: "Player cannot be found.",
                    status: "error",
                    duration: 2000
                })
            }
        }
    }
    return (
        <>
            <Button
                colorScheme='blue'
                mb={3}
                w={"100%"}
                onClick={onOpen}
            >
                Add Player to Blacklist
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#E6BD89"}
                    textColor={"#5B4528"}
                    borderRadius={"25px"}
                >
                    <ModalHeader>Add Player to Blacklist</ModalHeader>
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel htmlFor='usernameInput'>Username</FormLabel>
                            <Input
                                id="usernameInput"
                                placeholder='Username'
                                onChange={(e) => setUsername(e.target.value)}
                                borderColor={"black"}
                                backgroundColor={"#544C42"}
                                textColor={"white"}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            w={"100%"}
                            backgroundColor={"#80A732"}
                            borderWidth={"2px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                            colorScheme='green'
                            isDisabled={username.length < 3}
                            onClick={handleAddPlayerToBlacklist}
                        >
                            Add Player to Blacklist
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}
