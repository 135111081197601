export const releaseWakeLock = async (
  wakeLock: WakeLockSentinel | null,
  setWakeLock: (value: WakeLockSentinel | null) => void
) => {
  if (wakeLock !== null) {
    await wakeLock.release();
    setWakeLock(null);
    console.log("Wake Lock released successfully.");
  }
};
