import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from "../context/SyncContext"

export const VictoryPoints = (socket: SocketType) => {
    const { setPoints } = useSync()

    // Update Victory Poinnts
    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "VictoryPoint") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data);
                if (parsedData) {
                    setPoints(prevState => {
                        const newPoints = [...prevState];
                        newPoints[parsedData.turnIndex] = parsedData.point;
                        return newPoints
                    });
                }
            }
        }
    }, [socket && socket.lastJsonMessage])
}