export const getMyCityPositions = (settlePositions: any[], address: string) => {
  if (settlePositions.length > 0) {
    const myPositions = settlePositions.filter(
      (position) => position.owner === address && position.revenue === 2
    );
    return myPositions;
  }

  return [];
};
