import { Badge, Box, Button, Image, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { TournamentCardDetail } from '../../../Content/Tournament/Card/TournamentCardDetail'
import { CreateTournamentCheckin, CreateTournamentCheckinCancel, CreateTournamentSignup, CreateTournamentSignupCancel } from '../../../../../requests/TournamentRequests'
import { useDashboard } from '../../../../../context/DashboardContext'
import { getDateAndTime } from '../../../../../utils/constants/getDateAndTime'

type TicketCardType = {
    ticketName: string,
    ticketAsset: string,
    tournamentID: number,
    ticketClaimedAt: string
}

export const TicketCard = ({ ticketName, ticketAsset, tournamentID, ticketClaimedAt }: TicketCardType) => {
    const { tournamentList, setTournamentNotif } = useDashboard()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toast = useToast()

    const tournamentData = tournamentList.find((tournament) => tournament.ID === tournamentID)

    const { time, date } = getDateAndTime(ticketClaimedAt)

    const handleRegister = async () => {
        const response = await CreateTournamentSignup(tournamentID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully registered for the tournament.",
                status: "success",
                duration: 5000,
            })
            setTournamentNotif(prev => {
                if (!Array.isArray(prev)) {
                    prev = [];
                }

                const newTournament = {
                    banner_image: tournamentData.BannerImage,
                    name: tournamentData.Name,
                    class: tournamentData.Class,
                    is_closed: tournamentData.IsClosed,
                    checked_in: tournamentData.State === 2 ? true : false,
                    id: tournamentData.ID,
                    max_stage: tournamentData.MaxStage,
                    stage: 1,
                    startTime: tournamentData.StartTime,
                    state: tournamentData.State,
                    game_id: 0,
                    game_start_time: "0001-01-01T00:00:00Z",
                    player_state: tournamentData.State,
                    FreezeTimeEnd: "0001-01-01T00:00:00Z"
                };

                const newArray = [...prev, newTournament];

                newArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

                return newArray;
            });
        }
    }

    const handleCheckin = async () => {
        const response = await CreateTournamentCheckin(tournamentID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-in for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    const handleRegisterCancel = async () => {
        const response = await CreateTournamentSignupCancel(tournamentID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully cancel the registration for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    const handleCheckinCancel = async () => {
        const response = await CreateTournamentCheckinCancel(tournamentID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-out for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minW={"10vw"}
            maxW={"10vw"}
            me={"1vw"}
        >
            <Tooltip
                backgroundColor={"#745833"}
                label={`You can use this ticket to join to the ${ticketName}.`}
            >
                <Image
                    mb={"0.5vw"}
                    src={ticketAsset}
                />
            </Tooltip>
            <Badge
                colorScheme={'blackAlpha'}
                w={"100%"}
                whiteSpace={"wrap"}
                fontSize={"0.75vw"}
                textAlign={"center"}
                mb={"0.5vw"}
                borderRadius={"0.5vw"}
            >
                Claimed at {date} {time}
            </Badge>
            <Button
                width={"10vw"}
                height={"1.75vw"}
                fontSize={"1vw"}
                colorScheme={"green"}
                border={"0.15vw solid #3F7600"}
                boxShadow={"0 0.15vw 0 0 #3F7600"}
                onClick={() => {
                    tournamentData && onOpen()
                }}
            >
                Tournament Details
            </Button>

            {
                tournamentData &&
                <TournamentCardDetail
                    isOpen={isOpen}
                    onClose={onClose}
                    ID={tournamentData.ID}
                    TicketAsset={tournamentData.TicketImage}
                    Capacity={tournamentData.Capacity}
                    IsDoubleElimination={tournamentData.Class === 2}
                    IsChampionship={tournamentData.Class === 4}
                    CurrentPlayers={tournamentData.CurrentPlayers}
                    IsUserSignedUp={tournamentData.IsUserSignedUp}
                    IsPrivate={tournamentData.IsClosed}
                    Name={tournamentData.Name}
                    Prize={tournamentData.Prize}
                    StartTime={tournamentData.StartTime}
                    State={tournamentData.State}
                    CurrentStage={tournamentData.CurrentStage}
                    UserCheckedIn={tournamentData.UserCheckedIn}
                    HaveTicket={tournamentData.ticket_own}
                    handleCheckin={handleCheckin}
                    handleRegister={handleRegister}
                    handleCheckinCancel={handleCheckinCancel}
                    handleRegisterCancel={handleRegisterCancel}
                />
            }
        </Box>
    )
}