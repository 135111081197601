import { useEffect, useState } from 'react'
import { useSync } from '../../../context/SyncContext'
import { getColorNameForPlayerUIByIndex } from '../../../utils/assets/getColorByIndex'
import { useGame } from '../../../context/GameContext'

export const SetupNotification = () => {
    const { gameAssets } = useGame()
    const { gameData, myGameData } = useSync()

    const [showSettle, setShowSettle] = useState(false)
    const [showRoad, setShowRoad] = useState(false)

    useEffect(() => {
        if (myGameData) {
            const myTurnIndex = myGameData.turn_index

            if (gameData.Status === 1 && gameData.TurnIndex === myTurnIndex) {
                setShowSettle(true)
                setShowRoad(false)
            } else if (gameData.Status === 2 && gameData.TurnIndex === myTurnIndex) {
                setShowRoad(true)
                setShowSettle(false)
            } else {
                setShowSettle(false)
                setShowRoad(false)
            }
        } else {
            setShowSettle(false)
            setShowRoad(false)
        }
    }, [gameData, myGameData])

    useEffect(() => {
        if (showSettle) {
            const timeout = setTimeout(() => {
                setShowSettle(false);
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [showSettle]);

    useEffect(() => {
        if (showRoad) {
            const timeout = setTimeout(() => {
                setShowRoad(false);
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [showRoad]);

    if (showSettle) {
        return (
            <div id='setupStageIndicator' className={`${getColorNameForPlayerUIByIndex(myGameData.turn_index)}-game-started-banner`}>
                <img src={gameAssets.hammerIcon} alt='hammer' width={"5%"} className='me-3' />
                Build a Settlement
            </div>
        )
    } else if (showRoad) {
        return (
            <div id='setupStageIndicator' className={`${getColorNameForPlayerUIByIndex(myGameData.turn_index)}-game-started-banner`}>
                <img src={gameAssets.hammerIcon} alt='hammer' width={"5%"} className='me-3' />
                Build a Road
            </div>
        )
    } else {
        return null
    }
}