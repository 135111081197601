import { Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"
import { useSync } from "../../../context/SyncContext"

export const PlayerClockIcon = ({ turnIndex }: { turnIndex: number, }) => {
    const { gameData } = useSync()
    const { gameAssets } = useGame()

    const isMyTurn = gameData.TurnIndex === turnIndex
    const isCorrectStatus = gameData.Status === 1 || gameData.Status === 2 || gameData.Status === 3 || gameData.Status === 4

    if (isMyTurn && isCorrectStatus) {
        return (
            <Image
                src={gameAssets.clockIcon}
                alt="clock-icon"
                className='clock-icon'
            />
        )
    } else {
        return null
    }
}