const API_URL = import.meta.env.VITE_API_URL

export const GetQuests = async () => {
    try {
        const response = await fetch(
            API_URL + `/inventory/completed-quests`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get quests");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting quests:", error);
        return null;
    }
};

export const GetDailyQuest = async () => {
    try {
        const response = await fetch(
            API_URL + `/inventory/daily`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get daily quest");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting daily quest:", error);
        return null;
    }
};

export const GetQuestById = async (questID: number) => {
    try {
        const response = await fetch(
            API_URL + `/inventory/get-quest/${questID}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get quest");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting quest:", error);
        return null;
    }
};

export const GetItemsInInventory = async () => {
    try {
        const response = await fetch(
            API_URL + `/inventory/items`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get items");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting items:", error);
        return null;
    }
};

export const CreateClaimReward = async (questID: number) => {
    try {
        const response = await fetch(
            API_URL + `/inventory/claim-reward`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ questID })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to claim reward.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error claming reward:", error);
        return null;
    }
}

export const CreateOpenChest = async (chest_id: number, key_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/inventory/open-chest`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ chest_id, key_id })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to open chest.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error opening chest:", error);
        return null;
    }
}

export const CreateChangeSkinChoice = async (skin_name: string) => {
    try {
        const response = await fetch(
            API_URL + `/profile/set-skin`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ skin_name })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to change skin choice.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error changing skin choise:", error);
        return null;
    }
}