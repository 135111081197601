import { useNavigate } from "react-router-dom"
import { useGame } from "../../../../context/GameContext"
import { Button, useToast } from "@chakra-ui/react"
import { CreateJoinGame } from "../../../../requests/RoomRequests"

export const JoinButton = ({ roomID, disabled }: { roomID: number, disabled: boolean }) => {
    const { setGameID } = useGame()

    const navigate = useNavigate()

    const toast = useToast()
    const handleJoin = async (id: number) => {
        const response = await CreateJoinGame(id)

        if (response.message && response.message === "success") {
            setGameID(id)
            navigate(`/game`)
        } else if (response.error === "room is full") {
            toast({
                description: "Room is full.",
                status: "error",
                duration: 2000
            })
        } else if (response.error === "user already in another room") {
            toast({
                description: "You are already in another game.",
                status: "error",
                duration: 2000,
            })
        }
    }

    return (
        <Button
            colorScheme='green'
            backgroundColor={"#5ca643"}
            borderWidth={"2px"}
            borderColor={"black"}
            borderRadius={"10px"}
            isDisabled={disabled}
            onClick={() => handleJoin(roomID)}
        >
            Join
        </Button>
    )
}