const API_URL = import.meta.env.VITE_API_URL

export const GetMissionTickets = async () => {
    try {
        const response = await fetch(
            API_URL + `/mission/get-tickets`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get mission tickets");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting mission tickets:", error);
        return null;
    }
};

export const GetMissions = async () => {
    try {
        const response = await fetch(
            API_URL + `/mission/get-missions`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            },
        );

        if (!response.ok) {
            throw new Error("Failed to get missions");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting missions:", error);
        return null;
    }
};

export const CreateMission = async (id: string, event_type: string, required: number, quest_name: string, start_time: string) => {
    try {
        const response = await fetch(
            API_URL + `/mission/register`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ event_type, quest_name, id, required, start_time })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to claim reward.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error claming reward:", error);
        return null;
    }
}

export const DeleteMission = async (missionID: string) => {
    try {
        const response = await fetch(
            API_URL + `/mission/remove`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id: missionID })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to delete the mission.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error deleting the mission:", error);
        return null;
    }
}

export const CreateQuest = async (name: string, bannerImage: string, rewardImage: string, rewardText: string, requiredMissions: string[], rewardItems: string[], expiresAt: string) => {
    try {
        const response = await fetch(
            API_URL + `/mission/add-quest`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ name, bannerImage, rewardImage, rewardText, requiredMissions, rewardItems, expiresAt })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to add quest.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error adding quest:", error);
        return null;
    }
}


export const CreateAssignTicket = async (username: string, ticket_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/ticket/drop`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ username, ticket_id })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to assign ticket.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error assigning ticket:", error);
        return null;
    }
}

export const CreateAddPlayerToBlacklist = async (username: string) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/blacklist/add`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ username })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to add player to blacklist.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error adding player to blacklist:", error);
        return null;
    }
}

export const CreateBanUserFromTournament = async (username: string, game_id: number) => {
    try {
        const response = await fetch(
            API_URL + `/tournament/blacklist/ban`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ username, game_id })
            }
        );

        if (!response.ok) {
            throw new Error("Failed to ban player from tournament.");
        }

        const result = await response.json();

        if (result) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error banning player from tournament:", error);
        return null;
    }
}