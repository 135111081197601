import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../context/GameContext"

type PlayerDevCardsType = {
    devCardNumber: number
}

export const PlayerDevCards = ({ devCardNumber }: PlayerDevCardsType) => {
    const { gameAssets } = useGame()
    return (
        <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
        >
            <Box className='dev-card-number-icon' >
                <Image
                    src={gameAssets.totalDevCardIcon}
                    alt="total-dev-card-icon"
                    pos={"relative"}
                    zIndex={1}
                />
            </Box>

            <Box className='dev-card-number-text'>
                {devCardNumber}
            </Box>
        </Box>
    )
}