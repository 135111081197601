import { Box } from "@chakra-ui/react"
import { FaInfoCircle } from "react-icons/fa"

export const WalletAddress = ({ walletAddress }: { walletAddress: `0x${string}` | undefined }) => {
    if (walletAddress) {
        return (
            <Box display={"flex"} flexDir={"column"} alignItems={"center"} mt={5}>
                <Box textColor={"white"} fontSize={"22px"}>
                    Connected Wallet
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                    backgroundColor={"#534c41"}
                    border={"1px solid #1f1c18"}
                    height={"40px"}
                    width={"400px"}
                    borderRadius={"10px"}
                    p={"5px"}
                >
                    <Box textColor={"white"} fontWeight={"700"}>
                        {walletAddress}
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2} mb={2} textColor={"InfoText"}>
                    <FaInfoCircle className="me-2" fontSize={"25px"} />
                    Transactions will be made through the Connected Wallet.
                </Box>
            </Box>
        )
    } else {
        return null
    }
}