import { useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext'
import { useGame } from '../../../../context/GameContext';
import { Modal, ModalContent, ModalBody, ModalOverlay, Image, Box } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react'
import { GetQuests } from '../../../../requests/DashboardRequests';
import { QuestCard } from './QuestCard';
import { DashboardCloseButton } from '../../General/DashboardCloseButton';
import { useDashboard } from '../../../../context/DashboardContext';

export const QuestModal = ({ isOpen, onOpen, onClose, isDashboard }: { isOpen: boolean, onOpen: () => void, onClose: () => void, isDashboard: boolean }) => {
    const { questList, setQuestList } = useDashboard()
    const { dashboardAssets, isMobile } = useGame()
    const { loggedIn } = useAuth()

    useEffect(() => {
        const fetchQuests = async () => {
            const response = await GetQuests()
            response && setQuestList(response)
        }

        if (loggedIn) {
            fetchQuests()
        }
    }, [])

    const availableQuests = questList.filter(data => !data.is_quest_claimed)
    const availableQuestNumber = availableQuests.length

    const completedQuests = questList.filter(data => data.is_quest_claimed)
    const completedQuestNumber = completedQuests.length

    return (
        <>
            {
                isDashboard &&
                <Box
                    onClick={onOpen}
                    className="quest-game-modal-panel content-button-effects"
                >
                    <Image
                        src={dashboardAssets.questModalIcon}
                        alt='quest-icon'
                        m={"auto"}
                        width={"60%"}
                    />
                    {
                        availableQuestNumber > 0 &&
                        <Box className='red-info-circle'>
                            {availableQuestNumber}
                        </Box>
                    }
                    <Box className="content-button-title-color">
                        Quests
                    </Box>
                </Box>
            }

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#745833"}
                    textColor={"white"}
                    borderRadius={"25px"}
                    maxW={isMobile ? "75%" : "55vw"}
                    height={"90%"}
                >
                    <ModalBody
                        className='scroll-bar-with-margin'
                        backgroundColor={"#EAC28D"}
                        p={0}
                        borderRadius={"25px"}
                        overflowY={"auto"}
                        overflowX={"hidden"}
                    >
                        <DashboardCloseButton onClose={onClose} />

                        <Tabs variant={"soft-rounded"}>
                            <TabList borderRadius={"25px 25px 0px 0px"} backgroundColor={"#745833"} >
                                <Tab
                                    borderRadius={"20px 0px 0px 0px"}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    Available ({availableQuestNumber})
                                </Tab>
                                <Tab
                                    borderRadius={0}
                                    _selected={{ bg: "#53422b" }}
                                    textColor={"#efdebc"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    Completed ({completedQuestNumber})
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel className='quest-container'>
                                    {
                                        availableQuestNumber > 0 ?
                                            availableQuests.map((quest, index) => {
                                                return (
                                                    <QuestCard
                                                        key={index}
                                                        questID={quest.id}
                                                        questTitle={quest.title}
                                                        completedTasks={quest.completed_tasks}
                                                        notCompletedTasks={quest.not_completed_tasks}
                                                        rewardTitle={quest.reward}
                                                        totalTaskNumber={quest.completed_tasks.length + quest.not_completed_tasks.length}
                                                        questBannerAsset={quest.image}
                                                        questRewardAsset={quest.reward_image}
                                                        questDeadline={quest.deadline}
                                                        isQuestClaimed={quest.is_quest_claimed}
                                                        isQuestCompleted={quest.not_completed_tasks.length === 0}
                                                    />
                                                )
                                            }) :
                                            <Text
                                                position={"absolute"}
                                                left={0}
                                                right={0}
                                                fontSize={"25px"}
                                                textColor={"#53422b"}
                                                textAlign={"center"}
                                            >
                                                There is no available quest!
                                            </Text>
                                    }
                                </TabPanel>
                                <TabPanel className='quest-container'>
                                    {
                                        completedQuestNumber > 0 ?
                                            completedQuests.map((quest, index) => {
                                                return (
                                                    <QuestCard
                                                        key={index}
                                                        questID={quest.id}
                                                        questTitle={quest.title}
                                                        completedTasks={quest.completed_tasks}
                                                        notCompletedTasks={quest.not_completed_tasks}
                                                        rewardTitle={quest.reward}
                                                        totalTaskNumber={quest.completed_tasks.length + quest.not_completed_tasks.length}
                                                        questBannerAsset={quest.image}
                                                        questRewardAsset={quest.reward_image}
                                                        questDeadline={quest.deadline}
                                                        isQuestClaimed={quest.is_quest_claimed}
                                                        isQuestCompleted={true}
                                                    />
                                                )
                                            }) :
                                            <Text
                                                position={"absolute"}
                                                left={0}
                                                right={0}
                                                fontSize={"25px"}
                                                textColor={"#53422b"}
                                                textAlign={"center"}
                                            >
                                                There is no completed quest!
                                            </Text>
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    )
}