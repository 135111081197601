export const getDataByElementID = (event: React.MouseEvent<HTMLDivElement>) => {
  const target = event.target as HTMLDivElement;
  const id = target.id;

  const splittedData = id.split("/");
  const type = splittedData[0];

  if (type === "r") {
    const coordStart = splittedData[1].split(",");
    const xStart = Number(coordStart[0]);
    const yStart = Number(coordStart[1]);

    const coordEnd = splittedData[2].split(",");
    const xEnd = Number(coordEnd[0]);
    const yEnd = Number(coordEnd[1]);

    return {
      type: "r",
      xStart: xStart,
      yStart: yStart,
      xEnd: xEnd,
      yEnd: yEnd,
    };
  } else if (type === "h") {
    const coord = splittedData[1].split(",");
    const x = Number(coord[0]);
    const y = Number(coord[1]);

    return {
      type: "h",
      x: x,
      y: y,
    };
  } else if (type === "hex") {
    const id = Number(splittedData[1]);

    return {
      type: "hex",
      id: id,
    };
  }
};
