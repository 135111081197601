import { Box, Button, Text, Input, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { CreateWithdraw } from "../../../../requests/WalletRequests";

type ExchangeType = {
    balance: number,
    setBalance: (value: number) => void,
    walletAddress: `0x${string}` | undefined
}

export const Exchange = ({ balance, setBalance, walletAddress }: ExchangeType) => {
    const [withdrawAmount, setWithdrawAmount] = useState<string>("")

    const toast = useToast()

    const [message, setMessage] = useState("")

    useEffect(() => {
        if (withdrawAmount.length > 0 && Number(withdrawAmount) < 11) {
            setMessage("The minimum withdrawal amount is $11.")
        } else if ((Number(withdrawAmount) > balance)) {
            setMessage("You cannot withdraw more than the amount in your Pioneer Wallet.")
        } else {
            setMessage("")
        }
    }, [withdrawAmount])

    const handleWithdraw = async () => {
        if (withdrawAmount) {
            const response = await CreateWithdraw(Number(withdrawAmount))
            if (response && response.message) {
                toast({
                    description: "Withdraw request sent successfully. You can follow the Withdraw status in the Transaction History section.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })

                setBalance(balance - Number(withdrawAmount))
                setWithdrawAmount("")
            }
        }
    }

    return (
        <Box backgroundColor={"#e9d2b3"} w={"100%"} borderRadius={"10px"} mt={3}>
            <Box backgroundColor={"#685235"} textColor={"#e3cd9e"} borderRadius={"10px 10px 0px 0px"} textAlign={"center"} fontSize={"20px"} p={3}>
                Exchange
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-evenly"} p={5}>
                <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <Text>Withdraw Amount ($)</Text>
                    <Input
                        type="number"
                        placeholder="$"
                        _placeholder={{ color: "white" }}
                        backgroundColor={"#534c41"}
                        border={"1px solid #040303"}
                        textColor={"white"}
                        w={"150px"}
                        me={"125px"}
                        ms={"125px"}
                        min={10}
                        isDisabled={!walletAddress}
                        value={withdrawAmount}
                        onChange={(event) => setWithdrawAmount(event.target.value)}
                    />
                    {
                        message &&
                        <Box textAlign={"center"} textColor={"red"} mt={2}>
                            {message}
                        </Box>
                    }
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} pb={3}>
                <Button
                    backgroundColor={"#7fa631"}
                    colorScheme={"green"}
                    boxShadow={"0px 3px #195a32"}
                    borderRadius={"10px"}
                    isDisabled={Number(withdrawAmount) < 11 || (Number(withdrawAmount) > balance)}
                    onClick={handleWithdraw}
                >
                    Confirm Withdraw
                </Button>
            </Box>
        </Box>
    )
}