import { FaTrophy } from "react-icons/fa";
import { Box, Card, CardBody, Button, useDisclosure, useToast } from '@chakra-ui/react'
import { TournamentCardDetail } from "./TournamentCardDetail";
import { CreateTournamentCancel, CreateTournamentCheckin, CreateTournamentCheckinCancel, CreateTournamentSignup, CreateTournamentSignupCancel } from '../../../../../requests/TournamentRequests'
import { useAuth } from '../../../../../context/AuthContext'
import { useState } from "react";
import { GetQuestById } from "../../../../../requests/DashboardRequests";
import { QuestCardDetail } from "../../Quest/QuestCardDetail";
import { CardName } from "./CardName";
import { CardBanner } from "./CardBanner";
import { CardInfo } from "./CardInfo";
import { CardRegister } from "./CardRegister";
import { CardCheckin } from "./CardCheckin";
import { CardDelete } from "./CardDelete";
import { CardTicketInfo } from "./CardTicketInfo";
import { useDashboard } from "../../../../../context/DashboardContext";
import { CardRemaningTime } from "./CardRemainingTime";

type TournamentCardType = {
    ID: number,
    Capacity: number,
    Class: number,
    CurrentPlayers: number,
    BannerAsset: string,
    TicketAsset: string,
    IsUserSignedUp: boolean,
    IsPrivate: boolean,
    Name: string,
    Prize: number[],
    StartTime: string,
    State: number,
    CurrentStage: number,
    UserCheckedIn: boolean,
    HaveTicket: boolean,
    QuestID: number,
    onCloseTournamentModal: () => void
}

export const TournamentCard = ({
    ID,
    Capacity,
    Class,
    CurrentPlayers,
    BannerAsset,
    TicketAsset,
    IsUserSignedUp,
    IsPrivate,
    Name,
    Prize,
    StartTime,
    State,
    CurrentStage,
    UserCheckedIn,
    HaveTicket,
    QuestID,
    onCloseTournamentModal
}: TournamentCardType) => {
    const { setTournamentNotif } = useDashboard()
    const { isAdmin } = useAuth()

    const { isOpen, onClose, onOpen } = useDisclosure()
    const { isOpen: isOpenQuest, onClose: onCloseQuest, onOpen: onOpenQuest } = useDisclosure()

    const toast = useToast()

    const [questData, setQuestData] = useState<any>(null)

    const IsDoubleElim = Class === 2 || Class === 3
    const IsChampionShip = Class === 4

    const handleQuestDataFetch = async () => {
        const response = await GetQuestById(QuestID)

        if (response) {
            setQuestData(response)
            onOpenQuest()
        }
    }

    const handleRegister = async () => {
        const response = await CreateTournamentSignup(ID)

        if (response && response.message === "success") {
            toast({
                description: "You have successfully registered for the tournament. Press the Overview button to see details.",
                status: "success",
                duration: 5000,
            })
            setTournamentNotif(prev => {
                if (!Array.isArray(prev)) {
                    prev = [];
                }

                const newTournament = {
                    ...response.resp,
                    game_id: 0,
                    player_state: State,
                    checked_in: State === 2 ? true : false,
                    FreezeTimeEnd: "0001-01-01T00:00:00Z"
                };

                const newArray = [...prev, newTournament];

                newArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

                return newArray;
            });
        }
    }

    const handleCheckin = async () => {
        const response = await CreateTournamentCheckin(ID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-in for the tournament.",
                status: "success",
                duration: 5000,
            })
            setTournamentNotif(prevItems =>
                prevItems.map(item =>
                    item.id === ID ? { ...item, checked_in: true, player_state: 2 } : item
                )
            );
            onCloseTournamentModal()
        }
    }

    const handleRegisterCancel = async () => {
        const response = await CreateTournamentSignupCancel(ID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully cancel the registration for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    const handleCheckinCancel = async () => {
        const response = await CreateTournamentCheckinCancel(ID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully checked-out for the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    const handleTournamentDelete = async () => {
        const response = await CreateTournamentCancel(ID)
        if (response && response.message === "success") {
            toast({
                description: "You have successfully cancel the tournament.",
                status: "success",
                duration: 5000,
            })
        }
    }

    return (
        <Card
            backgroundColor={"#96754B"}
            textAlign={"center"}
            borderRadius={"2vw"}
            border={"0.25vw solid black"}
            height={"fit-content"}
            width={"90%"}
            m={"auto"}
            mb={"2vw"}
            mt={"2vw"}
        >
            <CardBody p={0} border={"1vw solid #7e582a"} borderRadius={"2vw"}>
                {/* Tournament Name */}
                <CardName
                    IsPrivate={IsPrivate}
                    Name={Name}
                />

                {/* Tournament Banner - Paid / Free Type - Tournament Time */}
                <CardBanner
                    BannerAsset={BannerAsset}
                    TicketAsset={TicketAsset}
                    StartTime={StartTime}
                    IsPrivate={IsPrivate}
                />

                {/* Tournament Main Information */}
                <CardInfo
                    CurrentPlayers={CurrentPlayers}
                    IsChampionship={IsChampionShip}
                    IsDoubleElim={IsDoubleElim}
                    Prize={Prize}
                />

                {/* Tournament Card Buttons Row 1 */}
                <Box display={"flex"} justifyContent={"space-evenly"} backgroundColor={"#eed6b7"} p={"0.5vw"}>
                    <Button
                        width={"40%"}
                        height={"40%"}
                        fontSize={"1.1vw"}
                        padding={"0.3vw"}
                        backgroundColor={"#db8242"}
                        border={"0.15vw solid #ae5e48"}
                        boxShadow={"0 0.15vw #ae5e48"}
                        colorScheme={"orange"}
                        onClick={onOpen}
                    >
                        <FaTrophy className="me-2" />
                        Overview
                    </Button>

                    <CardRegister
                        State={State}
                        CurrentStage={CurrentStage}
                        IsUserSignedUp={IsUserSignedUp}
                        IsPrivate={IsPrivate}
                        HaveTicket={HaveTicket}
                        handleRegister={handleRegister}
                        handleRegisterCancel={handleRegisterCancel}
                    />
                </Box>

                {/* Tournament Card Buttons Row 2 */}
                <CardCheckin
                    State={State}
                    IsUserSignedUp={IsUserSignedUp}
                    UserCheckedIn={UserCheckedIn}
                    handleCheckin={handleCheckin}
                    handleCheckinCancel={handleCheckinCancel}
                />

                {/* Tournament Delete Button */}
                <CardDelete
                    IsAdmin={isAdmin}
                    State={State}
                    handleTournamentDelete={handleTournamentDelete}
                />

                {/* Tournament Remaining Time */}
                {
                    IsUserSignedUp && (State === 1 || State === 2) &&
                    <CardRemaningTime
                        finishTime={StartTime}
                        isUserCheckedIn={UserCheckedIn}
                        isUserRegistered={IsUserSignedUp}
                        state={State}
                        stage={CurrentStage}
                        isChampionship={IsChampionShip}
                    />
                }

                {/* Player Ticket Info */}
                <CardTicketInfo
                    IsPrivate={IsPrivate}
                    IsUserSignedUp={IsUserSignedUp}
                    HaveTicket={HaveTicket}
                    QuestID={QuestID}
                    handleQuestDataFetch={handleQuestDataFetch}
                />

                {
                    questData &&
                    <QuestCardDetail
                        questBannerAsset={questData.image}
                        questRewardAsset={questData.reward_image}
                        completedTasks={questData.completed_tasks}
                        notCompletedTasks={questData.not_completed_tasks}
                        rewardTitle={questData.reward}
                        setQuest={null}
                        isOpen={isOpenQuest}
                        onClose={onCloseQuest}
                    />
                }

                <TournamentCardDetail
                    isOpen={isOpen}
                    onClose={onClose}
                    ID={ID}
                    TicketAsset={TicketAsset}
                    Capacity={Capacity}
                    IsDoubleElimination={IsDoubleElim}
                    IsChampionship={IsChampionShip}
                    CurrentPlayers={CurrentPlayers}
                    IsUserSignedUp={IsUserSignedUp}
                    IsPrivate={IsPrivate}
                    Name={Name}
                    Prize={Prize}
                    StartTime={StartTime}
                    State={State}
                    CurrentStage={CurrentStage}
                    UserCheckedIn={UserCheckedIn}
                    HaveTicket={HaveTicket}
                    handleCheckin={handleCheckin}
                    handleRegister={handleRegister}
                    handleCheckinCancel={handleCheckinCancel}
                    handleRegisterCancel={handleRegisterCancel}
                />
            </CardBody>
        </Card >
    )
}