import { Box } from "@chakra-ui/react"
import { GetTimeDifference } from "./GetTimeDifference"

export const RegisterState = ({ startTime }: { startTime: string }) => {
    return (
        <Box p={"0.25vw"}>
            Tournament will start in
            <GetTimeDifference finishTime={startTime} />
        </Box>
    )
}