import { Box, Image } from '@chakra-ui/react'
import { useGame } from '../../../../../context/GameContext'
import { getInventoryKeyAsset } from '../../../../../utils/assets/getInventoryKeyAsset'

type KeyCardType = {
    rarity: string,
}

export const KeyCard = ({ rarity }: KeyCardType) => {
    const { dashboardAssets } = useGame()

    return (
        <Box
            h={"100%"}
            minW={"10vw"}
            maxW={"10vw"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            me={"1vw"}
        >
            <Image
                src={getInventoryKeyAsset(rarity, dashboardAssets)}
                alt='chest-key'
            />
        </Box>
    )
}