export const getPlayerUsernameLabel = (turnIndex: number, assets: any) => {
    if (turnIndex === 0) {
        return assets.redNameLabel
    } else if (turnIndex === 1) {
        return assets.blueNameLabel
    } else if (turnIndex === 2) {
        return assets.pinkNameLabel
    } else if (turnIndex === 3) {
        return assets.grayNameLabel
    } else {
        return null
    }
}