import { Button } from "@chakra-ui/react"
import { FaEye } from "react-icons/fa";
import { useGame } from "../../../../context/GameContext";
import { useNavigate } from "react-router-dom";

export const SpectateButton = ({ roomID, State }: { roomID: number, State: number }) => {
    const { setGameID, setIsReconnect, setIsSpectator } = useGame()

    const navigate = useNavigate()

    const handleSpectate = async (roomID: number) => {
        setIsSpectator(true)
        setGameID(roomID)
        setIsReconnect(true)
        navigate(`/spectator`)
    }

    return (
        <Button
            colorScheme='red'
            backgroundColor={"#ce011b"}
            borderWidth={"2px"}
            borderColor={"black"}
            borderRadius={"10px"}
            isDisabled={State !== 1}
            onClick={() => handleSpectate(roomID)}
        >
            <FaEye className="me-2" />
            Spectate
        </Button>
    )
}