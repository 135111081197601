export const getPrevSettleAsset = (turnIndex: number, skinChoice: string) => {
  if (skinChoice === "Default") {
    if (turnIndex === 0) {
      return "default-red-settle-prev";
    } else if (turnIndex === 1) {
      return "default-blue-settle-prev";
    } else if (turnIndex === 2) {
      return "default-pink-settle-prev";
    } else if (turnIndex === 3) {
      return "default-grey-settle-prev";
    } else {
      return null;
    }
  } else if (skinChoice === "Halloween") {
    if (turnIndex === 0) {
      return "halloween-red-settle-prev";
    } else if (turnIndex === 1) {
      return "halloween-blue-settle-prev";
    } else if (turnIndex === 2) {
      return "halloween-pink-settle-prev";
    } else if (turnIndex === 3) {
      return "halloween-grey-settle-prev";
    } else {
      return null;
    }
  } else if (skinChoice === "Viking") {
    if (turnIndex === 0) {
      return "viking-red-settle-prev";
    } else if (turnIndex === 1) {
      return "viking-blue-settle-prev";
    } else if (turnIndex === 2) {
      return "viking-pink-settle-prev";
    } else if (turnIndex === 3) {
      return "viking-grey-settle-prev";
    } else {
      return null;
    }
  }
};
