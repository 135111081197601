import { Box, Image } from "@chakra-ui/react"
import { useGame } from "../../../../../../../context/GameContext"
import { FaCheckCircle, FaClock } from "react-icons/fa"
import { GetTimeDifference } from "../../../Notification/GetTimeDifference"

type SemiFinalType = {
    PlayerState: number,
    FreezeTimeEnd: string | null,
    StartTime: string
}

export const SemiFinal = ({ PlayerState, FreezeTimeEnd, StartTime }: SemiFinalType) => {
    const { gameAssets } = useGame()

    const showClockIcon = PlayerState === 2
    const showCheckIcon = PlayerState === 5

    const isRedBG = PlayerState === 2

    return (
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
            <Box
                backgroundColor={isRedBG ? "#cb3e50" : "#51ea56"}
                borderRadius={"50%"}
                p={"1vw"}
            >
                {
                    showClockIcon && <FaClock />
                }
                {
                    showCheckIcon && <FaCheckCircle />
                }
            </Box>
            <Box
                textColor={"#e6d1a4"}
                textDecoration={showCheckIcon ? "line-through" : undefined}
            >
                Semi Final
            </Box>
            <Image
                src={gameAssets.gameLogDivider}
                alt="game-log-divider"
                width={"50%"}
            />
            {
                PlayerState === 2 &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"}>
                    Get ready! The tournament will begin soon.
                    <GetTimeDifference finishTime={StartTime} />
                </Box>
            }
            {
                PlayerState === 5 && !FreezeTimeEnd &&
                <Box display={"flex"} flexDir={"column"} alignItems={"center"} fontSize={"1vw"} textAlign={"center"}>
                    Stay Focus! Waiting for other matches to end!
                </Box>
            }
            {
                PlayerState === 5 && FreezeTimeEnd &&
                <Box fontSize={"1.25vw"} textColor={"#51ea56"}>
                    You have passed the Semi Final.
                </Box>
            }
        </Box>
    )
}
