import { Box } from "@chakra-ui/react"

export const DotProgress = ({ dotText }: { dotText: string }) => {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            letterSpacing={"0.5vw"}
            ms={"1vw"}
            me={"1vw"}
        >
            {dotText}
        </Box>
    )
}