export const getOrdinalSuffix = (value: number) => {
  // Get the last digit of the number
  let lastDigit = value % 10;
  // Get the last two digits of the number
  let lastTwoDigits = value % 100;

  // Handle special cases for 11, 12, 13
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return value + "th";
  }

  // Determine the suffix based on the last digit
  switch (lastDigit) {
    case 1:
      return value + "st";
    case 2:
      return value + "nd";
    case 3:
      return value + "rd";
    default:
      return value + "th";
  }
};
