import { useEffect, useState } from "react";
import { SocketType } from "../../../utils/types/types";
import { getResIcon } from '../../../utils/assets/getResIcon';
import { useGame } from "../../../context/GameContext";
import { logDivElement, logResourceElement, logTextDivElement, usernameDivElement } from "../../../utils/gameLogs/gameLogs";
import { getUsernameByIndex } from "../../../utils/functions/getUsernameByIndex";
import { getColorByIndex } from "../../../utils/assets/getColorByIndex";
import { useSync } from "../../../context/SyncContext";

export const AddResourceInfo = (resource: any, resourceName: string, textColor: string, childElement: HTMLDivElement, isKnight: boolean, gameAssets: any) => {
    if (resource !== undefined) {
        const woodIcon = document.createElement("img")
        woodIcon.src = getResIcon(resourceName, gameAssets)
        woodIcon.alt = `${resourceName}-icon`
        woodIcon.style.width = "2vw"
        woodIcon.classList.add("me-3")

        const woodText = document.createElement("span")
        woodText.textContent = !isKnight ? textColor === "red" ? `-${resource}` : `+${resource}` : resource
        woodText.style.color = textColor
        woodText.style.fontWeight = "600"
        woodText.classList.add("me-1")

        childElement.appendChild(woodText)
        childElement.appendChild(woodIcon)
    }
}

export const MarketTradeNotifications = (socket: SocketType) => {
    const { players } = useSync()
    const { gameAssets } = useGame()

    const [tradeData, setTradeData] = useState(false)

    // Handle achieved trade
    useEffect(() => {
        if (socket && socket.lastJsonMessage && socket.lastJsonMessage.type === "MarketTrade") {
            const parsedData = JSON.parse(socket.lastJsonMessage.data);
            if (parsedData) {
                setTradeData(true)

                const playerElement = document.getElementById(`player-${parsedData.index}`)

                if (playerElement) {
                    const divElement = document.createElement("div")
                    divElement.classList.add("player-notification")
                    divElement.classList.add("market-trade-notif")

                    const childDivElementOne = document.createElement("div")
                    childDivElementOne.style.display = "flex"
                    childDivElementOne.style.alignItems = "center"
                    childDivElementOne.style.justifyContent = "center"

                    const textElement = document.createElement("span")
                    textElement.textContent = "Bank Trade"

                    const imgElement = document.createElement("img")
                    imgElement.src = gameAssets.bankButton
                    imgElement.alt = "bank-icon"
                    imgElement.style.width = "10%"
                    imgElement.style.marginRight = "0.5vw"

                    childDivElementOne.appendChild(imgElement)
                    childDivElementOne.appendChild(textElement)

                    divElement.appendChild(childDivElementOne)

                    const childDivElementTwo = document.createElement("div")
                    childDivElementTwo.style.display = "flex"
                    childDivElementTwo.style.justifyContent = "center"
                    childDivElementTwo.style.alignItems = "center"

                    AddResourceInfo(parsedData.wanted.wood, "wood", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.brick, "brick", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.sheep, "sheep", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.wheat, "wheat", "#2BD01F", childDivElementTwo, false, gameAssets)
                    AddResourceInfo(parsedData.wanted.ore, "ore", "#2BD01F", childDivElementTwo, false, gameAssets)

                    divElement.appendChild(childDivElementTwo)

                    const childDivElementThree = document.createElement("div")
                    childDivElementThree.style.display = "flex"
                    childDivElementThree.style.justifyContent = "center"
                    childDivElementThree.style.alignItems = "center"

                    AddResourceInfo(parsedData.offer.wood, "wood", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.brick, "brick", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.sheep, "sheep", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.wheat, "wheat", "red", childDivElementThree, false, gameAssets)
                    AddResourceInfo(parsedData.offer.ore, "ore", "red", childDivElementThree, false, gameAssets)

                    divElement.appendChild(childDivElementThree)
                    playerElement?.appendChild(divElement)

                    const gameLogs = document.getElementById("game-logs")
                    if (gameLogs) {
                        const logDiv = logDivElement()

                        const usernameDiv = usernameDivElement(getUsernameByIndex(players, parsedData.index), getColorByIndex(parsedData.index))

                        const trade = document.createElement("img")
                        trade.src = gameAssets.tradeButton
                        trade.alt = "trade-icon"
                        trade.style.height = "150%"
                        trade.style.marginRight = "0.5vw"

                        const bankTextDiv = usernameDivElement("BANK", "-1")

                        logDiv.appendChild(usernameDiv)
                        logDiv.appendChild(trade)
                        logDiv.appendChild(bankTextDiv)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv)
                        }

                        const logDiv2 = logDivElement()

                        const usernameDiv2 = usernameDivElement(getUsernameByIndex(players, parsedData.index), getColorByIndex(parsedData.index))

                        const logText = logTextDivElement("got")

                        logDiv2.appendChild(usernameDiv2)
                        logDiv2.appendChild(logText)

                        logResourceElement(parsedData.wanted.wood, "wood", parsedData.wanted.wood, logDiv2, gameAssets)
                        logResourceElement(parsedData.wanted.brick, "brick", parsedData.wanted.brick, logDiv2, gameAssets)
                        logResourceElement(parsedData.wanted.sheep, "sheep", parsedData.wanted.sheep, logDiv2, gameAssets)
                        logResourceElement(parsedData.wanted.wheat, "wheat", parsedData.wanted.wheat, logDiv2, gameAssets)
                        logResourceElement(parsedData.wanted.ore, "ore", parsedData.wanted.ore, logDiv2, gameAssets)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv2, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv2)
                        }

                        const logDiv3 = logDivElement()

                        const usernameDiv3 = usernameDivElement(getUsernameByIndex(players, parsedData.index), getColorByIndex(parsedData.index))

                        const logText3 = logTextDivElement("gave")

                        logDiv3.appendChild(usernameDiv3)
                        logDiv3.appendChild(logText3)

                        logResourceElement(parsedData.offer.wood, "wood", parsedData.offer.wood, logDiv3, gameAssets)
                        logResourceElement(parsedData.offer.brick, "brick", parsedData.offer.brick, logDiv3, gameAssets)
                        logResourceElement(parsedData.offer.sheep, "sheep", parsedData.offer.sheep, logDiv3, gameAssets)
                        logResourceElement(parsedData.offer.wheat, "wheat", parsedData.offer.wheat, logDiv3, gameAssets)
                        logResourceElement(parsedData.offer.ore, "ore", parsedData.offer.ore, logDiv3, gameAssets)

                        if (gameLogs.firstChild) {
                            gameLogs.insertBefore(logDiv3, gameLogs.firstChild)
                        } else {
                            gameLogs.appendChild(logDiv3)
                        }
                    }
                }
            }
        }
    }, [socket && socket.lastJsonMessage]);

    useEffect(() => {
        if (tradeData) {
            const timeout = setTimeout(() => {
                const element = document.querySelector(".market-trade-notif")
                if (element) {
                    element.remove()
                }
                setTradeData(false);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [tradeData]);
}