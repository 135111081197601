export const getStraightRoadAsset = (
  index: number,
  assets: any,
  skin: string
) => {
  if (skin === "Default") {
    if (index === 0) {
      return assets.defaultStraightRedRoad;
    } else if (index === 1) {
      return assets.defaultStraightBlueRoad;
    } else if (index === 2) {
      return assets.defaultStraightPinkRoad;
    } else if (index === 3) {
      return assets.defaultStraightGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Halloween") {
    if (index === 0) {
      return assets.halloweenStraightRedRoad;
    } else if (index === 1) {
      return assets.halloweenStraightBlueRoad;
    } else if (index === 2) {
      return assets.halloweenStraightPinkRoad;
    } else if (index === 3) {
      return assets.halloweenStraightGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Viking") {
    if (index === 0) {
      return assets.vikingStraightRedRoad;
    } else if (index === 1) {
      return assets.vikingStraightBlueRoad;
    } else if (index === 2) {
      return assets.vikingStraightPinkRoad;
    } else if (index === 3) {
      return assets.vikingStraightGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Air") {
    if (index === 0) {
      return assets.airStraightRedRoad;
    } else if (index === 1) {
      return assets.airStraightBlueRoad;
    } else if (index === 2) {
      return assets.airStraightPinkRoad;
    } else if (index === 3) {
      return assets.airStraightGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Ember") {
    if (index === 0) {
      return assets.emberStraightRedRoad;
    } else if (index === 1) {
      return assets.emberStraightBlueRoad;
    } else if (index === 2) {
      return assets.emberStraightPinkRoad;
    } else if (index === 3) {
      return assets.emberStraightGreyRoad;
    } else {
      return "";
    }
  } else if (skin === "Pagoda") {
    if (index === 0) {
      return assets.pagodaStraightRedRoad;
    } else if (index === 1) {
      return assets.pagodaStraightBlueRoad;
    } else if (index === 2) {
      return assets.pagodaStraightPinkRoad;
    } else if (index === 3) {
      return assets.pagodaStraightGreyRoad;
    } else {
      return "";
    }
  } else {
    return null;
  }
};
