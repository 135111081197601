import { Box, Image } from "@chakra-ui/react"
import { useSync } from "../../../../context/SyncContext"
import { getVPLabel } from "../../../../utils/assets/getVPLabel"
import { useGame } from "../../../../context/GameContext"

export const PlayerVictoryPoint = ({ turnIndex }: { turnIndex: number }) => {
    const { gameAssets } = useGame()
    const { gameData, points } = useSync()

    return (
        <Box display={"flex"} justifyContent={"center"}>
            <Box className={gameData.WinnerIndex === turnIndex ? 'vp-banner-winner-div' : 'vp-banner-loser-div'}>
                <Image
                    src={getVPLabel(turnIndex, gameAssets)}
                    alt='label'
                    w={"100%"}
                />
                <Box className={gameData.WinnerIndex === turnIndex ? 'winner-vp-text' : 'loser-vp-text'}>
                    {points[turnIndex]}
                </Box>
            </Box>
        </Box>
    )
}