import { useEffect, useState } from "react"

function getDeviceType() {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
    }

    return null;
}

export const useDeviceOS = () => {
    const [deviceOS, setDeviceOS] = useState<string | null>(null)

    useEffect(() => {
        setDeviceOS(getDeviceType())
    }, [])

    return deviceOS
}