import skinsData from '../../../../../data/availableSkinData.json';
import { useState } from 'react'
import { Box, Image } from '@chakra-ui/react'
import { SkinFilter } from './SkinFilter'
import { SkinSet } from './SkinSet'
import { useGame } from '../../../../../context/GameContext';
import { InfoMessage } from '../../../General/InfoMessage';

type SkinsType = {
    mySkins: any[],
    defaultSkinChoice: string,
    setDefaultSkinChoice: (value: string) => void
}

const colorWarning = "The color of the skin in the game will change depending on the color you have!"
const setWarning = "Once you collect all the pieces in a set, you can use the skin set."

export const Skins = ({ mySkins, defaultSkinChoice, setDefaultSkinChoice }: SkinsType) => {
    const { dashboardAssets } = useGame()

    const [skinFilter, setSkinFilter] = useState<string>("All")

    const filteredSkins = skinFilter === "All"
        ? skinsData.Skins
        : skinsData.Skins.filter((skin) => skin.chestName === skinFilter);

    return (
        <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            w={"100%"}
        >
            <Image
                src={dashboardAssets.inventorySkinBanner}
                alt='skin-banner'
                w={"100%"}
            />

            <SkinFilter
                skinFilter={skinFilter}
                setSkinFilter={setSkinFilter}
            />
            <InfoMessage message={colorWarning} />
            <InfoMessage message={setWarning} />

            {
                filteredSkins.map((skin, index) => {
                    return (
                        <SkinSet
                            key={index}
                            skinName={skin.skinName}
                            city={mySkins.filter((item) => item.type === "City" && item.name === skin.skinName)}
                            road={mySkins.filter((item) => item.type === "Road" && item.name === skin.skinName)}
                            settlement={mySkins.filter((item) => item.type === "Settlement" && item.name === skin.skinName)}
                            inUsage={skin.skinName === defaultSkinChoice}
                            isAvailable={skin.isAvailable}
                            fromChestName={skin.chestName!}
                            setDefaultSkinChoice={setDefaultSkinChoice}
                        />
                    )
                })
            }
        </Box>
    )
}