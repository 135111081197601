export const getMyRoadPosition = (roadPosition: any[], address: string) => {
  if (roadPosition.length > 0) {
    const myPositions = roadPosition.filter(
      (position) => position.owner === address
    );
    return myPositions;
  }

  return [];
};
