import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export const ProtectedDashboard = () => {
    const { loggedIn } = useAuth()

    if (!loggedIn) {
        return <Navigate to="/" />
    }

    if (loggedIn) {
        return <Outlet />
    }
}