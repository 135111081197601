import { Box, Tooltip, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useGame } from '../../../context/GameContext'

export const MusicManager = () => {
    const { gameAssets, soundEffects } = useGame()

    const [musicDisabled, setMusicDisabled] = useState<boolean>(false)
    const [musicVolume, setMusicVolume] = useState(0.5)

    const musicRef = useRef<any>();

    const [showTooltip, setShowTooltip] = useState(false)

    useEffect(() => {
        if (musicRef.current) {
            musicRef.current.volume = musicVolume;
        }
    }, [musicVolume]);

    useEffect(() => {
        if (localStorage.getItem('audioPaused') === "true") {
            setMusicDisabled(true);
        } else {
            setMusicDisabled(false);
        }
    }, [])

    const handleSoundTrack = () => {
        setMusicDisabled(!musicDisabled);
        if (musicDisabled) {
            localStorage.setItem('audioPaused', "false")
        } else {
            localStorage.setItem('audioPaused', "true")
        }
    };
    return (
        <Box display={"flex"}>
            <Box
                _hover={{
                    cursor: "pointer"
                }}
                width={"3vw"}
                height={"3vw"}
                me={"2.5vw"}
                background={"transparent"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onClick={handleSoundTrack}
            >
                {
                    musicDisabled ?
                        <img src={gameAssets.musicOf} alt='music-button' width={"75%"} /> :
                        <img src={gameAssets.musicOn} alt='music-button' width={"75%"} />
                }
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Box textAlign={"center"} fontSize={"1vw"}>Music Volume</Box>
                <Slider
                    id='musicSlider'
                    value={musicVolume * 100}
                    min={0}
                    max={100}
                    step={5}
                    w={"10vw"}
                    colorScheme='white'
                    isDisabled={musicDisabled}
                    onChange={(v) => setMusicVolume(v / 100)}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <SliderTrack backgroundColor={"#4B3B0A"} border={"0.1vw solid #4B3B0A"} height={"1vw"}>
                        <SliderFilledTrack backgroundColor={"white"} />
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg='#4B3B0A'
                        color='white'
                        placement='top'
                        isOpen={showTooltip}
                        label={`${(musicVolume * 100).toString().slice(0, 4)}%`}
                    >
                        <SliderThumb border={"0.1vw solid #4B3B0A"} height={"1.3vw"} width={"1.3vw"} />
                    </Tooltip>
                </Slider>
            </Box>
            <audio ref={musicRef} autoPlay muted={musicDisabled} loop>
                <source src={soundEffects.gameSoundTrack} type="audio/mp3" />
            </audio>
        </Box>
    )
}