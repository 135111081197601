import { Box, Button, Link } from "@chakra-ui/react"
import { FaDiscord } from "react-icons/fa"

export const JoinDiscord = () => {
    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={"0.5vw"}>
            <Box fontSize={"1vw"} me={"1vw"}>
                You can find players from our friendly discord:
            </Box>
            <Link display={"inline-flex"} href={"https://discord.com/invite/DvgwsgTXdp"} isExternal>
                <Button
                    leftIcon={<FaDiscord />}
                    width={"13vw"}
                    height={"3vh"}
                    fontSize={"1vw"}
                    backgroundColor={"#5865F2"}
                    colorScheme={"blue"}
                >
                    Join Pioneers Discord
                </Button>
            </Link>
        </Box>
    )
}