export const getUserStatsByIndex = (userStats: any[], index: number) => {
  if (userStats && userStats.length > 0) {
    const filtered = userStats.filter((value) => value.turnIndex === index);

    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};
