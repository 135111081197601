import { Box } from '@chakra-ui/react'
import { useSync } from '../../../context/SyncContext'
import { useGame } from '../../../context/GameContext'

export const RemainingCards = () => {
    const { gameAssets } = useGame()
    const { remainingRes } = useSync()

    return (
        <Box className="remaining-cards-box">
            <CardsInRow numberOfCards={remainingRes[0]} imageSrc={gameAssets.woodCard} />
            <CardsInRow numberOfCards={remainingRes[1]} imageSrc={gameAssets.brickCard} />
            <CardsInRow numberOfCards={remainingRes[2]} imageSrc={gameAssets.sheepCard} />
            <CardsInRow numberOfCards={remainingRes[3]} imageSrc={gameAssets.wheatCard} />
            <CardsInRow numberOfCards={remainingRes[4]} imageSrc={gameAssets.oreCard} />
        </Box>
    )
}

const CardsInRow = ({ numberOfCards, imageSrc }: { numberOfCards: number, imageSrc: string }) => {
    if (numberOfCards > 12) {
        return (
            <Box pos={"relative"} display={"grid"} w={"2vw"}>
                <img src={imageSrc} alt='card' className='cards-in-row' />
                <img src={imageSrc} alt='card' className='cards-in-row' style={{ paddingTop: "20%" }} />
                <img src={imageSrc} alt='card' className='cards-in-row' style={{ paddingTop: "40%" }} />
                <Box textColor={"green"} fontWeight={"bolder"} as={"span"} textAlign={"center"} fontSize={"1vw"}>
                    {"> 12"}
                </Box>
            </Box>
        )
    } else if (numberOfCards > 6) {
        return (
            <Box pos={"relative"} display={"grid"} w={"2vw"}>
                <img src={imageSrc} alt='card' className='cards-in-row' />
                <img src={imageSrc} alt='card' className='cards-in-row' style={{ paddingTop: "40%" }} />
                <Box textColor={"orange.700"} fontWeight={"bolder"} as={"span"} textAlign={"center"} fontSize={"1vw"}>{"> 6"}</Box>
            </Box>
        )
    } else if (numberOfCards > 0) {
        return (
            <Box pos={"relative"} display={"grid"} w={"2vw"}>
                <img src={imageSrc} alt='card' className='cards-in-row' style={{ paddingTop: "40%" }} />
                <Box textColor={"red"} fontWeight={"bolder"} as={"span"} textAlign={"center"} fontSize={"1vw"}>{"< 6"}</Box>
            </Box>
        )
    } else if (numberOfCards === 0) {
        return (
            <Box pos={"relative"} display={"grid"} w={"2vw"}>
                <img src={imageSrc} alt='card' className='cards-in-row' style={{ paddingTop: "40%" }} />
                <Box textColor={"red"} fontWeight={"bolder"} as={"span"} textAlign={"center"} fontSize={"1vw"}>{"0"}</Box>
            </Box>
        )
    }
}