import { Avatar, Box, CircularProgress } from "@chakra-ui/react"
import { FaRobot } from "react-icons/fa6";
import { getColorByIndex } from "../../../utils/assets/getColorByIndex"
import { useSync } from "../../../context/SyncContext";
import { TimeDifference } from "../Timer/TimeDifference";
import { useGame } from "../../../context/GameContext";
import { getAvatarByIndex } from "../../../utils/assets/getAvatarByIndex";
import { ChangeRoomLimitButton } from "./WaitingLobbyButtons/ChangeRoomLimitButton";

const playerBackgroundOne = "#e8c190"
const playerBackgroundTwo = "#eccca5"

export const TournamentLobby = () => {
    const { dashboardAssets } = useGame()
    const { players, gameData } = useSync()

    if (gameData.Status === 0) {
        return (
            <Box h={"100vh"} w={"100vw"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box className="lobby-box">
                    <Box className="lobby-box-header">
                        <Box>
                            The game is about to start...
                        </Box>
                        <ChangeRoomLimitButton />
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>
                        {
                            players.length > 0 ?
                                players?.map((player, index) => {
                                    const playerNumber = players.length
                                    return (
                                        <Box
                                            key={index}
                                            pos="relative"
                                            w={playerNumber === 3 ? "23.3vw" : "17.5vw"}
                                            h={"21vw"}
                                            display={"flex"}
                                            flexDir={"column"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            backgroundColor={index % 2 === 0 ? playerBackgroundOne : playerBackgroundTwo}
                                        >
                                            <Avatar
                                                src={getAvatarByIndex(player.turnIndex, dashboardAssets)}
                                                backgroundColor={getColorByIndex(player.turnIndex)}
                                                height={"10vw"}
                                                width={"10vw"}
                                                name={player.name}
                                                alignSelf={"center"}
                                                border={"0.25vw solid #8b795d"}
                                            />

                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                <Box className="lobby-username">
                                                    {player.username}
                                                    {player.isBot && <FaRobot className="ms-2" />}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }) :
                                <Box fontSize={"2vw"} margin={"auto"}>
                                    Loading New World...
                                </Box>
                        }
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"6vw"}>
                        <CircularProgress
                            size={"4vw"}
                            color={"#5B4528"}
                            isIndeterminate
                        >
                            <TimeDifference futureTime={gameData.StartTime} />
                        </CircularProgress>
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return null
    }
}