import { useState, useEffect } from "react"
import { GetBoardConfig } from "../requests/GameRequests"
import { useSync } from "../context/SyncContext"

export const useBoard = (gameID: number) => {
    const { setRobberPosition, gameData } = useSync()

    const [tileResources, setTileResources] = useState([])
    const [tileNumbers, setTileNumbers] = useState([])
    const [dockConfig, setDockConfig] = useState()
    const [desertPosition, setDesertPosition] = useState(-1)

    useEffect(() => {
        if (gameID > -1) {
            const fetchBoard = async () => {
                const data = await GetBoardConfig(gameID)

                if (data) {
                    const decodedString = window.atob(data.board);
                    const boardData = JSON.parse(decodedString);

                    setTileResources(boardData.hexes)
                    setTileNumbers(boardData.numbers)
                    setDockConfig(boardData.docks)
                    setDesertPosition(boardData.robber)

                    if (gameData.Status === 1) {
                        setRobberPosition(boardData.robber)
                    }
                }

            }

            fetchBoard()
        } else {
            setTileResources([])
            setTileNumbers([])
            setDockConfig(undefined)
            setDesertPosition(-1)
        }
    }, [gameID]);


    return { tileResources, tileNumbers, dockConfig, desertPosition }
}