import thiefIcon from '../assets/Robber/Flag_Robber.png'
import { useEffect } from "react"
import { SocketType } from "../utils/types/types"
import { useSync } from '../context/SyncContext'
import { useGame } from '../context/GameContext'
import { logDivElement, logTextDivElement, usernameDivElement } from '../utils/gameLogs/gameLogs'
import { getUsernameByIndex } from '../utils/functions/getUsernameByIndex'
import { getColorByIndex } from '../utils/assets/getColorByIndex'
import { PlaySoundEffect } from '../sounds/playSoundEffect'

export const RobberPosition = (socket: SocketType) => {
    const { sfxEnabled, sfxVolume, soundEffects } = useGame()
    const { setRobberPosition, players, gameData } = useSync()

    useEffect(() => {
        if (socket && socket.lastJsonMessage) {
            if (socket.lastJsonMessage.type === "ThiefMove") {
                const parsedData = JSON.parse(socket.lastJsonMessage.data)
                setRobberPosition(parsedData.position)

                const gameLogs = document.getElementById("game-logs")
                if (gameLogs) {
                    const logDiv = logDivElement()
                    const usernameDiv = usernameDivElement(getUsernameByIndex(players, gameData.TurnIndex), getColorByIndex(gameData.TurnIndex))

                    const logText = logTextDivElement("moved")

                    const thief = document.createElement("img")
                    thief.src = thiefIcon
                    thief.alt = "thief-icon"
                    thief.style.height = "150%"

                    logDiv.appendChild(usernameDiv)
                    logDiv.appendChild(logText)
                    logDiv.appendChild(thief)

                    if (gameLogs.firstChild) {
                        gameLogs.insertBefore(logDiv, gameLogs.firstChild)
                    } else {
                        gameLogs.appendChild(logDiv)
                    }
                }

                PlaySoundEffect(soundEffects.moveThiefSFX, sfxVolume, sfxEnabled)
            }
        }
    }, [socket && socket.lastJsonMessage])
}