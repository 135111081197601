import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react"
import { GetTournamentNotif } from "../../../../../requests/TournamentRequests"
import { Box, IconButton, Image } from "@chakra-ui/react"
import { NotificationStates } from "./NotificationStates"
import { SocketType } from "../../../../../utils/types/types"
import { TournamentNotif } from "../../../../../socket/TournamentNotif"
import { useGame } from "../../../../../context/GameContext"
import { getShorterString } from "../../../../../utils/functions/getShortString";
import { useDashboard } from "../../../../../context/DashboardContext";

export const TournamentNotification = ({ socket }: { socket: SocketType }) => {
    const { tournamentNotif, setTournamentNotif } = useDashboard()
    const { dashboardAssets } = useGame()

    const [selected, setSelected] = useState(0)

    useEffect(() => {
        const fetchTournaments = async () => {
            const response = await GetTournamentNotif()
            response && setTournamentNotif(response)
        }

        fetchTournaments()
    }, [])

    useEffect(() => {
        if (tournamentNotif && tournamentNotif.length < 2) {
            setSelected(0)
        }
    }, [tournamentNotif])

    TournamentNotif(socket)

    const tournament = tournamentNotif.find((data, index) => index === selected)

    if (tournament && tournamentNotif.length > 0) {
        return (
            <Box ml={"auto"} mr={"auto"} w={"100%"} pe={"1vw"} ps={"1vw"}>
                <Box pos={"relative"} textAlign={"center"}>
                    <Image
                        src={tournament.banner_image}
                        alt="banner"
                        w={"100%"}
                        height={"8vw"}
                        objectFit={"cover"}
                        borderRadius={"2vw 2vw 0 0vw"}
                    />
                    <Box className='tournament-notif-name'>
                        {
                            tournament.IsClosed &&
                            <Image
                                src={dashboardAssets.lockIcon}
                                alt='lock-icon'
                                w={"1.75vw"}
                                me={"1vw"}
                            />
                        }
                        {getShorterString(tournament.name, 20)}
                    </Box>
                </Box>

                <Box
                    backgroundColor={tournament.checked_in ? "#266318" : "#845854"}
                    fontSize={"1.25vw"}
                    textAlign={"center"}
                    borderRadius={"0 0 2vw 2vw"}
                >
                    <NotificationStates
                        State={tournament.player_state}
                        Start_Time={tournament.startTime}
                        FreezeTimeEnd={tournament.FreezeTimeEnd}
                        IsUserCheckedIn={tournament.checked_in}
                        TournamentID={tournament.id}
                        GameID={tournament.game_id}
                    />

                    {
                        tournamentNotif.length > 1 &&
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            backgroundColor={"transparent"}
                            pb={"0.5vw"}
                        >
                            <IconButton
                                w={"2vw"}
                                height={"2vw"}
                                backgroundColor={"#d7b281"}
                                colorScheme={"yellow"}
                                aria-label="previous-one"
                                me={2}
                                onClick={() => setSelected((value) => value - 1)}
                                isDisabled={selected === 0}
                                icon={<FaArrowLeft fontSize={"1.5vw"} />}
                            />
                            <IconButton
                                w={"2vw"}
                                height={"2vw"}
                                backgroundColor={"#d7b281"}
                                colorScheme={"yellow"}
                                aria-label="next-one"
                                onClick={() => setSelected((value) => value + 1)}
                                isDisabled={selected + 1 === tournamentNotif.length}
                                icon={<FaArrowRight fontSize={"1.5vw"} />}
                            />
                        </Box>
                    }
                </Box>
            </Box>
        )
    } else {
        return null
    }
}