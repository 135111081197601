import { AiFillSkin } from "react-icons/ai";
import { GiOpenTreasureChest } from "react-icons/gi";
import { FaTicket } from "react-icons/fa6";
import { useEffect, useState } from 'react'
import { Modal, ModalContent, ModalBody, useDisclosure, ModalOverlay, Image, Button } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { GetItemsInInventory } from '../../../../requests/DashboardRequests'
import { DashboardCloseButton } from '../../General/DashboardCloseButton';
import { Chests } from './Chests/Chests';
import { Keys } from './Keys/Keys';
import { Skins } from "./Skins/Skins";
import { Tickets } from "./Tickets/Tickets";
import { useGame } from "../../../../context/GameContext";
import { SocketType } from "../../../../utils/types/types";
import { SendAnalytiActionEvent } from "../../../../socket/sendMessage/SendAnalyticActionEvent";
import { useDashboard } from "../../../../context/DashboardContext";

const fetchItems = async (setItems: React.Dispatch<React.SetStateAction<any[]>>, setDailyTicketNumber: (value: number) => void, setDefaultSkinChoice: (value: string) => void) => {
    const response = await GetItemsInInventory()

    response && response.items && setItems(response.items)
    response && response.number_of_daily_tickets && setDailyTicketNumber(response.number_of_daily_tickets)
    response && response.defaultSkinChoice && setDefaultSkinChoice(response.defaultSkinChoice)
}

export const InventoryModal = ({ socket }: { socket: SocketType }) => {
    const { setDailyTicketNumber } = useDashboard()
    const { dashboardAssets, isMobile } = useGame()

    const [items, setItems] = useState<any[]>([])
    const [defaultSkinChoice, setDefaultSkinChoice] = useState("Default")

    const { isOpen, onClose, onOpen } = useDisclosure({
        onOpen() {
            fetchItems(setItems, setDailyTicketNumber, setDefaultSkinChoice)
            SendAnalytiActionEvent(socket, "InventoryModalOpen")
        },
    })

    useEffect(() => {
        fetchItems(setItems, setDailyTicketNumber, setDefaultSkinChoice)
    }, [])

    const myChests: any[] = []
    const myKeys: any[] = []
    const myTickets: any[] = []
    const mySkins: any[] = []

    items.forEach((item) => {
        if (item.type === "Chest") {
            myChests.push(item)
        } else if (item.type === "Key") {
            myKeys.push(item)
        } else if (item.type === "Ticket") {
            myTickets.push(item)
        } else if (item.type === "Road" || item.type === "Settlement" || item.type === "City") {
            mySkins.push(item)
        }
    })

    return (
        <>
            <Button
                flexDir={"column"}
                width={"7.5vw"}
                height={"7.5vw"}
                fontSize={"1.25vw"}
                borderRadius={"1vw"}
                backgroundColor={"#AC8358"}
                colorScheme={"orange"}
                onClick={onOpen}
            >
                <Image src={dashboardAssets.inventoryIcon} alt="inventory-icon" />
                Inventory
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                motionPreset={"slideInBottom"}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={"#745833"}
                    borderRadius={"25px"}
                    maxW={isMobile ? "95%" : "75%"}
                    height={isMobile ? "100vh" : "90vh"}
                >
                    <DashboardCloseButton onClose={onClose} />
                    <ModalBody
                        className="scroll-bar-with-margin"
                        backgroundColor={"#e9c18d"}
                        borderRadius={"25px"}
                        height={"100%"}
                        overflow={"auto"}
                        p={0}
                    >
                        <Tabs variant={"soft-rounded"}>
                            <TabList borderRadius={"25px 25px 0px 0px"} backgroundColor={"#98794d"} >
                                <Tab
                                    borderRadius={"25px 0px 0px 0px"}
                                    _selected={{ bg: "#53442c" }}
                                    textColor={"#ebdab4"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    <GiOpenTreasureChest className="me-2" />
                                    Treasures
                                </Tab>
                                <Tab
                                    borderRadius={"0px"}
                                    _selected={{ bg: "#53442c" }}
                                    textColor={"#ebdab4"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    <AiFillSkin className='me-2' />
                                    Skins
                                </Tab>
                                <Tab
                                    borderRadius={"0px"}
                                    _selected={{ bg: "#53442c" }}
                                    textColor={"#ebdab4"}
                                    p={"1.5vw"}
                                    fontSize={"2vw"}
                                >
                                    <FaTicket className='me-2' />
                                    Tickets
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Chests
                                        myChests={myChests}
                                        myKeys={myKeys}
                                        setItems={setItems}
                                    />
                                    <Keys
                                        myKeys={myKeys}
                                    />
                                </TabPanel>

                                <TabPanel p={0}>
                                    <Skins
                                        mySkins={mySkins}
                                        defaultSkinChoice={defaultSkinChoice}
                                        setDefaultSkinChoice={setDefaultSkinChoice}
                                    />
                                </TabPanel>

                                <TabPanel>
                                    <Tickets
                                        myTickets={myTickets}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    )
}