import { Box } from "@chakra-ui/react"
import { DailyTournaments } from "./Tournament/DailyTournaments"
import { DailyQuest } from "./Quest/DailyQuest"

export const DailyProgress = () => {
    return (
        <Box
            w={"20vw"}
            height={"100vh"}
            display={"flex"}
            flexDir={"column"}
        >
            <DailyTournaments />
            <DailyQuest />
        </Box>
    )
}